<div class="d-flex flex-row align-items-start justify-content-between mb-3 gap-3">
    <div class="d-flex flex-column align-items-start justify-content-start mb-3 gap-3 me-3">
        <h6 class="mb-2">{{ "SEARCH-PERIOD" | translate }}</h6>
        <app-period-date-filter (dateRangeSelected)="handleFirstDateRangeSelection($event)">
        </app-period-date-filter>
    </div>
    <div class="d-flex flex-column align-items-start justify-content-start mb-3 gap-3">
        <div class="ms-3 mx-2 d-flex align-items-center">
            <input
                    type="checkbox"
                    id="showOld"
                    (change)="toggleCompare()"
                    class="form-check-input ml-1">
            <label for="showOld" class="form-check-label ms-2 ml-3">
                {{ "COMPARE-WITH-OTHER" | translate }}
            </label>
        </div>
        <div *ngIf="comparingMode" class="border-dark mx-2 mt-1">
            <app-period-date-filter (dateRangeSelected)="handleCompareDateRangeSelection($event)">
            </app-period-date-filter>
        </div>
    </div>
</div>
