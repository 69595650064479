import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "environments/environment";
import * as moment from "moment";
import { BehaviorSubject, Observable, of } from "rxjs";
import { jwtDecode } from "jwt-decode";
import { tap } from "rxjs/operators";

interface DecodedToken {
  email: string;
  family_name: string;
  given_name: string;
  realm_access?: {
    roles: string[];
  };
  resource_access?: {
    [key: string]: {
      roles: string[];
    };
  };
  preferred_username?: string;
  name?: string;
}

interface User {
  username: string;
  fullName: string;
  roles: string[];
}

@Injectable()
export class AuthService {

  private currentUserSubject: BehaviorSubject<User | null>;
  public currentUser: Observable<User | null>;
  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User | null>(this.getUserFromToken());
    this.currentUser = this.currentUserSubject.asObservable();
  }

  private getUserFromToken(): User | null {
    const token = localStorage.getItem('id_token');
    if (token) {
      const decodedToken = this.getDecodedToken();
      if (decodedToken) {
        return {
          username: decodedToken.preferred_username || '',
          fullName: decodedToken.name || '',
          roles: this.getRolesFromToken()
        };
      }
    }
    return null;
  }

  // /**
  //  * Logs in the user by making a request to the authentication server.
  //  * @param {string} username - The username of the user.
  //  * @param {string} password - The password of the user.
  //  * @returns {Observable<any>} - An observable containing the server response.
  //  */
  // login(username: string, password: string): Observable<any> {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       "Content-Type": "application/x-www-form-urlencoded",
  //     }),
  //   };

  //   const params = new HttpParams({
  //     fromObject: {
  //       grant_type: "password",
  //       client_id: environment.clientId,
  //       client_secret: environment.clientSecret,
  //       username: username,
  //       password: password,
  //     },
  //   });

  //   return this.http.post(
  //     `${environment.baseUrlAuth}${environment.realmClient}/protocol/openid-connect/token`,
  //     params,
  //     httpOptions
  //   );
  // }

  /**
 * Logs in the user by making a request to the authentication server.
 * @param {string} username - The username of the user.
 * @param {string} password - The password of the user.
 * @returns {Observable<any>} - An observable containing the server response.
 */
login(username: string, password: string): Observable<any> {
  const httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    }),
  };

  const params = new HttpParams({
    fromObject: {
      grant_type: "password",
      client_id: environment.clientId,
      client_secret: environment.clientSecret,
      username: username,
      password: password,
    },
  });

  return this.http.post(
    `${environment.baseUrlAuth}${environment.realmClient}/protocol/openid-connect/token`,
    params,
    httpOptions
  ).pipe(
    tap((response: any) => {
      // After successful login, store tokens or any required data
      localStorage.setItem('token', response.access_token);

      // Mark this as the first login
      localStorage.setItem('firstLogin', 'true');
    })
  );
}
// Method to check if this is the user's first login after a successful login
isFirstLogin(): boolean {
  const firstLogin = localStorage.getItem('firstLogin');
  return firstLogin === 'true';
}

// Method to mark the first login as completed after the redirection
markFirstLoginAsCompleted() {
  localStorage.setItem('firstLogin', 'false');
}



  /**
   * Sets the session with the given authentication result.
   * @param {any} authResult - The authentication result containing tokens and expiration info.
   * @returns {void}
   */
  setSession(authResult: any): void {
    const expiresAt = moment().add(24, 'hours');

    localStorage.setItem("id_token", authResult.access_token);
    localStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()));

    this.currentUserSubject.next(this.getUserFromToken());
  }

  /**
   * Logs out the user by removing the session data and navigating to the login page.
   * @returns {void}
   */
  logout(): void {
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
    localStorage.removeItem("currentUrl");
    localStorage.removeItem("token");
    localStorage.removeItem("previousUrl");
    this.currentUserSubject.next(null);
    this.router.navigateByUrl("/pages/login");
  }

  public get currentUserValue(): User | null {
    return this.currentUserSubject.value;
  }

  /**
   * Checks if the user is currently logged in.
   * @returns {boolean} - True if the user is logged in, false otherwise.
   */
  isLoggedIn(): boolean {
    return moment().isBefore(this.getExpiration());
  }

  /**
   * Checks if the user is currently logged out.
   * @returns {boolean} - True if the user is logged out, false otherwise.
   */
  isLoggedOut(): boolean {
    return !this.isLoggedIn();
  }

  /**
   * Gets the expiration time of the current session.
   * @returns {moment.Moment} - The expiration time as a moment object.
   */
  getExpiration(): moment.Moment {
    const expiration = localStorage.getItem("expires_at");
    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }

  /**
   * Décode le token JWT stocké dans le localStorage.
   * @returns {DecodedToken | null} - Le token décodé.
   */
  getDecodedToken(): DecodedToken | null {
    const token = localStorage.getItem("id_token");
    if (token) {
      try {
        return jwtDecode<DecodedToken>(token);
      } catch (error) {
        console.error("Error decoding token", error);
      }
    }
    return null;
  }

  /**
   * Récupère les rôles à partir du token décodé.
   * @returns {string[]} - Liste des rôles de l'utilisateur.
   */
  getRolesFromToken(): string[] {
    const decodedToken = this.getDecodedToken();
    if (decodedToken) {
      const realmRoles = decodedToken.realm_access?.roles || [];
      const resourceRoles =
        decodedToken.resource_access?.["srtj-client"]?.roles || [];
      return [...realmRoles, ...resourceRoles];
    }
    return [];
  }

  /**
   * Vérifie si l'utilisateur a les permissions requises.
   * @param {string[]} requiredPermissions - Liste des permissions requises.
   * @returns {boolean} - Vrai si l'utilisateur a les permissions requises, faux sinon.
   */
  hasPermission(requiredPermissions: string[]): boolean {
    const userRoles = this.getRolesFromToken();

    const hasPermission = requiredPermissions.some((permission) =>
      userRoles.includes(permission)
    );

    return hasPermission;
  }

  /**
   * Teste le décodage du token et affiche les rôles dans la console. à supprimer lors de push
   */
  testDecodeToken(): void {
    const token = localStorage.getItem("id_token");
    if (token) {
      try {
        const decodedToken = jwtDecode<DecodedToken>(token);

        // Récupérer les rôles à partir du token décodé
        const realmRoles = decodedToken.realm_access?.roles || [];
        const resourceRoles =
          decodedToken.resource_access?.["srtj-client"]?.roles || [];
        const allRoles = [...realmRoles, ...resourceRoles];
      } catch (error) {
        console.error("Erreur lors du décodage du token:", error);
      }
    } else {
      console.log("Aucun token trouvé dans le localStorage.");
    }
  }

  /**
   * Vérifie si l'utilisateur a une permission spécifique pour un module donné.
   * @param {string} module - Le nom du module.
   * @param {string} permission - La permission requise.
   * @returns {Observable<boolean>} - Observable qui émet vrai si l'utilisateur a la permission, faux sinon.
   */
  hasModulePermission(module: string, permission: string): Observable<boolean> {
    const userRoles = this.getRolesFromToken();
    const requiredPermission = `${permission}_${module}`.toLowerCase();
    const hasPermission = userRoles.includes(requiredPermission);
    return of(hasPermission);
  }

  getUsernameFromToken(): string | null {
    const decodedToken = this.getDecodedToken();
    return decodedToken?.preferred_username || null;
  }
  getUserFullNameFromToken(): string | null {
    const decodedToken = this.getDecodedToken();
    return decodedToken?.name || null;
  }
}
