<!-- breadcrumb.component.html -->
<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item">
            <a [routerLink]="breadcrumbs?.parentPath !== null ? breadcrumbs?.parentPath : breadcrumbs?.childPath">
                <i [class]="breadcrumbs?.parentIcon"></i>
            </a>
        </li>
        <li class="breadcrumb-item" *ngIf="breadcrumbs?.parentBreadcrumb">{{ breadcrumbs?.parentBreadcrumb |translate }}
        </li>
        <li class="breadcrumb-item active"
            *ngIf="breadcrumbs?.childBreadcrumb && breadcrumbs?.childBreadcrumb !== breadcrumbs?.parentBreadcrumb">
            {{ breadcrumbs?.childBreadcrumb |translate}}
        </li>
    </ol>
</nav>