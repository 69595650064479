import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ControlValueAccessor} from '@angular/forms';
import {NgbDatepicker, NgbDatepickerI18n, NgbDateStruct, NgbPopover, NgbPopoverConfig, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import {noop} from 'rxjs';
import {AbstractFormElement} from '../abstract-form-elements';
import { DateTimeModel } from 'app/shared/models/date-time.model';
import { NotyService } from 'app/shared/services/noty.service';
import {CustomDatepickerI18nService} from '../../../services/custom-datepicker-i18n.service';


@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18nService }
  ]
})
export class DatepickerComponent extends AbstractFormElement implements ControlValueAccessor, OnInit, OnChanges, AfterViewInit {
  @Input() dateString: string;
  @Input() minDate: Date;

  @Input() inputDateFormat = 'dd/MM/yyyy';

  @ViewChild('popover') popover: NgbPopover;

  selectedDate: NgbDateStruct;

  private onTouched: () => void = noop;
  private onChange: (_: any) => void = noop;

  constructor(private popoverConfig: NgbPopoverConfig) {
    super();
    popoverConfig.autoClose = false;
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    if (this.popover) {
      // Prevent popover from closing when clicking inside it
      const popoverElement = document.querySelector('ngb-popover-window');
      if (popoverElement) {
        popoverElement.addEventListener('click', (event) => {
          event.stopPropagation();
        });
      }
    }
  }

  writeValue(newModel: string) {
    if (newModel) {
      const date = new Date(newModel);
      this.selectedDate = {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
      };
      this.dateString = newModel;
    } else {
      this.selectedDate = null;
      this.dateString = '';
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onInputChange($event: any) {
    const value = $event.target.value;
    
    try {
      const parsedDate = new Date(value);
      if (!isNaN(parsedDate.getTime())) {
        this.selectedDate = {
          year: parsedDate.getFullYear(),
          month: parsedDate.getMonth() + 1,
          day: parsedDate.getDate()
        };
        this.dateString = parsedDate.toISOString().split('T')[0];
        this.onChange(this.dateString);
      } else if (value.trim() === '') {
        this.selectedDate = null;
        this.dateString = '';
        this.onChange(this.dateString);
      }
    } catch (error) {
      // Handle invalid date input
      this.onChange(value);
    }
  }

  onDateChange($event: NgbDateStruct) {
    if ($event) {
      this.selectedDate = $event;
      const date = new Date($event.year, $event.month - 1, $event.day);
      this.dateString = date.toISOString().split('T')[0];
      this.onChange(this.dateString);
      this.ngModelChange.emit(this.dateString);
      this.popover.close(); // Close popover after selection
    }
  }

  inputBlur() {
    this.onTouched();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.control && changes.control.currentValue && changes.control.currentValue.value) {
      const date = new Date(changes.control.currentValue.value);
      this.selectedDate = {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
      };
      this.dateString = date.toISOString().split('T')[0];
    }
  }

  getToday(): NgbDateStruct {
    const date = new Date();
    return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
  }

  getMinDate(): NgbDateStruct {
    const currentDate = new Date();
    const minDate = new Date(currentDate.getFullYear() - 100, currentDate.getMonth(), currentDate.getDate());
    
    return { year: minDate.getFullYear(), month: minDate.getMonth() + 1, day: minDate.getDate() };
  }
}
