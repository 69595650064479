import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {
  transform(items: any[], searchQuery: string): any[] {
    if (!items || !searchQuery) {
      return items;
    }

    searchQuery = searchQuery.toLowerCase();

    const isMatch = items.some(item =>
        item?.toString().toLowerCase().includes(searchQuery)
    );

    if (isMatch) {
      return items;
    } else {
      return [];
    }
  }
}

