import { AuthService } from "app/shared/auth/auth.service";
import { Directive, ElementRef, Input, Renderer2, OnInit } from "@angular/core";

@Directive({
  selector: "[appHasPermission]",
})
export class HasPermissionDirective implements OnInit {
  @Input("appHasPermission") requiredPermissions: string[] = [];

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private authService: AuthService
  ) {}

  ngOnInit() {
    const hasPermission = this.authService.hasPermission(
      this.requiredPermissions
    );

    if (this.el.nativeElement && this.el.nativeElement.style) {
      if (!hasPermission) {
        this.renderer.setStyle(this.el.nativeElement, "display", "none");
      } else {
        this.renderer.setStyle(this.el.nativeElement, "display", "block");
      }
    } else {
      console.error(
        "El.nativeElement does not support styles:",
        this.el.nativeElement
      );
    }
  }
}
