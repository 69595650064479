<div class="modal-dialog-centered">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">{{ 'MODAL_TITLE' | translate }}</h4>
      <img src="/assets/img/map.png" alt="Vehicle Map" class="vehicle-map"
        ngbTooltip="{{'View_Vehicle_Position'|translate}}" placement="left" *ngIf="this.type =='update'"
        (click)="openMap()">
    </div>
    <div class="modal-body" [formGroup]="formGroup">
      <div class="form-row">
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label for="date">{{ 'DATE_LABEL' | translate }}</label>
            <input type="datetime-local" class="form-control" id="date" formControlName="requestDate" />
            <div *ngIf="formGroup.get('requestDate').invalid && notValid" class="text-danger">
              {{ 'DATE_REQUIRED' | translate }}
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label for="applicant">{{ 'APPLICANT_LABEL' | translate }}</label>
            <input type="text" class="form-control" formControlName="applicant" readonly>
          </div>
        </div>
      </div>
      <hr>
      <div class="form-row">
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label for="vehicleType">{{ 'VEHICLE_TYPE_LABEL' | translate }}</label>
            <ng-select [items]="vehicleType" bindLabel="name" bindValue="id" formControlName="vehicleTypeId"
              [placeholder]="'VEHICLE_TYPE_LABEL' | translate" [searchable]="true"
              (change)="onVehicleTypeChange($event)">
            </ng-select>
            <div *ngIf="formGroup.get('vehicleTypeId').invalid && notValid" class="text-danger">
              {{ 'VEHICLE_TYPE_REQUIRED' | translate }}
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label for="vehicle">{{ 'VEHICLE_LABEL' | translate }}</label>
            <ng-select [items]="vehicles" bindLabel="name" bindValue="id" formControlName="vehiclesId"
              [placeholder]="'VEHICLE_LABEL' | translate" [searchable]="true" [disabled]="true">
            </ng-select>
            <div *ngIf="formGroup.get('vehiclesId').invalid && notValid" class="text-danger">
              {{ 'VEHICLE_REQUIRED' | translate }}
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label for="applicant">{{ 'REQUEST_TYPE' | translate }}</label>
            <div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" id="intervention" formControlName="requestType"
                  value="Intervention">
                <label class="form-check-label" for="intervention">{{ 'REPAIR_REQUEST' | translate }}</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" id="maintenance" formControlName="requestType"
                  value="Maintenance">
                <label class="form-check-label" for="maintenance">{{ 'MAINTENANCE_REQUEST' | translate }}</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" id="other" formControlName="requestType" value="Other">
                <label class="form-check-label" for="other">{{ 'OTHER_REQUEST' | translate }}</label>
              </div>
              <div *ngIf="formGroup.get('requestType').invalid && notValid" class="text-danger">
                {{ 'ACTION_TYPE_REQUIRED' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group mb-2">
            <label for="applicant">{{ 'REQUEST_LEVEL' | translate }}</label>

            <select class="form-control" id="urgency" formControlName="urgency">
              <option value=null disabled selected hidden>{{ 'URGENCY' | translate }}</option>
              <option [value]="1">{{"HIGH"|translate}}</option>
              <option [value]="2">{{"MEDIUM"|translate}}</option>
              <option [value]="3">{{"LOW"|translate}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-12 col-12">
          <div class="form-group mb-2">
            <label for="description" class="col-form-label">{{ 'DESCRIPTION_LABEL' | translate }}</label>
          </div>
          <textarea class="form-control" id="description" formControlName="description" rows="3"></textarea>
          <div *ngIf="formGroup.get('description').invalid && notValid" class="text-danger">
            {{ 'DESCRIPTION_REQUIRED' | translate }}
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-12">
          <div class="form-group mb-2" [ngClass]="{'disabled': formGroup.get('state').value > 1}">
            <label for="attachment" class="col-form-label">{{ 'ATTACHMENT_LABEL' | translate }} <span
                style="font-size: 1em; font-weight: 400;">(png/jpeg/jpg)</span>
              <div class="text-muted small mt-1">
                {{ 'MAX_FILE_UPLOAD_INFO' | translate: {
                maxFiles: maxImageUploadLimit,
                maxTotalSize: maxTotalImageSizeMB,
                maxSingleSize: maxSingleImageSizeMB
                } }}
              </div>
            </label>
            <span *ngIf="selectedFiles.length !==0"> ({{ selectedFiles.length }}/{{ maxImageUploadLimit }})</span>
            <div class="file">
              <label class="file-input uploadfilecontainer" for="attachment">
                <input type="file" (change)="onFileSelected($event)" multiple id="attachment" name="attachment"
                  style="display: none;" />
                <div *ngIf="isError" class="text-danger ml-2">{{ errorMessage }}</div>
              </label>
              <div id="selected-files">
                <ul class="file-list">
                  <li *ngFor="let fileControl of formGroup.get('files').value; let i = index" class="file">
                    <img [src]="fileControl" alt="" class="file-image" (click)="openImageModal(fileControl)">
                    <em class="fa-solid fa-circle-xmark cross" (click)="removeFile(i)"></em>
                  </li>

                </ul>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="form-row">
        <div class="col-md-12" [appHasPermission]="['add_appointment']">
          <div class="form-group row align-items-center" *ngIf="formGroup.get('state').value >= 2">
            <div class="col-lg-3 col-3">
              <label for="appointment" class="col-form-label">{{ 'APPOINTMENT' | translate }}</label>
            </div>
            <div class="col-lg-9 col-9">
              <input type="date" id="appointment" formControlName="appointment" class="form-control">
            </div>
          </div>
        </div>
      </div>
      <div class="form-row" *ngIf="formGroup.get('state').value >= 2">
        <div class="col-md-12">
          <div class="form-check" [appHasPermission]="['add_appointment']">
            <input type="checkbox" id="enPanne" class="form-check-input" [(ngModel)]="enPanne"
              [ngModelOptions]="{standalone: true}" />
            <label class="form-check-label" for="enPanne">{{ 'EN_PANNE_LABEL' | translate }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer gap d-flex flex-wrap w-100">
      <button type="button" class="btn btn-primary" (click)="submitForm()">
        {{ (type === 'add' ? 'CREATE_BTN' : 'UPDATE_BTN') | translate }}
      </button>

      <!-- New buttons for Validate and Reject (only visible when state is 1) -->
      <ng-container *ngIf="formGroup.get('state').value === 1 && type == 'update'">
        <button type="button" class="btn btn-success" (click)="validateWorkRequest()"
          [appHasPermission]="['validate_reject']">
          {{ 'VALIDATE_BTN' | translate }}
        </button>
        <button type="button" class="btn btn-danger" (click)="rejectWorkRequest()"
          [appHasPermission]="['validate_reject']">
          {{ 'REJECT_BTN' | translate }}
        </button>
      </ng-container>

      <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('cancel')">
        {{ 'CANCEL_BTN' | translate }}
      </button>
    </div>
  </div>
</div>