import { HttpClient, HttpParams } from "@angular/common/http";
import { DecimalPipe } from "@angular/common";
import { Injectable, PipeTransform } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { debounceTime, delay, switchMap, tap, map } from "rxjs/operators";
import { SortDirection } from "app/shared/directives/sortable.directive";
import { BASE_URL_FLEET, M_BASE_URL } from "app/shared/global/var";
import { ScheduledTasks } from "../models/scheduled-tasks";

interface SearchResult {
  scheduledTasks: ScheduledTasks[];
  total: number;
}

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: string;
  sortDirection: SortDirection;
}

function compare(v1, v2) {
  return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
}

function sort(
  scheduledTasks: ScheduledTasks[],
  column: string,
  direction: string
): ScheduledTasks[] {
  if (direction === "") {
    return scheduledTasks;
  } else {
    return [...scheduledTasks].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === "asc" ? res : -res;
    });
  }
}

function matches(
  scheduledTasks: ScheduledTasks,
  term: string,
  pipe: PipeTransform
) {
  const lowercaseTerm = term.toLowerCase();

  return (
    (scheduledTasks.taskType?.name?.toLowerCase().includes(lowercaseTerm) ??
      false) ||
    (scheduledTasks.maintenanceType?.name
      ?.toLowerCase()
      .includes(lowercaseTerm) ??
      false) ||
    (scheduledTasks.label?.toLowerCase().includes(lowercaseTerm) ?? false) ||
    (scheduledTasks.vehicleType?.name?.toLowerCase().includes(lowercaseTerm) ??
      false) || // ensure vehicleType.name is valid
    (scheduledTasks.vehicle?.vehicleNumber
      ?.toLowerCase()
      .includes(lowercaseTerm) ??
      false) || // ensure vehicle registration is valid
    (pipe?.transform(scheduledTasks.frequency)?.includes(lowercaseTerm) ??
      false) ||
    (pipe
      ?.transform(scheduledTasks.frequencyMileage)
      ?.includes(lowercaseTerm) ??
      false) ||
    (pipe?.transform(scheduledTasks.notice)?.includes(lowercaseTerm) ??
      false) ||
    (pipe?.transform(scheduledTasks.lastExecKm)?.includes(lowercaseTerm) ??
      false)
  );
}

@Injectable({
  providedIn: "root",
})
export class ScheduledTasksService {
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _scheduledTasksList$ = new BehaviorSubject<ScheduledTasks[]>([]);
  private _total$ = new BehaviorSubject<number>(0);

  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: "",
    sortColumn: "",
    sortDirection: "",
  };

  constructor(private http: HttpClient, private pipe: DecimalPipe) {
    this._search$
      .pipe(
        tap(() => this._loading$.next(true)),
        debounceTime(200),
        switchMap(() => this._search()),
        delay(200),
        tap(() => this._loading$.next(false))
      )
      .subscribe((result) => {
        this._scheduledTasksList$.next(result.scheduledTasks);
        this._total$.next(result.total);
      });

    this._search$.next();
  }

  get scheduledTasksList$() {
    return this._scheduledTasksList$.asObservable();
  }
  get total$() {
    return this._total$.asObservable();
  }
  get loading$() {
    return this._loading$.asObservable();
  }
  get page() {
    return this._state.page;
  }
  get pageSize() {
    return this._state.pageSize;
  }
  get searchTerm() {
    return this._state.searchTerm;
  }

  set page(page: number) {
    this._set({ page });
  }
  set pageSize(pageSize: number) {
    this._set({ pageSize });
  }
  set searchTerm(searchTerm: string) {
    this._set({ searchTerm });
  }
  set sortColumn(sortColumn: string) {
    this._set({ sortColumn });
  }
  set sortDirection(sortDirection: SortDirection) {
    this._set({ sortDirection });
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  public _search(): Observable<SearchResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } =
      this._state;

    return this.fetchScheduledTasks(
      sortColumn,
      sortDirection,
      page,
      pageSize
    ).pipe(
      map((result: any) => {
        let scheduledTasks = result.data;
        const total = result.count;

        scheduledTasks = scheduledTasks.filter((task) =>
          matches(task, searchTerm, this.pipe)
        );
        scheduledTasks = sort(scheduledTasks, sortColumn, sortDirection);

        return { scheduledTasks, total };
      })
    );
  }

  fetchScheduledTasks(
    sortColumn: string,
    sortDirection: string,
    page: number,
    pageSize: number
  ): Observable<any> {
    const apiUrl = `${M_BASE_URL}/scheduled-tasks`;
    let params = new HttpParams()
      .set("column", sortColumn)
      .set("order", sortDirection)
      .set("page", page.toString())
      .set("perPage", pageSize.toString());

    return this.http.get<any>(apiUrl, { params });
  }

  remindTomorrow(taskId): Observable<ScheduledTasks> {
    const url = `${M_BASE_URL}/scheduled-tasks/${taskId}/remind-tomorrow`;
    return this.http.put<ScheduledTasks>(url, {}).pipe(
      tap(() => {
        this._search$.next();
      })
    );
  }
  getTaskByLabel(label: string): Observable<ScheduledTasks> {
    const url = `${M_BASE_URL}/scheduled-tasks/tasks/label/${label}`;
    return this.http.get<ScheduledTasks>(url);
  }

  getVehicleByRegistration(registration: string): Observable<any> {
    const url = `${BASE_URL_FLEET}/vehicles/by-registration/${registration}`;
    return this.http.get<ScheduledTasks>(url);
  }
}
