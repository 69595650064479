import { Component, OnInit, OnDestroy, AfterViewInit } from "@angular/core";
import {Subject, Subscription} from 'rxjs';
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import {filter, takeUntil} from 'rxjs/operators';
import { CrudService } from "./shared/services/crud.service";
import { Title } from "@angular/platform-browser";
import { OPN_BASE_URL } from "./shared/global/var";
import { AuthService } from "./shared/auth/auth.service";
import {CompanySettingsService} from './modules/company-settings/services/company-settings.service';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  private destroy$ = new Subject<void>();
  subscription: Subscription;
  profileConfig: any;
  acronym: string;
  logoUrl: string;

  constructor(
    private router: Router,
    private crudService: CrudService,
    private titleService: Title,
    private settingsService: CompanySettingsService,
  ) {}

    ngOnInit() {
        // Handle route changes
        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            takeUntil(this.destroy$)
        ).subscribe(() => {
            window.scrollTo(0, 0);
            this.updateTitle(this.router.url.split('?')[0]);
        });

        // Subscribe to company settings
        this.settingsService.profileConfig$.pipe(
            takeUntil(this.destroy$)
        ).subscribe(config => {
            if (config) {
                this.profileConfig = config;
                this.acronym = config.acronym || '';
                this.logoUrl = config.logo || '/assets/img/bus-svgrepo-com.png';
                this.updateFavicon(this.logoUrl);
                this.updateTitle(this.router.url.split('?')[0]);
            }
        });

        // Initial load of company settings
        this.settingsService.getAllCompanySettings().subscribe();
    }

  ngAfterViewInit() {}

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private updateFavicon(logoUrl: string) {
        const favicon: HTMLLinkElement | null = document.querySelector('#app-favicon');
        if (favicon) {
            favicon.href = logoUrl;
        }
    }

    private updateTitle(currentPageTitle: string) {
        currentPageTitle = currentPageTitle
            .replace('/', '')
            .replace('pages/', '')
            .replace(/-/g, ' ')
            .replace(/\b\w/g, l => l.toUpperCase());

        this.titleService.setTitle(`${currentPageTitle} - ${this.acronym}`);
    }
}
