<div class="modal-header d-flex flex-row" *ngIf="createItinerary">
    <form [formGroup]="itineraryForm" class="d-flex flex-row align-items-start mx-3 justify-content-between">
        <div class="input-group w-75">
            <input
            type="text"
             formControlName="itineraryLib"
            class="form-control"
            placeholder="{{ 'NAME' | translate }}"
            aria-label="Itinerary Name"
            aria-describedby="itinerary-name-input"
            >
        </div>
        <button [disabled]="itineraryForm.invalid || stations.length < 2"
                (click)="saveItinerary()"
                type="submit" class="btn btn-primary mx-5">
            {{ 'CREATE' | translate }}
        </button>
    </form>

    <button class="ms-auto"
            aria-label="Close"
            (click)="closeModal()">&times;</button>
</div>

<div class="modal-body">
    <div class="bg-light border rounded p-2 mb-4 mt-3">
        <div class="row text-center fw-bold">
            <span class="col-1">{{ 'Order' | translate }}</span>
            <span class="col-3">{{ 'Station' | translate }}</span>
            <span class="col-2">{{ 'Distance (prec)' | translate }}</span>
            <span class="col-2">{{ 'Duration' | translate }}</span>
            <span class="col-2">{{ 'Total Duration' | translate }}</span>
            <span *ngIf="createItinerary && showRemove" class="col-1">{{ 'REMOVE' | translate }}</span>
        </div>
    </div>

    <div *ngFor="let station of stations; let i = index" class="station-item">
        <div [ngClass]="station.status === 3 ? 'bg-light-danger bg-opacity-10' : 'bg-light border rounded'" class="p-3 mb-3">
            <div class="row text-center align-items-center">
                <span class="col-1 fw-semibold">{{ i + 1 }}</span>
                <span class="col-3">{{ station.station.name }}</span>
                <span class="col-2">{{ station.distance }}</span>
                <span class="col-2">{{ station.duration }}</span>
                <span class="col-2">{{ getDuration(station, stations) }}</span>
                <span *ngIf="createItinerary && station.status === 3" class="col-1">
                    <button class="btn btn-danger btn-sm rounded-circle" (click)="removeStation(station)" aria-label="Remove">&times;</button>
                </span>
            </div>
        </div>
    </div>
</div>

<div class="leaflet-map mt-4" style="width: 100%; height: 300px;"></div>
