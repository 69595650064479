<div class="modal-header">
  <h4 class="modal-title">
    {{ "ADD_PERMISSION_TO" | translate }} "{{ resource }}"
  </h4>
  <button type="button" class="close" aria-label="close" (click)="activeModal.dismiss()">
    <i class="fa-solid fa-xmark"></i>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="permissionForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label for="roleName">{{ "PERMISSION_NAME" | translate }} *</label>
      <input type="text" class="form-control" id="roleName" formControlName="roleName">
    </div>
    <div class="form-group">
      <label for="roleCode">{{ "CODE" | translate }}</label>
      <input type="text" class="form-control" id="roleCode" formControlName="roleCode" readonly>
    </div>
    <div class="form-group">
      <label for="roleRoute">{{ "API_ROUTE" | translate }}</label>
      <input type="text" class="form-control" id="roleRoute" formControlName="roleRoute" readonly>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">
        {{ "CANCEL" | translate }}
      </button>
      <button type="submit" class="btn btn-success">
        {{ "ADD" | translate }}
      </button>
    </div>
  </form>
</div>