import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-submit-button',
  templateUrl: './submit-button.component.html',
  styleUrls: ['./submit-button.component.scss']
})
export class SubmitButtonComponent implements OnInit {

  @Input() modelId: any;
  @Input() disabled = false;
  @Output() onClick = new EventEmitter()

  constructor() {
  }

  ngOnInit(): void {
  }

}
