

<div class="modal-header">
    <h4 class="modal-title">{{"DELETE_TRIP_MESSAGE" | translate}} {{trip.code}}  &hellip;?</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
      <i class="fa-solid fa-xmark"></i>
    </button>
</div>
<div class="mt-3" style="overflow-x: hidden;">

  <div  class=" row mb-2" *ngIf="associatedTrip">
    <p  class="col-6 ml-3" >{{"DELETE_ASSOCIATED_TRIP_MESSAGE" | translate}}{{associatedTrip.code}}!</p>
    <div  class="col-4">
  
  
</div>
</div>
<div  class=" row mb-2" *ngIf="!associatedTrip">
    <p  class="col-6 ml-3" >{{"NO_ASSOCIATED_TRIP_MESSAGE" | translate}}</p>
    <div  class="col-4">
  
</div>
</div>

</div>
<div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="deleteItem(trip.id)">{{"DELETE" | translate}}</button>
      <button *ngIf="associatedTrip" type="button" class="btn btn-danger" (click)="deleteSelectedTrips()">{{"DELETE_BOTH" | translate}}</button>
       <button type="button" class="btn btn-secondary" (click)="activeModal.close('Close click')">{{"CLOSE" | translate}}</button>
</div>