import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ItinerayMapComponent } from "app/shared/components/itineray-map/itineray-map.component";
import { OPN_BASE_URL } from "app/shared/global/var";
import { CrudService } from "app/shared/services/crud.service";
import { NotyService } from "app/shared/services/noty.service";
import { DragulaService } from "ng2-dragula";
import { forkJoin, of, Subscription } from "rxjs";
import { switchMap } from "rxjs/operators";
import L from 'leaflet';

interface Station {
  id: number;
  name: string;
  type: string;
  lat: string;
  lon: string;
  status: number;
  order?: number;
  distance?: number;
  duration?: number;
  stoppingTime?: number;
}

@Component({
  selector: "app-itinerary-form",
  templateUrl: "./itinerary-form.component.html",
  styleUrls: ["./itinerary-form.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ItineraryFormComponent implements OnInit {
  submitted: boolean = false;
  itineraryForm: FormGroup;
  selectedIndex: number | null = null;
  group1: Station[] = [];
  stations = [];

  shape = [];

  subs: Subscription[] = [];

  showSelectList: boolean = false;
  selectedStation: string | null = null;
  @Input() routeId: number;
  @Input() itinerariesLength: number;
  @ViewChild(ItinerayMapComponent) mapComponent: ItinerayMapComponent;
  @Input() itemId: string;
  @Output() listUpdated: EventEmitter<void> = new EventEmitter<void>();
  dataLength: any;
  generateReturnItinerary: boolean = false;

  shapeCalculated: any;
  returnShapeCalculated: any;
  returnItineraryId: any;

  /**
   * Constructor for ItineraryFormComponent
   * @param dragulaService Service for handling drag-and-drop functionality
   * @param activeModal Service for handling active modal instance
   * @param crudService Service for CRUD operations
   * @param fb FormBuilder for building reactive forms
   * @param changeDetectorRef Service to trigger change detection
   */

  constructor(
    private dragulaService: DragulaService,
    public activeModal: NgbActiveModal,
    private crudService: CrudService,
    private notyService: NotyService,
    private fb: FormBuilder
  ) {
    this.dragulaService.createGroup("DRAGULA_FACTS", {
      removeOnSpill: true,
    });

    const subDropModel = this.dragulaService
      .dropModel("DRAGULA_FACTS")
      .subscribe((data: any) => {
        this.group1 = data.sourceModel;
        this.reorderStations();
      });

    this.subs.push(subDropModel);

    this.itineraryForm = this.fb.group({
      itineraryLib: ["", Validators.required],
      direction: [1, Validators.required],
      routeId: [Validators.required],
      itineraryNo: [Validators.required],
      distance: [Validators.required],
      duration: [Validators.required],
      nbrOfStations: [Validators.required,],
      stoppingTime: [Validators.required,Validators.min(0)],

      itineraryStations: this.fb.array([], this.validateAtLeastOneStation),
      generateReturnItinerary: [false],
    });
  }

  /**
   * Lifecycle hook that is called after data-bound properties are initialized.
   * Fetches the list of stations from the server.
   */

  ngOnInit(): void {
    this.getStationList();
    if (this.itemId) {
      this.getShape(this.itemId);
      this.loadItineraryData();
    }
  }

  /**
   * Fetches the itinerary data based on the itemId and populates the form.
   */

  loadItineraryData() {
    this.crudService
      .getOne<any>(OPN_BASE_URL + "/itinerary", this.itemId)
      .subscribe(
        (data) => {
          this.populateForm(data);
        },
        (error) => {
          console.error("Error loading itinerary:", error);
        }
      );
  }

  /**
   * Populates the itinerary form with the retrieved data.
   * @param data The data to populate the form with
   */

  populateForm(data: any) {
    this.itineraryForm.patchValue({
      routeId: data.routeId,
      itineraryNo: data.itineraryNo,
      itineraryLib: data.itineraryLib,
      direction: data.direction,
      distance: data.distance,
      duration: data.duration,
      nbrOfStations: data.nbrOfStations,
    });

    data.itineraryStations.forEach((stationData: any) => {
      const station = stationData.station;
      const stationInfo = {
        id: station.id,
        name: station.name,
        type: station.type,
        lat: station.lat,
        lon: station.lon,
        status: station.status,
        order: stationData.stationOrder,
        distance: stationData.distance,
        duration: stationData.duration,
        stoppingTime: stationData.stoppingTime,
      };

      this.addStation(station.name, stationInfo);
    });

    const stations = this.group1.map((station) => ({
      lat: parseFloat(station.lat),
      lon: parseFloat(station.lon),
    }));

    this.mapComponent.updateMap(stations);
  }

  /**
   * Lifecycle hook that is called when the component is destroyed.
   * Cleans up the Dragula group.
   */

  ngOnDestroy() {
    this.dragulaService.destroy("DRAGULA_FACTS");
  }

  /**
   * Getter for the itineraryStations form array.
   * @returns The form array of itinerary stations
   */

  get itineraryStations(): FormArray {
    return this.itineraryForm.get("itineraryStations") as FormArray;
  }

  /**
   * Creates a FormGroup for a station with default or provided values.
   * @param station The station data to create the FormGroup with
   * @returns The created FormGroup
   */

  addStationFormGroup(station: Station): FormGroup {
    return this.fb.group({
      stationId: [station.id, Validators.required],
      stationOrder: [station.order ? station.order : 0, Validators.required],
      stationName: [station.name ? station.name : "", Validators.required],
      distance: [station.distance ? station.distance : 0, Validators.required],
      duration: [station.duration ? station.duration : 0, Validators.required],
      stoppingTime: [station.stoppingTime, Validators.required],
    });
  }

  validateAtLeastOneStation(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const formArray = control as FormArray;
    return formArray.length > 1 ? null : { noStations: true };
  }

  /**
   * Adds a placeholder station to the group and form array.
   */

  addPlaceholderStation() {
    const placeholder: Station = {
      id: 0,
      name: "",
      type: "",
      lat: "",
      lon: "",
      status: 0,
      order: this.group1.length + 1,
      distance: 0,
      duration: 0,
    };
    this.group1.push(placeholder);
    this.itineraryStations.push(this.addStationFormGroup(placeholder));
  }

  /**
   * Removes the last placeholder station if it exists.
   */

  removePlaceholderStation() {
    if (this.group1.length && !this.group1[this.group1.length - 1].name) {
      this.group1.pop();
      this.itineraryStations.removeAt(this.itineraryStations.length - 1);
    }
  }

  /**
   * Toggles the display of the select list for adding a new station.
   * Adds or removes a placeholder station as needed.
   */

  toggleSelectList() {
    if (!this.showSelectList) {
      this.addPlaceholderStation();
    } else {
      this.removePlaceholderStation();
    }
    this.showSelectList = !this.showSelectList;
  }

  /**
   * Getter for the form controls of the itinerary form.
   * @returns The form controls of the itinerary form.
   */

  get i() {
    return this.itineraryForm.controls;
  }

  /**
   * Fetches the list of stations from the server.
   */

  getStationList() {
    this.crudService
      .getAll<any>(OPN_BASE_URL + "/stations")
      .subscribe((data) => {
        this.stations = data.data;
      });
  }

  /**
   * Removes a station from the group and form array, updates the map and reorders stations.
   * @param index The index of the station to remove
   */

  removeStation(index: number) {
    this.group1.splice(index, 1);
    this.itineraryStations.removeAt(index);
    const stations = this.group1.map((station) => ({
      lat: parseFloat(station.lat),
      lon: parseFloat(station.lon),
    }));
    this.mapComponent.updateMap(stations);
    this.reorderStations();
  }

  /**
   * Reorders the stations in the group and updates the form array and map.
   */

  reorderStations(stoppingTimes: number[] = []) {
    this.group1.forEach((station, index) => {
      station.order = index + 1;
    });

    const previousStoppingTimes = stoppingTimes.length
      ? stoppingTimes
      : this.trackStoppingTimes();
    this.itineraryStations.clear();

    this.group1.forEach((station, index) => {
      const stationFormGroup = this.addStationFormGroup(station);
      if (previousStoppingTimes[index] !== undefined) {
        stationFormGroup
          .get("stoppingTime")
          .setValue(previousStoppingTimes[index]);
      }
      this.itineraryStations.push(stationFormGroup);
    });

    const stations = this.group1.map((station) => ({
      lat: parseFloat(station.lat),
      lon: parseFloat(station.lon),
    }));

    this.mapComponent.updateMap(stations);
  }

  trackStoppingTimes() {
    return this.itineraryStations.controls.map(
      (control: FormGroup) => control.get("stoppingTime").value
    );
  }

  /**
   * Saves the itinerary form data. This method handles the form submission logic,
   * including validation, payload creation, and API calls for saving the itinerary.
   * If the 'generateReturnItinerary' flag is set to true, it also creates and saves
   * a return itinerary.
   */

  saveForm() {
    this.submitted = true;
    if (this.itineraryForm.invalid) {
      return;
    }

    const formValue = this.itineraryForm.value;
    const generateReturnItinerary = formValue.generateReturnItinerary;
    const payload = {
      ...this.createPayload(formValue),
      routeId: this.routeId || formValue.routeId,
    };

    // Determine whether to call update or add API based on the presence of itemId
    const apiCall = this.itemId
      ? this.crudService.update(
          OPN_BASE_URL + "/itinerary/update/",
          this.itemId,
          payload
        )
      : this.crudService.post(OPN_BASE_URL + "/itinerary/add", payload);

    apiCall
      .pipe(
        switchMap((response: any) => {
          const itineraryId = response.id;

          // Create an array of observables for adding each station
          const addStationObservables = payload.itineraryStations.map(
            (station) =>
              this.crudService.post(OPN_BASE_URL + "/itinerary-station/add", {
                ...station,
                itineraryId: itineraryId,
              })
          );

          // Execute all station additions and wait for them to complete
          return forkJoin([of(response), ...addStationObservables]);
        }),
        switchMap(([mainResponse, ...stationResponses]: [any, any[]]) => {
          if (generateReturnItinerary) {
            const returnPayload = this.createReturnPayload(
              formValue,
              payload.itineraryNo
            );
            return this.crudService
              .post(OPN_BASE_URL + "/itinerary/add", returnPayload)
              .pipe(
                switchMap((returnResponse: any) => {
                  const returnItineraryId = returnResponse.id;
                  this.returnItineraryId = returnItineraryId;

                  // Create an array of observables for adding each station to the return itinerary
                  const addReturnStationObservables =
                    returnPayload.itineraryStations.map((station) =>
                      this.crudService.post(
                        OPN_BASE_URL + "/itinerary-station/add",
                        {
                          ...station,
                          itineraryId: returnItineraryId,
                        }
                      )
                      
                    );

                  // Execute all return station additions and wait for them to complete
                  return forkJoin([
                    of(mainResponse),
                    of(stationResponses),
                    of(returnResponse),
                    ...addReturnStationObservables,
                  ]);
                  
                })
              );
          }
          return of([mainResponse, stationResponses]);
          
        })
      )
      
      .subscribe(
        
        ([mainResponse, returnResponse]: [any, any]) => {
          this.listUpdated.emit();
          this.itemId
            ? this.updateItineraryShape(this.itemId)
            : this.saveItineraryShape(mainResponse.id);
         /* if (returnResponse) {
            this.itemId
              ? this.updateItineraryShape(this.itemId, true)
              : this.saveItineraryShape(returnResponse.id, true);
          }*/

          if(formValue.generateReturnItinerary){
              this.saveItineraryShape(this.returnItineraryId, true);
          }
        
          this.activeModal.close();
          this.notyService.displayNotification(
            "Itinerary saved successfully",
            "success"
          );
        },
        (error) => {
          console.error("Error saving itinerary:", error);
          this.notyService.displayNotification(
            "Itinerary save Failed",
            "error"
          );
        }
      );
  }
  /**
   * Creates a payload for the itinerary API call.
   * @param formValue The form values to create the payload from
   * @returns The payload object
   */

  createPayload(formValue: any) {
    const itineraryStations = this.itineraryStations.value.map(
      (station, index) => ({
        stationId: station.stationId,
        stationOrder: index + 1,
        distance: station.distance,
        duration: station.duration,
        stoppingTime: Number(station.stoppingTime),
      })
    );

    const suffix = formValue.direction === 1 ? "-A" : "-R";
    const updatedItineraryLib =
      formValue.itineraryLib.replace(/-[AR]$/, "") + suffix;

    const nbrOfStations = this.group1.length;
    const totalDistance = itineraryStations.reduce(
      (acc, station) => acc + station.distance,
      0
    );

    const totalDuration = itineraryStations.reduce(
      (acc, station) => acc + station.duration + (station.stoppingTime || 0),
      0
    );
    return {
      ...formValue,
      routeId: this.routeId || formValue.routeId,
      itineraryNo: this.itemId
        ? formValue.itineraryNo
        : (this.itinerariesLength || 0) + 1,
      itineraryLib: updatedItineraryLib,
      itineraryStations: itineraryStations,
      nbrOfStations: nbrOfStations,
      distance: totalDistance,
      avgDuration: totalDuration,
    };
  }

  /**
   * Creates a payload for the return itinerary based on the original form values.
   * @param formValue The original form values
   * @returns The return itinerary payload object
   */

  createReturnPayload(formValue: any, mainItineraryNo: number) {
    const reversedStations = [...this.group1].reverse();
    const originalStations = this.itineraryStations.value;
    const returnItineraryStations = reversedStations.map((station, index) => {
      const originalStation = originalStations[index];
      return {
        stationId: station.id,
        stationOrder: index + 1,
        distance: originalStation.distance,
        duration: originalStation.duration,
        stoppingTime: originalStation.stoppingTime || 0,
      };
    });
    const returnDirection = formValue.direction === 1 ? 0 : 1;
    const returnSuffix = returnDirection === 1 ? "-A" : "-R";
    const returnItineraryLib =
      formValue.itineraryLib.replace(/-[AR]$/, "") + returnSuffix;

    const nbrOfStations = reversedStations.length;
    const totalDistance = returnItineraryStations.reduce(
      (acc, station) => acc + station.distance,
      0
    );
    const totalDuration = returnItineraryStations.reduce(
      (acc, station) => acc + station.duration + (station.stoppingTime || 0),
      0
    );
    return {
      ...formValue,
      routeId: this.routeId || formValue.routeId,
      itineraryNo: mainItineraryNo + 1, // Incrementing the itinerary number for the return itinerary
      direction: returnDirection,
      itineraryLib: returnItineraryLib,
      itineraryStations: returnItineraryStations,
      nbrOfStations: nbrOfStations,
      distance: totalDistance,
      avgDuration: totalDuration,
    };
  }

  /**
   * Adds a station to the group and form array, and updates the map.
   * This method handles both adding new stations and integrating existing stations
   * into the itinerary form. It ensures the station is properly formatted and
   * updates the map component accordingly.
   *
   * @param name - The name of the station to add
   * @param station - The station data to add, including its details such as id, type,
   *                  latitude, longitude, status, order, distance, and duration
   */

  addStation(stationName: string, existingData?: any) {
    const foundStation = this.stations.find(
      (station) => station.name === stationName
    );
    if (!foundStation && !existingData) {
      return;
    }

    const stationData = existingData || {
      id: foundStation.id,
      name: stationName,
      type: foundStation.type,
      lat: foundStation.lat,
      lon: foundStation.lon,
      status: foundStation.status,
      order: this.group1.length + 1,
      distance: 0,
      duration: 0,
      stoppingTime: foundStation.stoppingTime || 0,
    };

    const currentStoppingTimes = this.trackStoppingTimes();

    if (this.group1.length && !this.group1[this.group1.length - 1].name) {
      const lastStation = this.group1[this.group1.length - 1];
      Object.assign(lastStation, stationData);
      const stationFormGroup = this.itineraryStations.at(
        this.itineraryStations.length - 1
      ) as FormGroup;

      stationFormGroup.patchValue({
        stationId: stationData.id,
        stationOrder: stationData.order,
        stationName: stationData.name,
        distance: stationData.distance,
        duration: stationData.duration,
        stoppingTime: stationData.stoppingTime,
      });
    } else {
      this.group1.push(stationData);
      this.itineraryStations.push(this.addStationFormGroup(stationData));
    }

    const stations = this.group1.map((station) => ({
      lat: parseFloat(station.lat),
      lon: parseFloat(station.lon),
    }));

    this.mapComponent.updateMap(stations);
    this.showSelectList = false;
    this.reorderStations(currentStoppingTimes);
  }

  /**
   * Updates the distance and duration of each station in the itinerary based on the calculated route data.
   * This method takes the legs data from a routing calculation and updates the corresponding form controls
   * in the itinerary form.
   *
   * @param legsData - An array of objects containing the distance (in meters) and duration (in seconds) for each leg of the route.
   */

  onRouteCalculated(legsData: { distance: number; duration: number }[]): void {
    this.itineraryStations.at(0).patchValue({ distance: 0, duration: 0 });

    for (let i = 0; i < legsData.length; i++) {
      const stationFormGroup = this.itineraryStations.at(i + 1) as FormGroup;
      const distanceInKm = (legsData[i].distance / 1000).toFixed(2);
      const durationInMin = (legsData[i].duration / 60).toFixed(2);
      stationFormGroup.patchValue({
        distance: parseFloat(distanceInKm),
        duration: parseFloat(durationInMin),
      });
    }
  }

  /**
   * Updates the direction value in the itinerary form based on the checked state.
   * This method toggles the direction between 1 and 0 depending on whether the checkbox is checked or not.
   *
   * @param checked - A boolean value indicating whether the direction checkbox is checked.
   */

  onDirectionChange(checked: boolean): void {
    this.itineraryForm.get("direction").setValue(checked ? 1 : 0);
  }

  /**
   * Calculates the total distance of the itinerary in kilometers.
   * This getter method sums up the distance of all stations in the itinerary and converts the total distance to kilometers.
   *
   * @returns A string representing the total distance in kilometers, formatted to two decimal places.
   */

  get totalDistance(): string {
    // Calculate total distance in meters, convert to kilometers, and format with 2 decimal places
    const distanceInKm = this.itineraryStations.value.reduce(
      (acc: number, station: any) => acc + (station.distance || 0),
      0
    );
    return distanceInKm.toFixed(2);
  }

  /**
   * Calculates the total duration of the itinerary in minutes.
   * This getter method sums up the duration of all stations in the itinerary and converts the total duration to minutes.
   *
   * @returns A string representing the total duration in minutes, formatted to two decimal places.
   */

  get totalDuration(): string {
    const durationInMin = this.itineraryStations.value.reduce(
      (acc: number, station: any) =>
        acc + (station.duration || 0) + (station.stoppingTime || 0),
      0
    );
    return durationInMin.toFixed(2);
  }

  getCardTitle(): string {
    return this.itemId ? "UPDATE_ITINERARY" : "ADD_ITINERARY";
  }

  onShapeCalculated(event: any) {
    this.shapeCalculated = event;
  }

  onReturnShapeCalculated(event: any) {
    this.returnShapeCalculated = event;
  }

  saveItineraryShape(itineraryId: string, returnShape = false) {
    const shapeData = returnShape
      ? this.returnShapeCalculated
      : this.shapeCalculated;
    this.crudService
      .post(OPN_BASE_URL + `/shape/create/multiple/${itineraryId}`, shapeData)
      .subscribe(
        () => {
          console.info("Shape saving initiated.");
        },
        (error) => {
          console.error("Error initiating shape saving:", error);
        }
      );
  }
  updateItineraryShape(itineraryId: string, returnShape = false) {
    if(itineraryId) {
      const shapeData = returnShape
          ? this.returnShapeCalculated
          : this.shapeCalculated;
      this.crudService
          .post(OPN_BASE_URL + `/shape/update/multiple/${itineraryId}`, shapeData)
          .subscribe(
              () => {
                console.info("Shape saving initiated.");
              },
              (error) => {
                console.error("Error initiating shape saving:", error);
              }
          );
    }
  }

  getShape(itineraryId: string): void {
    this.crudService
        .getAll(OPN_BASE_URL + `/shape/all/${itineraryId}`)
        .subscribe((res: any) => {
          const coordinates = res.map((coord: any) => {
            return [coord.lat, coord.lon];
          });

          this.shape = coordinates;
        });
  }
}
