<div ngbDropdown display="dynamic" class="d-inline-block"
    [placement]="['bottom-right', 'bottom-left', 'top-right', 'top-left']" [container]="'body'" #myDrop1="ngbDropdown"
    class="border dropdown p-2 rounded">
    <div class="d-flex cursor-pointer" id="dropdownBasic1" ngbDropdownToggle (click)="$event.stopPropagation();">
        <div class="align-content-center flex-shrink-0 mr-2">
            <span><i class="fa-solid fa-calendar-week font-large-1"></i></span>
        </div>
        <div class="flex-grow-1 gap-05 ms-3 align-content-center">
            <h6 class="font-meduim-3 mb-0">{{ selectedRange?.label }}</h6>
            <p class="font-small-2 mb-0">{{ formatDateRange(selectedRange?.value) }}</p>
        </div>
    </div>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <div class="row">
            <div class="col" *ngIf="showCalendar">
                <ngb-datepicker #dp1 ngModel class="bg-white border" (ngModelChange)="onDateChange($event)"
                    [displayMonths]="2" [dayTemplate]="t" [markDisabled]="isDisabled">
                </ngb-datepicker>
                <ng-template #t let-date="date" let-focused="focused">
                    <span class="custom-day" [class.focused]="focused"
                        [class.range]="isFrom(date) || isTo(date) || isInside(date) || isHovered(date)"
                        [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                        (mouseleave)="hoveredDate = null">
                        {{ date.day }}
                    </span>
                </ng-template>
            </div>
        </div>
    </div>
</div>