<div class="email-search-box w-100">
    <div class="media d-flex align-items-center">
        <!-- <span class="email-app-sidebar-toggle ft-filter cursor-pointer font-large-1 mr-2" *ngIf="!isVehiculeListVisible"
            (click)="onSidebarToggle(true)"></span>
        <span class="email-app-sidebar-toggle ft-x cursor-pointer font-large-1 mr-2" *ngIf="isVehiculeListVisible"
            (click)="onSidebarToggle(false)"></span> -->

        <div class="media-body mr-2">
            <form action="javascript:">
                <div class="position-relative has-icon-left">
                    <input class="form-control" placeholder="{{'SEARCH_VEHICLES' | translate }}" id="timesheetinput1"
                        name="registration" type="text" [(ngModel)]="searchQuery">
                    <div class="form-control-position">
                        <i class="ft-search"></i>
                    </div>
                </div>
            </form>
        </div>
        <button class="btn btn-primary mr-2" (click)="unselectVehicle()">
            {{"SHOW_VEHICLES" | translate}}
        </button>
        <div class="" ngbDropdown display="dynamic" (openChange)="onDropdownToggle($event)">
            <button class="btn btn-info text-white" ngbDropdownToggle aria-haspopup="true" aria-expanded="false">
                <i class="ft-upload"></i> {{ "Exporter" | translate }}
            </button>
            <div ngbDropdownMenu aria-labelledby="exportDropdown" [ngClass]="{
                                    'custom-dropdown-menu': isExporting,
                                    'd-none': !isExporting
                                }">
                <a class="dropdown-item pr-5 text-black-50" (click)="downloadGtfsData()">
                    {{ "EXPORT_GTFS" | translate }}
                </a>
                <a class="dropdown-item text-black-50" (click)="downloadGtfsRtData()">
                    {{ "EXPORT_GTFS_RT" | translate }}
                </a>
            </div>
        </div>

    </div>
</div>

<div class="rt-track-w overflow-hidden d-flex">


    <!-- Sidebar (Vehicle List) -->
    <div class="rt-track-sidebar">

        <div class="chat-sidebar-content">
            <!-- <div class="chat-fixed-search py-2 px-3 my-1">
                <form action="javascript:">
                    <div class="position-relative has-icon-left">
                        <input class="form-control"
                               placeholder="{{'SEARCH_VEHICLES' | translate }}"
                               id="timesheetinput1"
                               name="registration" type="text"
                               [(ngModel)]="searchQuery"> 
                        <div class="form-control-position">
                            <i class="ft-search"></i>
                        </div>
                    </div>
                </form>
            </div>
            <hr class="m-0"> -->



            <!-- <div class="card mt-0"
                *ngIf="familyNamesList && familyNamesList.length > 0 && vehiclesList && vehiclesList.length > 0">

              
                <div class="card-body p-2">
                    <ngb-accordion #acc="ngbAccordion" [closeOthers]="true" activeIds="ngb-panel-0">

                        <ngb-panel *ngFor="let familyName of familyNamesList"
                            [title]="familyName.familyName + '('+ familyName.familyVehiculesCount+')' ">
                            <ng-template ngbPanelContent>
                            
                                <div class="media py-2"
                                    *ngFor="let vehicule of filterVehiculeByFamilyName(familyName.familyName) | search:'vehicleNumber':searchQuery"
                                    [ngClass]="{'active': selectedVehicle?.id === vehicule.id}">
                                    <div class="media-body">
                                        <span class="font-small-3 mb-0 font-weight-bold">
                                            #{{ vehicule.vehicleNumber ? vehicule.vehicleNumber : "" }} - {{
                                            vehicule.registration }}
                                        </span>
                                        <p class="grey font-small-2 m-0 break-word">{{ vehicule.location }}</p>
                                    </div>
                                    <button type="button" class="btn px-2"
                                        [ngClass]="{'bg-primary text-white': selectedVehicle?.id === vehicule.id}"
                                        (click)="onVehicleClick(vehicule)">
                                        <i class="ft-eye"></i>
                                    </button>
                                </div>


                            </ng-template>
                        </ngb-panel>

                    </ngb-accordion>
                </div>
            </div> -->





            <div class="card mt-0"
                *ngIf="familyNamesList && familyNamesList.length > 0 && vehiclesList && vehiclesList.length > 0">
                <!--Collapsible Accordion Starts-->
                <div class="card-body p-2 accordion-scroll-container">
                    <ngb-accordion #acc="ngbAccordion" [closeOthers]="true" activeIds="ngb-panel-0">
                        <ngb-panel *ngFor="let familyName of familyNamesList"
                            [title]="familyName.familyName + '('+ familyName.familyVehiculesCount+')'">
                            <ng-template ngbPanelContent>
                                <div class="scrollable-content">
                                    <div class="media py-2"
                                        *ngFor="let vehicule of filterVehiculeByFamilyName(familyName.familyName) | search:'vehicleNumber':searchQuery"
                                        [ngClass]="{'active': selectedVehicle?.id === vehicule.id}">
                                        <div class="media-body">
                                            <span class="font-small-3 mb-0 font-weight-bold">
                                                #{{ vehicule.vehicleNumber ? vehicule.vehicleNumber : "" }} - {{
                                                vehicule.registration }}
                                            </span>
                                            <p class="grey font-small-2 m-0 break-word">{{ vehicule.location }}</p>
                                        </div>
                                        <button type="button" class="btn px-2"
                                            [ngClass]="{'bg-primary text-white': selectedVehicle?.id === vehicule.id}"
                                            (click)="onVehicleClick(vehicule)">
                                            <i class="ft-eye"></i>
                                        </button>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>
                </div>
            </div>
            <!--Collapsible Accordion Ends-->

            <!-- <div id="users-list" class="list-group" style="max-height: 50rem; overflow-y: auto;">
                <div class="users-list-padding position-relative">
                    <a class="list-group-item"
                       *ngFor="let vehicle of vehiclesList | search:'registration':searchQuery"
                       (click)="onVehicleClick(vehicle)"
                       [ngClass]="{'active-vehicle': selectedVehicle?.id === vehicle.id}">
                        <div class="media chat-list-text py-1"
                             [ngClass]="{'align-items-center': !vehicle.isActiveChat}">
                            <span class="avatar-status-{{vehicle.vehicleNumber}}"></span>
                            <i></i>
                            <div class="media-body">
                                <h6 class="list-group-item-heading mb-1">
                                    <span class="label">{{"IMMATRICULATION" | translate}} : </span> {{ vehicle.registration }}
                                </h6>
                                <h6 class="list-group-item-heading mb-1">
                                    <span class="label">{{"VEHICLE_NUMBER" | translate}} : </span> {{ vehicle.vehicleNumber }}
                                </h6>
                                <h6 class="list-group-item-heading mb-1">
                                    <span class="label">{{"FAMILY" | translate}} :</span> {{ vehicle.family.familyName }}
                                </h6>
                                <h6 class="list-group-item-heading mb-1">
                                    <span class="label">{{"LOCATION" | translate}} : </span> {{ vehicle.location }}
                                </h6>
                            </div>

                        </div>
                    </a>
                </div>
            </div> -->

            <!-- modal details trajet -->
            <ng-template #mapModal let-modal style="width: 800px">
                <button type="button" class="close " aria-label="Close" (click)="closeMapModal()">
                    <span aria-hidden="true" class="close-icon d-flex float-right mr-2">&times;</span>
                </button>
                <app-vehicle-tracking (vehicleRt)="handleVehicleRt($event)" [vehicle]="selectedVehicle"
                    [isHistoric]="false" [rt]="selectedRtData"></app-vehicle-tracking>
            </ng-template>
        </div>
    </div>
    <!-- Chat Window (Empty Space) -->
    <div class="chats flex-grow-1">

        <div class="real-time-map" style="width: 100%; height: 55rem;"></div>
    </div>

</div>
