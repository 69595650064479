<div class="modal-header">

  <h4 class="modal-title ">{{"Work_Order"|translate}}</h4>


</div>
<div class="modal-body " *ngIf="item">
  <form [formGroup]="workOrderForm">
    <div class="card">
      <div class="card-header">
        <i class="fa fa-info-circle mr-2 align-middle"></i>
        <span class="text-bold-600">{{"General_information" | translate}}</span>
      </div>
      <div class="card-body">
        <div class="row mb-3">
          <div class="col-md-4 col-12 mb-2">
            <span class="text-bold-600">{{"Date"| translate}}</span>
            <br>
            <span>{{item?.date | date: 'yyyy-MM-dd'}}</span>
          </div>
          <div class="col-md-4 col-12 mb-2">
            <span class="text-bold-600">{{"Requestor" | translate}}</span>
            <br>
            <span>{{item.requestor}}</span>
          </div>
          <div class="col-md-4 col-12 mb-2">
            <!-- <span class=" badge badge-info">DT - #{{item.number}}</span> -->
            <span (click)="viewWorkOrderDetail(item.workRequest)" class=" badge badge-info cursor-pointer ">DT -
              #{{item.workRequestId}}</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-md-4 col-12 mb-2">
            <span class="text-bold-600">{{"Vehicle"| translate}}</span>
            <br>
            <span>{{item.workRequest.vehicles.vehicleNumber}} - {{item.workRequest.vehicleType.vehicleBrand.brandName}}
              | {{item.workRequest.vehicleType.name}} | {{item.workRequest.vehicles.registration}}</span>
          </div>
          <div class="col-md-6 col-12 mb-2">
            <span class="text-bold-600">{{"Intervention_Object" | translate}}</span>
            <br>
            <span>{{item.interventionObject}}</span>
          </div>
        </div>

        <div class="row ">
          <div class="col-12 mb-2">
            <span class="text-bold-600">{{"Description"| translate}}</span>
            <br>
            <span> {{item.description}}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <!-- <i class="ft-settings mr-2 align-middle"></i> -->
        <i class="fa fa-tools mr-2 align-middle"></i>
        <span class="text-bold-600">{{"Interventions" | translate}}</span>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">{{ 'Intervention_Number' | translate }}</th>
                <th scope="col">{{ 'Start_Date' | translate }}</th>
                <th scope="col">{{ 'Responsible' | translate }}</th>
                <th scope="col">{{ 'Operator' | translate }}</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of paginatedInterventions" class="cursor-pointer">
                <td>
                  <span class="badge badge-secondary">{{ item.number }}</span>
                </td>
                <td>
                  <span>{{ item.date | date: 'dd/MM/yyyy HH:mm' }} </span>
                </td>
                <td>
                  {{ item.supervisor }}
                </td>
                <td>
                  <!-- Display all technicians' matricule, firstName, lastName -->
                  <span *ngFor="let tech of item.techniciens; let isLast = last">
                    {{ tech.matricule }} - {{ tech.firstName }} {{ tech.lastName }}
                    <span *ngIf="!isLast">, </span> <!-- Add a comma between technicians, but not after the last one -->
                  </span>
                </td>

                <td class="">
                  <div class="position-relative d-flex align-items-center cursor-pointer">
                    <div ngbDropdown display="dynamic" placement="left" class="d-inline-block">
                      <em class="fas fa-ellipsis-h mt-1" id="chatOptions" ngbDropdownToggle></em>
                      <div ngbDropdownMenu aria-labelledby="chatOptions">
                        <a (click)="editIntervention(item)" class="dropdown-item"
                          [appHasPermission]="['update_work_order']">{{"Edit"|translate}}</a>
                        <a class="dropdown-item danger"
                          (click)="openInterventionDeleteModal(item.id,'intervention',item.number)"
                          [appHasPermission]="['update_work_order']">{{"DELETE"|translate}}</a>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
        <div class="px-2 py-1 d-flex align-content-center flex-wrap text-white">
          <button type="button" class="btn bg-info px-2 w-100" (click)="addIntervention(item.id)"
            [appHasPermission]="['update_work_order']">
            <em class="ft-plus-circle mx-1 white"></em> <span class="text-white">{{ 'Add_Intervention' |
              translate}}</span>
          </button>
        </div>
        <div class="d-flex justify-content-end flex-wrap pt-2">
          <ngb-pagination [(page)]="page" [pageSize]="pageSize" [maxSize]="5" (pageChange)="onPageChange($event)"
            [collectionSize]="interventionsLength"></ngb-pagination>
          <ng-select [(ngModel)]="pageSize" (ngModelChange)="onPageChange($event)" [clearable]="false"
            [ngModelOptions]="{standalone: true}">
            <ng-option [value]="5">5 / page</ng-option>
            <ng-option [value]="10">10 / page</ng-option>
            <ng-option [value]="15">15 / page</ng-option>
          </ng-select>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <!-- <i class="ft-settings mr-2 align-middle"></i> -->
        <i class="fa fa-check-circle mr-2 align-middle"></i>
        <span class="text-bold-600">{{"Result" | translate}}</span>
      </div>
      <div class="card-body">
        <div class="row mb-2">
          <div class="col-12">
            <span class="text-bold-600 ">{{"Defect_Eliminated"| translate}}</span>
            <br>
            <li class="d-inline-block mr-4 mt-1">
              <div class="radio">
                <input type="radio" formControlName="defectEliminated" id="yes" [value]="1">
                <label for="yes">{{"Yes" | translate}}</label>
              </div>
            </li>
            <li class="d-inline-block mr-3 mt-1">
              <div class="radio">
                <input type="radio" formControlName="defectEliminated" id="no" [value]="0">
                <label for="no">{{"No" | translate}}</label>
              </div>
            </li>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <span class="text-bold-600">{{"Observations"| translate}}</span>
            <br>
            <input type="text" id="description" formControlName="observation" class="form-control mt-1">
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer  ">
  <button type="button" class="btn btn-primary" (click)="save()"><i class="ft-check-square mr-1"></i>{{"Save" | translate}}</button>
  <button type="button" class="btn btn-secondary" (click)="cancel()"><i class="ft-x mr-1"></i>{{ 'CANCEL' |
    translate }}</button>
</div>