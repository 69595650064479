import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { OPN_BASE_URL } from "app/shared/global/var";
import { CrudService } from "app/shared/services/crud.service";
import { NotyService } from "app/shared/services/noty.service";

@Component({
  selector: "app-multiple-deletion-modal",
  templateUrl: "./multiple-deletion-modal.component.html",
  styleUrls: ["./multiple-deletion-modal.component.scss"],
})
export class MultipleDeletionModalComponent implements OnInit {
  @Input() routeDetail;

  @Input() tripId: number;
  @Input() trip: any;
  @Input() associatedTrip: any;
  @Input() associatedTripId: number;

  @Output() itemDeleted = new EventEmitter<any>();

  constructor(
    private crudService: CrudService,
    private notyService: NotyService,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    //initialize
  }

  deleteItem(tripId): void {
    this.crudService.delete(OPN_BASE_URL + "/trip/delete", tripId).subscribe(
      (data: any) => {
        this.itemDeleted.emit();
        this.notyService.displayNotification(
          "Trip deleted successfully",
          "success"
        );
      },
      (error) => {
        this.notyService.displayNotification("Failed delete", "error");
      }
    );
    this.activeModal.close();
  }

  deleteSelectedTrips(): void {
    const ids: number[] = [];
    if (this.trip && this.trip.id) {
      ids.push(this.trip.id);
    }
    if (this.associatedTrip && this.associatedTrip.id) {
      ids.push(this.associatedTrip.id);
    }

    if (ids.length > 0) {
      this.crudService
        .deleteMultiple(OPN_BASE_URL + "/trip/delete", ids)
        .subscribe(
          (response) => {
            this.itemDeleted.emit();
            this.activeModal.close();
            this.notyService.displayNotification(
              "Trips deleted successfully",
              "success"
            );
          },
          (error) => {
            console.error("Error occurred while deleting trips:", error);
            this.notyService.displayNotification("Failed delete", "error");
          }
        );
    } else {
      console.error("No trip IDs available for deletion.");
    }
  }
}
