import { Injectable } from '@angular/core';
import {WebSocketSubject} from 'rxjs/internal-compatibility';
import {Subject} from 'rxjs';
import { REAL_TIME } from 'app/shared/global/var';
import {UsersService} from '../../../shared/services/users.service';
import {AuthService} from '../../../shared/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RealTimeTrackingService {
  private websocket: WebSocketSubject<any>;
  public message$: Subject<any> = new Subject();


  constructor(
      private authService: AuthService,
  ) { }

  connect(): void {
    if (this.websocket) {
      this.websocket.complete();
      console.info("WebSocket connection already exists");
    }
    const protocol = window.location.protocol.replace('http', 'ws');
    const host = window.location.host;
    const  username = this.authService.getDecodedToken().preferred_username;
     const wsUrl = `${protocol}//${host}/wsfleet${REAL_TIME}/${username}`;
    this.websocket = new WebSocketSubject(wsUrl);
    this.websocket.subscribe(
        (message) => this.onMessage(message),
        (err) => this.onError(err),
        () => this.onClose()
    );
  }

  sendMessage(message: string): void {
    if (this.websocket) {
      this.websocket.next(message);
    }
  }

  closeConnection(): void {
    if (this.websocket) {
      this.websocket.complete();
      console.info("WebSocket connection closed");
    }
  }

  private onMessage(message: any): void {
    this.message$.next(message); // Emit the message to the component
  }

  private onError(err: any): void {
    console.error('WebSocket error:', err);
  }

  private onClose(): void {
    console.info('WebSocket connection closed');
  }
}
