<section class="cd-horizontal-timeline" [ngClass]="{'loaded': loaded}">
  <div class="timeline">
    <div class="events-wrapper">
      <div class="events" #eventsWrapper [style.width.px]="eventsWrapperWidth">
        <ol>
          <li *ngFor="let item of timelineElements; let index = index">
            <a #timelineEvents>{{item.title}}<br />{{item.date_diff}}</a>
            <span></span>
          </li>
        </ol>
        <span class="filling-line" aria-hidden="true" #fillingLine></span>
      </div>
    </div>
    <ul class="cd-timeline-navigation">
      <li>
        <a href="#" (click)="onScrollClick($event, false)" class="prev" [ngClass]="{'inactive': prevLinkInactive}">
          {{ "PREV" | translate }}
        </a>
      </li>
      <li>
        <a href="#" (click)="onScrollClick($event, true)" class="next" [ngClass]="{'inactive': nextLinkInactive}">
          {{ "NEXT" | translate }}
        </a>
      </li>
    </ul>
  </div>
</section>