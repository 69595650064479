import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { RouteInfo } from '../vertical-menu/vertical-menu.metadata';
import { ROUTES } from '../vertical-menu/vertical-menu-routes.config';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(
    private router: Router, 
    private authService: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.isLoggedIn()) {
      const firstAccessibleRoute = this.findFirstAccessibleRoute(
        ROUTES,
        this.authService.getRolesFromToken()
      );

      if (firstAccessibleRoute && firstAccessibleRoute.path) {
        this.router.navigate([firstAccessibleRoute.path]);
        return false;
      }
      
      return false;
    }
    return true;
  }

  private findFirstAccessibleRoute(routes: RouteInfo[], userRoles: string[]): RouteInfo | null {
    for (const route of routes) {
      if (this.hasAccess(route, userRoles)) {
        if (!route.submenu || route.submenu.length === 0) {
          return route;
        }
        const accessibleSubRoute = this.findFirstAccessibleRoute(route.submenu, userRoles);
        if (accessibleSubRoute) {
          return accessibleSubRoute;
        }
      }
    }
    return null;
  }

  private hasAccess(route: RouteInfo, roles: string[]): boolean {
    if (route.permissions) {
      return route.permissions.some(permission => roles.includes(permission));
    }
    return true;
  }

}
