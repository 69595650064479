import {Component, Input, OnInit} from '@angular/core';
import {TripsInstances} from '../../../modules/operating-networks/trips-management/models/trips-instances.model';
import {CompanySettingsService} from '../../../modules/company-settings/services/company-settings.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-trips-list-modal',
  templateUrl: './trips-list-modal.component.html',
  styleUrls: ['./trips-list-modal.component.scss']
})
export class TripsListModalComponent implements OnInit {
  @Input() trips : TripsInstances[] =   [];

  delayConstraints = {
    minorDelay: 0,
    majorDelay: 0,
    minorAdvancement: 0,
    majorAdvancement: 0
  }

  constructor(
      private companySettingsService: CompanySettingsService,
      public activeModal: NgbActiveModal,

  ) { }

  ngOnInit(): void {
    // sort tips by plannedDeparture
    this.trips.sort((a, b) => {
      if (a.plannedDeparture < b.plannedDeparture) {
        return -1;
      }
      if (a.plannedDeparture > b.plannedDeparture) {
        return 1;
      }
      return 0;
    })
    this.delayConstraints = this.companySettingsService.getCompanySettings();
  }

}
