import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-top-table',
  templateUrl: './top-table.component.html',
  styleUrls: ['./top-table.component.scss']
})
export class TopTableComponent implements OnInit {
  @Input() data : any;
  @Input() top = 5;
  @Input() topTableTitle : any = '';
  @Input() value : string = '';
  @Input() unit : string = '';
  @Input() isFlop: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.data.slice(0, this.top);
  }

}
