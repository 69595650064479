// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  baseUrlAuth: "/realms/",
  baseUrlAdmin: "/admin/realms/",
  realmClient: "quarkus",
  clientId: "srtj-client",
  // wsNotificationsPath: "localhost:9051/wsnotification",

  wsNotificationsPath: `${
    window.location.protocol === "https:"
      ? "wss://" + window.location.hostname + "/wsnotification"
      : "ws://" + window.location.hostname + ":9051/wsnotification"
  }`,
  clientSecret: "xY0LZgbBczx0kzntqlRMzNx58ATABUYn",
  clientCode: "86b14c16-bfc0-4cae-b3a5-6db2f2c20ce9",
  realmManagementCode: "80260b65-a81b-42aa-ba66-904e3068e8e9",
};
