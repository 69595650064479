import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable, Subject, BehaviorSubject, timer } from "rxjs";
import { webSocket, WebSocketSubject } from "rxjs/webSocket";
import { retryWhen, delay, tap } from "rxjs/operators";
@Injectable({
  providedIn: "root",
})
export class WebsocketService {
  private socket$: WebSocketSubject<any>;
  private messagesSubject = new Subject<any>();
  private connectedSubject = new BehaviorSubject<boolean>(false);
  private readonly RECONNECT_INTERVAL = 1800000; // 30 minutes en millisecondes
  private currentUserId: string | null = null;
  public connect(userId: string): void {
    this.disconnect();
    this.currentUserId = userId;
    const fullUrl = `${environment.wsNotificationsPath}/notifications/${userId}`;
    this.socket$ = webSocket({
      url: fullUrl,
      openObserver: {
        next: () => {
          this.connectedSubject.next(true);
        },
      },
      closeObserver: {
        next: () => {
          this.connectedSubject.next(false);
        },
      },
    });
    this.socket$
      .pipe(
        retryWhen((errors) =>
          errors.pipe(
            tap((err) => console.error("WebSocket error:", err)),
            delay(this.RECONNECT_INTERVAL) // Attendre 30 minutes avant la reconnexion en cas d'erreur
          )
        )
      )
      .subscribe(
        (message) => this.messagesSubject.next(message),
        (error) => console.error("WebSocket error:", error)
      );
  }
  public sendMessage(message: any): void {
    if (this.socket$ && !this.socket$.closed) {
      this.socket$.next(message);
    } else {
      console.error("WebSocket is not connected.");
    }
  }
  public getMessages(): Observable<any> {
    return this.messagesSubject.asObservable();
  }
  public isConnected(): Observable<boolean> {
    return this.connectedSubject.asObservable();
  }
  public disconnect(): void {
    if (this.socket$) {
      this.socket$.complete();
    }
    this.connectedSubject.next(false);
    this.currentUserId = null;
  }
}
