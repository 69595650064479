<div class="modal-header">
  <h4 class="modal-title"> {{ isEditMode ? ('Edit_Intervention' | translate) : ('New_Intervention' | translate) }}
  </h4>
  <button type="button" class="close" aria-label="Close " (click)="activeModal.close('Cross click')">
    <i class="fa-solid fa-xmark"></i>
  </button>
</div>

<div class="modal-body ">
  <form [formGroup]="operatorForm" (ngSubmit)="save()">

    <div class="form-row">
      <div class="col-md-6 col-12">
        <div class="form-group mb-2">
          <label for="basic-form-1">{{"Intervention_Date"| translate}}</label>
          <div class="row">
            <div class="input-group col-7">
              <input class="form-control" id="date" formControlName="date" placeholder="{{'DATE_FORMAT' | translate}}"
                ngbDatepicker #d="ngbDatepicker" (dateSelect)="onDateSelect($event)"
                [ngClass]="{ 'is-invalid': submitted && o.date.invalid, 'is-valid': submitted && o.date.invalid }">

              <div class="input-group-append">
                <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                  <i class="fa fa-calendar"></i>
                </button>
              </div>
              <div *ngIf="submitted && o.date.errors?.required" class="invalid-feedback">
                {{ "Intervention_Date" | translate }} {{ "REQUIRED" | translate }}
              </div>
            </div>
            <div class="col-5">
              <ngb-timepicker [spinners]="false" formControlName="time" class="mb-2"
                [ngClass]="{ 'is-invalid': submitted && o.time.invalid, 'is-valid': submitted && o.time.invalid }">
              </ngb-timepicker>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-12">
        <div class="form-group mb-2">
          <label for="basic-form-2">{{"Intervention_Supervisor" | translate}}</label>
          <input type="text" id="supervisor" formControlName="supervisor"
            [ngClass]="{ 'is-invalid': submitted && o.supervisor.invalid, 'is-valid': submitted && o.supervisor.invalid }"
            class="form-control">
          <div *ngIf="submitted && o.supervisor.errors?.required" class="invalid-feedback">
            {{ "Intervention_Supervisor" | translate }} {{ "REQUIRED" | translate }}
          </div>
        </div>
      </div>

      <div class="col-md-3 col-12">
        <div class="form-group mb-2">
          <label for="basic-form-2">{{"Intervention_Duration" | translate}}</label>
          <input type="text" id="duration" formControlName="duration" class="form-control" readonly>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col-6">
        <div class="form-group mb-2">
          <label for="basic-form-1">{{"Diagnostic" | translate}}</label>
          <input type="text" id="diagnostic" formControlName="diagnostic" class="form-control">
        </div>
      </div>
      <div class="col-6">
        <div class="form-group mb-2">
          <label for="interventionType">{{ 'INTERVENTION_TYPE_LABEL' | translate }}</label>
          <ng-select [items]="interventionTypes" bindLabel="name" bindValue="id" formControlName="interventionTypeId"
            [clearable]="false" [placeholder]="'SELECT_INTERVENTION_TYPE' | translate"
            [ngClass]="{ 'is-invalid': submitted && o.interventionTypeId.invalid, 'is-valid': submitted && o.interventionTypeId.valid}">
          </ng-select>
          <div *ngIf="submitted && o.interventionTypeId.errors?.required" class="invalid-feedback">
            {{ 'INTERVENTION_TYPE_REQUIRED' | translate }}
          </div>
        </div>

      </div>
    </div>
    <div class="form-row ">
      <div class="col-12">
        <div class="form-group mb-2">
          <label for="basic-form-1">{{"Operator"| translate}}</label>
          <div class=" border border-light">
            <div class="table-responsive">
              <table class="table border-top-0">
                <thead>
                  <tr>
                    <th class="col-3">{{ 'Mat_Operator'|translate }}</th>
                    <th class="col-3">{{ 'Name_Operator'|translate }}</th>
                    <th class="col-3">{{ 'Total_Work_Duration'|translate }}</th>
                    <th class="col-2">{{ 'Work_Log'|translate }}
                    </th>
                    <th scope="col-1"></th>




                  </tr>
                </thead>
                <tbody *ngFor="let operator of selectedOperators">
                  <tr class="cursor-pointer ">
                    <td class="col-3">
                      <span>{{ operator.matricule }}</span>
                    </td>
                    <td class="col-3">
                      <span>
                        {{ operator.firstName }} {{ operator.lastName }}
                      </span>
                    </td>

                    <td class="col-3">
                      {{ operator.totalWorkDuration }}
                    </td>

                    <td class="col-2">
                      <span (click)="isEditMode && addWorkDiary(operator)"
                        [ngClass]="{'text-muted': !isEditMode, 'text-info': isEditMode}">
                        <i class="fa-regular fa-clock"
                          [ngClass]="{'text-info': isEditMode, 'text-muted': !isEditMode}"></i>
                      </span>
                    </td>

                    <td> <span class="col-1" (click)="removeOperator(operator)"><i class="fa fa-trash text-danger"
                          aria-hidden="true"></i>
                      </span></td>
                  </tr>
                </tbody>
              </table>


            </div>
            <div *ngIf="showOperatorForm" class="p-2">
              <div class="d-flex flex-grow-1 flex-column flex-md-row">
                <ng-select [items]="availableOperators" bindLabel="fullName" bindValue="id"
                  formControlName="selectedOperator" [clearable]="false" [placeholder]="'Select_Operator' | translate"
                  [searchable]="true" class="flex-grow-1 mb-2 mb-md-0">
                </ng-select>
                <div class="ml-md-2 d-flex">
                  <button class="btn btn-success mr-2" (click)="saveOperator()"
                    [disabled]="!operatorForm.get('selectedOperator')?.value">
                    {{ 'Add' | translate }}
                  </button>
                  <button class="btn btn-danger" (click)="cancelAddOperator()">
                    {{ 'Cancel' | translate }}
                  </button>
                </div>
              </div>
            </div>
            <div class=" px-2 py-1 d-flex flex-wrap text-white">
              <button type="button" class="btn bg-info px-2 w-100" (click)="addOperator()"
                [appHasPermission]="['update_work_order']">
                <em class="ft-plus-circle mx-1 white"></em> <span class="text-white">{{ 'Add_Operator' | translate
                  }}</span>
              </button>
            </div>
            <div *ngIf="operatorError" class=" px-2 py-1 d-flex align-content-center flex-wrap text-danger">
              {{ 'At_least_one_operator_required' | translate }}
            </div>
          </div>

        </div>
      </div>
    </div>


    <!-- <span class="text-bold-600 mt-2 ">{{"Oil_Change"| translate}}</span> -->
    <div class="form-row border border-light p-2 mt-2">
      <div class="col-md-6 col-12" *ngIf="shouldShowOilQuantity()">
        <div class="form-group mb-2">
          <label for="basic-form-1">{{"Oil_Quantity" | translate}}</label>
          <div class="input-group">
            <input class="form-control" type="number" min="0" id="oilQuantity" formControlName="oilQuantity">
            <div class="input-group-append">
              <span class="input-group-text">L</span>
            </div>
          </div>
          <div *ngIf="submitted && o.oilQuantity.errors?.['min']" class="invalid-feedback d-block">
            {{ 'Oil_Quantity' | translate }} {{ 'MUST_BE_POSITIVE' | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="form-group mb-2">
          <label for="basic-form-2">{{"Index_Kilometers" | translate}}</label>
          <div class="input-group">
            <input class="form-control" type="number" min="0" id="indexKilometers" formControlName="indexKilometers">
            <div class="input-group-append">
              <span class="input-group-text">km</span>
            </div>
          </div>
          <div *ngIf="submitted && o.indexKilometers.errors?.['min']" class="invalid-feedback d-block">
            {{ 'Index_Kilometers' | translate }} {{ 'MUST_BE_POSITIVE' | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-12 col-12">
        <div class="form-group mb-2">
          <label for="basic-form-1">{{"Description" | translate}}</label>
          <input type="text" class="form-control" type="text" id="observation" formControlName="observation">
        </div>
      </div>
    </div>

  </form>
</div>

<div class="modal-footer  ">

  <button type="button" class="btn btn-info" (click)="save()">{{"Save" | translate}}</button>
  <button type="button" class="btn btn-danger" (click)="closeModal()">{{"CLOSE"|translate}}</button>
</div>