import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-cancel-button',
  templateUrl: './cancel-button.component.html',
  styleUrls: ['./cancel-button.component.scss']
})
export class CancelButtonComponent implements OnInit {

  @Input() disabled = false;
  @Output() onClick = new EventEmitter()

  constructor() { }

  ngOnInit(): void {
  }

}
