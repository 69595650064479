<ng-container *ngFor="let field of fields">
  <ng-container *ngIf="!field.hide">
    <!-- image -->
    <ng-container *ngIf="field.type==='file'; else parentTmpl">
      <app-dropzone
        (ngModelChange)="files[field.name]=$event;fileUploaded.emit($event);filedChanged.emit(files);markOneFieldPristine(field.name)"
        [acceptedFiles]="field.acceptedFiles"
        [control]="getFormControl(field.name)"
        [idName]="field.name"
        [labelPlaceholder]="(field.placeholder|translate) | translate"
        [validators]="field.validators"
      ></app-dropzone>
    </ng-container>
    <!-- end image-->

    <!-- parent -->
    <ng-template #parentTmpl>
      <ng-container *ngIf="field.type==='parent'; else selectTmpl">
        <div>
          <h5 style="font-weight: 700">{{field.placeholder|translate }}</h5>

          <app-form-group (fileUploaded)="fileUploaded.emit($event)" [fields]="field.children"
                          [form]="controls[field.name]" [relatedModels]="relatedModels"
                          [url]="url"></app-form-group>

        </div>
      </ng-container>
    </ng-template>
    <!-- end parent -->

    <!-- select-->
    <ng-template #selectTmpl>
      <ng-container *ngIf="field.type==='select'; else textAreaTmpl">
        <app-select
          (ngModelChange)="field.backend?.relatedUrl?setRelatedModels(field.backend.relatedFormControl,field.backend.relatedUrl,field.backend.specificField,{relatedField:field.backend.relatedField,relatedValue:$event},field.backend.relatedFormControl):null;
                          field.backend?.fill?fillFields(field,$event,relatedModels[field.name]):null; satisfyCondition(field, $event)"
          (searchChange)="field.backend?.async?search(field,$event):null"
          [async]="field.backend?.async"
          [control]="getFormControl(field.name)"
          [data]="relatedModels[field.name]"
          [hidden]="field.conditional"
          [idName]="field.name"
          [labelField]="field.labelField" [labelPlaceholder]="field.placeholder|translate "
          [multiple]="field.multipleSelect" [prepareLabelFieldFunction]="field.prepareLabelFieldFunction"
          [validators]="field.validators"
          [valueField]="field.valueField"></app-select>
      </ng-container>
    </ng-template>
    <!-- end select -->

    <!-- text area -->
    <ng-template #textAreaTmpl>
      <ng-container *ngIf="field.type==='text-area'; else dateTmpl">

        <app-text-area [control]="getFormControl(field.name)"
                       [idName]="field.name"
                       [hidden]="field.conditional"
                       [labelPlaceholder]="field.placeholder|translate"
                       [validators]="field.validators"></app-text-area>
      </ng-container>
    </ng-template>

    <!-- date -->
    <ng-template #dateTmpl>
      <ng-container *ngIf="field.type==='date'; else arrayTmpl">
        <app-datepicker (ngModelChange)="log($event);getFormControl(field.name).setValue($event)"
                        [control]="getFormControl(field.name)"
                        [idName]="field.name"
                        [hidden]="field.conditional"
                        [labelPlaceholder]="field.placeholder|translate "
                        [minDate]="field.minDate"
                        [validators]="field.validators"></app-datepicker>
      </ng-container>
    </ng-template>
    <!-- end date -->

    <!-- array -->
    <ng-template #arrayTmpl>
      <ng-container *ngIf="field.type==='array'; else radioTmpl">
        <div style="display: flex; align-items: center;">
          <h5 style="font-weight: 600; margin-right: 10px;">{{ field.placeholder|translate }}</h5>
          <i class="ft-plus-square" (click)="addToFormArray(field)" style="cursor: pointer; font-size: 27px;"></i>
      </div>
      
        <div>
          <ng-container *ngFor="let control of getFormArray(field.name).controls; let i=index">
            <div class="border border-secondary p-3 m-3 rounded">
              <i class="ft-trash-2 mx-2"(click)="log(i);removeFromArrayField(field.name,i)" style="cursor: pointer; font-size: 27px"></i>
              <i class="ft-copy"(click)="duplicateInArrayField(field,i)"style="cursor: pointer; font-size: 27px"></i>
              <div class="pull-right">({{i}})</div>
              <app-form-group (fileUploaded)="fileUploaded.emit($event)" [fields]="field.children"
                              [form]="control" [relatedModels]="relatedModels"
                              [url]="url"></app-form-group>
            </div>
          </ng-container>
        </div>
        <div>
          <i class="ft-plus-square" (click)="addToFormArray(field)"
          *ngIf="getFormArray(field.name).controls.length"
          style="cursor: pointer; font-size: 27px"></i>

        
        </div>
      </ng-container>

    </ng-template>
    <!-- end array -->

    <!-- radio -->
    <ng-template #radioTmpl>
      <ng-container *ngIf="field.type==='radio'; else elseTmpl">
        <app-radio (ngModelChange)="log($event);hideField($event.field,$event.hide)"
                   [canClear]="!field.validators?.required"
                   [control]="getFormControl(field.name)"
                   [idName]="field.name"
                   [labelPlaceholder]="field.placeholder|translate"
                   [options]="field.radioOptions"
                   [hidden]="field.conditional"
                   [validators]="field.validators"></app-radio>
      </ng-container>
    </ng-template>
    <!-- end radio -->

    <!-- else -->
    <ng-template #elseTmpl>
      <app-input *ngIf="field.type!=='hidden'"
                 [control]="getFormControl(field.name)" [idName]="field.name"
                 [labelPlaceholder]="field.placeholder|translate"
                 [type]="field.type"
                 [validators]="field.validators"></app-input>
    </ng-template>
  </ng-container>
</ng-container>
