import { NgModule } from '@angular/core';
import {CommonModule} from "@angular/common";

import { FilterPipe } from './filter.pipe';
import { SearchPipe } from './search.pipe';
import { ShortNamePipe } from './short-name.pipe';
import { IdFormatterPipe } from './id-formatter.pipe';

@NgModule({
  declarations:[FilterPipe, SearchPipe, ShortNamePipe, IdFormatterPipe],
  imports:[CommonModule],
  exports:[FilterPipe, SearchPipe, ShortNamePipe,IdFormatterPipe]
})

export class PipeModule{}
