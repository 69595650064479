import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private spinnerSubject: BehaviorSubject<boolean>;
  private nbLoading = 0;

  constructor() {
    this.spinnerSubject = new BehaviorSubject<boolean>(true);
  }

  get spinnerObservable() {
    return this.spinnerSubject.asObservable();
  }

  dontHideSpinner() {
    if (this.nbLoading === 0) {
      this.spinnerSubject.next(false);
    }
    this.nbLoading++;
  }

  hideSpinner() {
    this.nbLoading--;
    if (this.nbLoading < 0) {
      this.nbLoading = 0;
    }
    if (this.nbLoading === 0) {
      this.spinnerSubject.next(true);
    }
  }
}
