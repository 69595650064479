export const ICON_PATH = 'path://M1.11979167,1.11111112 C1.11979167,0.497461393 1.61725306,' +
    '0 2.23090279,0 L12.2309028,0 C12.8445525,1.43824153e-08 13.3420139,0.497461403 ' +
    '13.3420139,1.11111112 L13.3420139,10 L15.5642361,10 C16.7915356,10 17.7864583,10.9949228 ' +
    '17.7864583,12.2222222 L17.7864583,16.6666667 C17.7865523,17.28025 18.2839861,17.7776077 ' +
    '18.8975694,17.7776077 C19.5111527,17.7776077 20.0085866,17.28025 20.0086805,16.6666667 ' +
    'L20.0086805,8.88888888 L17.7864583,8.88888888 C17.1728086,8.88888888 16.6753472,8.3914275' +
    ' 16.6753472,7.77777779 L16.6753472,3.79333333 L15.6197917,2.73777777 C15.1859413,2.30392741 ' +
    '15.1859413,1.60051702 15.6197917,1.16666667 L15.6197917,1.16666667 C16.053642,0.732816318 ' +
    '16.7570524,0.732816318 17.1909028,1.16666667 L21.9053472,5.88111112 C22.1140468,6.08922811 ' +
    '22.2312072,6.37193273 22.2309028,6.66666667 L22.2309028,16.6666667 C22.2309028,18.5076158' +
    ' 20.7385186,20 18.8975695,20 C17.0566203,20 15.5642361,18.5076158 15.5642361,16.6666667 ' +
    'L15.5642361,12.2222222 L13.3420139,12.2222222 L13.3420139,17.7777778 L13.3420139,17.7777778 ' +
    'C13.9556636,17.7777778 14.453125,18.2752392 14.453125,18.8888889 L14.453125,18.8888889 ' +
    'C14.453125,19.5025386 13.9556636,20 13.3420139,20 L1.11979165,20 C0.506141934,' +
    '20 0.00868054688,19.5025386 0.00868054687,18.8888889 L0.00868054687,18.8888889 ' +
    'C0.00868054688,18.2752392 0.506141934,17.7777778 1.11979165,17.7777778 L1.11979167,17.7777778 ' +
    'L1.11979167,1.11111112 Z M3.34201388,2.22222221 L3.34201388,8.88888888 L11.1197917,8.88888888' +
    ' L11.1197917,2.22222221 L3.34201388,2.22222221 Z '

export const TEMPERATURE_ICON = 'path://M15,4 H20 M15,8 H20 M17,12 H20 M8,15.9998 C7.44772,15.9998 ' +
    '7,16.4475 7,16.9998 C7,17.5521 7.44772,17.9998 8,17.9998 C8.55228,17.9998 9,17.5521 9,16.9998 C9,16.4475 8.55228,15.9998 8,' +
    '15.9998 Z M8,15.9998 V9 M8,16.9998 L8.00707,17.0069 M12,16.9998 C12,19.209 10.2091,20.9998 8,20.9998 C5.79086,20.9998 4,19.209 ' +
    '4,16.9998 C4,15.9854 4.37764,15.0591 5,14.354 L5,6 C5,4.34315 6.34315,3 8,3 C9.65685,3 11,4.34315 11,6 V14.354 C11.6224,15.0591 ' +
    '12,15.9854 12,16.9998 Z'
