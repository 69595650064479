import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "../auth/auth-guard.service";

export const Full_ROUTES: Routes = [

  {
    path: "page",
    loadChildren: () =>
      import("../../page/page.module").then((m) => m.PageModule),
    canActivate: [AuthGuard],
  },

  {
    path: "user-management",
    loadChildren: () =>
      import("../../modules/users-management/users-management.module").then(
        (m) => m.UsersManagementModule
      ),
      canActivate: [AuthGuard],
    data: {
      breadcrumb: "USERS_LIST",
      permissions: ["User_Management"],
    },
  },
  {
    path: "settings",
    loadChildren: () =>
      import("../../modules/company-settings/company-settings.module").then(
        (m) => m.CompanySettingsModule
      ),
      canActivate: [AuthGuard],
    data: {
      breadcrumb: "COMPANY-SETTINGS",
      permissions: ["Company Settings"],
    },
  },
  {
    path: "profile",
    loadChildren: () =>
      import("../../modules/user-profile/user-profile.module").then(
        (m) => m.UserProfileModule
      ),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: "Profile",
      permissions: ['User_Profile']
    },
  },

  {
    path: "notification",
    loadChildren: () =>
      import("../../modules/notification/notification-history.module").then(
        (m) => m.NotificationHistoryModule
      ),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: "Notification",
      permissions: ["Notification_List"]
      
    },
  },
  {
    path: "fleet-management",
    loadChildren: () =>
      import("../../modules/fleet-management/fleet-management.module").then(
        (m) => m.FleetManagementModule
      ),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: "FLEET_MANAGEMENT",
      permissions: ["Fleet_Management"],
    },
  },
  {
    path: "staff-management",
    loadChildren: () =>
      import("../../modules/staff-management/staff-management.module").then(
        (m) => m.StaffManagementModule
      ),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: "STAFF_MANAGEMENT",
      permissions: ["Staff_Management"], 
    },
  },
  {
    path: "operating-networks",
    loadChildren: () =>
      import("../../modules/operating-networks/operating-networks.module").then(
        (m) => m.OperatingNetworksModule
      ),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: "OPERATING_NETWORK",
      permissions: ["Operating_Networks"], 
    },
  },
  {
    path: "maintenance-management",
    loadChildren: () =>
      import(
        "../../modules/maintenance-management/maintenance-management.module"
      ).then((m) => m.MaintenanceManagementModule),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: "MAINTENANCE",
      permissions: ["Maintenances"], 
    },
  },
  {
    path: "energy-management",
    loadChildren: () =>
      import("../../modules/energy-management/energy-management.module").then(
        (m) => m.EnergyManagementModule
      ),
    canActivate: [AuthGuard],
    data: {
      breadcrumb: "ENERGY_MANAGEMENT",
      permissions: ["Energy_Management"], 
    },
  },
  {
    path: "real-time-tracking",
    loadChildren: () =>
      import(
        "../../modules/real-time-tracking/real-time-tracking.module"
      ).then((m) => m.RealTimeTrackingModule),
    canActivate: [AuthGuard],
    data: { permissions: ["Real_Time_Tracking"] },
  },
];
