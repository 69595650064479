import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
// @ts-ignore
import L from 'leaflet';
import { RealTimeTrackingService } from '../services/real-time-tracking.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Vehicle } from 'app/modules/maintenance-management/work-request/models/work-request';
import { Station } from 'app/modules/operating-networks/stations-management/models/stations.model';
import { BASE_URL_FLEET, OPN_BASE_URL } from 'app/shared/global/var';
import { CrudService } from 'app/shared/services/crud.service';
import { GtfsService } from '../services/gtfs.service';
import {ZoomService} from '../../../shared/services/zoom.service';

export interface RealTimeData {
    chauffeur: string;
    vitesse: number;
    localite: string;
    isAlert: any;
    alert: any;
    temp: number;
    dist: number;
    rpm: number;
    car: number;
    vitesseInst: number;
    id: string;
    date: string;
    lat: number;
    lon: number;
    contact: string;
    move: string;
}

@Component({
    selector: 'app-vehicles-list-tracking',
    templateUrl: './vehicles-list-tracking.component.html',
    styleUrls: ['./vehicles-list-tracking.component.scss']
})

export class VehiclesListTrackingComponent implements OnInit, OnDestroy {

    mapModalRef: NgbModalRef | null = null;

    isExporting = false;

    // isVehiculeListVisible = false;


    isContentOverlay = false;
    searchQuery: '';
    vehiclesList: Vehicle[] = [];
    familyNamesList: string[] = [];
    stationsList: Station[] = [];
    realTimeData: RealTimeData[] = [];
    selectedRtData: RealTimeData;
    selectedVehicle: Vehicle;
    private map = L.map;
    private stationsMarkers: any[] = [];
    private vehiclesMarkers: any[] = [];
    private langChangeSub: Subscription;

    @ViewChild('mapModal') mapModal: ElementRef;


    constructor(
        private crudService: CrudService,
        private _elementRef: ElementRef,
        private realTimeTrackingService: RealTimeTrackingService,
        private modalService: NgbModal,
        private translate: TranslateService,
        private gtfsService: GtfsService,
        private zoomService: ZoomService
    ) {
    }

    ngOnInit(): void {
        this.loadData();
        this.langChangeSub = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            if (this.selectedVehicle) {
                const rtData = this.realTimeData.find(rt => parseInt(rt.id, 10) === this.selectedVehicle.idBoitier);
                const marker = this.vehiclesMarkers.find(m => m.options.icon.options.text === this.selectedVehicle.vehicleNumber);
                if (rtData && marker) {
                    this.openPopup(rtData, marker, this.selectedVehicle);
                }
            }
        });
    }

    loadData() {
        this.stationsList = [];
        this.realTimeData = [];
        this.vehiclesList = [];

        // Call to get stations
        this.crudService.getAll(OPN_BASE_URL + '/stations').subscribe({
            next: (result: any) => {
                this.stationsList = result.data || [];
                this.loadRealTimeData(); // Proceed to load real-time data
            },
            error: (err) => {
                console.error('Error fetching stations:', err);
                this.stationsList = [];
                this.loadRealTimeData();
            }
        });
    }

    loadRealTimeData() {
        this.crudService.getAll(OPN_BASE_URL + '/rt/all').subscribe({
            next: (result: any) => {
                this.realTimeData = result || [];
                this.loadVehicles(); // Proceed to load vehicles
            },
            error: (err) => {
                console.error('Error fetching real-time data:', err);
                // Handle error for real-time data if needed
                this.realTimeData = [];
                this.loadVehicles();
            }
        });
    }

    updateFamilyNamesList() {
        // const familyNames = this.vehiclesList
        //             .filter(vehicle => vehicle.family)
        //             .map(vehicle => vehicle.family.familyName);

        //         this.familyNamesList = [...new Set(familyNames)];

        const familyCountsMap = this.vehiclesList
            .filter(vehicle => vehicle.family) // Filtre les véhicules qui ont une famille
            .reduce((acc, vehicle) => {
                const familyName = vehicle.family.familyName;

                // Si la famille existe déjà dans acc, on incrémente le compteur, sinon on initialise à 1
                if (acc[familyName]) {
                    acc[familyName].familyVehiculesCount++;
                } else {
                    acc[familyName] = { familyName, familyVehiculesCount: 1 };
                }

                return acc;
            }, {});

        // Convertir l'objet en tableau
        this.familyNamesList = Object.values(familyCountsMap);
    }

    loadVehicles() {
        this.crudService.getAll(BASE_URL_FLEET + '/vehicles/list').subscribe({
            next: (data: any) => {
                this.vehiclesList = data || [];

                this.updateFamilyNamesList();

                this.getUpdatedData();
                for (const vehicle of this.vehiclesList) {
                    const rtData = this.realTimeData.find(rt => parseInt(rt.id, 10) === vehicle.idBoitier);
                    if (rtData) {
                        vehicle.location = rtData.localite;
                    }
                }
                this.initMap();
            },
            error: (err) => {
                console.error('Error fetching vehicles:', err);
                this.vehiclesList = [];
                this.initMap();
            }
        });
    }


    ngOnDestroy(): void {
        this.clearMap();
      //  this.realTimeTrackingService.closeConnection();
    }

    initMap(): void {
        const el = this._elementRef.nativeElement.querySelector('.real-time-map');
        this.map = L.map(el, {
            attributionControl: false,
            center: [34.551117, 9.369019],
            zoom: 10,
            maxZoom: 20,
            minZoom: 5
        });
        L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
            attribution: '',
        }).addTo(this.map);
        for (const station of this.stationsList) {
            this.addStationsMarkers([station.lat, station.lon], station.name);
        }
        for (const rt of this.realTimeData) {
            const vehicle = this.vehiclesList.find(v => v.idBoitier === parseInt(rt.id, 10));
            if (vehicle) {
                this.addVehiclesMarkers([rt.lat, rt.lon], vehicle);
            }
        }
        this.fitMapToMarkers();
    }

    addStationsMarkers(latlng: any, stationName: string): void {
        const initialIconSize: [number, number] = [25, 41];
        const iconUrl = "./assets/img/leaflet/bus-stop.png";
        const marker = this.zoomService.createResizableIcon(this.map, latlng, iconUrl, stationName, initialIconSize);
        marker.bindPopup(stationName);
        this.stationsMarkers.push(marker);
    }

    clearMap(): void {
        this.stationsMarkers.forEach(marker => {
            this.map.removeLayer(marker);
        });
        this.vehiclesMarkers.forEach(marker => {
            this.map.removeLayer(marker);
        });
      //  this.map.remove();
    }

    fitMapToMarkers(): void {
        if (this.stationsMarkers.length > 0) {
            const group = new L.featureGroup(this.stationsMarkers);
            this.map.fitBounds(group.getBounds());
        }
    }

    // clicking on a vehicle in list
    onVehicleClick(vehicle: Vehicle, fromMarker = false) {
        this.selectedVehicle = vehicle;
        this.selectedRtData = this.realTimeData.find(rt => parseInt(rt.id, 10) === vehicle.idBoitier);
        const marker = this.vehiclesMarkers.find(m => m.options.icon.options.text === vehicle.vehicleNumber);
        if (marker) {
            this.map.setView(marker.getLatLng(), 15);
        }
        const rtData = this.realTimeData.find(rt => parseInt(rt.id, 10) === vehicle.idBoitier);
        if (rtData) {
            this.openPopup(rtData, marker, vehicle);
        }
    }

    openPopup(rtData: RealTimeData, marker: any, vehicle: Vehicle): void {
        // Fetch all translations at once
        this.translate.get([
          'Track', 'DATE', 'Speed', 'Temp', 'RPM', 'Fuel_Level', 'Distance','Lat','Lon','Location', 'N/A'
        ]).subscribe((translations: any) => {
          // Helper function to check if value is undefined/null and return N/A if it is
          const getValue = (value: any, unit: string = ''): string => {
            if (value === undefined || value === null || value === '') {
              return translations['N/A'];
            }
            return `${value}${unit}`;
          };

          const popupContent = `
            <div class="popup-container d-flex flex-column">
              <h3 class="bg-light-info flex-grow-1 font-medium-2 p-2 rounded text-center vehicle-number">
                <i class="fas fa-bus text-info mr-1"></i>${getValue(vehicle.vehicleNumber)}
              </h3>
              <div class="vehicle-stats font-small-3">
                <div style="display: flex; justify-content: space-between;">
                  <span><strong>${translations['DATE']}:</strong></span>
                  <span>${getValue(rtData.date ? rtData.date.replace('T', ' ').replace('Z', '') : null)}</span>
                </div>
                <div style="display: flex; justify-content: space-between;">
                  <span><strong>${translations['Speed']}:</strong></span>
                  <span>${getValue(rtData.vitesseInst, ' km/h')}</span>
                </div>
                <div style="display: flex; justify-content: space-between;">
                  <span><strong>${translations['Temp']}:</strong></span>
                  <span>${getValue(rtData.temp, ' °C')}</span>
                </div>
                <div style="display: flex; justify-content: space-between;">
                  <span><strong>${translations['RPM']}:</strong></span>
                  <span>${getValue(rtData.rpm)}</span>
                </div>
                <div style="display: flex; justify-content: space-between;">
                  <span><strong>${translations['Fuel_Level']}:</strong></span>
                  <span>${getValue(rtData.car, ' L')}</span>
                </div>
                <div style="display: flex; justify-content: space-between;">
                  <span><strong>${translations['Distance']}:</strong></span>
                  <span>${getValue(rtData.dist, ' km')}</span>
                </div>
                 <div style="display: flex; justify-content: space-between;">
                  <span><strong>${translations['Lat']}:</strong></span>
                  <span>${getValue(rtData.lat)}</span>
                </div>
                  <div style="display: flex; justify-content: space-between;">
                  <span><strong>${translations['Lon']}:</strong></span>
                  <span>${getValue(rtData.lon)}</span>
                </div>
                <div style="display: flex; justify-content: space-between;">
                  <span><strong>${translations['Location']}:</strong></span>
                  <span>${getValue(vehicle.location)}</span>
                </div>
              </div>
              <button id="trackButton" class="btn btn-info mt-2">
                ${translations['Track']}
              </button>
            </div>
          `;

          // Bind the popup content to the marker
          marker.bindPopup(popupContent);
          marker.openPopup();
          marker.on('click', () => {
            this.onVehicleClick(vehicle, true);
          });
        });
      }

    getUpdatedData(): void {
        this.realTimeTrackingService.connect();
        this.realTimeTrackingService.message$.subscribe((message) => {
            this.handleMessage(message);
        });
    }


    updateVehicleMarker(vehicle: Vehicle, rtData: RealTimeData): void {
        const marker = this.vehiclesMarkers.find(m => m.options.icon.options.text === vehicle.vehicleNumber);

        if (marker) {
            marker.setLatLng([rtData.lat, rtData.lon]);
            marker.on('click', () => {
                // this.selectedVehicle = vehicle;
                this.selectedRtData = this.realTimeData.find(rt => parseInt(rt.id, 10) === vehicle.idBoitier);
                this.openPopup(rtData, marker, vehicle);
            });
            this.updatePopupContent(marker, vehicle, rtData);
        }
    }

    updatePopupContent(marker: any, vehicle: Vehicle, rtData: RealTimeData): void {
        if(rtData.vitesseInst === undefined){
            rtData.vitesseInst = 0;
        }else if (rtData.rpm === undefined){
            rtData.rpm = 0;
        }
        this.translate.get([
            'Track', 'DATE', 'Speed', 'Temp', 'RPM', 'Fuel Level', 'Distance', 'Lat','Lon','Location'
        ]).subscribe((translations: any) => {
            const popupContent = `
        <div class="popup-container d-flex flex-column">
            <h3 class="bg-light-info flex-grow-1 font-medium-2 p-2 rounded text-center vehicle-number">
                <i class="fas fa-bus text-info mr-1"></i>${vehicle.vehicleNumber}
            </h3>
            <div class="vehicle-stats font-small-3">
                <div style="display: flex; justify-content: space-between;">
                    <span><strong>${translations['DATE']}:</strong></span>
                    <span>${rtData.date.replace('T', ' ').replace('Z', '')}</span>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <span><strong>${translations['Speed']}:</strong></span>
                    <span>${rtData.vitesseInst} km/h</span>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <span><strong>${translations['Temp']}:</strong></span>
                    <span>${rtData.temp} °C</span>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <span><strong>${translations['RPM']}:</strong></span>
                    <span>${rtData.rpm}</span>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <span><strong>${translations['Fuel Level']}:</strong></span>
                    <span>${rtData.car} L</span>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <span><strong>${translations['Distance']}:</strong></span>
                    <span>${rtData.dist} km</span>
                </div>
                  <div style="display: flex; justify-content: space-between;">
                    <span><strong>${translations['Lat']}:</strong></span>
                    <span>${rtData.lat} </span>
                </div>
                  <div style="display: flex; justify-content: space-between;">
                    <span><strong>${translations['Lon']}:</strong></span>
                    <span>${rtData.lon} </span>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <span><strong>${translations['Location']}:</strong></span>
                    <span>${rtData.localite}</span>
                </div>
            </div>
            <button id="trackButton" class="btn btn-info mt-2">
                ${translations['Track']}
            </button>
        </div>
        `;

            marker.setPopupContent(popupContent);

            // Use a setTimeout to ensure the DOM is updated before we try to add the event listener
            setTimeout(() => {
                const trackButton = marker.getPopup()?.getElement().querySelector('#trackButton');
                if (trackButton) {
                    trackButton.addEventListener('click', (e: Event) => {
                        e.preventDefault(); // Prevent default button behavior
                        this.selectedVehicle = vehicle;
                        this.selectedRtData = this.realTimeData.find(rt => parseInt(rt.id, 10) === vehicle.idBoitier);
                        this.openMapModal(this.mapModal);
                       // marker.closePopup();
                    });
                }
           }, 0);
        });
    }

    addVehiclesMarkers(latlng: any, vehicle: Vehicle): void {
        const initialIconSize: [number, number] = [30, 41];
        const iconUrl = vehicle.family.bus ? './assets/img/markers/bus.png' : './assets/img/markers/car.png';
        const marker = this.zoomService.createResizableIcon(this.map, latlng, iconUrl, vehicle.vehicleNumber, initialIconSize);
        marker.on('click', () => {
            this.selectedVehicle = vehicle;
            const rtData = this.realTimeData.find(rt => parseInt(rt.id, 10) === vehicle.idBoitier);
            if (rtData) {
                this.openPopup(rtData, marker, vehicle);
            }
        });

        // onMarkerClick openModal
        marker.on('popupopen', () => {
            const trackButton = marker.getPopup().getElement().querySelector('#trackButton');
            if (trackButton) {
                trackButton.replaceWith(trackButton.cloneNode(true)); // Clear previous listeners

                const newTrackButton = marker.getPopup().getElement().querySelector('#trackButton');
                newTrackButton.addEventListener('click', () => {
                    // this.selectedVehicle = vehicle;
                    this.selectedRtData = this.realTimeData.find(rt => parseInt(rt.id, 10) === vehicle.idBoitier);
                    this.openMapModal(this.mapModal);
                    marker.closePopup();
                });
            }
        });
        // Add the new marker to the array
        this.vehiclesMarkers.push(marker);
    }


    handleMessage(message: any): void {
        const rtData = JSON.parse(JSON.stringify(message));
        const vehicle = this.vehiclesList.find(v => v.idBoitier === parseInt(message.id, 10));
        if (vehicle) {
            this.updateVehicleMarker(vehicle, rtData);
            vehicle.location = this.realTimeData.find(rt => parseInt(rt.id, 10) === vehicle.idBoitier).localite;
            const index = this.realTimeData.findIndex(rt => parseInt(rt.id, 10) === vehicle.idBoitier);
            if (index !== -1) {
                const driver = rtData.chauffeur;
                this.realTimeData[index] = rtData;
                this.realTimeData[index].chauffeur = driver;
            }
        }
    }

    openMapModal(content: any) {
        if (this.mapModalRef) {
            this.mapModalRef.close();
        }

        this.mapModalRef = this.modalService.open(content, { size: 'xl', centered: true });
    }


    closeMapModal() {
        if (this.mapModalRef) {
            this.mapModalRef.close();
        }

        this.getUpdatedData();

        if (this.selectedVehicle && this.selectedRtData) {
            const marker = this.vehiclesMarkers.find(m =>
                m.options.icon.options.text === this.selectedVehicle.vehicleNumber
            );

            if (marker) {
                this.map.setView(marker.getLatLng(), 15);
               // this.updatePopupContent(marker, this.selectedVehicle, this.selectedRtData);
                marker.openPopup();
            }
        }
    }

    unselectVehicle() {
        this.searchQuery = '';
        this.selectedVehicle = null;
        this.selectedRtData = null;
        this.vehiclesMarkers.forEach(marker => {
            marker.closePopup();
        });
        this.fitMapToMarkers();
    }

    filterVehiculeByFamilyName(familyName: string) {
        return this.vehiclesList.filter(v => v.family?.familyName == familyName)
    }

    downloadGtfsRtData() {
        if (this.isExporting) {
            this.gtfsService.exportGtfsRtData().subscribe((data) => {
                const url = window.URL.createObjectURL(data);
                const anchor = document.createElement('a');
                anchor.href = url;
                anchor.download = 'gtfs_rt_feed.pb';  // Set the file name
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
                window.URL.revokeObjectURL(url);
            })
            this.isExporting = false;
        }
    }

    downloadGtfsData() {
        if (this.isExporting) {
            this.gtfsService.exportGtfsData().subscribe((data) => {
                const url = window.URL.createObjectURL(data);
                const anchor = document.createElement('a');
                anchor.href = url;
                anchor.download = 'gtfs.zip';
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
                window.URL.revokeObjectURL(url);
            })
            this.isExporting = false;
        }
    }

    // onSidebarToggle(value){
    //     this.isVehiculeListVisible = value;
    // }

    handleVehicleRt(data: any) {
        this.handleMessage(data);
    }
}
