import { Component, ElementRef, Input, OnDestroy, OnInit } from "@angular/core";
// @ts-ignore
import L from "leaflet";
import { HttpClient, HttpParams } from "@angular/common/http";
import { RealTimeData } from "../vehicles-list-tracking/vehicles-list-tracking.component";
import { SaeService } from "../services/sae.service";
import { Vehicle } from "app/modules/maintenance-management/work-request/models/work-request";
import { OPN_BASE_URL } from "app/shared/global/var";
import { CrudService } from "app/shared/services/crud.service";
import {  NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RouteTripMapComponent } from "../../operating-networks/route-management/route-detail/route-trip-map/route-trip-map.component";
import {ZoomService} from '../../../shared/services/zoom.service';

@Component({
  selector: "app-real-time-map",
  templateUrl: "./real-time-map.component.html",
  styleUrls: ["./real-time-map.component.scss"],
})
export class RealTimeMapComponent implements OnInit, OnDestroy {
  private map = L.map;
  private markers: any[] = [];
  private vehicleMarker: any[] = [];
  private routeLine: any;
  //@ts-ignore
  routeLines: L.Polyline[] = []; // Array to store all route lines
  //@ts-ignore
  osrmRouteLines: L.Polyline[] = []; // Array to store all route lines
  lastLocation: { lat: number; lon: number };
  vehiclesCoordinates: any[] = [];
  archivedVehiclesCoordinates: any[] = [];
  stationsCoordinates: any[] = [];

  language = localStorage.getItem("langue");

  @Input() stations: any[] = [];
  @Input() idBoitier: string;
  @Input() isHistoric = false;
  @Input() startDate: string;
  @Input() endDate: string;
  @Input() vehicle: Vehicle;
  @Input() isTracking = false;
  @Input() trip: any;
  @Input() itinerary: any;
  @Input() dateDepart: any;
  @Input() dateArrivee: any;

  isStationIgnored = false;

  constructor(
    private _elementRef: ElementRef,
    private http: HttpClient,
    private saeService: SaeService,
    private crudService: CrudService,
    private modalService: NgbModal,
    private zoomService: ZoomService
  ) {}

  ngOnInit(): void {
    this.initMap(); // Initialize the map
    this.markers = []; // Initialize the markers array
    if (this.stations && this.stations.length > 0) {
      this.stations.sort((a, b) => a.stationOrder - b.stationOrder);
      for (const station of this.stations) {
        this.addMarkers(
          [station.station.lat, station.station.lon],
          station.station.name
        );
      }
    //  this.map.fitBounds(this.markers.map((marker) => marker.getLatLng()));
      this.map.setView(this.markers[0].getLatLng(), 12);
      if (this.trip) {
        this.getShape();
      }
      if (this.trip && this.trip.bus) {
        this.vehicle = this.trip.bus;
        if (this.trip.status === 3) {
          this.dateDepart = this.trip.rtDeparture
            ? this.trip.rtDeparture
            : this.trip.plannedDeparture;
          this.dateArrivee = this.trip.rtArrival
            ? this.trip.rtArrival
            : this.trip.estimatedArrival;
          this.getArchiveData();
          this.getUpdatedData();
          this.getMostLikelyTrip();

        } else if (this.trip.status === 5) {
          this.isHistoric = true;
          this.dateDepart = this.trip.rtDeparture;
          this.dateArrivee = this.trip.rtArrival;

          this.getMostLikelyTrip();
          this.getArchiveData();
          this.getStopTime();
        } else if (this.dateDepart && this.dateArrivee && this.vehicle) {
            this.getArchiveData();
        }
      }
    } else if (this.vehicle) {
      if (this.dateDepart && this.dateArrivee) {
        this.getArchiveData();
        if(this.vehicle.family.bus){
            this.getMostLikelyTrip();
        }
      } else {
        this.dateDepart = new Date(
          new Date().setHours(new Date().getHours() - 1)
        );
        this.dateArrivee = new Date();
        this.getArchiveData();
      }
    }
  }

  /**
   * Lifecycle hook that is called when the component is destroyed.
   * Clears the map by removing all markers and the route line.
   */
  ngOnDestroy(): void {
    this.markers.forEach((marker) => this.map.removeLayer(marker));
    this.routeLines.forEach((line) => this.map.removeLayer(line));
    this.osrmRouteLines.forEach((line) => this.map.removeLayer(line));
    this.clearMap();
    // this.saeService.closeConnection();
  }

  /**
   * Initializes the map and sets its view to a default center and zoom level.
   * Adds a tile layer to the map and draws the route using OSRM.
   */
  initMap(): void {
    this.clearMap();

    const el = this._elementRef.nativeElement.querySelector(".tracking-map");
    this.map = L.map(el, {
      center: [34.551117, 9.369019],
      zoom: 10,
      minZoom: 5
    });

    L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
      attribution: "",
      attributionControl: false,
    }).addTo(this.map);
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 300);
  }

  /**
   * Adds a marker to the map at the specified latitude and longitude.
   * The marker is customized with a bus stop icon and a popup displaying the station name.
   *
   * @param {any} latlng - The latitude and longitude of the marker.
   * @param {string} stationName - The name of the station to display in the popup.
   */
  addMarkers(latlng: any, stationName: string): void {
    const initialIconSize: [number, number] = [25, 41];
    const iconUrl = "./assets/img/leaflet/bus-stop.png";

    const customIcon = L.icon({
      iconUrl: "./assets/img/leaflet/bus-stop.png",
      iconSize: [25, 41],
      text: stationName,
    });
    const marker = this.zoomService.createResizableIcon(this.map, latlng, iconUrl, stationName, initialIconSize);
    marker.bindPopup(stationName);
    marker.on("click", () => {
      marker.openPopup();
    });
    this.markers.push(marker);
  }

  /**
   * Clears the map by removing all markers and the route line.
   * Resets the markers array and sets the route line to null.
   */
  clearMap(): void {
    this.markers.forEach((marker) => this.map.removeLayer(marker));
    this.markers = [];
    if (this.routeLine) {
      this.map.removeLayer(this.routeLine);
      this.routeLine = null;
    }
  }
  /**
   * Adds a marker for a vehicle to the map.
   * The marker is customized with an icon based on the vehicle type (bus or car).
   *
   * @param {any} latlng - The latitude and longitude of the marker.
   * @param {Vehicle} vehicle - The vehicle object containing details for the marker.
   */
  addVehiclesMarkers(latlng: any, vehicle: Vehicle): void {
    const initialIconSize: [number, number] = [30, 41];
    const iconUrl = vehicle.bus || vehicle.family.bus ? './assets/img/markers/bus.png' : './assets/img/markers/car.png';
    const marker = this.zoomService.createResizableIcon(this.map, latlng, iconUrl, vehicle.vehicleNumber, initialIconSize);
    this.vehicleMarker.push(marker);
    this.vehicleMarker.push(marker);
  }

  /**
   * Adds a marker to the map indicating the start of a trip.
   * The marker is customized with a "go" icon.
   *
   * @param {any} latlng - The latitude and longitude of the marker.
   */
  addTripStartMarker(latlng: any): void {
    const initialIconSize: [number, number] = [25, 25];
    const iconUrl = './assets/img/markers/go.png';
    const marker = this.zoomService.createResizableIcon(this.map, latlng, iconUrl, '', initialIconSize);
    this.markers.push(marker);
  }

  /**
   * Fetches archived data for a specific vehicle within a date range.
   * Converts the departure and arrival dates to ISO strings and makes an HTTP GET request to retrieve the data.
   * Draws the route on the map, adds markers for the last location and trip start, and fetches updated data.
   */
  getArchiveData(): void {
    this.dateDepart = this.dateDepart.replace("T", " ");
    this.dateArrivee = this.dateArrivee.replace("T", " ");
    this.http
      .get(OPN_BASE_URL + `/archive2/${this.vehicle.idBoitier}/dataRange`, {
        params: {
          startDate: this.dateDepart.split('.')[0],
          endDate: this.dateArrivee.split('.')[0]
        },
      })
      .subscribe((res: any) => {
        if (res.length >= 2) {
          const bounds = L.latLngBounds();

          for (let i = 0; i < res.length - 1; i++) {
            const routeLine = L.polyline(
                [
                  [res[i].lat, res[i].lon],
                  [res[i + 1].lat, res[i + 1].lon],
                ],
                {
                  color: "#1e90ff",
                  weight: 5,
                }
            ).addTo(this.map);

            bounds.extend([res[i].lat, res[i].lon]);
            bounds.extend([res[i + 1].lat, res[i + 1].lon]);
            this.routeLines.push(routeLine);
          }
          this.map.fitBounds(bounds, { padding: [50, 50] });
          this.lastLocation = {
            lat: res[res.length - 1].lat,
            lon: res[res.length - 1].lon,
          };
          this.addVehiclesMarkers(
            [this.lastLocation.lat, this.lastLocation.lon],
            this.vehicle
          );

          this.addTripStartMarker([res[0].lat, res[0].lon]);
          this.map.setView(this.lastLocation, 12);
         // this.map.fitBounds([this.lastLocation, [res[0].lat, res[0].lon]]);
          if (this.vehicle && (this.vehicle.bus || this.vehicle.family.bus) && this.isHistoric) {
            this.archivedVehiclesCoordinates = res;
          }
        } else if (res.length === 1) {
          this.lastLocation = {
            lat: res[0].lat,
            lon: res[0].lon,
          };
          this.addVehiclesMarkers(
            [this.lastLocation.lat, this.lastLocation.lon],
            this.vehicle
          );
        }
      });
  }

  /**
   * Connects to the real-time tracking service and subscribes to messages.
   * Handles incoming messages by updating the vehicle marker on the map.
   *
   * @param {number} idTrip - The ID of the trip to track in real-time.
   */
  getUpdatedData(): void {
    this.saeService.connect();
    this.saeService.message$.subscribe((message) => {
      if (this.trip.id === parseInt(message.TripId, 10)) {
        this.handleMessage(message);
      }
    });
  }

  /**
   * Handles incoming real-time tracking messages.
   * Updates the vehicle marker on the map if the message is for the current vehicle.
   *
   * @param {any} message - The incoming message containing real-time tracking data.
   */
  handleMessage(message: any): void {
    this.updateVehicleMarker(this.vehicle, message);
  }

  /**
   * Updates the vehicle marker on the map with new real-time data.
   * Removes the previous marker, creates a new marker with the updated location, and draws the route.
   *
   * @param {Vehicle} vehicle - The vehicle object containing details for the marker.
   * @param {RealTimeData} rtData - The real-time data containing the new location.
   */
  updateVehicleMarker(vehicle: Vehicle, rtData: RealTimeData): void {
    const lat = rtData.lat;
    const lon = rtData.lon;
    const newLatLng = [lat, lon];

    // Remove all previous vehicle markers
    if (this.vehicleMarker.length > 0) {
      this.vehicleMarker.forEach((m) => this.map.removeLayer(m));
    }
    const initialIconSize: [number, number] = [30, 41];
    const iconUrl = vehicle.bus || vehicle.family.bus ? './assets/img/markers/bus.png' : './assets/img/markers/car.png';
    const marker = this.zoomService.createResizableIcon(this.map, newLatLng, iconUrl, vehicle.vehicleNumber, initialIconSize);
    if (this.vehicleMarker.length > 0) {
      this.vehicleMarker[this.vehicleMarker.length - 1].remove();
    }
    this.vehicleMarker = [marker];
    this.vehiclesCoordinates.push(newLatLng);
    if (this.lastLocation) {
      // Draw the route from stored vehicle coordinates to the new location
      const color = rtData.isAlert ? "red" : "#1e90ff";
      const routeLine = L.polyline([this.lastLocation, newLatLng], {
        color: color,
        weight: 5,
      }).addTo(this.map);
      this.routeLines.push(routeLine);
    }
    this.lastLocation = { lat, lon };
  }

  getShape(): void {
    this.crudService
      .getAll(OPN_BASE_URL + `/shape/all/${this.itinerary.id}`)
      .subscribe((res: any) => {
        if(!res || res.length === 0) {
          this.drawRouteOSRM(this.stations).then(
            () => {
                console.info('Route drawn with OSRM');
            }
          )
        } else {
          const coordinates = res.map((coord: any) => {
            return [coord.lat, coord.lon];
          });

          const newRouteLine = L.polyline(coordinates, {
            color: '#228B22',
            weight: 3,
          }).addTo(this.map);
          this.osrmRouteLines.push(newRouteLine);
          // Fit the map bounds to the route line
          const group = new L.featureGroup(this.osrmRouteLines);
        }
      });
  }

  /**
   * Draws a route on the map using the OSRM API.
   * Fetches the route data from OSRM and draws a polyline on the map.
   * Adjusts the map bounds to fit the route.
   *
   * @param {Object[]} stations - An array of station objects with latitude and longitude properties.
   * @returns {Promise<void>} A promise that resolves when the route is drawn.
   */
  async drawRouteOSRM(stations: { lat: number; lon: number }[]): Promise<void> {
    if (stations.length < 2) {
      return;
    }

    const coordinates = stations
        .map((station) => `${station.lon},${station.lat}`)
        .join(';');
    const url = `/route/v1/driving/${coordinates}?overview=full&geometries=geojson`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.code !== 'Ok') {
        console.error('OSRM request failed:', data);
        return;
      }

      const route = data.routes[0];

      const routeCoordinates = route.geometry.coordinates.map((coord: any[]) => [
        coord[1],
        coord[0],
      ]);

      if (this.routeLine) {
        this.map.removeLayer(this.routeLine);
      }
      this.routeLine = L.polyline(routeCoordinates, {
        color: 'blue',
        weight: 5,
      }).addTo(this.map);

      // Fit the map to the bounds of the route
      this.map.fitBounds(this.routeLine.getBounds());
    } catch (error) {
      console.error('Error fetching OSRM route:', error);
    }
  }

  getMostLikelyTrip(): void {
    let tripId = 0;
    if(!this.trip) {
      this.crudService
          .getAllWithParams(
              OPN_BASE_URL + `/trips-instance/bus-trip/${this.vehicle.id}`,
              new HttpParams().set(
                  'date',
                  this.dateDepart.replace('T', ' ').replace('Z', '')
              )
          )
          .subscribe((res: any) => {
            if (!res) {
              return;
            }
            tripId = res.idTrip;
          });
    } else {
      tripId = this.trip.id
    }

    this.crudService
          .getAll(OPN_BASE_URL + `/shape/trip/${tripId}`)
          .subscribe((res: any) => {
            if(!res) return
            if (res.length > 2) {
              // Draw the route
              for (let i = 0; i < res.length - 1; i++) {
                // Fix: Stop before the last element
                const routeLine = L.polyline(
                  [
                    [res[i].lat, res[i].lon],
                    [res[i + 1].lat, res[i + 1].lon],
                  ],
                  {
                    color: "#228B22",
                    weight: 2,
                  }
                ).addTo(this.map);
                this.routeLines.push(routeLine);
              }
              this.map.setView(this.lastLocation, 15);
              this.map.fitBounds([this.lastLocation, [res[0].lat, res[0].lon]]);
            }
            this.crudService
              .getAll(
                OPN_BASE_URL +
                  "/itinerary-station/by-itinerary/" +
                  res[0].itinerary.id
              )
              .subscribe((itineraryStations: any[]) => {
                this.stationsCoordinates = itineraryStations;
                for (const station of itineraryStations) {
                  this.addMarkers(
                    [station.station.lat, station.station.lon],
                    station.station.name
                  );
                }
              });
          });
  }

  addItineraryToggleButton(): void {
    const buttonControl = L.control({ position: "topright" });

    buttonControl.onAdd = () => {
      const button = L.DomUtil.create("button", "btn btn-info");
      button.innerHTML =
        this.language === "fr" ? "Sauvegarder l'itineraire" : "Save itinerary";
      button.onclick = () => {
        const modalRef = this.modalService.open(RouteTripMapComponent, {
          size: "lg",
        });
        modalRef.componentInstance.stations = this.stations; // Pass data to modal if needed
        modalRef.componentInstance.shape = this.archivedVehiclesCoordinates;
        modalRef.componentInstance.createItinerary = true;
        modalRef.componentInstance.trip = this.trip;
        modalRef.componentInstance.itinerary = this.itinerary;

        modalRef.result.then(
          (result) => {
            console.info("Modal closed with result:", result);
          },
          (reason) => {
            console.info("Modal dismissed:", reason);
          }
        );
      };
      return button;
    };

    buttonControl.addTo(this.map);
  }

  getStopTime(): any {
    this.crudService
      .getAll(OPN_BASE_URL + `/stop-times/trips-instance/${this.trip.id}`)
      .subscribe((r: any) => {
        r.map((stopTime: any) => {
          if (stopTime.state === 3) {
            this.isStationIgnored = true;
          }
          this.stations.map((station: any) => {
            if (station.stationId === stopTime.itineraryStation.stationId) {
              station.status = stopTime.state;
              if (stopTime.rtArrivalTime) {
                station.duration =
                  Math.round(
                    new Date(stopTime.rtArrivalTime).getTime() -
                      new Date(this.trip.rtDeparture).getTime()
                  ) / 60000;
              }
            }
          });
        });
        if (this.isStationIgnored) {
          this.addItineraryToggleButton();
        }
      });
  }
}
