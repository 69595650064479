import { Component, Input, OnInit } from "@angular/core";
import { Station } from "app/modules/operating-networks/stations-management/models/stations.model";
import { OPN_BASE_URL } from "app/shared/global/var";
import { CrudService } from "app/shared/services/crud.service";

@Component({
  selector: "app-trip-deviation",
  templateUrl: "./trip-deviation.component.html",
  styleUrls: ["./trip-deviation.component.scss"],
})
export class TripDeviationComponent implements OnInit {
  @Input() itinerary: any;
  @Input() trip: any;
  @Input() stations: Array<Station> = [];

  constructor() {}

  ngOnInit(): void {
  }
}
