<div class="chart-wrapper">
    <!-- Title -->
    <div class="chart-title">{{ title }}</div>

    <!-- Subtitle -->
    <div *ngIf="subtitle" class="chart-subtitle">
        <ng-container *ngIf="isSlashSubtitle(); else noSlashTemplate">
            <span class="before-slash">{{ getSubtitlePart(0) }}</span> /
            <span class="after-slash">{{ getSubtitlePart(1) }}</span>
        </ng-container>
        <ng-template #noSlashTemplate>
            <span class="no-slash">{{ subtitle }}</span>
        </ng-template>
    </div>

    <!-- Chart Container -->
    <div [id]="id" #chartContainer class="chart-container"></div>

    <!-- No Data Message -->
    <div *ngIf="data.length === 0" class="no-data-message">
        <i class="icon-ban font-medium-4"></i>
        <p class="font-small-4 secondary darken-1">
            {{ "NO_DATA_DATE_RANGE" | translate }}
        </p>
    </div>

    <!-- New Value Section (if showOld is false) -->
    <div *ngIf="!showOld" class="value-section">
        <div class="new-value-1">
            <ng-container *ngIf="isSlashNewValue(); else noSlashValue">
                <span class="before-slash">{{ getNewValuePart(0) }}</span> /
                <span class="after-slash">{{ getNewValuePart(1) }}</span>
            </ng-container>
            <ng-template #noSlashValue>
                <span class="no-slash">{{ newValue }}</span>
            </ng-template>
        </div>
    </div>

    <!-- Old Value and Percentage Change (if showOld is true) -->
    <div *ngIf="showOld" class="value-section">
        <div class="new-value">
            <ng-container *ngIf="isSlashNewValue(); else noSlashValue">
                <span class="before-slash">{{ getNewValuePart(0) }}</span> /
                <span class="after-slash">{{ getNewValuePart(1) }}</span>
            </ng-container>
            <ng-template #noSlashValue>
                <span class="no-slash">{{ newValue }}</span>
            </ng-template>
        </div>

        <div class="old-value">

            <ng-container *ngIf="isSlashOldValue(); else noSlashOldValue">
                <span class="before-slash">{{ old.split('/')[0] }}</span> /
                <span class="after-slash">{{ old.split('/')[1] }}</span>
            </ng-container>
            <ng-template #noSlashOldValue>
                <span class="no-slash">{{ old }}</span>
            </ng-template>
            <div class="percentage-change" [ngStyle]="{'border-color': percentageColor()}">
                <span [style.color]="percentageColor()">{{ percentageArrow() }}{{ percentage }}%</span>
            </div>
        </div>
    </div>
</div>