import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { BASE_API_ENERGY, OPN_BASE_URL } from "../global/var";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class CrudService {

  private apiUrl = '${OPN_BASE_URL}/trips-instances';
  constructor(private http: HttpClient) {}
  private cache: { [key: string]: any } = {};
  
/**
 * Fetches paginated items from the specified URL.
 * 
 * @param <T> The type of the observable response.
 * @param url The endpoint URL to fetch data from.
 * @param offset The offset for pagination.
 * @param limit The number of items to fetch.
 * @param username (Optional) The username to include in the query.
 * @return An Observable containing the paginated items.
 */
getAllPaginate<T>(url: string, offset: number, limit: number, username?: string,  startDate?: string,
  endDate?: string): Observable<T> {
  // Start constructing the query parameters
  let queryParams = `offset=${offset}&limit=${limit}`;
  
  // Add the username parameter if it's provided
  if (username) {
    queryParams += `&username=${encodeURIComponent(username)}`;
  }
  if (startDate) {
    queryParams += `&startDate=${encodeURIComponent(startDate)}`;
  }

  // Add endDate if it's provided
  if (endDate) {
    queryParams += `&endDate=${encodeURIComponent(endDate)}`;
  }

  // Check if the URL already contains query parameters
  const urlWithQueryParams = url.includes("?") ? `${url}&${queryParams}` : `${url}?${queryParams}`;

  // Make the HTTP GET request with the constructed URL
  return this.http.get<T>(urlWithQueryParams);
}



getPaginate<T>(url: string): Observable<T> {
  // Faire la requête HTTP GET directement avec l'URL fournie (qui contient déjà les paramètres)
  return this.http.get<T>(url);
}


getDataByVehicleAndDateRange<T>(url: string, startDate?: string, endDate?: string): Observable<T> {
  let queryParams = '';

  // Ajouter uniquement les paramètres de date
  if (startDate) {
    queryParams += `startDate=${encodeURIComponent(startDate)}`;
  }
  if (endDate) {
    queryParams += queryParams ? `&endDate=${encodeURIComponent(endDate)}` : `endDate=${encodeURIComponent(endDate)}`;
  }

  const urlWithQueryParams = url.includes("?") ? `${url}&${queryParams}` : `${url}?${queryParams}`;

  return this.http.get<T>(urlWithQueryParams);
}


getAverageConsumption(vehicleId: number, startDate: string, endDate: string): Observable<number> {
  const url = `${BASE_API_ENERGY}/refuels/average-consumption/${vehicleId}`;
  const params = new HttpParams()
    .set('startDate', startDate)
    .set('endDate', endDate);

  return this.http.get<number>(url, { params });
}



getNumber(url: string): Observable<number> {
  return this.http.get<any>(url).pipe(
    map(response => response.value)
  );
}


  
  /**
 * Fetches all items from the specified URL.
 * 
 * @param <T> The type of the observable response.
 * @param url The endpoint URL to fetch data from.
 * @return An Observable containing all items.
 */

  getAll<T>(url: string): Observable<T> {
    return this.http.get<T>(url);
  }
  
  /**
 * Fetches a single item by its ID from the specified URL.
 * 
 * @param <T> The type of the observable response.
 * @param url The endpoint URL to fetch data from.
 * @param id The ID of the item to fetch.
 * @return An Observable containing the item.
 */

  // getOne<T>(url: string, id: string): Observable<T> {
  //   return this.http.get<T>(`${url}/${id}`);
  // }

  getOne<T>(url: string, id1: string, id2?: string): Observable<T> {
   
    const fullUrl = id2 ? `${url}/${id1}/${id2}` : `${url}/${id1}`;
    return this.http.get<T>(fullUrl);
  }
  
  /**
 * Fetches a single item by its ID along with specified relations from the URL.
 * 
 * @param <T> The type of the observable response.
 * @param url The endpoint URL to fetch data from.
 * @param id The ID of the item to fetch.
 * @param relations The related entities to include.
 * @return An Observable containing the item with its relations.
 */

  getOneWithRelations<T>(
    url: string,
    id: string,
    relations: string[]
  ): Observable<T> {
    const relationsQuery = relations.join(",");
    return this.http.get<T>(`${url}/${id}?relations=${relationsQuery}`);
  }
  
  /**
 * Posts data to the specified URL.
 * 
 * @param url The endpoint URL to post data to.
 * @param values The data to post.
 * @return An Observable containing the server response.
 */

  post(url: string, values: any): Observable<any> {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.post(url, values, { headers });
  }
  
  /**
 * Updates an item by its ID at the specified URL.
 * 
 * @param url The endpoint URL to update data at.
 * @param id The ID of the item to update.
 * @param values The data to update.
 * @return An Observable containing the server response.
 */

  update(url: string, id: string, values: any): Observable<any> {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.put(`${url}/${id}`, values, { headers });
  }
  
  /**
 * Updates multiple items at the specified URL.
 * 
 * @param url The endpoint URL to update data at.
 * @param ids The IDs of the items to update.
 * @return An Observable containing the server response.
 */

  updateMultiple(url: string, ids: number[]): Observable<any> {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    
    return this.http.put<any>(url, ids, { headers });
  }
  
  /**
 * Cancels a mission by its ID at the specified URL with a cancellation reason.
 * 
 * @param url The endpoint URL to cancel the mission at.
 * @param id The ID of the mission to cancel.
 * @param msg The cancellation reason.
 * @return An Observable containing the server response.
 */

  cancelMission(url: string, id: string, msg: any): Observable<any> {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.put(`${url}/${id}`, { cancelReason: msg }, { headers });
  }
  
  /**
 * Deletes an item by its ID from the specified URL.
 * 
 * @param url The endpoint URL to delete data from.
 * @param id The ID of the item to delete.
 * @return An Observable containing the server response.
 */

  delete(url: string, id: string): Observable<any> {
    return this.http.delete(`${url}/${id}`);
  }
  
  /**
 * Deletes multiple items by their IDs from the specified URL.
 * 
 * @param url The endpoint URL to delete data from.
 * @param ids The IDs of the items to delete.
 * @return An Observable containing the server response.
 */

  deleteMultiple(url: string, ids: number[]): Observable<any> {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.delete<any>(url, { headers, body: ids });
  }
  
  /**
 * Downloads a file from the specified URL.
 * 
 * @param url The endpoint URL to download the file from.
 * @param body The request body (optional).
 * @return An Observable containing the file as an ArrayBuffer.
 */

  download(url: string, body = {}): Observable<ArrayBuffer> {
    return this.http.post(url, body, { responseType: "arraybuffer" });
  }
  
  /**
 * Checks the availability of a driver within a specified date range.
 * 
 * @param url The endpoint URL to check availability at.
 * @param selectedMissionId The ID of the selected mission.
 * @param id The ID of the driver.
 * @param startDate The start date of the availability check.
 * @param endDate The end date of the availability check.
 * @return An Observable containing the availability status.
 */

  checkDriverAvailability(
    url: string,
    selectedMissionId: any,
    id: number,
    startDate: string,
    endDate: string
  ): Observable<any> {
    const params = new HttpParams()
      .set("driverId", id)
      .set("startDate", startDate)
      .set("endDate", endDate)
      .set("selectedMissionId", selectedMissionId);

    return this.http.get<any>(url, { params, observe: "response" });
  }
  
  /**
 * Checks the availability of a receiver within a specified date range.
 * 
 * @param url The endpoint URL to check availability at.
 * @param selectedMissionId The ID of the selected mission.
 * @param id The ID of the receiver.
 * @param startDate The start date of the availability check.
 * @param endDate The end date of the availability check.
 * @return An Observable containing the availability status.
 */

  checkReceiverAvailability(
    url: string,
    selectedMissionId: any,
    id: number,
    startDate: string,
    endDate: string
  ): Observable<any> {
    const params = new HttpParams()
      .set("receiverId", id)
      .set("startDate", startDate)
      .set("endDate", endDate)
      .set("selectedMissionId", selectedMissionId);

    return this.http.get<any>(url, { params, observe: "response" });
  }
  
  /**
 * Checks the availability of a bus within a specified date range.
 * 
 * @param url The endpoint URL to send the request to.
 * @param selectedMissionId The ID of the selected mission.
 * @param id The ID of the bus.
 * @param startDate The start date of the availability check (ISO 8601 format).
 * @param endDate The end date of the availability check (ISO 8601 format).
 * @returns An Observable of the HTTP response.
 */

  checkBusAvailability(
    url: string,
    selectedMissionId: any,
    id: number,
    startDate: string,
    endDate: string
  ): Observable<any> {
    const params = new HttpParams()
      .set("busId", id)
      .set("startDate", startDate)
      .set("endDate", endDate)
      .set("selectedMissionId", selectedMissionId);

    return this.http.get<any>(url, { params, observe: "response" });
  }
  
  /**
 * Retrieves data from the specified URL with the given parameters.
 * 
 * @param url The endpoint URL to send the request to.
 * @param id The service group ID.
 * @param startDate The start date of the range (ISO 8601 format).
 * @param endDate The end date of the range (ISO 8601 format).
 * @returns An Observable of the HTTP response.
 */

  getWithParams(
    url: string,
    id: number,
    startDate: string,
    endDate: string
  ): Observable<any> {
    const encodedStartDate = encodeURIComponent(startDate);
    const encodedEndDate = encodeURIComponent(endDate);
    return this.http.get<any>(
      `${url}?serviceGroupId=${id}&startDate=${encodedStartDate}&endDate=${encodedEndDate}`
    );
  }
  
  /**
 * Removes an entity by its ID.
 *
 * @param url - The endpoint URL to call.
 * @param id - The ID of the entity to remove.
 * @returns An Observable of the HTTP response.
 */

  remove(url: string, id: number): Observable<any> {
    return this.http.put(`${url}/${id}`, {});
  }

  removeStaffFromTrip(staffId: number, tripId: number): Observable<any> {
    const url = `${OPN_BASE_URL}/trips-instance/remove-staff/${staffId}/${tripId}`;
    return this.http.put(url, {});
  }
  
  
  /**
 * Removes multiple entities by their IDs.
 *
 * @param url - The endpoint URL to call.
 * @param ids - An array of IDs of the entities to remove.
 * @returns An Observable of the HTTP response.
 */

  removeMultiple(url: string, ids: number[]): Observable<any> {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.put(`${url}`, { ids }, { headers });
  }

  /**
 * Updates trip instances with the provided values.
 *
 * @param url - The endpoint URL to call.
 * @param tripIds - An array of trip IDs to update.
 * @param updatedValues - The updated values for the trips.
 * @returns An Observable of the HTTP response.
 */
  updateTripsInstances(
    url: string,
    tripIds: number[],
    updatedValues: any
  ): Observable<any> {
    let params = new HttpParams();
    tripIds.forEach((id) => {
      params = params.append("ids", id.toString());
    });

    return this.http.put(url, updatedValues, { params });
  }
  
  /**
 * Updates trip instances with the provided values for drivers.
 *
 * @param url - The endpoint URL to call.
 * @param tripIds - An array of trip IDs to update.
 * @param updatedValues - The updated values for the trips.
 * @returns An Observable of the HTTP response.
 */

  updateTripsInstancesDriver(
    url: string,
    tripIds: number[],
    updatedValues: any
  ): Observable<any> {
    let params = new HttpParams();
    tripIds.forEach((id) => {
      params = params.append("ids", id);
    });
    return this.http.put(url, updatedValues, { params });
  }
  
  /**
 * Updates trip instances with the provided values for receivers.
 *
 * @param url - The endpoint URL to call.
 * @param tripIds - An array of trip IDs to update.
 * @param updatedValues - The updated values for the trips.
 * @returns An Observable of the HTTP response.
 */

  updateTripsInstancesReceiver(
    url: string,
    tripIds: number[],
    updatedValues: any
  ): Observable<any> {
    let params = new HttpParams();
    tripIds.forEach((id) => {
      params = params.append("ids", id);
    });
    return this.http.put(url, updatedValues, { params });
  }
  

  /**
 * Updates trip instances for buses.
 *
 * @param url - The API endpoint URL.
 * @param tripIds - An array of trip IDs.
 * @param updatedValues - The updated values to be sent in the request body.
 * @returns An Observable with the HTTP response.
 */

  updateTripsInstancesBus(
    url: string,
    tripIds: number[],
    updatedValues: any
  ): Observable<any> {
    let params = new HttpParams();
    tripIds.forEach((id) => {
      params = params.append("ids", id);
    });
    return this.http.put(url, updatedValues, { params });
  }

/**
 * Retrieves data within a specific date range.
 *
 * @param url - The API endpoint URL.
 * @param startDate - The start date.
 * @param endDate - The end date.
 * @returns An Observable with the retrieved data.
 */

getInSpecificRange(url: string, startDate: string, endDate: string): Observable<any> {
  return this.http.get<any>(`${url}?startDate=${startDate}&endDate=${endDate}`);
}
 
/**
 * Retrieves trips by route ID and date range.
 *
 * @param url - The API endpoint URL.
 * @param lineNumber - The line number.
 * @param startDate - The start date.
 * @param endDate - The end date.
 * @returns An Observable with the retrieved trips.
 */

getTripsByRouteIdAndDateRange(url: string,lineNumber: number, startDate: string, endDate: string): Observable<any> {
  const params = new HttpParams()
    .set("lineNumber", lineNumber.toString())
    .set("startDate", startDate)
    .set("endDate", endDate);

  return this.http.get<any>(url, { params });
}

getTripsByRouteIdDateRangeAndPagination(url: string,lineNumber: number, startDate: string, endDate: string, page:number,perPage:number): Observable<any> {
  const params = new HttpParams()
    .set("lineNumber", lineNumber.toString())
    .set("startDate", startDate)
    .set("endDate", endDate)
    .set("page", page)
    .set("perPage", perPage);

  return this.http.get<any>(url, { params });
}

/**
 * Retrieves trips by bus ID and date range.
 *
 * @param url - The API endpoint URL.
 * @param busId - The bus ID.
 * @param startDate - The start date.
 * @param endDate - The end date.
 * @returns An Observable with the retrieved trips.
 */

getTripsByBusIdAndDateRange(url: string,busId: number, startDate: string, endDate: string): Observable<any> {
  const params = new HttpParams()
    .set("busId", busId.toString())
    .set("startDate", startDate)
    .set("endDate", endDate);

  return this.http.get<any>(url, { params });
}

  /**
   * Verifies trip schedules by sending a request to the backend.
   *
   * @param url The endpoint URL of the backend API.
   * @param startDate The start date for verification.
   * @param days The number of days to check.
   * @param instancesToCheck The list of trip instances to verify.
   * @returns An Observable containing the list of missing trip instances.
   */


  verifyTripSchedule(
    url: string,
    startDate: string,
    days: number,
    instancesToCheck: any[]
  ): Observable<HttpResponse<any>> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    // Append startDate and days as query parameters to the URL
    const apiUrl = `${url}?date=${startDate}&days=${days}`;

    return this.http.post<any>(apiUrl, instancesToCheck, { headers, observe: 'response' });
  }


 
/**
 * Checks the availability of the given trips within the specified date range.
 *
 * @param url The URL to which the availability request should be sent.
 * @param trips The array of trip objects for which availability is to be checked.
 * @param startDate The start date for the availability check (formatted as a string).
 * @param endDate The end date for the availability check (formatted as a string).
 * @returns An Observable of the HTTP response containing the availability data.
 */

  checkAvailabilityForTrips(url: string, trips: any[], startDate: string, endDate: string): Observable<any> {
    
    const params = {
        startDate: startDate,
        endDate: endDate
    };

   
    return this.http.post(url, trips, { 
        params: params,
        observe: 'response' 
    });
}

/**
 * Updates the grouped instances by sending a PUT request to the specified URL.
 *
 * @param url The URL to which the update request should be sent.
 * @param groupedInstances The array of grouped instance objects to be updated.
 * @returns An Observable of the HTTP response containing the result of the update operation.
 */

updateGroupedInstances(url: string, groupedInstances: any[]): Observable<any> {
  return this.http.put(url, groupedInstances);
}

getAllWithParams(url: string, params: HttpParams): Observable<any> {
  return this.http.get(url, {params});
}








}
