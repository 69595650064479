import { CrudService } from "app/shared/services/crud.service";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as L from "leaflet";
import { OPN_BASE_URL, STATIONS } from "app/shared/global/var";
import { NotyService } from "app/shared/services/noty.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MapComponent } from "app/shared/components/map/map.component";

@Component({
  selector: "app-stations-form",
  templateUrl: "./stations-form.component.html",
  styleUrls: ["./stations-form.component.scss"],
})
export class StationsFormComponent implements OnInit {
  stationForm: FormGroup; // Form group for the station form
  id; // ID of the station (if editing)
  @ViewChild(MapComponent) mapComponent: MapComponent; // Reference to the MapComponent
  @Output() closeModal = new EventEmitter<void>();
  @Input() isModal: boolean = false;
  langue = localStorage.getItem("langue");
  constructor(
    private fb: FormBuilder,
    private crudService: CrudService,
    private notyService: NotyService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    // Subscribe to route parameters to get the station ID
    this.route.params.subscribe((params) => {
      this.id = +params["id"];
    });
  }

  ngOnInit(): void {
    this.initForm(); // Initialize the form
    this.fetchStationData(); // Fetch the station data if an ID is present
  }

  /**
   * Initialize the station form with validation rules.
   */
  initForm(): void {
    this.stationForm = this.fb.group({
      name: ["", Validators.required],
      type: ["", Validators.required],
      lat: ["", [Validators.required, Validators.min(-90), Validators.max(90)]],
      lon: [
        "",
        [Validators.required, Validators.min(-180), Validators.max(180)],
      ],
      status: [1, Validators.required],
    });
  }

  /**
   * Lifecycle hook that runs after the component's view has been fully initialized.
   * Used here to subscribe to changes in the latitude and longitude fields.
   */
  ngAfterViewInit(): void {
    this.stationForm.get("lat").valueChanges.subscribe(() => {
      this.updateMarkerPosition(); // Update marker position on latitude change
    });
    this.stationForm.get("lon").valueChanges.subscribe(() => {
      this.updateMarkerPosition(); // Update marker position on longitude change
    });
  }

  /**
   * Update the marker position on the map based on the latitude and longitude form values.
   */
  updateMarkerPosition(): void {
    if (this.mapComponent && this.stationForm) {
      const lat = this.stationForm.get("lat").value;
      const lon = this.stationForm.get("lon").value;
      if (lat && lon) {
        if (this.id) {
          this.mapComponent.setMarker(L.latLng(lat, lon), "isEdited"); // Set edited marker if ID exists
        } else {
          this.mapComponent.setMarker(L.latLng(lat, lon), ""); // Set new marker if no ID
        }
      }
    }
  }

  /**
   * Handles the selection of a location on the map.
   * @param {Object} location - The selected location with latitude and longitude.
   * @param {number} location.lat - Latitude of the selected location.
   * @param {number} location.lon - Longitude of the selected location.
   */
  onLocationSelected(location: { lat: number; lon: number }): void {
    this.stationForm.patchValue({
      lat: location.lat,
      lon: location.lon,
    });
  }

  /**
   * Fetch the station data from the API if an ID is present.
   */
  fetchStationData(): void {
    if (this.id) {
      this.crudService.getOne(OPN_BASE_URL + STATIONS, this.id).subscribe(
        (station) => {
          this.stationForm.patchValue(station); // Populate the form with station data
        },
        (error) => {
          console.error("Error fetching station data", error);
        }
      );
    }
  }

  /**
   * Handle the form submission to either create a new station or update an existing one.
   */
  onSubmit(): void {
    if (this.stationForm.valid) {
      const station = this.stationForm.value;
      const url = OPN_BASE_URL + STATIONS;

      if (this.id) {
        // If we will update the station
        this.crudService.update(url, this.id, station).subscribe(
          (response) => {

            const successMessage = this.langue === 'fr' 
            ? "Station mise à jour avec succès" 
            : "Station updated successfully";
          this.notyService.displayNotification(successMessage, "success");
            this.router.navigate(["operating-networks/stations/list"]); // Navigate to the list page
          },
          (error) => {
            console.error("Error saving station", error);
            const failedMessage = this.langue === 'fr' 
            ? "Échec de la mise à jour" 
            : "Failed Update";
          this.notyService.displayNotification(failedMessage, "error");
          }
        );
      } else {
        // If we will add a station
        this.crudService.post(url, station).subscribe(
          (response) => {
  
            const successMessage = this.langue === 'fr' 
            ? "Station ajoutée avec succès" 
            : "Station added successfully";
          this.notyService.displayNotification(successMessage, "success");

            !this.isModal
              ? this.router.navigate(["operating-networks/stations/list"])
              : this.closeModal.emit(); // Navigate to the list page
          },
          (error) => {
            console.error("Error saving station", error);
            const failedMessage = this.langue === 'fr' 
            ? "Échec de l'ajout" 
            : "Failed Add";
          this.notyService.displayNotification(failedMessage, "error");
          }
        );
      }
    }
  }
  cancel() {
    !this.isModal
      ? this.router.navigate(["operating-networks/stations/list"])
      : this.closeModal.emit();
  }
}
