import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { NgbActiveModal, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { M_BASE_URL } from "app/shared/global/var";
import { CrudService } from "app/shared/services/crud.service";
import { forkJoin } from "rxjs";

@Component({
  selector: "app-work-diary",
  templateUrl: "./work-diary.component.html",
  styleUrls: ["./work-diary.component.scss"],
})
export class WorkDiaryComponent implements OnInit {
  @Input() operator: any;
  @Input() intervention: any;
  workDiaryForm: FormGroup;
  isFormVisible = false;
  timeSlots: any;
  workDiary;
  groupedWorkDiary: any[];
  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private crudService: CrudService
  ) {}

  /**
   * Initializes the component by setting up the form and retrieving necessary data.
   * This method is automatically called when the component is initialized.
   */

  ngOnInit(): void {
    this.initForm();
    this.getTimeSlotList();
    this.getWorkDiaryByOperator();
  }

  /**
   * Initializes the reactive form for the work diary. The form contains a date field
   * and an array of diary entries.
   */

  initForm() {
    this.workDiaryForm = this.fb.group({
      date: [null, Validators.required],
      entries: this.fb.array([]),
    });
  }

  /**
   * Returns the 'entries' form array from the work diary form.
   *
   * @returns {FormArray} The form array representing work diary entries.
   */

  get entries(): FormArray {
    return this.workDiaryForm.get("entries") as FormArray;
  }

  /**
   * Makes the work diary form visible to allow the user to add a new entry.
   */

  addWorkDiary() {
    this.isFormVisible = true;
  }

  /**
   * Updates the form's date field with the selected date from the date picker.
   *
   * @param date {NgbDateStruct} The selected date from the date picker.
   */

  onDateSelect(date: NgbDateStruct) {
    this.workDiaryForm.patchValue({ date });
  }

  /**
   * Formats a date (NgbDateStruct) into a string in the 'YYYY-MM-DD' format.
   *
   * @param date {NgbDateStruct} The date to format.
   * @returns {string} The formatted date string or an empty string if the date is invalid.
   */

  formatDate(date: NgbDateStruct): string {
    if (!date) return "";
    const year = date.year;
    const month = date.month.toString().padStart(2, "0");
    const day = date.day.toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  /**
   * Saves the work diary entries to the server if the form is valid. Filters the entries
   * to include only those with valid start and end times, calculates the duration for each entry,
   * and sends the data to the server.
   */

  save() {
    if (this.workDiaryForm.valid) {
      const formValue = this.workDiaryForm.value;
      const formattedDate = this.formatDate(formValue.date);
      const workDiaries = formValue.entries
        .filter((entry) => entry.startTime && entry.endTime)
        .map((entry) => ({
          date: formattedDate,
          startTime: entry.startTime,
          endTime: entry.endTime,
          duration: this.calculateDurationInMinutes(
            entry.startTime,
            entry.endTime
          ),
          sessionId: entry.sessionId,
          operatorId: this.operator.id,
          interventionId: this.intervention.id,
        }));

      if (workDiaries.length > 0) {
        this.crudService
          .post(M_BASE_URL + "/workDiary/add", workDiaries)
          .subscribe(
            (response) => {
              this.isFormVisible = false;
              this.getWorkDiaryByOperator();

              const totalDuration = workDiaries.reduce(
                (sum, diary) => sum + diary.duration,
                0
              );
              this.activeModal.close(totalDuration);
            },
            (error) => {
              console.error("Error saving work diaries", error);
            }
          );
      } else {
        console.warn("No valid entries to save");
      }
    }
  }

  /**
   * Closes the modal dialog without saving any changes.
   */

  closeModal() {
    this.activeModal.dismiss("Modal Closed");
  }

  /**
   * Retrieves the list of time slots from the server and initializes the form entries accordingly.
   */

  getTimeSlotList() {
    this.crudService
      .getAll<any>(M_BASE_URL + "/timeSlot")
      .subscribe((data: any) => {
        this.timeSlots = data;
        this.initEntries();
      });
  }

  /**
   * Clears the current diary entries and initializes new entries for each available time slot.
   */

  initEntries() {
    this.entries.clear();
    this.timeSlots.forEach((slot) => {
      this.entries.push(this.createEntry(slot));
    });
  }

  /**
   * Retrieves the work diary data for the selected operator and intervention from the server.
   * Groups the retrieved data by date and updates the work diary entries.
   */

  getWorkDiaryByOperator() {
    forkJoin({
      timeSlots: this.crudService.getAll<any>(`${M_BASE_URL}/timeSlot`),
      workDiary: this.crudService.getOne<any>(
        `${M_BASE_URL}/workDiary`,
        this.operator.id,
        this.intervention.id
      ),
    }).subscribe(
      (result: any) => {
        this.timeSlots = result.timeSlots;
        this.workDiary = result.workDiary.map((diaryItem: any) => {
          const matchingTimeSlot = this.timeSlots.find(
            (slot: any) => slot.id === diaryItem.sessionId
          );
          return {
            ...diaryItem,
            sessionName: matchingTimeSlot
              ? matchingTimeSlot.session
              : "Unknown Session",
            formattedDuration: this.formatDuration(diaryItem.duration),
          };
        });
        this.groupedWorkDiary = this.transformInstancesByDate();
      },
      (error) => {
        console.error("Error retrieving data:", error);
      }
    );
  }

  /**
   * Groups the work diary entries by date.
   *
   * @returns {any[]} An array of grouped work diary entries, with each group representing a specific date.
   */

  transformInstancesByDate(): any[] {
    if (!this.workDiary) return [];

    const grouped = this.workDiary.reduce((acc, item) => {
      const date = item.date.split("T")[0];
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {} as { [key: string]: any[] });

    return Object.keys(grouped).map((date) => ({
      date,
      instances: grouped[date],
      isCollapsed: false, // Initially, all groups are open
    }));
  }

  /**
   * Toggles the collapsed state of a work diary entry group.
   *
   * @param index {number} The index of the group to toggle.
   */

  toggleCollapse(index: number) {
    this.groupedWorkDiary[index].isCollapsed =
      !this.groupedWorkDiary[index].isCollapsed;
  }

  /**
   * Returns the name of the day of the week for a given date string.
   *
   * @param dateString {string} The date string in 'YYYY-MM-DD' format.
   * @returns {string} The name of the day of the week in French.
   */

  getDayOfWeek(dateString: string): string {
    const days = [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
    ];
    const date = new Date(dateString);
    return days[date.getDay()];
  }

  /**
   * Calculates the duration in minutes between two time strings.
   *
   * @param startTime {string} The start time in 'HH:mm' format.
   * @param endTime {string} The end time in 'HH:mm' format.
   * @returns {number} The duration in minutes between the start and end times.
   */

  calculateDurationInMinutes(startTime: string, endTime: string): number {
    const start = new Date(`1970-01-01T${startTime}`);
    const end = new Date(`1970-01-01T${endTime}`);
    return Math.round((end.getTime() - start.getTime()) / 60000);
  }

  /**
   * Formats a duration (in minutes) into a string in the 'Xh Ym' format.
   *
   * @param durationInMinutes {number} The duration in minutes.
   * @returns {string} The formatted duration string.
   */

  formatDuration(durationInMinutes: number): string {
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;
    return `${hours}h ${minutes}m`;
  }

  /**
   * Creates a new entry form group for a time slot.
   *
   * @param slot {any} The time slot data.
   * @returns {FormGroup} The form group for the time slot entry.
   */

  createEntry(slot: any): FormGroup {
    return this.fb.group({
      startTime: [
        "",
        this.timeRangeValidator(slot.fromHour, slot.toHour, "start"),
      ],
      endTime: ["", this.timeRangeValidator(slot.fromHour, slot.toHour, "end")],
      sessionId: [slot.id],
    });
  }

  /**
   * Validator function to check if a time is within the allowed range for a time slot.
   *
   * @param fromHour {string} The start hour for the time slot.
   * @param toHour {string} The end hour for the time slot.
   * @param type {'start' | 'end'} The type of the time being validated (start or end).
   * @returns {ValidatorFn} A validation function that checks if the time is within the range.
   */

  timeRangeValidator(
    fromHour: string,
    toHour: string,
    type: "start" | "end"
  ): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const time = control.value;
      if (!time) {
        return null;
      }

      const [fromHours, fromMinutes] = fromHour.split(":").map(Number);
      const [toHours, toMinutes] = toHour.split(":").map(Number);
      const [inputHours, inputMinutes] = time.split(":").map(Number);

      const fromTime = fromHours * 60 + fromMinutes;
      const toTime = toHours * 60 + toMinutes;
      const inputTime = inputHours * 60 + inputMinutes;

      if (inputTime < fromTime || inputTime > toTime) {
        return { timeOutOfRange: { value: control.value, type: type } };
      }

      return null;
    };
  }

  /**
   * Removes a work diary entry by its session ID.
   *
   * @param sessionId {number} The session ID of the entry to remove.
   */

  hasValidEntries(): boolean {
    return this.entries.controls.some(
      (entry) =>
        entry.get("startTime").value &&
        entry.get("endTime").value &&
        !entry.get("startTime").errors &&
        !entry.get("endTime").errors
    );
  }
}
