import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as echarts from 'echarts';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() data: any;
  @Input() title: string;
  @Input() subtitle: string = '';
  @Input() id: string;
  @Input() showOld = false;
  @Input() old = '';
  @Input() newValue = '';
  @Input() percentage = 0;
  @Input() isOpposite = false;

  @ViewChild('chartContainer', { static: true }) chartContainer!: ElementRef;
  private chartInstance: echarts.ECharts | null = null;

  ngOnInit() {
    this.initPieChart();

  }

  ngAfterViewInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      this.chartInstance?.dispose();
      this.initPieChart();
    }
  }

  ngOnDestroy(): void {
    this.chartInstance?.dispose();
  }

  initPieChart(): void {
    const element = this.chartContainer.nativeElement;
    echarts.dispose(element);
    const pie = echarts.init(element);

    const pieChartCenter = this.showOld ? ['30%', '40%'] : ['30%', '50%'];

    const option = {
      backgroundColor: '#ffffff',
      tooltip: {trigger: 'item'},
      legend: {
        orient: 'vertical',
        icon: 'circle',
        top: '5%',
        left: '50%',
        itemWidth: 10,
        itemHeight: 10,
        formatter: (name) => {
          const value = this.data.find(d => d.name === name)?.value;

          // Function to insert line breaks dynamically
          const addLineBreaks = (str, maxLen) => {
            const regex = new RegExp(`(.{1,${maxLen}})(\\s|$)`, 'g');
            return str.match(regex)?.join('\n') || str;
          };

          const wrappedName = addLineBreaks(name, 25); // Break after 10 characters or nearest space

          return `${wrappedName} (${value})`;
        },
      },
      series: [
        {
          name: this.title,
          type: 'pie',
          radius: '70%',
          center: pieChartCenter,
          data: this.data,
          label: {show: false},
          itemStyle: {borderColor: '#fff', borderWidth: 2, padding: 5},
          emphasis: {itemStyle: {shadowBlur: 10, shadowColor: 'rgba(0, 0, 0, 0.5)'}}
        }
      ]
    };


    pie.setOption(option);

    let lastSelectedState: Record<string, boolean> | null = null;

    // Function to reset filters
    const resetFilters = () => {
      const resetSelected = option.series!.reduce((acc, series) => {
        acc[series.name!] = true;
        return acc;
      }, {} as Record<string, boolean>);

      pie.setOption({
        legend: {
          selected: resetSelected,
        },
      });
      lastSelectedState = null;
    };

    // Add event listener for legend clicks
    pie.on('legendselectchanged', function (params: any) {
      const currentSelected = params.selected;
      let newSelected: Record<string, boolean>;

      if (lastSelectedState === null) {
        // First selection after reset
        newSelected = Object.keys(currentSelected).reduce((acc, key) => {
          acc[key] = key === params.name;
          return acc;
        }, {} as Record<string, boolean>);
      } else {
        // Subsequent selections
        if (currentSelected[params.name]) {
          // If the clicked item is being selected
          newSelected = {
            ...lastSelectedState,
            [params.name]: true
          };
        } else {
          // If the clicked item is being deselected
          newSelected = {
            ...lastSelectedState
          };
          delete newSelected[params.name];

          // If all items are deselected, reset to show all
          if (Object.values(newSelected).every(v => !v)) {
            resetFilters();
            return;
          }
        }
      }

      lastSelectedState = newSelected;

      pie.setOption({
        legend: {
          selected: newSelected,
        },
      });
    });
    this.chartInstance = pie;
  }

  isSlashSubtitle(): boolean {
    return /^.+\/.+$/.test(this.subtitle);
  }

  getSubtitlePart(index: number): string {
    return this.subtitle.split('/')[index];
  }

  isSlashNewValue(): boolean {
    return /^.+\/.+$/.test(this.newValue);
  }

  isSlashOldValue(): boolean {
    return /^.+\/.+$/.test(this.old);
  }

  getNewValuePart(index: number): string {
    return this.newValue.split('/')[index];
  }

  percentageColor(): string {
    if(this.isOpposite) {
      return this.percentage > 0 ? '#ff4444' : '#4caf50';
    }
    return this.percentage >= 0 ? '#4caf50' : '#ff4444';
  }

  percentageArrow(): string {
   // return this.percentage >= 0 ? '↑' : '↓';
    if (this.percentage > 0) {
      return '↑';
    } else if (this.percentage < 0) {
      return '↓';
    } else {
      return '—';
    }
  }
}





