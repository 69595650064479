import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  ElementRef,
  AfterViewInit,
  ChangeDetectorRef,
  HostListener,
} from "@angular/core";
import { ROUTES } from "./vertical-menu-routes.config";
import { HROUTES } from "../horizontal-menu/navigation-routes.config";
import { NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { customAnimations } from "../animations/custom-animations";
import { DeviceDetectorService } from "ngx-device-detector";
import { ConfigService } from "../services/config.service";
import { Subscription } from "rxjs";
import { LayoutService } from "../services/layout.service";
import { CrudService } from "../services/crud.service";
import { OPN_BASE_URL } from "../global/var";
import { CompanySettingsService } from "../../modules/company-settings/services/company-settings.service";
import { AuthService } from "../auth/auth.service";
import { RouteInfo } from "./vertical-menu.metadata";

@Component({
  selector: "app-sidebar",
  templateUrl: "./vertical-menu.component.html",
  styleUrls: ["./vertical-menu.component.scss"],
  animations: customAnimations,
})
export class VerticalMenuComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("toggleIcon") toggleIcon: ElementRef;
  public menuItems: any[];
  level: number = 0;
  logoUrl = "assets/img/logo.png";
  public config: any = {};
  protected innerWidth: any;
  layoutSub: Subscription;
  configSub: Subscription;
  routerSub: Subscription;
  perfectScrollbarEnable = true;
  collapseSidebar = false;
  resizeTimeout;
  profileConfig: any;
  acronym: string;
  activeMenuPaths: Set<string> = new Set(); // Track active menu paths
  firstAccessiblePath: string | null = null;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private deviceService: DeviceDetectorService,
    private crudService: CrudService,
    private settingsService: CompanySettingsService,
    private authService: AuthService
  ) {
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;
    this.isTouchDevice();
  }

  ngOnInit() {
    this.menuItems = ROUTES;
    this.getCompanySettings();
    const userRoles = this.authService.getRolesFromToken();
    this.firstAccessiblePath = this.findFirstAccessiblePath(this.menuItems, userRoles);
    
    // Subscribe to router events to track active menu items
    this.routerSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.updateActiveMenuPaths(event.urlAfterRedirects);
        localStorage.setItem('currentUrl', event.urlAfterRedirects);
      }
    });

    const currentUrl = localStorage.getItem('currentUrl');
    
    if (this.authService.isLoggedIn()) {
      if (currentUrl && currentUrl !== '/pages/login') {
        this.router.navigate([currentUrl]);
        this.updateActiveMenuPaths(currentUrl);
      } else if (this.firstAccessiblePath && this.authService.isFirstLogin()) {
        this.router.navigate([this.firstAccessiblePath]);
        this.updateActiveMenuPaths(this.firstAccessiblePath);
        this.authService.markFirstLoginAsCompleted();
      }
    }

    this.settingsService.settings$.subscribe((settings) => {
      this.logoUrl = settings.logo ? settings.logo : "/assets/img/bus-svgrepo-com.png";
    });
    
    this.loadMenuItems();
    
    // Initialize active menu paths based on current URL
    this.updateActiveMenuPaths(this.router.url);
  }

  ngAfterViewInit() {
    this.configSub = this.configService.templateConf$.subscribe(
      (templateConf) => {
        if (templateConf) {
          this.config = templateConf;
        }
        this.loadLayout();
        this.cdr.markForCheck();
      }
    );

    this.layoutSub = this.layoutService.overlaySidebarToggle$.subscribe(
      (collapse) => {
        if (this.config.layout.menuPosition === "Side") {
          this.collapseSidebar = collapse;
        }
      }
    );
  }

  @HostListener("window:resize", ["$event"])
  onWindowResize(event) {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout(() => {
      this.innerWidth = event.target.innerWidth;
      this.loadLayout();
    }, 500);
  }

  loadLayout() {
    if (this.config.layout.menuPosition === "Top") {
      // Horizontal Menu
      if (this.innerWidth < 1200) {
        // Screen size < 1200
        this.menuItems = HROUTES;
      }
    } else if (this.config.layout.menuPosition === "Side") {
      // Vertical Menu
      this.loadMenuItems(); // Re-load menu items for vertical layout
    }

    if (this.config.layout.sidebar.backgroundColor === "white") {
      // this.logoUrl = 'assets/img/logo-dark.png';
    } else {
      // this.logoUrl = 'assets/img/logo.png';
    }

    if (this.config.layout.sidebar.collapsed) {
      this.collapseSidebar = true;
    } else {
      this.collapseSidebar = false;
    }
  }

  loadMenuItems() {
    const userRoles = this.authService.getRolesFromToken();
    this.menuItems = this.filterRoutes(ROUTES, userRoles);
  }

  filterRoutes(routes: RouteInfo[], roles: string[]): RouteInfo[] {
    return routes
      .filter((route) => this.hasAccess(route, roles))
      .map((route) => ({
        ...route,
        submenu: route.submenu ? this.filterRoutes(route.submenu, roles) : [],
      }));
  }

  hasAccess(route: RouteInfo, roles: string[]): boolean {
    if (!route.permissions || route.permissions.length === 0) {
      return true;
    }
    return route.permissions.some((permission) => roles.includes(permission));
  }

  // Method to check if a menu item should be expanded
  isMenuExpanded(menuItem: RouteInfo): boolean {
    if (!menuItem.path) return false;
    
    // Check if this menu item's path is in our active paths
    return this.activeMenuPaths.has(menuItem.path) || 
           // Check if any child paths are active
           (menuItem.submenu && menuItem.submenu.some(subItem => 
             this.activeMenuPaths.has(subItem.path) ||
             // Check grandchild paths too
             (subItem.submenu && subItem.submenu.some(grandChild => 
               this.activeMenuPaths.has(grandChild.path)))
           ));
  }

  // Method to update active menu paths based on current URL
  private updateActiveMenuPaths(currentUrl: string) {
    this.activeMenuPaths.clear();
    
    const addParentPaths = (routes: RouteInfo[], parentPath: string = '') => {
      routes.forEach(route => {
        if (route.path) {
          // If current URL starts with this route's path, add it to active paths
          if (currentUrl.startsWith(route.path)) {
            this.activeMenuPaths.add(route.path);
            if (parentPath) {
              this.activeMenuPaths.add(parentPath);
            }
          }
          
          // Check submenu items
          if (route.submenu && route.submenu.length > 0) {
            addParentPaths(route.submenu, route.path);
          }
        }
      });
    };

    addParentPaths(this.menuItems);
    this.cdr.detectChanges();
  }

  findFirstAccessiblePath(routes: RouteInfo[], roles: string[]): string | null {
    for (const route of routes) {
      if (this.hasAccess(route, roles)) {
        if (route.path && !route.isExternalLink && (!route.submenu || route.submenu.length === 0)) {
          return route.path;
        }
        if (route.submenu && route.submenu.length > 0) {
          const subPath = this.findFirstAccessiblePath(route.submenu, roles);
          if (subPath) {
            return subPath;
          }
        }
      }
    }
    return null;
  }

  toggleSidebar() {
    let conf = this.config;
    conf.layout.sidebar.collapsed = !this.config.layout.sidebar.collapsed;
    this.configService.applyTemplateConfigChange({ layout: conf.layout });

    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }

  fireRefreshEventOnWindow = function () {
    const evt = document.createEvent("HTMLEvents");
    evt.initEvent("resize", true, false);
    window.dispatchEvent(evt);
  };

  CloseSidebar() {
    this.layoutService.toggleSidebarSmallScreen(false);
  }

  isTouchDevice() {
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();

    if (isMobile || isTablet) {
      this.perfectScrollbarEnable = false;
    } else {
      this.perfectScrollbarEnable = true;
    }
  }

  getCompanySettings() {
    this.crudService
      .getAll<any>(OPN_BASE_URL + "/company-settings/all")
      .subscribe(async (res: any) => {
        const settingsMap = res.map((item: any) => ({
          configKey: item.configKey,
          configValue: JSON.parse(item.configValue),
        }));

        this.profileConfig = settingsMap.find(
          (item) => item.configKey === "profile-config"
        );
        this.acronym = this.profileConfig?.configValue?.name || "";
        this.logoUrl = this.profileConfig?.configValue?.logo;
        if (!this.logoUrl || this.logoUrl === "") {
          this.logoUrl = "/assets/img/bus-svgrepo-com.png";
        }
      });
  }

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event) {
    localStorage.setItem('previousUrl', this.router.url);
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }
  }
}