import { Component, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-image-modal',
  template: `
    <div class="modal-body">
      <div class="image-container" 
           (click)="toggleZoom($event)"
           #container>
        <img [src]="imageSrc" 
             [class.zoomed]="isZoomed"
             class="zoomable-image" 
             alt="Enlarged image"
             #image>
      </div>
    </div>
  `,
  styles: [`
    .image-container {
      overflow: hidden;
      position: relative;
      width: 100%;
      height: 500px; /* Adjust as needed */
      cursor: zoom-in;
    }
    .zoomable-image {
      width: 100%;
      height: 100%;
      object-fit: contain;
      transition: transform 0.3s ease-out;
    }
    .zoomable-image.zoomed {
      transform: scale(2);
      cursor: zoom-out;
    }
  `]
})
export class ImageModalComponent implements AfterViewInit {
  @Input() imageSrc: string;
  @ViewChild('container') container: ElementRef;
  @ViewChild('image') image: ElementRef;

  isZoomed = false;
  private scale = 2; // Zoom scale factor

  constructor(public activeModal: NgbActiveModal) {}

  ngAfterViewInit() {
    // Ensure the image is loaded before allowing zoom
    this.image.nativeElement.onload = () => {
      this.container.nativeElement.style.cursor = 'zoom-in';
    };
  }

  toggleZoom(event: MouseEvent) {
    this.isZoomed = !this.isZoomed;

    if (this.isZoomed) {
      const rect = this.image.nativeElement.getBoundingClientRect();
      const x = (event.clientX - rect.left) / rect.width;
      const y = (event.clientY - rect.top) / rect.height;

      this.image.nativeElement.style.transformOrigin = `${x * 100}% ${y * 100}%`;
      this.container.nativeElement.style.cursor = 'zoom-out';
    } else {
      this.image.nativeElement.style.transformOrigin = 'center center';
      this.container.nativeElement.style.cursor = 'zoom-in';
    }
  }
}