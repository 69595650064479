<div *ngIf="control" class="mb-3">
  <div class="form-group input-group row mb-0">
    <label 
      [class.text-danger]="control.invalid && (control.dirty || control.touched)"
      [for]="idName"
      class="col-xl-3 col-md-4 mx-1">
      <span *ngIf="controlIsRequired(control)" class="text-danger">*</span>
      {{labelPlaceholder | translate | titlecase}}
    </label>
    
    <input
      (blur)="inputBlur()"
      (change)="onInputChange($event)"
      [disabled]="true"
      [ngModel]="dateString | date:inputDateFormat"
      class="form-control col-xl-8 col-md-7"
      style="height: 38px; border-bottom-left-radius: 8px; border-top-left-radius: 8px;"
      [id]="idName"
    />
    
    <div class="input-group-append">
      <button
        #popover="ngbPopover"
        [disabled]="disabled"
        [ngbPopover]="calendarContent"
        class="btn btn-outline-secondary"
        type="button"
        [id]="idName"
      >
        <i class="ft-calendar"></i>
      </button>
    </div>
  </div>
</div>

<ng-template #calendarContent>
  <ngb-datepicker 
    (ngModelChange)="onDateChange($event)"
    [minDate]="getMinDate()"
    [ngModel]="selectedDate"
    id="dp"
    name="datepicker">
  </ngb-datepicker>
</ng-template>