import {Pipe, PipeTransform} from '@angular/core';
@Pipe({
  name: 'imagePath'
})
export class ImagePathPipe implements PipeTransform {

  transform(path: string): string {
    return ""
  }

}
