import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export interface Trip {
  departure: string;
  arrival: string;
  id: number;
}

export function tripOverlapValidator(allTrips: Trip[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const selectedTripIds = control.value as number[];
    
    if (!selectedTripIds || selectedTripIds.length === 0) {
      return null;
    }

    const selectedTrips = allTrips
      .filter(trip => selectedTripIds.includes(trip.id))
      .sort((a, b) => {
        const [aHours, aMinutes] = a.departure.split(':').map(Number);
        const [bHours, bMinutes] = b.departure.split(':').map(Number);
        return (aHours * 60 + aMinutes) - (bHours * 60 + bMinutes);
      });

    for (let i = 0; i < selectedTrips.length; i++) {
      const trip1 = selectedTrips[i];
      const [departure1Hours, departure1Minutes] = trip1.departure.split(':').map(Number);
      const [arrival1Hours, arrival1Minutes] = trip1.arrival.split(':').map(Number);

      const start1 = new Date();
      const end1 = new Date();
      start1.setHours(departure1Hours, departure1Minutes, 0, 0);
      end1.setHours(arrival1Hours, arrival1Minutes, 0, 0);

      for (let j = i + 1; j < selectedTrips.length; j++) {
        const trip2 = selectedTrips[j];
        const [departure2Hours, departure2Minutes] = trip2.departure.split(':').map(Number);
        const [arrival2Hours, arrival2Minutes] = trip2.arrival.split(':').map(Number);

        const start2 = new Date();
        const end2 = new Date();
        start2.setHours(departure2Hours, departure2Minutes, 0, 0);
        end2.setHours(arrival2Hours, arrival2Minutes, 0, 0);

        if (
          (start1 < end2 && end1 > start2) ||
          (start1.getTime() === start2.getTime() && end1.getTime() === end2.getTime())
        ) {
          return {
            tripOverlap: {
              trip1,
              trip2,
              message: `Overlap between trips at ${trip1.departure} and ${trip2.departure}`
            }
          };
        }
      }
    }

    return null;
  };
}