import { Component, Input, OnInit } from "@angular/core";
import { Vehicle } from "../../models/work-request";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-vehicle-position",
  templateUrl: "./vehicle-position.component.html",
  styleUrls: ["./vehicle-position.component.scss"],
})
export class VehiclePositionComponent {
  @Input() vehicle: Vehicle;
  @Input() requestDate: string;

  startDate: string;
  endDate: string;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.calculateDateRange();
  }

  calculateDateRange() {
    if (this.requestDate) {
      // Convert request date to Date object
      const requestDateTime = new Date(this.requestDate);

      // Calculate start date (request date - 1 hour)
      const startDateTime = new Date(
        requestDateTime.getTime() - 60 * 60 * 1000
      );
      this.startDate = startDateTime.toISOString().split('.')[0];

      // End date is the request date
      this.endDate = requestDateTime.toISOString().split('.')[0];
    }
  }
}
