import { DatePipe } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { OPN_BASE_URL } from "app/shared/global/var";
import { CrudService } from "app/shared/services/crud.service";
import { NotyService } from "app/shared/services/noty.service";

@Component({
  selector: "app-trip-reporter-modal",
  templateUrl: "./trip-reporter-modal.component.html",
  styleUrls: ["./trip-reporter-modal.component.scss"],
  providers: [DatePipe],
})
export class TripReporterModalComponent implements OnInit {
  @Input() data;
  @Output() itemUpdated: EventEmitter<void> = new EventEmitter<void>();

  reportForm: FormGroup;
  private initialPlannedDeparture: Date;
  private initialEstimatedArrival: Date;
  overlappingDriverMission: string | null = null;
  overlappingReceiverMission: string | null = null;
  overlappingBusMission: string | null = null;
  overlappingBussMissionId: number;
  overlappingReceiverMissionId: number;
  overlappingDriverMissionId: number;
  langue = localStorage.getItem("langue");

  overlappingDriverMissionData: any;
  overlappingReceiverMissionData: any;
  overlappingBusMissionData: any;

  constructor(
    public activeModal: NgbActiveModal,
    private crudService: CrudService,
    private fb: FormBuilder,
    private notyService: NotyService,
    private datePipe: DatePipe
  ) {
    this.reportForm = this.fb.group({
      id: [""],
      plannedDeparture: [""],
      estimatedArrival: [""],
    });
  }

  ngOnInit(): void {
    this.data.driverAvailable =
      this.data.receiverAvailable =
      this.data.busAvailable =
        0;

    if (this.data && this.data.plannedDeparture && this.data.estimatedArrival) {
      this.initialPlannedDeparture = new Date(this.data.plannedDeparture);
      this.initialEstimatedArrival = new Date(this.data.estimatedArrival);

      const plannedDepartureTime = {
        hour: this.initialPlannedDeparture.getHours(),
        minute: this.initialPlannedDeparture.getMinutes(),
      };

      const estimatedArrivalTime = {
        hour: this.initialEstimatedArrival.getHours(),
        minute: this.initialEstimatedArrival.getMinutes(),
      };

      this.reportForm.patchValue({
        id: this.data.id,
        plannedDeparture: plannedDepartureTime,
        estimatedArrival: estimatedArrivalTime,
      });
    }

    // Listen for changes in plannedDeparture and update estimatedArrival
    this.reportForm.get("plannedDeparture").valueChanges.subscribe((time) => {
      this.updateEstimatedArrival(time);
    });
  }

  updateEstimatedArrival(time: { hour: number; minute: number }): void {
    if (this.initialPlannedDeparture && this.initialEstimatedArrival) {
      const duration =
        this.initialEstimatedArrival.getTime() -
        this.initialPlannedDeparture.getTime();

      const newPlannedDeparture = new Date(this.initialPlannedDeparture);
      newPlannedDeparture.setHours(time.hour, time.minute);

      const newEstimatedArrival = new Date(
        newPlannedDeparture.getTime() + duration
      );

      const estimatedArrivalTime = {
        hour: newEstimatedArrival.getHours(),
        minute: newEstimatedArrival.getMinutes(),
      };

      const newPlannedDepartureStr = this.datePipe.transform(
        newPlannedDeparture,
        "yyyy-MM-ddTHH:mm:ss"
      );
      const newEstimatedArrivalStr = this.datePipe.transform(
        newEstimatedArrival,
        "yyyy-MM-ddTHH:mm:ss"
      );

      // Call checkDriverAvailability with new plannedDeparture and estimatedArrival
      this.checkDriverAvailability({
        plannedDeparture: newPlannedDepartureStr,
        estimatedArrival: newEstimatedArrivalStr,
      });

      this.checkReceiverAvailability({
        plannedDeparture: newPlannedDepartureStr,
        estimatedArrival: newEstimatedArrivalStr,
      });

      this.checkBusAvailability({
        plannedDeparture: newPlannedDepartureStr,
        estimatedArrival: newEstimatedArrivalStr,
      });

      this.reportForm.patchValue({
        estimatedArrival: estimatedArrivalTime,
      });
    }
  }

  get formattedEstimatedArrival(): string {
    const estimatedArrival = this.reportForm.get("estimatedArrival").value;
    if (estimatedArrival) {
      const date = new Date(this.initialEstimatedArrival);
      date.setHours(estimatedArrival.hour, estimatedArrival.minute);
      return this.datePipe.transform(date, "yyyy-MM-ddTHH:mm:ss");
    }
    return "";
  }

  checkDriverAvailability(data: {
    plannedDeparture: string;
    estimatedArrival: string;
  }): void {
    if (this.data.driver) {
      const url = OPN_BASE_URL + "/trips-instance/check-driver-availability";

      this.crudService
        .checkDriverAvailability(
          url,
          this.data.id,
          this.data.driver.id,
          data.plannedDeparture,
          data.estimatedArrival
        )
        .subscribe((response) => {
          if (response.status === 200) {
            if (response.body === true) {
              this.overlappingDriverMission = null;
              this.overlappingDriverMissionData = null;
              this.data.driverAvailable = 0;
            }
          } else if (response.status === 209) {
            this.overlappingDriverMissionData = response.body;

            const overlappingMissions = response.body.map(
              (item: any) => item.lineDirection
            );
            this.overlappingDriverMission = overlappingMissions;

            const overlappingMissionsIds = response.body.map(
              (item: any) => item.id
            );
            this.overlappingDriverMissionId = overlappingMissionsIds;
            this.data.driverAvailable = 1;
          }
        });
    }
  }

  checkReceiverAvailability(data: {
    plannedDeparture: string;
    estimatedArrival: string;
  }): void {
    if (this.data.receiver) {
      const url = OPN_BASE_URL + "/trips-instance/check-receiver-availability";
      this.crudService
        .checkReceiverAvailability(
          url,
          this.data.id,
          this.data.receiver.id,
          data.plannedDeparture,
          data.estimatedArrival
        )
        .subscribe((response) => {
          if (response.status === 200) {
            if (response.body === true) {
              this.overlappingReceiverMission = null;
              this.overlappingReceiverMissionData = null;
              this.data.receiverAvailable = 0;
            }
          } else if (response.status === 209) {
            this.overlappingReceiverMissionData = response.body;

            const overlappingReceiverMissions = response.body.map(
              (item: any) => item.lineDirection
            );
            this.overlappingReceiverMission = overlappingReceiverMissions;

            const overlappingReceiverMissionsIds = response.body.map(
              (item: any) => item.id
            );
            this.overlappingReceiverMissionId = overlappingReceiverMissionsIds;
            this.data.receiverAvailable = 1;
          }
        });
    }
  }

  checkBusAvailability(data: {
    plannedDeparture: string;
    estimatedArrival: string;
  }): void {
    if (this.data.bus) {
      const url = OPN_BASE_URL + "/trips-instance/check-bus-availability";
      this.crudService
        .checkBusAvailability(
          url,
          this.data.id,
          this.data.bus.id,
          data.plannedDeparture,
          data.estimatedArrival
        )
        .subscribe((response) => {
          if (response.status === 200) {
            if (response.body === true) {
              this.overlappingBusMission = null;
              this.overlappingBusMissionData = null;
              this.data.busAvailable = 0;
            }
          }
          if (response.status === 209) {
            this.overlappingBusMissionData = response.body;

            const overlappingBusMissions = response.body.map(
              (item: any) => item.lineDirection
            );
            this.overlappingBusMission = overlappingBusMissions;
            const overlappingBussMissionIds = response.body.map(
              (item: any) => item.id
            );
            this.overlappingBussMissionId = overlappingBussMissionIds;
            this.data.busAvailable = 1;
          }
        });
    }
  }

  saveForm(): void {
    const plannedDepartureControl =
      this.reportForm.get("plannedDeparture").value;
    const estimatedArrivalControl =
      this.reportForm.get("estimatedArrival").value;

    const plannedDepartureDate = new Date(this.initialPlannedDeparture);
    plannedDepartureDate.setHours(
      plannedDepartureControl.hour,
      plannedDepartureControl.minute
    );

    const estimatedArrivalDate = new Date(this.initialEstimatedArrival);
    estimatedArrivalDate.setHours(
      estimatedArrivalControl.hour,
      estimatedArrivalControl.minute
    );

    const formattedPlannedDeparture = this.datePipe.transform(
      plannedDepartureDate,
      "yyyy-MM-ddTHH:mm:ss"
    );
    const formattedEstimatedArrival = this.datePipe.transform(
      estimatedArrivalDate,
      "yyyy-MM-ddTHH:mm:ss"
    );

    if (!formattedPlannedDeparture || !formattedEstimatedArrival) {
      console.error("Error formatting dates.");
      return;
    }

    const formData = {
      ...this.data,
      plannedDeparture: formattedPlannedDeparture,
      estimatedArrival: formattedEstimatedArrival,
    };

    if (this.data.driverAvailable === undefined) this.data.driverAvailable = 0;
    if (this.data.receiverAvailable === undefined)
      this.data.receiverAvailable = 0;
    if (this.data.busAvailable === undefined) this.data.busAvailable = 0;

    if (
      this.data.driverAvailable == 0 &&
      this.data.receiverAvailable == 0 &&
      this.data.busAvailable == 0
    ) {
      this.saveToServer(formData);
    }
  }

  private saveToServer(formData: any): void {
    this.crudService
      .update(OPN_BASE_URL + "/trips-instance/report", this.data.id, formData)
      .subscribe(
        (response) => {
          this.activeModal.close();
          this.itemUpdated.emit(formData);

          const successMessage =
            this.langue === "fr"
              ? "Voayge reporté avec succès"
              : "Trip reported successfully";

          this.notyService.displayNotification(successMessage, "success");
        },
        (error) => {
          console.error("Error updating service:", error);
          const errorMessage =
            this.langue === "fr" ? "Échec de Report" : "Failed Report";
          this.notyService.displayNotification(errorMessage, "error");
        }
      );
  }

  isSaveDisabled(): boolean {
    return (
      this.data.driverAvailable === 1 ||
      this.data.receiverAvailable === 1 ||
      this.data.busAvailable === 1
    );
  }

  closeModal(): void {
    this.overlappingDriverMission = null;
    this.overlappingReceiverMission = null;
    this.overlappingBusMission = null;
    this.data.driverAvailable = null;
    this.data.receiverAvailable = null;
    this.data.busAvailable = null;

    this.overlappingDriverMissionData =
      this.overlappingReceiverMissionData =
      this.overlappingBusMissionData =
        null;

    this.activeModal.close(this.reportForm.reset());
  }

  // tooltip overlapping Trpis
  toggleWithGreeting(popover, overlappingType: string, tId: number) {
    let tripOverlap = undefined;

    let tripNumber = "";
    let tripRoute = "";
    let plannedDeparture = "";
    let plannedArrival = "";

    switch (overlappingType) {
      case "Driver":
        tripOverlap = this.overlappingDriverMissionData.find(
          (t) => t.idTrip == tId
        );
        break;
      case "Receiver":
        tripOverlap = this.overlappingReceiverMissionData.find(
          (t) => t.idTrip == tId
        );
        break;
      case "Bus":
        tripOverlap = this.overlappingBusMissionData.find(
          (t) => t.idTrip == tId
        );
        break;

      default:
    }

    if (tripOverlap) {
      tripNumber = tripOverlap.lineDirection;
      tripRoute = tripOverlap.tripRoute;
      plannedDeparture = tripOverlap.plannedDeparture;
      plannedArrival = tripOverlap.estimatedArrival;
    }

    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open({ tripNumber, tripRoute, plannedDeparture, plannedArrival });
    }
  }
}
