import { RouteInfo } from "./vertical-menu.metadata";

// Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  // 1. Dashboard
  {
    path: "/real-time-tracking",
    title: "MENU.REAL_TIME_TRACKING",
    icon: "fas fa-map-marker-alt",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
    permissions: ["Real_Time_Tracking"],
  },

  {
    path: "/security-quality",
    title: "MENU.SECURITY_QUALITY",
    icon: "fas fa-shield-alt",
    badge: "",
    class: "has-sub",
    badgeClass: "",
    isExternalLink: false,
    permissions: ["Security_Quality"],
    submenu: [
      {
        path: "security-quality/dashboard",
        title: "MENU.DASHBOARD",
        icon: "fas fa-chart-simple",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        permissions: ["SQ_Dashboard"],
      },
      {
        path: "security-quality/report",
        title: "MENU.MAINTENANCE_REPORT",
        icon: "fas fa-file-alt",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        permissions: ["SQ_Report"],
      },
    ],
  },
  {
    path: "/operating-networks",
    title: "MENU.OPERATING_NETWORK",
    icon: "ft-globe",
    badge: "",
    class: "has-sub",
    badgeClass: "",
    isExternalLink: false,
    permissions: ["Operating_Networks"],
    submenu: [
      {
        path: "operating-networks/dashboard",
        title: "MENU.DASHBOARD",
        icon: "fas fa-chart-simple",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        permissions: ["Operating_Dashboard"],
      },
      {
        path: "operating-networks/trips-instances/list",
        title: "MENU.TRIPS_LIST",
        icon: "fas fa-route",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        permissions: ["Trips_List"],
      },
      {
        path: "operating-networks/rent/list",
        title: "MENU.RENT",
        icon: "fas fa-bus",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        permissions: ["Rent_Management"],
      },
      {
        path: "",
        title: "MENU.SERVICES_LIST",
        icon: "fas fa-clipboard-list",
        class: "has-sub",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        permissions: ["Service Parameter"],
        submenu: [
          {
            path: "operating-networks/services/list",
            title: "MENU.SERVICE_LIST",
            icon: "ft-layers submenu-icon",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
            permissions: ["Service_List"],
          },
          {
            path: "operating-networks/services/period",
            title: "MENU.SERVICE_PERIOD_LIST",
            icon: "ft-clock submenu-icon",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
            permissions: ["Service_Period_List"],
          },
          {
            path: "operating-networks/services/group",
            title: "MENU.SERVICE_GROUP_LIST",
            icon: "ft-grid submenu-icon",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
            permissions: ["Service_Group_List"],
          },
          {
            path: "operating-networks/exceptional-dates/list",
            title: "MENU.EXCEPTIONAL_DATES",
            icon: "ft-calendar submenu-icon",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
            permissions: ["Exceptional_dates"],
          },
        ],
      },
      {
        path: "operating-networks/route/list",
        title: "MENU.ROUTE_LIST",
        icon: "fas fa-map-signs",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        permissions: ["Route_List"],
      },
      {
        path: "operating-networks/operating_report/report",
        title: "MENU.OPERATING_REPORT",
        icon: "fas fa-file-alt",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        permissions: ["Operating_Report"],
      },
      {
        path: "",
        title: "MENU.PARAMETER",
        icon: "",
        class: "has-sub",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        permissions: ["Parameter"],
        submenu: [
          {
            path: "operating-networks/center/list",
            title: "MENU.CENTER_LIST",
            icon: "ft-map-pin submenu-icon",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
            permissions: ["Center_List"],
          },
          {
            path: "operating-networks/agency/list",
            title: "MENU.AGENCY_LIST",
            icon: "ft-home submenu-icon",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
            permissions: ["Agency_List"],
          },
          {
            path: "operating-networks/routeType/list",
            title: "MENU.ROUTE_TYPE_LIST",
            icon: "ft-trending-up submenu-icon",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
            permissions: ["Route_Type_List"],
          },
          {
            path: "operating-networks/stations/list",
            title: "MENU.STATIONS_LIST",
            icon: "ft-stop-circle submenu-icon",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
            permissions: ["Stations_List"],
          },

          {
            path: "operating-networks/stationType/list",
            title: "MENU.STATION_TYPE_LIST",
            icon: "ft-layers submenu-icon",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
            permissions: ["Station_Type_List"],
          },
        ],
      },
    ],
  },
  // 3. Gestion de la Flotte
  {
    path: "/fleet-management",
    title: "MENU.FLEET_MANAGEMENT",
    icon: "fas fa-car",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    permissions: ["Fleet_Management"],
    submenu: [
      {
        path: "fleet-management/vehicle/list",
        title: "MENU.VEHICLE_LIST",
        icon: "ft-list",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        permissions: ["Vehicle_List"],
      },
      {
        path: "",
        title: "MENU.PARAMETER",
        icon: "",
        class: "has-sub",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        permissions: ["Fleet_Parameter"],
        submenu: [
          {
            path: "fleet-management/vehicle-family/list",
            title: "MENU.VEHICLE_FAMILY_LIST",
            icon: "ft-align-justify submenu-icon",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
            permissions: ["Vehicle_Family_List"],
          },
          {
            path: "fleet-management/bus-type/list",
            title: "MENU.BUS_TYPE_LIST",
            icon: "ft-layout submenu-icon",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
            permissions: ["Bus_Type_List"],
          },
          {
            path: "fleet-management/vehicle-brand/list",
            title: "MENU.VEHICLE_BRAND_LIST",
            icon: "ft-tag submenu-icon",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
            permissions: ["Vehicle_Brand_List"],
          },
          {
            path: "fleet-management/vehicle-model/list",
            title: "MENU.VEHICLE_MODEL_LIST",
            icon: "ft-grid submenu-icon",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
            permissions: ["Vehicle_Model_List"],
          },
        ],
      },
    ],
  },
  {
    path: "/staff-management",
    title: "MENU.STAFF_MANAGEMENT",
    icon: "ft-users",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    permissions: ["Staff_Management"],
    submenu: [
      {
        path: "staff-management/staff/list",
        title: "MENU.STAFF_LIST",
        icon: "ft-user",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        permissions: ["Staff_List"],
      },
      {
        path: "staff-management/affectation-history/list",
        title: "MENU.AFFECTATION_HISTORY",
        icon: "ft-calendar",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        permissions: ["Staff_Affectation_History"],
      },      
      {
        path: "staff-management/service-plan/list",
        title: "MENU.STAFF_SERVICES_PLANS",
        icon: "ft-clipboard",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        permissions: ["Staff_Services_Plans"],
      },
      {
        path: "staff-management/report/report",
        title: "MENU.STAFF_REPORT",
        icon: "fas fa-file-alt",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        permissions: ["Staff_Report"],
      },
      {
        path: "staff-management/clocking/list",
        title: "MENU.CLOCKING",
        icon: "ft-clock",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        permissions: ["Staff_Clocking"],
      },
      {
        path: "",
        title: "MENU.PARAMETER",
        icon: "",
        class: "has-sub",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        permissions: ["Staff_Parameter"],
        submenu: [
          {
            path: "staff-management/staff-type/list",
            title: "MENU.STAFF_TYPE_LIST",
            icon: "ft-users submenu-icon",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
            permissions: ["Staff_Type_List"],
          },
        ],
      },
    ],
  },
  {
    path: "/maintenance-management",
    title: "MENU.MAINTENANCE",
    icon: "icon-wrench",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    permissions: ["Maintenances"],
    submenu: [
      {
        path: "maintenance-management/dashboard",
        title: "MENU.DASHBOARD_MAINTENANCE",
        icon: "fas fa-chart-line",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        permissions: ["Maintenance_Dashboard"],
      },
      {
        path: "maintenance-management/work-request/list",
        title: "MENU.WORK_REQUEST_LIST",
        icon: "ft-file-text",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        permissions: ["Work_Request_List"],
      },
      {
        path: "maintenance-management/work-order/list",
        title: "MENU.WORK_ORDER_LIST",
        icon: "fas fa-clipboard-list",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        permissions: ["Work_Order_List"],
      },
      {
        path: "maintenance-management/scheduled-tasks/list",
        title: "MENU.SCHEDULED_TASKS_LIST",
        icon: "fas fa-calendar-alt",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        permissions: ["Scheduled_Tasks_List"],
      },
      {
        path: "maintenance-management/report",
        title: "MENU.MAINTENANCE_REPORT",
        icon: "fas fa-file-alt",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        permissions: ["Maintenance_Report"],
      },
      {
        path: "",
        title: "MENU.PARAMETER",
        icon: "",
        class: "has-sub",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        permissions: ["Maintenance_Parameter"],
        submenu: [
          {
            path: "maintenance-management/intervention-category/list",
            title: "MENU.INTERVENTION_CATEGORY_LIST",
            icon: "ft-layers submenu-icon",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
            permissions: ["Intervention_Category_List"],
          },
          {
            path: "maintenance-management/maintenance-type/list",
            title: "MENU.MAINTENANCE_TYPE_LIST",
            icon: "ft-settings submenu-icon",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
            permissions: ["Maintenance_Type_List"],
          },
          {
            path: "maintenance-management/intervention-type/list",
            title: "MENU.INTERVENTION_TYPE_LIST",
            icon: "fas fa-tools submenu-icon",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
            permissions: ["Intervention_Type_List"],
          },
          {
            path: "maintenance-management/intervention-nature/list",
            title: "MENU.INTERVENTION_NATURE_LIST",
            icon: "fas fa-tools submenu-icon",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
            permissions: ["Intervention_Nature_List"],
          },
        ],
      },
    ],
  },
  {
    path: "/energy-management",
    title: "MENU.ENERGY_MANAGEMENT",
    icon: "fas fa-tachometer-alt",
    badge: "",
    class: "has-sub",
    badgeClass: "",
    isExternalLink: false,
    permissions: ["Energy_Management"],
    submenu: [
      {
        path: "energy-management/dashboard",
        title: "Energy dashboard",
        icon: "fas fa-chart-pie",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        permissions: ["Energy_Dashboard"],
      },
      {
        path: "energy-management/refuelling/list",
        title: "MENU.RAVITAILLEMENT",
        icon: "fas fa-gas-pump",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        permissions: ["Refuelling_List"],
      },
      {
        path: "energy-management/energy_report/report",
        title: "ENERGY_REPORT",
        icon: "fas fa-file-alt",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        permissions: ["Energy_Report"],
      },
      {
        path: "",
        title: "MENU.PARAMETER",
        icon: "",
        class: "has-sub",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        permissions: ["Energy_Parameter"],
        submenu: [
          {
            path: "energy-management/energy/list",
            title: "MENU.STANDARD_LIST",
            icon: "ft-sliders submenu-icon",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
            permissions: ["Standard_List"],
          },
          {
            path: "energy-management/service-station/list",
            title: "MENU.SERVICE_STATION_LIST",
            icon: "ft-droplet submenu-icon",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
            permissions: ["Service_station_List"],
          },
        ],
      },
    ],
  },
  // 8. Gestion des Utilisateurs
  {
    path: "/user-management",
    title: "MENU.USERS_MANAGEMENT",
    icon: "ft-user",
    badge: "",
    class: "has-sub",
    badgeClass: "",
    isExternalLink: false,
    permissions: ["User_Management"],
    submenu: [
      {
        path: "user-management/users-list/list",
        title: "MENU.USERS_LIST",
        icon: "ft-list",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        permissions: ["Users_List"],
      },
      {
        path: "",
        title: "MENU.ACCESS_MANAGEMENT",
        icon: "ft-lock",
        badge: "",
        class: "has-sub",
        badgeClass: "",
        isExternalLink: false,
        permissions: ["Access_Management"],
        submenu: [
          {
            path: "user-management/access-management/permission",
            title: "MENU.PERMISSION_MANAGEMENT",
            icon: "ft-unlock submenu-icon",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
            permissions: ["Permission_Management"],
          },
          {
            path: "user-management/access-management/create-role",
            title: "MENU.ROLE_MANAGEMENT",
            icon: "ft-shield submenu-icon",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
            permissions: ["Role_Management"],
          },
        ],
      },
    ],
  },
  {
    path: "/complaint-list",
    title: "MENU.COMPLAINT",
    icon: "far fa-envelope-open",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
    permissions: ["Complaint"],
  },
];
