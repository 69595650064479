import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AbstractControl, FormArray, FormControl} from '@angular/forms';
import * as RandExp from 'randexp';

/**
 * Base class for all element form (eg: select, input...)
 */
@Component({
  template: ``
})
export class AbstractFormElement {
  @Input() validators: any;
  /**
   * Form Control of the element, needed for validation
   */
  @Input() control: FormControl | FormArray;

  /**
   * Placeholder for label, contains i18n string
   */
  @Input() labelPlaceholder: string;

  /**
   * Id value
   */
  @Input() idName: string;

  /**
   * Tells if element is disabled or not
   */
  @Input() disabled: boolean;

  /**
   * Emits value after changes
   */
  @Output() ngModelChange = new EventEmitter();

  @Output() valueAdded = new EventEmitter();
  @Output() valueRemoved = new EventEmitter();

  prepareTooltips(validators: any) {
    const toolTips = [];
    if (validators.required) {
      toolTips.push('Field required');
    }
    if (validators.email) {
      toolTips.push('Field email');
    }
    if (typeof validators.min === 'number') {
      toolTips.push('Min field value ' + validators.min);
    }
    if (typeof validators.max === 'number') {
      toolTips.push('Max field value ' + validators.max);
    }
    if (typeof validators.minLength === 'number' && typeof validators.maxLength === 'number') {
      toolTips.push('Max Field length ' + validators.maxLength);
    } else if (typeof validators.minLength === 'number') {
      toolTips.push('Min field length ' + validators.minLength);
    } else if (typeof validators.maxLength === 'number') {
      toolTips.push('Max field length ' + validators.maxLength);
    }
    if (validators.regex) {
      toolTips.push('Field must be like : ' + new RandExp(validators.regex).gen());
    }
    return toolTips;
  }

  log(data) {
  }

  controlIsRequired(control) {
    try {
      const validator = control.validator({} as AbstractControl);
      return !!(validator && validator.required);
    } catch (e) {
      return false;
    }

  }

  isFormArray(control): boolean {
    return control instanceof FormArray;
  }

  isFormControl(control): boolean {
    return control instanceof FormControl;
  }

  preventDefault(event: UIEvent) {
    event.preventDefault();
    event.stopPropagation();
  }
}
