import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { TimelineElement } from './timeline-element';

@Component({
  selector: 'app-vertical-timeline',
  templateUrl: './vertical-timeline.component.html',
  styleUrls: ['./vertical-timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush, // Add this
  animations: [
    trigger('scrollAnimation', [
      transition('* => up', [
        animate('300ms ease-out', keyframes([
          style({ transform: 'translateY(100%)', offset: 0 }),
          style({ transform: 'translateY(0%)', offset: 1 })
        ]))
      ]),
      transition('* => down', [
        animate('300ms ease-out', keyframes([
          style({ transform: 'translateY(-100%)', offset: 0 }),
          style({ transform: 'translateY(0%)', offset: 1 })
        ]))
      ])
    ])
  ]
})
export class VerticalTimelineComponent implements OnInit, AfterViewInit {
  @Input() events: TimelineElement[] = [];
  @ViewChild('timelineContent') timelineContent!: ElementRef;

  eventsWithSpacing: (TimelineElement & {
    spacing?: number;
    isStart?: boolean;
  })[] = [];

  isAtTop: boolean = true;
  isAtBottom: boolean = false;
  currentPage: number = 0;
  eventsPerPage: number = 4;
  scrollDirection: 'up' | 'down' | null = null;

  private readonly BASE_SPACING = 40;
  private readonly MAX_SPACING = 240;
  private readonly MIN_SPACING = 20;

  constructor(private cdr: ChangeDetectorRef) {} // Inject ChangeDetectorRef

  ngOnInit() {
    this.calculateSpacing();
    this.checkScrollPosition();
  }

  ngAfterViewInit() {
    // Use setTimeout to run after change detection
    setTimeout(() => {
      this.checkScrollPosition();
      this.cdr.detectChanges(); // Manually trigger change detection
    });
  }

  @HostListener('window:resize')
  onResize() {
    this.checkScrollPosition();
  }

  private calculateSpacing() {
    const sortedEvents = [...this.events].sort((a, b) => 
      a.date.getTime() - b.date.getTime()
    );

    this.eventsWithSpacing = sortedEvents.map((event, index) => {
      const nextEvent = sortedEvents[index + 1];
      let spacing: number;

      if (index === 0) {
        spacing = 0;
      } else if (nextEvent) {
        const timeDiff = nextEvent.date.getTime() - event.date.getTime();
        const hoursDiff = timeDiff / (1000 * 60 * 60);
        spacing = this.calculateSpacingFromHours(hoursDiff);
      } else {
        spacing = this.MIN_SPACING;
      }

      return {
        ...event,
        spacing,
        isStart: index === 0
      };
    });
  }

  private calculateSpacingFromHours(hours: number): number {
    const spacing = this.BASE_SPACING * Math.log2(hours + 1);
    return Math.min(Math.max(spacing, this.MIN_SPACING), this.MAX_SPACING);
  }

  private checkScrollPosition() {
    this.isAtTop = this.currentPage === 0;
    this.isAtBottom = this.currentPage === Math.floor((this.eventsWithSpacing.length - 1) / this.eventsPerPage);
  }

  scrollUp() {
    if (!this.isAtTop) {
      this.scrollDirection = 'down';
      this.currentPage--;
      this.checkScrollPosition();
      this.cdr.detectChanges(); // Manually trigger change detection
    }
  }

  scrollDown() {
    if (!this.isAtBottom) {
      this.scrollDirection = 'up';
      this.currentPage++;
      this.checkScrollPosition();
      this.cdr.detectChanges(); // Manually trigger change detection
    }
  }

  onAnimationDone() {
    this.scrollDirection = null;
    this.cdr.detectChanges(); // Manually trigger change detection
  }

  get displayedEvents() {
    const start = this.currentPage * this.eventsPerPage;
    return this.eventsWithSpacing.slice(start, start + this.eventsPerPage);
  }
}