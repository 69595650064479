import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AbstractFormElement} from '../abstract-form-elements';

@Component({
  selector: 'app-dropzone',
  templateUrl: './dropzone.component.html',
  styleUrls: ['./dropzone.component.scss']
})
export class DropzoneComponent extends AbstractFormElement implements OnChanges {
  @Input() acceptedFiles: string;
  files: any = [];

  ngOnChanges(changes: SimpleChanges): void {
  }

  uploadFile(event) {
    for (let index = 0; index < event.length; index++) {
      
      const element = event[index];
      this.files.push(element);
    }
    
    
    this.ngModelChange.emit(this.files);

  }

  deleteAttachment(index) {
    this.files.splice(index, 1);
  }
}