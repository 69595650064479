import {Injectable} from '@angular/core';
import * as Noty from 'noty';

@Injectable({
  providedIn: 'root'
})
export class NotyService {

  constructor() {
  }

  displayNotification(message: string, type: 'success' | 'warning' | 'error') {
    new Noty({
      theme: 'sunset',
      type,
      text: message,
      timeout: 7000
    }).show();    
  }


}
