import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormGroup, FormBuilder, FormArray, FormControl} from '@angular/forms';
import {getRelatedModel, prepareValidators, hideField} from 'app/shared/global/funct';
import {CrudService} from 'app/shared/services/crud.service';

@Component({
    selector: 'app-form-group',
    templateUrl: './form-group.component.html',
    styleUrls: ['./form-group.component.scss']
})
export class FormGroupComponent implements OnInit {

    @Input() fields: any[];
    @Input() url: string;
    @Input() form: FormGroup;
    @Input() files: any = {};
    @Input() relatedModels = {};
    @Output() fileUploaded: EventEmitter<any>;
    @Output() filedChanged: EventEmitter<any>;
    @Input() private modelId: string;


    constructor(private crudService: CrudService,
                private fb: FormBuilder) {
        this.fileUploaded = new EventEmitter<any>();
        this.filedChanged = new EventEmitter<any>();
    }

    /**
     * Returns form controls
     */
    get controls() {
        return this.form.controls;
    }

    /**
     * Get one form array by name, name can be "att1.att2.att3" and so one
     */
    getFormArray(name: string): FormArray {
        return this.form.get(name) as FormArray;
    }

    ngOnInit(): void {

    }

    ngOnChanges(changes: SimpleChanges) {
        
    }

    /**
     * Get one form control by name, name can be "att1.att2.att3" and so one
     */
    getFormControl(name: string): FormControl {
        return this.form.get(name) as FormControl;
    }


    markOneFieldPristine(name: string) {
        this.controls[name].markAsPristine();
    }

    async setRelatedModels(fieldName,
                           url,
                           specificField = null,
                           search: { relatedField: string, relatedValue: any } = null,
                           formControlName = null) {
        getRelatedModel(url, search, formControlName)
            .then((data: any) => {
                if (formControlName) {
                    this.getFormControl(formControlName).setValue(null);
                }
                this.relatedModels[fieldName] = data;
                if (specificField) {
                    this.relatedModels[fieldName] = this.relatedModels[fieldName][0][specificField];
                }
            });
    }

    addToFormArray(field: any) {

        const temp = {};
        field.children
            .forEach(child => {
                temp[child.name] = [child.defaultValue, prepareValidators(child, this.form, this.modelId)];
            });

        this.getFormArray(field.name).push(this.fb.group(temp));
    }

    log($event: any) {
    }

    hideField(fieldName: string, hide: boolean) {
        hideField(fieldName, hide, this.fields, this.form);
    }

    removeFromArrayField(control: string | FormArray, i: number) {
        if (typeof control === 'string') {
            this.getFormArray(control).removeAt(i);
        } else {
            control.removeAt(i);
        }
    }

    duplicateInArrayField(field: any, i: number) {
        const temp = {};
        field.children
            .forEach(child => {
                temp[child.name] = [this.getFormArray(field.name).at(i).value[child.name], prepareValidators(child, this.form, this.modelId)];
            });
        this.getFormArray(field.name).insert(i + 1, this.fb.group(temp));
    }

    fillFields(field: any, $event: any, relatedModels: any[]) {
        const model = relatedModels.find(m => m.id === $event);
        for (const fill of field.backend.fill) {
            this.getFormControl(fill.field).setValue(model[fill.attribute]);
            this.getFormControl(fill.field).updateValueAndValidity();
        }
    }


    /**
     * Satisfies the condition for a given field based on the event value.
     * This method checks if the event value matches the condition specified for the field.
     * If the condition is not satisfied, it sets the field as conditional and clears its value.
     *
     * @param {any} field - The field to check the condition for.
     * @param {any} $event - The event value to check against the field's condition.
     */
    satisfyCondition(field: any, $event: any) {
        const eventArray = Array.isArray($event) ? $event : [$event];
        const conditionalField = this.fields.find(f => f.condition?.field === field.name);

        if (!eventArray.length) {
            if (conditionalField) {
                conditionalField.conditional = true;
                this.form.get(conditionalField.name).setValue(null);
            }
        } else if (conditionalField) {
            conditionalField.conditional = !eventArray.includes(conditionalField.condition.value);
            if (conditionalField.conditional) {
                this.form.get(conditionalField.name).setValue(null);
            }
        }
    }
}
