<!-- Sidebar Header starts -->
<div class="sidebar-header" *ngIf="config?.layout.menuPosition === 'Side'">
  <div class="logo clearfix">
    <a [routerLink]="[firstAccessiblePath || '']" class="logo-text mr-3">
      <!-- Wrapper for vertical alignment and text wrapping -->
      <div class="logo-content text-center">
        <!-- Image logo -->
        <div class="logo-img mb-2 mr-4">
          <img [src]="logoUrl" alt="SAE logo" [ngStyle]="{
                  'width': config.layout.sidebar.collapsed ? '30px' : '70px',
                  'height': 'auto'
               }" />
        </div>
        <!-- Text acronym -->
        <!-- <span class="text d-block text-center" *ngIf="!config.layout.sidebar.collapsed">{{acronym}}</span> -->
      </div>
    </a>
    <a class="nav-toggle d-none d-lg-none d-xl-block" id="sidebarToggle" href="javascript:;" (click)="toggleSidebar()">
      <i #toggleIcon [ngClass]="{
        'ft-toggle-left': config.layout.sidebar.collapsed,
        'ft-toggle-right': !config.layout.sidebar.collapsed
      }" class="toggle-icon"></i>
    </a>
    <a class="nav-close d-block d-lg-block d-xl-none" id="sidebarClose" href="javascript:;" (click)="CloseSidebar()">
      <i class="ft-x"></i>
    </a>
  </div>
</div>


<!-- Sidebar Header Ends -->

<!-- Sidebar Content starts -->
<div class="scrollable-container mb-3">
  <div class="sidebar-content main-menu-content" [perfectScrollbar] [disabled]="!perfectScrollbarEnable">
    <div class="nav-container">
      <ul class="navigation" appSidebarDropdown>
        <!-- First level menu -->
        <li appSidebarlink *ngFor="let menuItem of menuItems" [parent]="menuItem.title" [path]="menuItem.path"
          [open]="isMenuExpanded(menuItem)"
          [sidebarGroupActive]="isMenuExpanded(menuItem)"
          level="{{level + 1}}" [hasSub]="menuItem.class.includes('has-sub') ? true: false" [ngClass]="{
          'nav-item': true,
          'has-sub': menuItem.submenu && menuItem.submenu.length > 0,

          'active': activeMenuPaths.has(menuItem.path) && !(menuItem.submenu && menuItem.submenu.length > 0)
        }">
          <a appSidebarAnchorToggle *ngIf="menuItem.submenu.length > 0 && !menuItem.isExternalLink">
            <i [ngClass]="[menuItem.icon]"></i>
            <span class="menu-title">{{menuItem.title | translate }}</span>
            <span *ngIf="menuItem.badge && menuItem.badge != '' "
              [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
          </a>
          <a appSidebarAnchorToggle routerLink="{{menuItem.path}}"
            *ngIf="menuItem.submenu.length === 0 && !menuItem.isExternalLink">
            <i [ngClass]="[menuItem.icon]"></i>
            <span class="menu-title">{{menuItem.title | translate }}</span>
            <span *ngIf="menuItem.badge && menuItem.badge != '' "
              [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
          </a>
          <a [href]="[menuItem.path]" target="_blank" *ngIf="menuItem.isExternalLink">
            <i [ngClass]="[menuItem.icon]"></i>
            <span class="menu-title">{{menuItem.title | translate }}</span>
            <span *ngIf="menuItem.badge && menuItem.badge != '' "
              [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
          </a>
          <!-- Second level menu -->
          <ul appSidebarDropdown class="menu-content" *ngIf="menuItem.submenu.length > 0 ">
            <li appSidebarlink *ngFor="let menuSubItem of menuItem.submenu" [parent]="menuItem.title"
              [hasSub]="menuSubItem.class.includes('has-sub') ? true: false" [path]="menuSubItem.path"
              [sidebarGroupActive]="isMenuExpanded(menuSubItem)"
              [open]="isMenuExpanded(menuSubItem)"
              level="{{level + 2}}" [ngClass]="{'has-sub': menuSubItem.class.includes('has-sub') ? true: false}"
              [routerLinkActive]="menuSubItem.submenu.length != 0 ? 'open' : 'active'">
              <a appSidebarAnchorToggle *ngIf="menuSubItem.submenu.length > 0 && !menuSubItem.isExternalLink">
                <i [ngClass]="[menuSubItem.icon]"></i>
                <span class="menu-title">{{menuSubItem.title | translate }}</span>
                <span *ngIf="menuSubItem.badge && menuSubItem.badge != '' "
                  [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
              </a>
              <a appSidebarAnchorToggle routerLink="{{menuSubItem.path}}"
                *ngIf="menuSubItem.submenu.length === 0 && !menuSubItem.isExternalLink">
                <i [ngClass]="[menuSubItem.icon]"></i>
                <span class="menu-title">{{menuSubItem.title | translate }}</span>
                <span *ngIf="menuSubItem.badge && menuSubItem.badge != '' "
                  [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
              </a>
              <a routerLink="{{menuSubItem.path}}" target="_blank" *ngIf="menuSubItem.isExternalLink">
                <i [ngClass]="[menuSubItem.icon]"></i>
                <span class="menu-title">{{menuSubItem.title | translate }}</span>
                <span *ngIf="menuSubItem.badge && menuSubItem.badge != '' "
                  [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
              </a>
              <!-- Third level menu -->
              <ul appSidebarDropdown class="menu-content" *ngIf="menuSubItem.submenu.length > 0">
                <li appSidebarlink *ngFor="let menuSubsubItem of menuSubItem.submenu" [parent]="menuItem.title"
                  [hasSub]="false" [path]="menuSubsubItem.path" level="{{level + 3}}" routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: true}"
                  [sidebarGroupActive]="isMenuExpanded(menuSubsubItem)"
                  [open]="isMenuExpanded(menuSubsubItem)"
                  [ngClass]="config.layout.menuPosition === 'Side' ? menuSubsubItem.class : ''">
                  <a appSidebarAnchorToggle routerLink="{{menuSubsubItem.path}}"
                    *ngIf="!menuSubsubItem.isExternalLink; else externalSubSubLinkBlock">
                    <i [ngClass]="[menuSubsubItem.icon]"></i>
                    <span class="menu-title">{{menuSubsubItem.title | translate }}</span>
                    <span *ngIf="menuSubsubItem.badge && menuSubsubItem.badge != '' "
                      [ngClass]="[menuSubsubItem.badgeClass]">{{menuSubsubItem.badge}}</span>
                  </a>
                  <ng-template #externalSubSubLinkBlock>
                    <a routerLink="{{menuSubsubItem.path}}" target="_blank">
                      <i [ngClass]="[menuSubsubItem.icon]"></i>
                      <span class="menu-title">{{menuSubsubItem.title | translate }}</span>
                      <span *ngIf="menuSubsubItem.badge && menuSubsubItem.badge != '' "
                        [ngClass]="[menuSubsubItem.badgeClass]">{{menuSubsubItem.badge}}</span>
                    </a>
                  </ng-template>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</div>
<!-- Sidebar Content Ends -->

<!-- Add the Powered by section -->
<!-- <div class="sidebar-footer text-center mt-auto">
  <div class="powered-by">
    <img src="assets/img/logos/logo-bws.png" alt="Powered by BWS" class="powered-by-logo"[ngStyle]="{
      'width': config.layout.sidebar.collapsed ? '40px' : '70px',
      'height': 'auto'
   }" />
    <p class="powered-by-text font-weight-bold text-white" *ngIf="!config.layout.sidebar.collapsed">Powered by Be Wireless Solutions</p>
  </div>
</div> -->