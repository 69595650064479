import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import { SpinnerService } from '../services/spinner.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {

  constructor(private spinnerService: SpinnerService, private spinner :NgxSpinnerService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.has('skipLoader')) {
      const newHeaders = request.headers.delete('skipLoader');
      const newRequest = request.clone({headers: newHeaders});
      return next.handle(newRequest);
    } else {
      this.spinnerService.dontHideSpinner();
      return next.handle(request)
        .pipe(finalize(() => {
          this.spinnerService.hideSpinner();
        }));
    }
  }
}
