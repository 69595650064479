
<div class="modal-header">
  <h4 class="modal-title" data-testid="delete-modal-title">{{"DELETE_TITLE" | translate}}</h4>
  <button type="button" class="close" data-testid="delete-modal-close" aria-label="Close" (click)="activeModal.close('Cross click')">
    <i class="fa-solid fa-xmark"></i>
  </button>
</div>
<div class="modal-body">
  <p data-testid="delete-modal-message">{{"DELETE_MESSAGE" | translate}} <strong>{{itemName}}</strong> ?</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-danger" data-testid="delete-modal-confirm" (click)="deleteItem()">{{"DELETE" | translate}}</button>
  <button type="button" class="btn btn-secondary" data-testid="delete-modal-cancel" (click)="activeModal.close('Close click')">{{"CANCEL" | translate}}</button>
</div>