import {Component, OnChanges, SimpleChanges} from '@angular/core';
import {AbstractFormElement} from "../abstract-form-elements";

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss']
})
export class TextAreaComponent extends AbstractFormElement implements OnChanges {

  ngOnChanges(changes: SimpleChanges): void {
  }

}