import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AbstractFormElement} from '../abstract-form-elements';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent extends AbstractFormElement implements OnChanges {
  @Input() type: 'text' | 'number' | 'email' | 'password' | 'checkbox' | 'date';

  checked;

  ngOnChanges(changes: SimpleChanges): void {

    if (this.type === 'checkbox' && this.control) {
      // @ts-ignore
      this.checked = this.control.value;
    }
  }

  onChange($event: Event) {
    if (this.type === 'checkbox') {
      // @ts-ignore
      this.control.setValue($event.target.checked);
    }
  }
}