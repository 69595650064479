import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {OPN_BASE_URL} from '../../../shared/global/var';

@Injectable({
  providedIn: 'root'
})
export class GtfsService {

  constructor(
      private http: HttpClient
  ) { }

  exportGtfsRtData(): Observable<any> {
    return this.http.get(OPN_BASE_URL + "/gtfs/export-rt", {responseType: 'blob'});
  }

  exportGtfsData(): Observable<any> {
    return this.http.get(OPN_BASE_URL + "/gtfs/export", {responseType: 'blob'});
  }
}
