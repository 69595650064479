import { Injectable, PipeTransform } from "@angular/core";
import { WorkRequest } from "../models/work-request";
import { SortDirection } from "app/shared/directives/sortable.directive";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { DecimalPipe, formatDate } from "@angular/common";
import { debounceTime, delay, map, switchMap, tap } from "rxjs/operators";
import { M_BASE_URL } from "../../../../shared/global/var";

interface SearchResult {
  workRequests: WorkRequest[];
  total: number;
}

/**
 * Interface representing the state of the work request list.
 */
interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: string;
  sortDirection: SortDirection;
}

/**
 * Compares two values for sorting purposes.
 * @param v1 The first value to compare.
 * @param v2 The second value to compare.
 * @returns -1 if v1 < v2, 1 if v1 > v2, 0 if equal.
 */
function compare(v1, v2) {
  return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
}

/**
 * Sorts the table data.
 * @param workRequests The work requests to sort.
 * @param column The column to sort.
 * @param direction The sorting direction.
 */
function sort(
  workRequests: WorkRequest[],
  column: string,
  direction: string
): WorkRequest[] {
  if (direction === "") {
    return workRequests;
  } else {
    return [...workRequests].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === "asc" ? res : -res;
    });
  }
}
function matches(
  workRequest: WorkRequest,
  term: string,
  pipe: PipeTransform
) {
  const lowercaseTerm = term.toLowerCase();

  return (
    (workRequest.description?.toLowerCase().includes(lowercaseTerm) ??
      false) || // ensure vehicle registration is valid
    (pipe?.transform(workRequest.vehicles.vehicleNumber)?.includes(lowercaseTerm) ??
      false) ||
    (pipe
      ?.transform(workRequest.requestDate)
      ?.includes(lowercaseTerm) ??
      false)
  );
}

@Injectable({
  providedIn: "root",
})
export class WorkRequestService {
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _workRequests$ = new BehaviorSubject<WorkRequest[]>([]);
  private _total$ = new BehaviorSubject<number>(0);

  private _selectedApplicantIds$ = new BehaviorSubject<string[]>([]);
  private _selectedUrgencies$ = new BehaviorSubject<number[]>([]);
  private _selectedVehicleIds$ = new BehaviorSubject<number[]>([]);
  private _selectedStates$ = new BehaviorSubject<number[]>([]);

  private _startDate$ = new BehaviorSubject<string | null>(null);
  private _endDate$ = new BehaviorSubject<string | null>(null);

  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: "",
    sortColumn: "",
    sortDirection: "asc",
  };
  
  constructor(private http: HttpClient, private pipe: DecimalPipe) {
    this._search$
      .pipe(
        tap(() => this._loading$.next(true)),
        debounceTime(200),
        switchMap(() => this._search()),
        delay(200),
        tap(() => this._loading$.next(false))
      )
      .subscribe((result) => {
        this._workRequests$.next(result.workRequests);
        this._total$.next(result.total);
      });

    this._search$.next();
  }

  get loading$() {
    return this._loading$.asObservable();
  }

  get workRequests$() {
    return this._workRequests$.asObservable();
  }

  get total$() {
    return this._total$.asObservable();
  }

  get page() {
    return this._state.page;
  }
  get pageSize() {
    return this._state.pageSize;
  }
  get searchTerm() {
    return this._state.searchTerm;
  }

  get selectedApplicants$(): BehaviorSubject<string[]> {
    return this._selectedApplicantIds$;
  }

  set selectedApplicants$(value: BehaviorSubject<string[]>) {
    this._selectedApplicantIds$ = value;
  }

  get selectedUrgencies$(): BehaviorSubject<number[]> {
    return this._selectedUrgencies$;
  }

  set selectedUrgencies$(value: BehaviorSubject<number[]>) {
    this._selectedUrgencies$ = value;
  }

  get selectedVehicleIds$(): BehaviorSubject<number[]> {
    return this._selectedVehicleIds$;
  }

  set selectedVehicleIds$(value: BehaviorSubject<number[]>) {
    this._selectedVehicleIds$ = value;
  }

  get selectedStates$(): BehaviorSubject<number[]> {
    return this._selectedStates$;
  }

  set selectedStates$(value: BehaviorSubject<number[]>) {
    this._selectedStates$ = value;
  }

  get startDate$(): BehaviorSubject<string | null> {
    return this._startDate$;
  }

  set startDate$(value: BehaviorSubject<string | null>) {
    this._startDate$ = value;
  }

  get endDate$(): BehaviorSubject<string | null> {
    return this._endDate$;
  }

  set endDate$(value: BehaviorSubject<string | null>) {
    this._endDate$ = value;
  }

  // Setters to update the state and trigger a search
  set page(page: number) {
    this._set({ page });
  }

  set pageSize(pageSize: number) {
    this._set({ pageSize });
  }

  set searchTerm(searchTerm: string) {
    this._set({ searchTerm });
  }

  set sortColumn(sortColumn: string) {
    this._set({ sortColumn });
  }

  set sortDirection(sortDirection: SortDirection) {
    this._set({ sortDirection });
  }


  /**
   * Updates the state and triggers a new search.
   * @param patch Partial State object to update.
   */
  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  /**
   * Performs the search based on the current state.
   * @returns An Observable of SearchResult.
   */
  private _search(): Observable<SearchResult> {
    const { sortColumn, sortDirection, pageSize, page,searchTerm } = this._state;
  
    return this.fetchWorkRequests(sortColumn, sortDirection, page, pageSize).pipe(
      map((result: any) => {
        let workRequests = result.data;
        workRequests = workRequests.filter((task) =>
          matches(task, searchTerm, this.pipe)
      );
        workRequests = sort(workRequests, sortColumn, sortDirection);
        const total = result.count;
        return { workRequests, total };
      })
    );
  }

  fetchWorkRequests(
    sortColumn: string,
    sortDirection: SortDirection,
    page: number,
    perPage: number
  ): Observable<any> {
    const url = M_BASE_URL + "/workRequest/all";
    let params = new HttpParams()
      .set("column", sortColumn)
      .set("order", sortDirection)
      .set("page", page.toString())
      .set("perPage", perPage.toString());

    const applicantIds = this._selectedApplicantIds$.getValue();
    const urgencies = this._selectedUrgencies$.getValue();
    const vehicleIds = this._selectedVehicleIds$.getValue();
    const states = this._selectedStates$.getValue();
    const startDate = this._startDate$.getValue();
    const endDate = this._endDate$.getValue();

    if (applicantIds && applicantIds.length > 0) {
      applicantIds.forEach((applicantId) => {
        params = params.append("applicantIds", applicantId.toString());
      });
    }

    if (urgencies && urgencies.length > 0) {
      urgencies.forEach((urgency) => {
        params = params.append("urgencies", urgency.toString());
      });
    }

    if (vehicleIds && vehicleIds.length > 0) {
      vehicleIds.forEach((vehicleId) => {
        params = params.append("vehicleIds", vehicleId.toString());
      });
    }

    if (states && states.length > 0) {
      states.forEach((state) => {
        params = params.append("states", state.toString());
      });
    }

    if (startDate) {
      params = params.set("startDate", startDate);
    }

    if (endDate) {
      params = params.set("endDate", endDate);
    }

    return this.http.get<any>(url, { params });
  }

  fetchAllWorkRequests(): Observable<WorkRequest[]> {
    const url = M_BASE_URL + "/workRequest";
    return this.http.get<WorkRequest[]>(url);
  }

  setApplicantIds(applicants: string[]) {
    this._selectedApplicantIds$.next(applicants);
    this._search$.next();
  }

  setUrgencies(urgencies: number[]) {
    this._selectedUrgencies$.next(urgencies);
    this._search$.next();
  }

  setVehicleIds(vehicleIds: number[]) {
    this._selectedVehicleIds$.next(vehicleIds);
    this._search$.next();
  }

  setStates(states: number[]) {
    this._selectedStates$.next(states);
    this._search$.next();
  }

  setDateRange(startDate: string | null, endDate: string | null) {
    this._startDate$.next(startDate);
    this._endDate$.next(endDate);
    this._search$.next();
  }

  changeState(id: number, newState: number): Observable<WorkRequest> {
    return this.http.post<WorkRequest>(
      `${M_BASE_URL}/workRequest/${id}/change-state?newState=${newState}`,
      {}
    );
  }

  updateAppointment(
    id: number,
    appointment: string,
    enPanne: boolean
  ): Observable<WorkRequest> {
    return this.http.post<WorkRequest>(
      `${M_BASE_URL}/workRequest/${id}/create-appointment?appointment=${appointment}&enPanne=${enPanne}`,
      {}
    );
  }

  createNotifWorkRequest(newWorkRequest): Observable<WorkRequest> {
    const url = `${M_BASE_URL}/workRequest/add`;
    return this.http.post<WorkRequest>(url, newWorkRequest).pipe(
      tap(() => {
        this._search$.next();
      })
    );
  }

}
