import {Agency, Family, Fuel, Genre, Oil, Staff} from '../../../operating-networks/trips-management/models/trips-instances.model';
import {Model} from '@swimlane/ngx-datatable';

export interface Vehicle {
    bus: boolean;
    id: number;
    idBoitier: number;
    parkingNumber: string;
    vehicleNumber: string;
    registration: string;
    capacity: number;
    circulationDate: string;
    status: number;
    genreId: number;
    genre: Genre;
    modelId: number;
    model: Model;
    familyId: number;
    family: Family;
    agencyId: number;
    agency: Agency;
    tankCapacity: number;
    defaultDriverId: number | null;
    oilStandardId: number;
    oil: Oil;
    fuelStandardId: number;
    fuel: Fuel;
    location: string;
}

export interface WorkRequest {
    appointment: any;
    id: number;
    applicantId: number;
    applicant?: string;
    vehicleTypeId: number;
    vehiclesId: number;
    vehicles?: Vehicle;
    requestDate: string;
    requestType: string;
    description: string;
    files: string[];
    state: number;
    urgency: number;
    enPanne?: boolean;
    // O_T: string;
}
