import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "app/shared/auth/auth.service";
import {
  BASE_API_FLEET,
  BASE_API_STAFF,
  OPN_BASE_URL,
} from "app/shared/global/var";
import { CrudService } from "app/shared/services/crud.service";
import { throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";

interface GroupedInstance {
  date: string;
  instances: any[];
  isCollapsed: boolean;
}
@Component({
  selector: "app-grouped-assignment-modal",
  templateUrl: "./grouped-assignment-modal.component.html",
  styleUrls: ["./grouped-assignment-modal.component.scss"],
})
export class GroupedAssignmentModalComponent implements OnInit {
  @Input() data;
  @Output() itemUpdated: EventEmitter<void> = new EventEmitter<void>();

  checkAvailabilityResponse: any;

  vehicles: any;
  groupForm: FormGroup;
  submitted: boolean = false;
  tripsInstance: any;

  selectedReceiverId;
  filtredInstance: any;
  applicants: any;
  driver: any;
  receiver: any;
  missions: any;
  busList: any;
  startDate: any;
  endDate: any;
  selectedDriverId: any;
  selectedBusId: any;
  filtredInstanceIds: number[] = [];

  showPopover = false;
  groupedReceivers: any[];
  groupedDrivers: any[];
  username: string;

  groupedInstances: GroupedInstance[] = [];
  isCollapsed: boolean[] = [];
  langue = localStorage.getItem("langue");

  initialFilteredInstance: any[];
  isAvailabilityChecked: boolean = false;

  public viewState: "form" | "assignment" = "form";
  formViewData: any;
  reviewViewData: any;
  exportViewData: any;

  constructor(
    public activeModal: NgbActiveModal,
    private crudService: CrudService,
    private fb: FormBuilder,
    private authservice: AuthService,
    private cdr: ChangeDetectorRef
  ) {
    this.groupForm = this.fb.group({
      id: [""],
      bus: this.fb.group({
        id: [""],
      }),
      departure: [""],
      driver: this.fb.group({
        id: [""],
      }),
      receiver: this.fb.group({
        id: [""],
      }),
    });
    this.username = this.authservice.getUsernameFromToken();
  }

  /**
   * Angular lifecycle hook that is called after data-bound properties of a directive are initialized.
   */

  ngOnInit(): void {
    this.filtredInstance = Array.from(this.data);
    this.initialFilteredInstance = [...this.filtredInstance];
    this.groupedInstances = this.transformInstancesByDate();

    this.isCollapsed = this.transformInstancesByDate().map(() => false);
    this.fetchApplicants();
    this.fetchVehicleList();

    this.filtredInstanceIds = this.filtredInstance.map(
      (instance) => instance.id
    );
  }

  groupBy(item: any) {
    return item.group;
  }

  /**
   * Deselects an instance from the filtered instances list.
   *
   * @param trip The trip instance to deselect.
   */

  // deselectInstance(trip) {
  //   if (this.filtredInstance) {
  //     this.filtredInstance = this.filtredInstance.filter(
  //       (instance) => instance !== trip
  //     );
  //   }
  // }

  /**
   * Fetches the list of applicants.
   */

  fetchApplicants() {
    const url = `${BASE_API_STAFF}/staff?username=${encodeURIComponent(
      this.username
    )}`;
    this.crudService.getAll(url).subscribe((data: any) => {
      this.applicants = data.data;
      this.driver = this.applicants.filter((applicant) =>
        applicant.job.includes(1)
      );
      this.receiver = this.applicants.filter((applicant) =>
        applicant.job.includes(2)
      );

      this.groupedReceivers = [
        ...this.receiver.map((r) => ({
          id: r.id,
          name: `#${r.matricule} - ${r.firstName} ${r.lastName}`,
          group: this.langue === "fr" ? "Liste des receveurs" : "Receiver List"
        })),
      ];
      this.groupedDrivers = [
        ...this.driver.map((r) => ({
          id: r.id,
          name: `#${r.matricule} - ${r.firstName} ${r.lastName}`,
          group: this.langue === "fr" ? "Liste des chauffeurs" : "Driver List"
        })),
      ];
    });
  }

  /**
   * Fetches the list of available vehicles.
   */

  fetchVehicleList() {
    const url = `${BASE_API_FLEET}/vehicles?username=${encodeURIComponent(
      this.username
    )}`;
    this.crudService.getAll(url).subscribe(
      (data: any) => {
        this.vehicles = data.data;
        this.busList = this.vehicles.filter(
          (vehicle) => vehicle.family.bus === true && vehicle.status === 1
        );
        this.busList = [
          // { id: 0, vehicleNumber: 'Unassigned' },
          ...this.busList,
        ];
      },
      (error) => {
        console.error("Error fetching applicants:", error);
      }
    );
  }

  /**
   * Handles the selection change for the driver. Updates the filtered instances by assigning
   * or removing the selected driver based on the current selection. If the selected driver has
   * a default vehicle, it will automatically update the bus selection.
   *
   * @param selectedItem The selected driver object from the selection list.
   */

  onDriverChange(selectedItem: any): void {
    const previousSelectedDriverId = this.selectedDriverId;
    this.selectedDriverId = selectedItem ? Number(selectedItem.id) : null;

    if (this.selectedDriverId === null) {
      this.filtredInstance = this.filtredInstance.map((instance) => {
        if (
          instance.driver &&
          instance.driver.id === previousSelectedDriverId
        ) {
          return { ...instance, driver: null };
        }
        return instance;
      });
    } else {
      const selectedDriver = this.driver.find(
        (driver) => driver.id === this.selectedDriverId
      );
      if (selectedDriver) {
        this.filtredInstance = this.filtredInstance.map((instance) => {
          if (!instance.driver) {
            return { ...instance, driver: selectedDriver };
          }
          return instance;
        });

        if (selectedDriver.defaultVehicleId) {
          const defaultVehicle = this.vehicles.find(
            (v) => v.id === selectedDriver.defaultVehicleId
          );
          if (defaultVehicle) {
            this.updateBusSelectList(defaultVehicle);
            this.onBusChange({ id: defaultVehicle.id });
          }
        }
      }
    }

    this.updateInstances("driver", this.selectedDriverId);
    this.groupForm.get("driver.id")?.setValue(this.selectedDriverId);
  }

  /**
   * Handles the bus selection change. Updates the filtered instances by assigning
   * or removing the selected bus based on the current selection.
   *
   * @param selectedItem The selected bus object from the selection list.
   */

  onBusChange(selectedItem: any): void {
    const previousSelectedBusId = this.selectedBusId;
    this.selectedBusId = selectedItem ? Number(selectedItem.id) : null;

    if (this.selectedBusId === null) {
      this.filtredInstance = this.filtredInstance.map((instance) => {
        if (instance.bus && instance.bus.id === previousSelectedBusId) {
          return { ...instance, bus: null };
        }
        return instance;
      });
    } else {
      const selectedBus = this.busList.find(
        (bus) => bus.id === this.selectedBusId
      );
      if (selectedBus) {
        this.filtredInstance = this.filtredInstance.map((instance) => {
          if (!instance.bus) {
            return { ...instance, bus: selectedBus };
          }
          return instance;
        });
      }
    }

    this.updateInstances("bus", this.selectedBusId);
    this.groupForm.get("bus.id")?.setValue(this.selectedBusId);
  }

  /**
   * Handles the selection change for the receiver. Updates the filtered instances by assigning
   * or removing the selected receiver based on the current selection.
   *
   * @param selectedItem The selected receiver object from the selection list.
   */

  onReceiverChange(selectedItem: any): void {
    const previousSelectedReceiverId = this.selectedReceiverId;
    this.selectedReceiverId = selectedItem ? Number(selectedItem.id) : null;

    if (this.selectedReceiverId === null) {
      this.filtredInstance = this.filtredInstance.map((instance) => {
        if (
          instance.receiver &&
          instance.receiver.id === previousSelectedReceiverId
        ) {
          return { ...instance, receiver: null };
        }
        return instance;
      });
    } else {
      const selectedReceiver = this.receiver.find(
        (receiver) => receiver.id === this.selectedReceiverId
      );
      if (selectedReceiver) {
        this.filtredInstance = this.filtredInstance.map((instance) => {
          if (!instance.receiver) {
            return { ...instance, receiver: selectedReceiver };
          }
          return instance;
        });
      }
    }

    this.updateInstances("receiver", this.selectedReceiverId);
    this.groupForm.get("receiver.id")?.setValue(this.selectedReceiverId);
  }

  /**
   * Updates the filtered instances by setting the selected item (driver, receiver, or bus)
   * to all instances where the selected field is not already set. Marks the instance as overridden.
   *
   * @param field The field to update (driver, receiver, or bus).
   * @param selectedId The ID of the selected item.
   */

  updateInstances(
    field: "driver" | "receiver" | "bus",
    selectedId: number
  ): void {
    const selectedItem = this.getSelectedItem(field, selectedId);
    if (selectedItem) {
      this.filtredInstance = this.filtredInstance.map((instance) => {
        if (!instance[field]) {
          return {
            ...instance,
            [field]: selectedItem,
            [`override${field.charAt(0).toUpperCase() + field.slice(1)}`]: true,
          };
        }
        return instance;
      });
    }
  }

  /**
   * Updates the bus selection list by adding the default vehicle if it's not already in the list.
   * Sets the selected bus ID in the form.
   *
   * @param defaultVehicle The default vehicle associated with the selected driver.
   */

  updateBusSelectList(defaultVehicle: any): void {
    if (!this.busList.some((bus) => bus.id === defaultVehicle.id)) {
      this.busList = [...this.busList, defaultVehicle];
    }
    this.groupForm.get("bus.id")?.setValue(defaultVehicle.id);
    this.selectedBusId = defaultVehicle.id;
  }

  /**
   * Groups the filtered instances by their planned departure date.
   *
   * @return A list of grouped instances, where each group contains instances for the same date.
   */

  transformInstancesByDate(): any[] {
    if (this.filtredInstance) {
      const grouped = this.filtredInstance.reduce((acc, instance) => {
        const date = instance.plannedDeparture.split("T")[0];
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(instance);
        return acc;
      }, {} as { [key: string]: any[] });

      return Object.keys(grouped).map((date) => ({
        date,
        instances: grouped[date],
        isCollapsed: false, // Initially, all groups are open
      }));
    }
    return [];
  }

  /**
   * Returns the day of the week for the given date string in French.
   *
   * @param dateString The date string in ISO format (YYYY-MM-DD).
   * @return The name of the day of the week in French.
   */

  getDayOfWeek(dateString: string): string {
    const daysFr = [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
    ];

    const daysEn = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const date = new Date(dateString);
    const dayIndex = date.getDay();

    return this.langue === "fr" ? daysFr[dayIndex] : daysEn[dayIndex];
  }

  /**
   * Toggles the overrideDriver flag for the given trip.
   *
   * @param trip The trip object for which the overrideDriver flag should be toggled.
   * @param checked The new value of the overrideDriver flag.
   */

  toggleOverrideDriver(trip: any, checked: boolean): void {
    trip.overrideDriver = checked;
  }
  /**
   * Toggles the overrideReceiverDayOff flag for the given trip.
   *
   * @param trip The trip object for which the overrideReceiverDayOff flag should be toggled.
   * @param checked The new value of the overrideReceiverDayOff flag.
   */

  toggleOverrideReceiverDayOff(trip: any, checked: boolean): void {
    trip.overrideReceiverDayOff = checked;
  }

  /**
   * Toggles the overrideDriverDayOff flag for the given trip.
   *
   * @param trip The trip object for which the overrideDriverDayOff flag should be toggled.
   * @param checked The new value of the overrideDriverDayOff flag.
   */

  toggleOverrideDriverDayOff(trip: any, checked: boolean): void {
    trip.overrideDriverDayOff = checked;
  }
  /**
   * Toggles the overrideReceiver flag for the given trip.
   *
   * @param trip The trip object for which the overrideReceiver flag should be toggled.
   * @param checked The new value of the overrideReceiver flag.
   */

  toggleOverrideReceiver(trip: any, checked: boolean): void {
    trip.overrideReceiver = checked;
  }
  /**
   * Toggles the overrideBus flag for the given trip.
   *
   * @param trip The trip object for which the overrideBus flag should be toggled.
   * @param checked The new value of the overrideBus flag.
   */

  toggleOverrideBus(trip: any, checked: boolean): void {
    trip.overrideBus = checked;
  }

  /**
   * Toggles the collapsed state of a group of instances at a given index.
   *
   * @param index - The index of the group to toggle.
   */

  toggleCollapse(index: number) {
    this.groupedInstances[index].isCollapsed =
      !this.groupedInstances[index].isCollapsed;
  }

  /**
   * Checks the availability of the selected trip instances, applying the latest driver, bus, and receiver selections.
   * Updates the filtered instances list with the latest availability status.
   */

  checkAvailabilityForInstances(): void {
    const url = OPN_BASE_URL + "/trips-instance/check-all-availabilities";
    const startDate = this.filtredInstance[0]?.plannedDeparture;
    const endDate =
      this.filtredInstance[this.filtredInstance.length - 1]?.estimatedArrival;

    const instancesWithLatestSelections = this.filtredInstance.map(
      (instance, index) => {
        const updatedInstance = { ...instance };
        const initialInstance = this.initialFilteredInstance[index];

        if (!initialInstance.driver && this.selectedDriverId) {
          updatedInstance.driver = this.getSelectedItem(
            "driver",
            this.selectedDriverId
          );
        }

        if (!initialInstance.bus && this.selectedBusId) {
          updatedInstance.bus = this.getSelectedItem("bus", this.selectedBusId);
        }

        if (!initialInstance.receiver && this.selectedReceiverId) {
          updatedInstance.receiver = this.getSelectedItem(
            "receiver",
            this.selectedReceiverId
          );
        }

        return updatedInstance;
      }
    );

    this.crudService
      .checkAvailabilityForTrips(
        url,
        instancesWithLatestSelections,
        startDate,
        endDate
      )
      .pipe(
        tap((response: any) => {
          if (response.status === 200) {
            this.checkAvailabilityResponse = response.body;
            this.filtredInstance = (response.body as any[]).map(
              (apiInstance: any, index) => {
                const initialInstance = this.initialFilteredInstance[index];
                return {
                  ...apiInstance,
                  driver: initialInstance.driver || apiInstance.driver,
                  receiver: initialInstance.receiver || apiInstance.receiver,
                  bus: initialInstance.bus || apiInstance.bus,
                  checked: true,
                  overrideDriver: false,
                  overrideDriverDayOff: false,
                  overrideReceiver: false,
                  overrideReceiverDayOff: false,
                  overrideBus: false,
                };
              }
            );

            this.groupedInstances = this.transformInstancesByDate();
            this.cdr.detectChanges();
          }
        }),
        catchError((error) => {
          console.error("Error during availability checks:", error);
          return throwError(error);
        })
      )
      .subscribe();

    this.isAvailabilityChecked = true;
  }

  /**
   * Returns the appropriate badge class based on the trip's status for the specified type (driver, receiver, or bus).
   *
   * @param trip The trip instance containing driver, receiver, and bus details.
   * @param type The type of entity ('driver', 'receiver', or 'bus') for which to get the badge class.
   * @return A string representing the badge class to apply for the UI element.
   */

  getBadgeClass(trip: any, type: "driver" | "receiver" | "bus"): string {
    if (!trip.checked) {
      return "badge-secondary";
    }
    switch (type) {
      case "driver":
        if (trip.driver === null) {
          return "badge-secondary";
        } else if (trip.driverConflict) {
          return "bg-danger";
        } else if (trip.driverDayOff) {
          return "bg-warning";
        } else {
          return "bg-success";
        }
      case "receiver":
        if (trip.receiver === null) {
          return "badge-secondary";
        } else if (trip.receiverConflict) {
          return "bg-danger";
        } else if (trip.receiverDayOff) {
          return "bg-warning";
        } else {
          return "bg-success";
        }
      case "bus":
        if (trip.bus === null) {
          return "badge-secondary";
        } else if (trip.busConflict) {
          return "bg-danger";
        } else {
          return "bg-success";
        }
      default:
        return "badge-secondary";
    }
  }

  /**
   * Saves the form by updating grouped trip instances on the server.
   * After a successful update, the modal is closed.
   */

  saveForm() {
    this.crudService
      .updateGroupedInstances(
        OPN_BASE_URL + "/trips-instance/update-grouped",
        this.groupedInstances
      )
      .subscribe(
        (response) => {
          this.activeModal.close();
          this.itemUpdated.emit();
        },
        (error) => {
          console.error("Erreur lors de la mise à jour des instances", error);
        }
      );
    
  }

  /**
   * Removes the driver from a specific trip instance and stores the removed driver for potential undo.
   *
   * @param trip The trip instance from which to remove the driver.
   */

  removeDriver(trip: any) {
    trip.removedDriver = trip.driver;
    trip.driver = null;
    this.updateInstancesToExport({
      ...trip,
      plannedDeparture: trip.plannedDeparture,
    });
  }

  /**
   * Restores the previously removed driver to the trip instance.
   *
   * @param trip The trip instance to which the removed driver will be restored.
   */

  undoRemoveDriver(trip: any) {
    trip.driver = trip.removedDriver;
    trip.removedDriver = null;
    this.updateInstancesToExport({
      ...trip,
      plannedDeparture: trip.plannedDeparture,
    });
  }

  /**
   * Removes the bus from a specific trip instance and stores the removed bus for potential undo.
   *
   * @param trip The trip instance from which to remove the bus.
   */

  removeBus(trip: any) {
    trip.removedBus = trip.bus;
    trip.bus = null;
    this.updateInstancesToExport({
      ...trip,
      plannedDeparture: trip.plannedDeparture,
    });
  }

  /**
   * Restores the previously removed bus to the trip instance.
   *
   * @param trip The trip instance to which the removed bus will be restored.
   */

  undoRemoveBus(trip: any) {
    trip.bus = trip.removedBus;
    trip.removedBus = null;
    this.updateInstancesToExport({
      ...trip,
      plannedDeparture: trip.plannedDeparture,
    });
  }

  /**
   * Removes the receiver from a specific trip instance and stores the removed receiver for potential undo.
   *
   * @param trip The trip instance from which to remove the receiver.
   */

  removeReceiver(trip: any) {
    trip.removedReceiver = trip.receiver;
    trip.receiver = null;
    this.updateInstancesToExport({
      ...trip,
      plannedDeparture: trip.plannedDeparture,
    });
  }

  /**
   * Restores the previously removed receiver to the trip instance.
   *
   * @param trip The trip instance to which the removed receiver will be restored.
   */

  undoRemoveReceiver(trip: any) {
    trip.receiver = trip.removedReceiver;
    trip.removedReceiver = null;
    this.updateInstancesToExport({
      ...trip,
      plannedDeparture: trip.plannedDeparture,
    });
  }

  /**
   * Updates the filtered instances list and grouped instances after modifying a trip instance.
   *
   * @param updatedTrip The updated trip instance containing new or modified driver, bus, or receiver data.
   */

  updateInstancesToExport(updatedTrip: any): void {
    const updatedTripDate = new Date(
      updatedTrip.plannedDeparture
    ).toDateString();

    this.filtredInstance = this.filtredInstance.map((instance: any) => {
      if (
        instance.id === updatedTrip.id &&
        new Date(instance.plannedDeparture).toDateString() === updatedTripDate
      ) {
        return { ...instance, ...updatedTrip };
      }
      return instance;
    });

    const collapseState = this.groupedInstances.map(
      (group) => group.isCollapsed
    );

    this.groupedInstances = this.transformInstancesByDate();

    this.groupedInstances.forEach((group, index) => {
      if (index < collapseState.length) {
        group.isCollapsed = collapseState[index];
      }
    });

    this.cdr.detectChanges();
  }

  /**
   * Advances the view state from the form view to the assignment view.
   * Stores the initial state of filtered instances for later use.
   */

  onNextClicked(): void {
    if (this.viewState === "form") {
      this.initialFilteredInstance = JSON.parse(
        JSON.stringify(this.filtredInstance)
      );
      this.viewState = "assignment";
    }
  }

  /**
   * Returns the view state from the assignment view back to the form view.
   * Restores the initial state of filtered instances and updates grouped instances accordingly.
   */

  returnToPrevious(): void {
    if (this.viewState === "assignment") {
      this.filtredInstance = JSON.parse(
        JSON.stringify(this.initialFilteredInstance)
      );
      this.groupedInstances = this.transformInstancesByDate();
      this.checkAvailabilityResponse = null;
      this.viewState = "form";
    }
  }

  /**
   * Retrieves the selected item (driver, receiver, or bus) based on the field and the provided ID.
   *
   * @param field The type of entity ('driver', 'receiver', or 'bus') to be retrieved.
   * @param id The ID of the selected entity.
   * @return The selected entity (driver, receiver, or bus) corresponding to the provided ID.
   */

  private getSelectedItem(
    field: "driver" | "receiver" | "bus",
    id: number
  ): any {
    switch (field) {
      case "driver":
        return this.driver.find((d) => d.id === id);
      case "receiver":
        return this.receiver.find((r) => r.id === id);
      case "bus":
        return this.busList.find((b) => b.id === id);
    }
  }

  // tooltip overlapping Trpis
  toggleOverlappingTrips(
    popover,
    overlappingTrigger: string,
    currentTrip: any
  ) {
    let overlappings = [];

    let overlappingMsg = "";
    // Driver_On_Leave
    // Driver_Already_Affected
    // Assignation_OK
    // Assigned
    // Not_Assigned
    let assignationEmpty = false;

    if (this.checkAvailabilityResponse) {
      // verification done
      //  get the overlapping trips
      let tripOverlapping = this.checkAvailabilityResponse.find(
        (t) => t.id == currentTrip.id
      );

      if (tripOverlapping) {
        switch (overlappingTrigger) {
          case "Driver":
            {
              if (!currentTrip.driver) {
                overlappingMsg = "Not_Assigned";
                assignationEmpty = true;
              } else {
                if (tripOverlapping.driverConflict) {
                  overlappingMsg = "Already_Affected";
                  overlappings = tripOverlapping.driverOverlappingTrips;
                } else if (tripOverlapping.driverDayOff) {
                  overlappingMsg = "Driver_On_Leave";
                  assignationEmpty = true;
                } else {
                  overlappingMsg = "Assignation_OK";
                }

                assignationEmpty = false;
              }
            }
            break;
          case "Receiver":
            {
              if (!currentTrip.receiver) {
                overlappingMsg = "Not_Assigned";
                assignationEmpty = true;
              } else {
                if (tripOverlapping.receiverConflict) {
                  overlappingMsg = "Already_Affected";
                  overlappings = tripOverlapping.receiverOverlappingTrips;
                } else if (tripOverlapping.receiverDayOff) {
                  overlappingMsg = "Receiver_On_Leave";
                  assignationEmpty = true;
                } else {
                  overlappingMsg = "Assignation_OK";
                }
                assignationEmpty = false;
              }
            }
            break;
          case "Bus":
            {
              if (!currentTrip.bus) {
                overlappingMsg = "Not_Assigned";
                assignationEmpty = true;
              } else {
                if (tripOverlapping.busConflict) {
                  overlappingMsg = "Already_Affected";
                  overlappings = tripOverlapping.busOverlappingTrips;
                } else {
                  overlappingMsg = "Assignation_OK";
                }
                assignationEmpty = false;
              }
            }
            break;

          default:
        }
      }
      if (popover.isOpen()) {
        popover.close();
      } else {
        popover.open({
          overlappings,
          overlappingMsg,
          assignationEmpty,
          overlappingTrigger,
        });
      }
    } // verification not yet
    else {
      if (popover.isOpen()) {
        popover.close();
      } else {
        switch (overlappingTrigger) {
          case "Driver":
            {
              if (currentTrip.driver) {
                overlappingMsg = "Assigned";
                assignationEmpty = false;
              } else {
                overlappingMsg = "Not_Assigned";
                assignationEmpty = true;
              }
            }
            break;
          case "Receiver":
            {
              if (currentTrip.receiver) {
                overlappingMsg = "Assigned";
                assignationEmpty = false;
              } else {
                overlappingMsg = "Not_Assigned";
                assignationEmpty = true;
              }
            }
            break;
          case "Bus":
            {
              if (currentTrip.bus) {
                overlappingMsg = "Assigned";
                assignationEmpty = false;
              } else {
                overlappingMsg = "Not_Assigned";
                assignationEmpty = true;
              }
            }
            break;

          default:
        }
        popover.open({
          overlappings,
          overlappingMsg,
          assignationEmpty,
          overlappingTrigger,
        });
      }
    }
  }
}
