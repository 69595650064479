<div class="row">
    <div class="col-12">
        <div id="kick-start" class="card">
            <div class="card-content">
                <div class="card-body">
                    <section id="extended">
                        <div class="row">
                            <div class="col-12">
                                <div class="d-flex flex-row justify-content-between">
                                    <div class="mr-2 w-25">
                                        <form action="javascript:">
                                            <div class="position-relative has-icon-left">
                                                <input class="form-control" placeholder="{{'SEARCH' | translate }}"
                                                    id="timesheetinput1" name="staffType" type="text"
                                                    [(ngModel)]="searchQuery">
                                                <div class="form-control-position ">
                                                    <i class="ft-search"></i>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="btn-popup float-right pb-2" style="display: flex; align-items: center;">

                                        <a *ngIf="showCreateButton" [routerLink]="createButtonLink"
                                            class="btn btn-outline-primary"><span class="mr-1"><i class="fa-solid fa-plus"></i></span>{{ createButtonText | titlecase | translate }}</a>
                                        <a *ngIf="showFilter" class="ml-2" (click)="isCollapsed = !isCollapsed"
                                            [attr.aria-expanded]="!isCollapsed">
                                            <button type="button" class="btn bg-light-primary">
                                                <span class="d-flex align-items-center">
                                                    <i class="ft-filter mr-1"></i>
                                                    <span>{{ "FILTER" | translate }}</span>
                                                    <span class="badge badge-pill badge-danger ml-1"
                                                        *ngIf="selectedOptionsCount > 0">
                                                        {{ selectedOptionsCount }}
                                                    </span>
                                                </span>
                                            </button>

                                        </a>
                                        <div *ngIf="showExportButton" class="dropdown ml-2">
                                            <button class="btn btn-primary dropdown-toggle" type="button"
                                                id="exportDropdown" data-toggle="dropdown" aria-haspopup="true"
                                                aria-expanded="false" (click)="isExporting = !isExporting">
                                                <i class="ft-upload"></i> {{ "EXPORT" | translate }}
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="exportDropdown" [ngClass]="{
                                      'position-absolute bg-white p-2': isExporting,
                                      'd-none': !isExporting
                                    }" class="filter">
                                                <a class="dropdown-item" (click)="exportToCSV()">{{ "EXPORT_TO_CSV" |
                                                    translate }}</a>
                                                <a class="dropdown-item" (click)="exportToExcel()">{{ "EXPORT_TO_EXCEL"
                                                    |
                                                    translate }}</a>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                                <div *ngIf="showFilter" [ngClass]="{
                                      'position-absolute bg-white p-2': isCollapsed,
                                      'd-none': !isCollapsed
                                    }" class="filter">
                                    <div id="collapseExample" [ngbCollapse]="!isCollapsed" class="mt-2">

                                        <div class="side-nav filter-tab-content">
                                            <h5 class="my-2 ml-- text-bold-500">
                                                <span class="float-right cursor-pointer" (click)="closeFilter()">
                                                    <em class="fas fa-times"></em> </span>{{ "FILTER" | translate }}
                                            </h5>
                                            <button class="deleteAll" (click)="resetFilter()">
                                                {{ "DELETE_ALL" | translate }}
                                            </button>
                                            <div>
                                                <div *ngFor="let field of filterFields">
                                                    <ngb-accordion #acc="ngbAccordion">
                                                        <ngb-panel id="ngb-panel-0">
                                                            <ng-template ngbPanelTitle>
                                                                <span class="panel-title" style="color: black">
                                                                    <em class="fas fa-chevron-right mr-2"></em>
                                                                    <span>{{ field.title | translate }}</span>
                                                                </span>
                                                            </ng-template>
                                                            <ng-template ngbPanelContent>
                                                                <div class="filter-content">
                                                                    <div *ngIf="field.filterType === 'select'">
                                                                        <ul class="list-unstyled"
                                                                            style="margin-top: -20px">
                                                                            <li *ngFor="let option of field.options"
                                                                                class="mb-1 ml-4">
                                                                                <div class="checkbox mb-2">
                                                                                    <input type="checkbox"
                                                                                        [id]="'checkbox' + option?.name"
                                                                                        [checked]="selectedFilters[field.name]?.includes(option?.id)"
                                                                                        (change)="onCheckboxChange(option, field)"
                                                                                        name="checkbox{{ option?.id }}" />
                                                                                    <label
                                                                                        [for]="'checkbox' + option?.name"><span>{{
                                                                                            option?.name
                                                                                            }}</span></label>

                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div *ngIf="field.filterType === 'date'">
                                                                        <nz-range-picker
                                                                            [(ngModel)]="dateRange[field.name]"
                                                                            (ngModelChange)="filterData()"
                                                                            nzShowTime></nz-range-picker>
                                                                    </div>
                                                                </div>
                                                            </ng-template>
                                                        </ngb-panel>
                                                    </ngb-accordion>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div class="table-striped">
                                        <ng-container>
                                            <nz-table [nzData]="dataToDisplay" [nzFrontPagination]="false"
                                                [nzNoResult]="noResultTmpl">
                                                <thead>
                                                    <tr>
                                                        <th *ngFor="let header of fields;let i=index">
                                                            {{ header.title |translate }}
                                                            <nz-dropdown-menu #searchDropDown="nzDropdownMenu">
                                                                <div class="ant-table-filter-dropdown">
                                                                    <div class="search-box">
                                                                        <label for="">{{ "operator"|translate }} :
                                                                        </label>
                                                                        <select class="form-control"
                                                                            [(ngModel)]="selectedOperators[header.name]">
                                                                            <option
                                                                                *ngFor="let operator of operatorOptions"
                                                                                [value]="operator.value">{{
                                                                                operator.label }}
                                                                            </option>
                                                                        </select>
                                                                        <label for="">{{ "search"|translate }} :
                                                                        </label>
                                                                        <input (keydown.enter)="search()"
                                                                            class="form-control"
                                                                            [(ngModel)]="searchFields[header.searchKey]"
                                                                            nz-input
                                                                            placeholder="{{'SEARCH' | translate }} {{fields[i].title}}"
                                                                            type="text" />
                                                                        <button (click)="search()"
                                                                            class="ant-btn search-button ant-btn-primary ant-btn-sm mr-2 ant-btn-round"
                                                                            nz-button nzSize="small" nzType="primary">
                                                                            {{ "search" | translate }}
                                                                        </button>
                                                                        <button (click)="reset(false,header.searchKey)"
                                                                            nz-button class="ant-btn-round"
                                                                            nzSize="small">{{ "reset"| translate }}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </nz-dropdown-menu>
                                                            <nz-dropdown-menu #dateDropDown="nzDropdownMenu">
                                                                <div class="ant-table-filter-dropdown">
                                                                    <div class="search-box">
                                                                        <nz-range-picker
                                                                            (ngModelChange)="rangeChange($event,header.searchKey)"
                                                                            [(ngModel)]="temp"></nz-range-picker>
                                                                        <br>
                                                                        <button (click)="search()"
                                                                            class="ant-btn search-button ant-btn-primary ant-btn-sm mr-2 ant-btn-round"
                                                                            nz-button nzSize="small" nzType="primary">
                                                                            {{ "search"|translate }}
                                                                        </button>
                                                                        <button (click)="reset(true,header.searchKey)"
                                                                            nz-button class="ant-btn-round"
                                                                            nzSize="small" nzType="warning">{{
                                                                            "reset"|translate }}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </nz-dropdown-menu>
                                                        </th>
                                                        <th *ngIf="(canDelete||canEditThroughDatatable||canEditThroughForm)"
                                                            class="text-right">
                                                            {{ "Actions"|translate }}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <ng-container
                                                        *ngFor="let data of dataToDisplay;let currentLine=index">
                                                        <tr [class.cursor-pointer]="!canEditThroughForm"
                                                            [class.editable-row]="canEditThroughDatatable">
                                                            <ng-container
                                                                *ngFor="let field of data | search: searchQuery : data; let l = last; let currentField = index">
                                                                <td *ngIf="!l&&!fields[currentField].datatableEditKey"
                                                                    [class.selected-td]="selectedLine===currentLine">
                                                                    <ng-container
                                                                        *ngIf="fields[currentField].type==='file' && field.length;else dateTmpl">
                                                                        <ng-container *ngIf="field">
                                                                            <img alt=""
                                                                                src="{{field[0].path|imagePath}}"
                                                                                width="100px">
                                                                        </ng-container>
                                                                        <ng-container *ngIf="!field.length">
                                                                            NA
                                                                        </ng-container>
                                                                    </ng-container>
                                                                    <ng-template #actionTmpl>
                                                                        <ng-container
                                                                            *ngIf="fields[currentField].type==='action';else dateTmpl">
                                                                            <a (click)="fields[currentField].onAction(data[data.length-1])"
                                                                                *ngIf="field" class="btn btn-secondary">
                                                                                {{ fields[currentField].actionText
                                                                                }}</a>
                                                                        </ng-container>
                                                                    </ng-template>
                                                                    <ng-template #dateTmpl>
                                                                        <ng-container
                                                                            *ngIf="fields[currentField].type==='date';else elseTmpl">
                                                                            {{ field|date:'yyyy-MM-dd HH:mm:ss' }}
                                                                        </ng-container>
                                                                    </ng-template>
                                                                    <ng-template #elseTmpl>
                                                                        {{ field }}
                                                                    </ng-template>
                                                                </td>
                                                                <td *ngIf="!l&&fields[currentField].datatableEditKey"
                                                                    [class.selected-td]="selectedLine===currentLine">
                                                                    <div *ngIf="!editCache[data[data.length-1]]?.edit; else editCode"
                                                                        class="editable-cell">
                                                                        <div class="editable-cell-value-wrap">
                                                                            {{ field }}
                                                                        </div>
                                                                    </div>
                                                                    <ng-template #editCode>
                                                                        <input
                                                                            [(ngModel)]="editCache[data[data.length-1]]?.data[fields[currentField].datatableEditKey]"
                                                                            nz-input type="text" />
                                                                    </ng-template>
                                                                </td>

                                                                <td *ngIf="l&&(canDelete||canEditThroughDatatable||canEditThroughForm)"
                                                                    [class.editable-row-operations]="canEditThroughDatatable"
                                                                    [class.selected-td]="selectedLine===currentLine"
                                                                    (click)="$event.stopPropagation()"
                                                                    class="text-right">
                                                                    <ng-container
                                                                        *ngIf="!editCache[data[data.length-1]]?.edit; else saveTpl">
                                                                        <div
                                                                            class="position-relative d-flex align-items-center justify-content-end cursor-pointer">
                                                                            <div container="body" ngbDropdown
                                                                                display="dynamic"
                                                                                class="d-inline-block">
                                                                                <i class="fas fa-ellipsis-h mt-1"
                                                                                    id="rowOptions"
                                                                                    ngbDropdownToggle></i>
                                                                                <div ngbDropdownMenu
                                                                                    aria-labelledby="rowOptions">
                                                                                    <a *ngIf="hasDetails&&redirectTo"
                                                                                        class="dropdown-item"
                                                                                        (click)="showDetail(data[data.length-1])">
                                                                                        {{
                                                                                        "View_DETAILS" | translate }}
                                                                                    </a>
                                                                                    <a *ngIf="canEditThroughDatatable && !canEditThroughForm && hasEditControl "
                                                                                        (click)="startEdit(data[data.length-1])"
                                                                                        class="dropdown-item">
                                                                                        {{
                                                                                        "EDIT" | translate }}
                                                                                    </a>
                                                                                    <a *ngIf="canEditThroughForm && !canEditThroughDatatable && hasEditControl "
                                                                                        [routerLink]="createUpdateRoute(data[data.length-1])"
                                                                                        class="dropdown-item">
                                                                                        {{
                                                                                        "EDIT" | translate }}
                                                                                    </a>
                                                                                    <a *ngIf="canEditThroughForm && canEditThroughDatatable && hasEditControl "
                                                                                        (click)="openEditConfirmation(data[data.length-1])"
                                                                                        class="dropdown-item">
                                                                                        {{
                                                                                        "EDIT" | translate }}
                                                                                    </a>
                                                                                    <a *ngIf="canDelete"
                                                                                        (click)="deleteElement(data[data.length-1], $event)"
                                                                                        class="dropdown-item text-danger">
                                                                                        {{ "DELETE" | translate }}
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </ng-container>
                                                                    <ng-template #saveTpl>
                                                                        <a (click)="saveEdit(data[data.length-1])"
                                                                            class="mr-2">
                                                                            <i
                                                                                class="ft-save success font-medium-3"></i>
                                                                        </a>
                                                                        <a (nzOnConfirm)="cancelEdit(data[data.length-1])"
                                                                            nz-popconfirm
                                                                            nzPopconfirmTitle="Sure to cancel?">
                                                                            <i class="ft-x danger font-medium-3"></i>
                                                                        </a>
                                                                    </ng-template>
                                                                </td>
                                                            </ng-container>
                                                        </tr>
                                                    </ng-container>
                                                </tbody>
                                            </nz-table>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="float-right d-inline-flex" *ngIf="nbElements > 0">
                                    <ngb-pagination [(page)]="pageIndex" [pageSize]="pageSize"
                                        [collectionSize]="nbElements" (pageChange)="updateRouteQueryParams()"
                                        [maxSize]="3" style="margin-left: 5px;"></ngb-pagination>
                                </div>
                                <div class="float-right d-inline-flex" *ngIf="nbElements > 0">
                                    <ng-select [(ngModel)]="pageSize" (ngModelChange)="updateRouteQueryParams()"
                                        [clearable]="false">
                                        <ng-option [value]="10" selected> 10 / page</ng-option>
                                        <ng-option [value]="20">20 / page</ng-option>
                                        <ng-option [value]="40">40 / page</ng-option>
                                        <ng-option [value]="60">60 / page </ng-option>
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                        <ng-template #noResultTmpl>
                            <nz-empty *ngIf="!dataToDisplay?.length" [nzNotFoundContent]="contentTpl">
                                <ng-template #contentTpl>
                                    <span>
                                        {{'NoData'|translate}}
                                    </span>
                                </ng-template>
                            </nz-empty>
                        </ng-template>
