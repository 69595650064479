<div class="modal-content border-0">
    <div class="modal-header">
        <h4 class="modal-title font-medium-3">{{"TRIPS_DETAILS"|translate}}</h4>
        <button type="button" aria-label="Close" class="close" (click)="closeModal()">
            <i class="fa-solid fa-xmark"></i>
        </button>
    </div>
    <div class="modal-body">
        <div class="d-flex gap-05 font-medium-1">
            <!-- route -->
            <div class="badge badge-dark  p-2 rounded-pill font-weight-bold ">{{data.lineNumber}}</div>
            <!-- trip code -->
            <div class="badge badge-secondary rounded-pill p-2 font-weight-bold">
                <i class="fa-solid fa-bus mr-2"></i>{{data.lineDirection}}
            </div>
            <!-- trip route -->
            <div class="badge badge-secondary flex-grow-1 p-2 rounded-pill">{{ data.tripRoute }}</div>
            <!-- departure -->
            <div class="badge badge-light rounded-pill flex-grow-1 p-2">
                {{data.plannedDeparture | date :"dd/MM/yyyy"}} <b>{{data.plannedDeparture | date :"HH:mm"}}</b> <i
                    class="fa-solid mx-1 fa-arrow-right"></i>
                <b>{{data.estimatedArrival | date :"HH:mm"}}</b>
            </div>
            <!-- status -->
            <div class="align-items-center badge rounded-pill badge-light d-flex justify-content-center text-center">
                <div class="status-pill mr-1"
                    [ngClass]="{'bg-secondary': data.status == '1', 'bg-info': data.status == '2', 'bg-warning': data.status == '3', 'bg-danger': data.status == '4', 'bg-success': data.status == '5' , 'bg-grey': data.status =='6'}">
                </div>
                {{ data.status == 1 ? ("NOT_ASSIGNED" | translate) : (data.status == 2 ? ("ASSIGNED" | translate) :
                (data.status == 3 ? ("IN_PROGRESS" | translate) : (data.status == 4 ?
                ("CANCELLED" | translate) : (data.status == 5 ? ("COMPLETED" | translate) : (data.status == 6 ?
                ("REPORTED" | translate) : ""))))) }}
            </div>
            <!-- action -->
            <div ngbDropdown *ngIf="data.status !== 4" display="dynamic" class="d-inline-block" style="max-width: min-content;"
                [placement]="['bottom-right', 'bottom-left', 'top-right', 'top-left']" [container]="'body'">
                <button class="bg-light-info btn dropdown-toggle float-right px-2 right rounded-pill"
                    id="dropdownBasic1" ngbDropdownToggle>
                    <i class="fa-solid fa-ellipsis"></i>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">

                    <button *ngIf="data.status === 1 || data.status === 2" class="dropdown-item w-100"
                        (click)="openReportModal(data)" [appHasPermission]="['postpone_trip']">
                        <i class="fa-solid fa-clock-rotate-left mr-1"></i>{{"REPORT" | translate}}</button>

                    <button *ngIf="!isEditing " type="button" class="dropdown-item w-100 bg-light-danger"
                        (click)="ConfirmText()" [appHasPermission]="['cancel_trip']">
                        <i class="fa-regular fa-calendar-xmark mr-1"></i> {{"CANCEL_TRIP" | translate}}
                    </button>
                </div>
            </div>
        </div>

        <ul ngbNav #basicnav="ngbNav" class="nav-tabs mt-3">

            <li class="col-sm-auto col-lg-3" [ngbNavItem]="1" [formGroup]="assignmenetForm">
                <a class="text-dark text-bold-600 text-center" ngbNavLink>{{"TRIPS_INFO"|translate}}</a>

                <ng-template ngbNavContent>

                    <div class="d-flex justify-content-end gap-05 mt-2">

                        <button  type="button" class="btn btn-light round "
                            [disabled]=" data.status === 4 && isEditDisabled()" *ngIf="!isEditing && data.status !== 4 "
                            (click)="editMission()" [appHasPermission]="['update_trip']">
                            {{"EDIT"|translate}}
                            <i class="fas fa-pen ml-1"></i>
                        </button>

                        <button *ngIf="isEditing" type="submit" [disabled]="isSaveDisabled()"
                            class="btn bg-light-info round" (click)="saveForm()">{{"SAVE"|translate}}</button>

                        <button *ngIf="isEditing" type="button" class="btn bg-light-danger round"
                            (click)="cancelEdit()">{{"CANCEL"|translate}}</button>


                    </div>

                    <div class="w-100">
                        <div class="w-100" *ngIf="data.status === 4">
                            <div class="col d-flex align-items-center bg-grey bg-lighten-4 rounded p-2  mb-3 mt-3">
                                <div class="point-list-none w-100" style="text-align: center;">
                                    <h4 class="text-danger" for="cancelReason">{{"CANCEL_REASON" | translate}}</h4>
                                    <p id="cancelReason">{{data.cancelReason}} </p>
                                </div>
                            </div>
                        </div>

                        <div class="trip-ressourcess row" [class.editing-w]="isEditing">

                            <!-- Overlapping Trips - tooltips -->
                            <ng-template #popTitle1 let-tripNumber="tripNumber" let-tripRoute="tripRoute">
                                <b>{{tripNumber}}</b> {{tripRoute}}
                            </ng-template>
                            <ng-template #popContent1 let-plannedDeparture="plannedDeparture"
                                let-plannedArrival="plannedArrival">
                                est. dep: <b> {{plannedDeparture | date :"HH:mm"}}</b>
                                <br>
                                est. arriv. <b> {{plannedArrival | date :"HH:mm"}}</b>
                            </ng-template>

                            <!-- driver card -->
                            <div *ngIf="data.status !== 4" class="col-lg-6 col-sm-12 p-3">
                                <div class="card card-outline-grey text-center box-shadow-0 my-0">
                                    <div class="card-content">
                                        <div class="card-body">
                                            <div class="media">
                                                <div class="media-body text-left">
                                                    <div class="point-list-none" formGroupName="driver">
                                                        <div *ngIf="!isEditing">

                                                            <div *ngIf="data.driver">
                                                                <h3 class="mb-1 info">{{data.driver.matricule}}</h3>
                                                                <span>{{data.driver.firstName}}
                                                                    {{data.driver.lastName}}</span>
                                                            </div>

                                                            <div *ngIf="!data.driver">
                                                                <h3 class="mb-1 danger">
                                                                    <i class="fa-solid fa-slash"></i>
                                                                </h3>
                                                                <span class="text-danger">
                                                                    {{"UNASSIGNED"|translate}} !
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div *ngIf="isEditing">
                                                            <ng-select id="drivers" formControlName="id"
                                                                [items]="groupedDrivers" [groupBy]="groupBy"
                                                                bindLabel="name" bindValue="id" [searchable]="true"
                                                                placeholder="{{'SELECT_DRIVER'|translate}}"
                                                                (change)="onDriverChange($event)">
                                                            </ng-select>
                                                        </div>

                                                        <div *ngIf="overlappingDriverMissionData"
                                                            class="mt-2 d-flex flex-column gap-1">

                                                            <span class="text-danger ">
                                                                {{"DRIVER_ALREADY_ASSIGNED" | translate}}:
                                                            </span>

                                                            <!-- overlappingDriverMissions -->
                                                            <div class="d-flex justify-content-center gap-1">
                                                                <button
                                                                    *ngFor="let trip of overlappingDriverMissionData"
                                                                    type="button"
                                                                    class="btn bg-light-danger px-2 py-1 rounded-pill"
                                                                    [ngbPopover]="popContent1"
                                                                    [popoverTitle]="popTitle1" triggers="manual"
                                                                    #pDriver="ngbPopover"
                                                                    (click)="toggleWithGreeting(pDriver,'Driver', trip.idTrip)">
                                                                    {{trip.lineDirection}}
                                                                </button>
                                                            </div>
                                                            <div class="d-flex gap-1 text-dark">
                                                                <input type="checkbox" id="removeDriverCheckbox"
                                                                    (change)="toggleRemoveDriver()">
                                                                <label for="removeDriverCheckbox">
                                                                    {{"FORCE_ASSIGNMENT" | translate}}:
                                                                    {{"MISSION_WILL_NO_LONGER_BE_ASSIGNED" |
                                                                    translate}}
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div *ngIf="selectedDriverOnLeave && (!overlappingDriverMissionData || overlappingDriverMissionData.length == 0)"
                                                            class="mt-2 d-flex flex-column gap-1">
                                                            <span class="text-danger ">
                                                                {{"DRIVER_ON_REST" | translate}} <i
                                                                    class="ml-1 fa-solid fa-bed"></i>
                                                            </span>
                                                            <div class="d-flex gap-1 text-dark">
                                                                <input type="checkbox" id="forceAssignDriverCheckbox"
                                                                    (change)="toggleForceAssignDriver()">
                                                                <label for="forceAssignDriverCheckbox">
                                                                    {{"FORCE_ASSIGNMENT" | translate}}.
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="media-right align-self-center">
                                                    <img src="./assets/img/icons/driver.png" class="float-right">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- receiver card -->
                            <div *ngIf="data.status !== 4" class="col-lg-6 col-sm-12 p-3">
                                <div class="card card-outline-grey text-center box-shadow-0 my-0">
                                    <div class="card-content">
                                        <div class="card-body">
                                            <div class="media">
                                                <div class="media-body text-left">
                                                    <div class="point-list-none" formGroupName="receiver">

                                                        <div *ngIf="!isEditing">

                                                            <div *ngIf="data.receiver">
                                                                <h3 class="mb-1 info">{{data.receiver.matricule}}</h3>
                                                                <span>{{data.receiver.firstName}}
                                                                    {{data.receiver.lastName}}</span>
                                                            </div>

                                                            <div *ngIf="!data.receiver">
                                                                <h3 class="mb-1 danger">
                                                                    <i class="fa-solid fa-slash"></i>
                                                                </h3>
                                                                <span class="text-danger">
                                                                    {{"UNASSIGNED"|translate}} !
                                                                </span>
                                                            </div>

                                                        </div>

                                                        <div *ngIf="isEditing">
                                                            <ng-select id="receivers" formControlName="id"
                                                                [items]="groupedReceivers" [groupBy]="groupBy"
                                                                bindLabel="name" bindValue="id" [searchable]="true"
                                                                placeholder="{{'SELECT_RECEIVER'|translate}}"
                                                                (change)="onReceiverChange($event)">

                                                            </ng-select>
                                                        </div>

                                                        <div *ngIf="overlappingReceiverMissionData"
                                                            class="mt-2 d-flex flex-column gap-1">
                                                            <span class="text-danger ">
                                                                {{"RECEIVER_ALREADY_ASSIGNED" | translate}}:
                                                            </span>
                                                            <!-- overlappingDriverMission -->
                                                            <div class="d-flex justify-content-center gap-1">
                                                                <button
                                                                    *ngFor="let trip of overlappingReceiverMissionData"
                                                                    type="button"
                                                                    class="btn bg-light-danger px-2 py-1 rounded-pill"
                                                                    [ngbPopover]="popContent1"
                                                                    [popoverTitle]="popTitle1" triggers="manual"
                                                                    #pReceiver="ngbPopover"
                                                                    (click)="toggleWithGreeting(pReceiver,'Receiver', trip.idTrip)">
                                                                    {{trip.lineDirection}}
                                                                </button>
                                                            </div>

                                                            <div class="d-flex gap-1 text-dark">
                                                                <input type="checkbox" id="removeReceiverCheckbox"
                                                                    (change)="toggleRemoveReceiver()">
                                                                <label for="removeReceiverCheckbox">
                                                                    {{"FORCE_ASSIGNMENT" | translate}}:
                                                                    {{"MISSION_WILL_NO_LONGER_BE_ASSIGNED" |
                                                                    translate}}
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <!-- Receiver en repos -->
                                                        <div *ngIf="selectedReceiverOnLeave  && (!overlappingDriverMissionData || overlappingDriverMissionData.length == 0)"
                                                            class="mt-2 d-flex flex-column gap-1">
                                                            <span class="text-danger ">{{"RECEIVER_ON_REST" |
                                                                translate}} <i class="ml-1 fa-solid fa-bed"></i></span>

                                                            <div class="d-flex gap-1 text-dark">
                                                                <input type="checkbox" id="forceAssignReceiverCheckbox"
                                                                    (change)="toggleForceAssignReceiver()">
                                                                <label for="forceAssignReceiverCheckbox">
                                                                    {{"FORCE_ASSIGNMENT" | translate}}.
                                                                </label>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                                <div class="media-right align-self-center">
                                                    <img src="./assets/img/icons/cashier.png">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Bus card -->
                            <div *ngIf="data.status !== 4" class="col-lg-6 col-sm-12 p-3">
                                <div class=" card card-outline-grey text-center box-shadow-0 my-0">
                                    <div class="card-content">
                                        <div class="card-body">
                                            <div class="media">
                                                <div class="media-body text-left">

                                                    <div class="point-list-none " formGroupName="bus">

                                                        <div *ngIf="!isEditing">

                                                            <div *ngIf="data.bus">
                                                                <h3 class="mb-1 info">{{ data.bus.vehicleNumber }}</h3>
                                                                <span>
                                                                    {{ data.bus.brandName }}
                                                                    / {{ data.bus.modelName }}</span>
                                                            </div>

                                                            <div *ngIf="!data.bus">
                                                                <h3 class="mb-1 danger">
                                                                    <i class="fa-solid fa-slash"></i>
                                                                </h3>
                                                                <span class="text-danger">
                                                                    {{"UNASSIGNED"|translate}} !
                                                                </span>
                                                            </div>

                                                        </div>

                                                        <div *ngIf="isEditing">
                                                            <ng-select [items]="busList" bindLabel="vehicleNumber"
                                                                bindValue="id" formControlName="id"
                                                                [placeholder]="'SELECT_BUS' | translate"
                                                                [searchable]="true" (change)="onBusChange($event)"
                                                                [appendTo]="'body'" class="custom-select-placement">
                                                            </ng-select>
                                                            <div *ngIf="overlappingBusMissionData"
                                                                class="mt-2 d-flex flex-column gap-1">
                                                                <span class="text-danger ">
                                                                    {{"BUS_ALREADY_ASSIGNED" | translate}}
                                                                </span>

                                                                <!-- overlappingBusMission -->
                                                                <div class="d-flex justify-content-center gap-1">
                                                                    <button
                                                                        *ngFor="let trip of overlappingBusMissionData"
                                                                        type="button"
                                                                        class="btn bg-light-danger px-2 py-1 rounded-pill"
                                                                        [ngbPopover]="popContent1"
                                                                        [popoverTitle]="popTitle1" triggers="manual"
                                                                        #pBus="ngbPopover"
                                                                        (click)="toggleWithGreeting(pBus,'Bus', trip.idTrip)">
                                                                        {{trip.lineDirection}}
                                                                    </button>
                                                                </div>

                                                                <div class="d-flex gap-1 text-dark">
                                                                    <input type="checkbox" id="removeBusCheckbox"
                                                                        (change)="toggleRemoveBus()">
                                                                    <label for="removeBusCheckbox">
                                                                        {{"FORCE_ASSIGNMENT" | translate}}:
                                                                        {{"MISSION_WILL_NO_LONGER_BE_ASSIGNED" |
                                                                        translate}}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>
                                                <div class="media-right align-self-center">
                                                    <img src="./assets/img/icons/buss.png" class="float-right" alt="">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </ng-template>
            </li>

            <!-- tabulation - progression -->
            <li class="col-sm-auto col-lg-2" [ngbNavItem]="2" [class.disabled]="data.status === 4"
                [attr.aria-disabled]="data.status === 4 ? 'true' : null">
                <a class="text-dark text-bold-600 text-center" ngbNavLink>{{"PROGRESSION"|translate}}</a>
                <ng-template ngbNavContent>
                    <!--<horizontal-timeline [timelineElements]="timeline" [progression]="data.progression"
                        [showContent]="true"></horizontal-timeline>-->
                    <app-trip-progress [tripsStations]="handledStations" [trip]="data"
                        [itinerary]="itinerary"></app-trip-progress>
                </ng-template>
            </li>
            <!-- tabulation - deviation -->
            <li class="col-sm-auto col-lg-3" [ngbNavItem]="3" [class.disabled]="data.status === 4"
                [attr.aria-disabled]="data.status === 4 ? 'true' : null">
                <a class="text-dark text-bold-600 text-center" ngbNavLink>{{"DEVIATIONS" | translate}}</a>
                <ng-template ngbNavContent>
                    <app-trip-deviation [stations]="handledStations" [trip]="data"
                        [itinerary]="itinerary"></app-trip-deviation>
                </ng-template>
            </li>
            <!-- tabulation - map -->
            <li class="col-sm-auto col-lg-2" [ngbNavItem]="4" [class.disabled]="data.status === 4"
                [attr.aria-disabled]="data.status === 4 ? 'true' : null">
                <a class="text-dark text-bold-600 text-center" ngbNavLink>{{"MAP" | translate}}</a>
                <ng-template ngbNavContent>
                    <app-real-time-map [stations]="handledStations" [itinerary]="itinerary" [trip]="data"
                        [isHistoric]="false"></app-real-time-map>
                </ng-template>
            </li>
            <!-- tabulation - historic -->
            <li class="col-sm-auto col-lg-2" [ngbNavItem]="5">
                <a ngbNavLink class="text-dark text-bold-600 text-center">{{"HISTORY" | translate}}</a>
                <ng-template ngbNavContent class="p-5">

                    <div class="card mt-0 box-shadow-0">
                        <div class="card-content">
                            <div class="card-body">
                                <div class="media pt-0 pb-3 d-flex align-items-center" *ngFor="let log of missionLogs">
                                    <div class="status-icon avatar avatar-lg">
                                        <span class="avatar-content">
                                            {{ getResponsableInitials(log.responsableName)}}
                                        </span>
                                    </div>
                                    <div class="media-body">
                                        <h4 class="font-small-3 mb-0">
                                            <strong class="mr-1">
                                                {{ log.responsableName.toLowerCase() === 'system' ? (this.langue == 'fr'
                                                ? 'Le système ' : 'The system ') : log.responsableName }}
                                            </strong>

                                            <span class="font-weight-light mr-1">{{ getActionDescription(log) }}</span>
                                            <span class="status-date-time">{{ formatDateTime(log.timestamp) }}</span>
                                            <!-- <span class="status-date-time">{{ formatDateTime(log.timestamp) }}</span> -->
                                        </h4>
                                        <!-- Only show old and new values for updates -->
                                        <!-- <p class="text-muted font-small-3 m-0">
                                            <span class="status-old text-muted">{{ log.oldValue || 'NONE-M' | translate }}</span>
                                            <i class="fa-solid fa-right-long mx-1"></i>
                                            <span class="status-new">{{ log.newValue || 'NONE-M' | translate }}</span>
                                        </p> -->
                                        <p class="text-muted font-small-3 m-0">
                                            <span class="status-old text-muted">{{ getStatusOrValue(log.oldValue) || 'NONE-M' | translate }}</span>
                                            <i class="fa-solid fa-right-long mx-1"></i>
                                            <span class="status-new">{{ getStatusOrValue(log.newValue) || 'NONE-M' | translate }}</span>
                                          </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>

        </ul>

        <div [ngbNavOutlet]="basicnav" class="nav-content p-0"></div>
    </div>
</div>
