export const OPN_BASE_URL = "/api/operating-network";
export let BASE_API = OPN_BASE_URL;
export const M_BASE_URL = "/api/maintenance";
export let M_BASE_API = M_BASE_URL;
export const BASE_URL_FLEET = "/api/fleet-management";
export const BASE_URL_STAFF = "/api/staff";
export const Energy_BASE_URL = "/api/energy";
export let BASE_API_ENERGY = Energy_BASE_URL;
export let BASE_API_FLEET = BASE_URL_FLEET;
export let BASE_API_STAFF = BASE_URL_STAFF;

export const WS_NOTIF_URL = "/wsnotification";
export let NOTIF_API = WS_NOTIF_URL;

export const WS_ALERTS_URL = "/wsalerts";
export let ALERTS_API = WS_ALERTS_URL;

export const User_BASE_URL = "/api/user-management";
export let BASE_API_USER = User_BASE_URL;

export const INSCRIPTION_BASE_URL = "/api/alert-management";
export let BASE_API_INSCRIPTION = INSCRIPTION_BASE_URL;

export const ALERTS_BASE_URL = "/api/alert-management/alert";
export let BASE_API_ALERTS = ALERTS_BASE_URL;
export const ALERT = "/alert";

export const USERS = "/users";
export const PRODUCTS = "/products";
export const CENTER = "/centers";
export const AGENCY = "/agency";
export const ROUTETYPE = "/routeType";
export const STAFF = "/staff";
export const STAFFTYPE = "/staff-types";
export const BUSTYPE = "/bus-types";
export const VEHICLEMODEL = "/vehicle-models";
export const VEHICLEBRAND = "/vehicle-brands";
export const VEHICLEFAMILY = "/vehicle-families";
export const MARQUE = "/vehicle-brands";
export const ROUTES = "/routes";
export const SERVICES = "/services";
export const STATIONS = "/stations";
export const VEHICLE = "/vehicles";
export const INTERVENTION_TYPE = "/interventionType";
export const INTERVENTION_CATEGORY = "/interventionCategory";
export const MAINTENANCE_TYPE = "/maintenanceType";
export const STANDARD = "/standard";
export const REAL_TIME = "/real-time-data";
export const SAE = "/sae-data";
export const MOBILE_OPT = "/mobile-opt";

export const NOMINATIM_URL = "/reverse";
