<div class="modal-header">
  <h4 class="modal-title">{{ getCardTitle() | translate }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
    <i class="fa-solid fa-xmark"></i>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="itineraryForm" (ngSubmit)="saveForm()">
    <div class="form-row">
      <div class="col-md-4 col-12">
        <div class="form-group mb-2">
          <label for="basic-form-1">{{ "Itinerary_Name" | translate
            }}</label>
          <input type="text" id="validationTooltip01" class="form-control" formControlName="itineraryLib"
            [ngClass]="{ 'is-invalid': submitted && i.itineraryLib.invalid, 'is-valid': submitted && !i.itineraryLib.invalid }" />
          <div *ngIf="submitted && i.itineraryLib.errors?.required" class="invalid-feedback">
            {{ "Itinerary_Lib" | translate }} {{ "REQUIRED" | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-4 col-12">
        <div class="form-group mb-2 d-flex justify-content-md-center">
          <div class="custom-switch custom-switch-success custom-control-inline mb-1 mb-xl-0 mt-md-4">
            <input type="checkbox" class="custom-control-input" id="color-switch-3" formControlName="direction"
              (change)="onDirectionChange($event.target.checked)">
            <label class="custom-control-label mr-1" for="color-switch-3">
              <span> <ng-container *ngIf="itineraryForm.get('direction').value === 1; else inactiveLabel">
                  {{ "Aller" | translate }}
                </ng-container>
                <ng-template #inactiveLabel>
                  {{ "Retour" | translate }}
                </ng-template></span>
            </label>

          </div>
        </div>
      </div>
      <div class="col-md-4 col-12">
        <div class="form-group mb-2  mt-md-4">
          <input class="col-auto" type="checkbox" id="backItinerary" formControlName="generateReturnItinerary">
          <span class="ml-2">{{"Generate_Return_Itinerary" | translate}}</span>
        </div>
      </div>
    </div>
    <div class="form-row mt-2">
      <div class="col-md-6 col-12">
        <div class="form-group mb-2">
          <span class="badge bg-light-warning text-bold-600 p-2 col-12">
            {{ totalDistance }}km
          </span>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="form-group mb-2">
          <span class="badge  bg-light-success text-bold-600 p-2 col-12">
            {{ totalDuration }} min
          </span>
        </div>
      </div>
    </div>
    <div class="border p-2 mt-2 rounded mb-2">
      <div class="table-responsive mt-2">
        <table class="table table-striped">
          <thead>
            <tr>
              <th class="text-bold-600"># {{ 'Order' | translate }}</th>
              <th class="text-bold-600">{{ 'Station' | translate }}</th>
              <th class="text-bold-600">{{ 'Distance(prec)' | translate }}</th>
              <th class="text-bold-600">{{ 'Duration' | translate }}</th>
              <th class="text-bold-600">{{ 'Stopping_Duration' | translate }}</th>
              <th class="text-bold-600">{{ 'Actions' | translate }}</th>
            </tr>
          </thead>
          <tbody [dragula]="'DRAGULA_FACTS'" [(dragulaModel)]="group1">
            <tr *ngFor="let item of group1; let i = index" class="draggable">
              <td>{{ item.order }}</td>
              <td>
                <div *ngIf="showSelectList && !item.name && i === group1.length - 1">
                  <select class="form-control" (change)="addStation($event.target.value)">
                    <option value="">none</option>
                    <option *ngFor="let station of stations" [value]="station.name">{{ station.name }}</option>
                  </select>
                </div>
                <div *ngIf="item.name">{{ item.name }}</div>
              </td>
              <td>{{ itineraryStations.at(i).get('distance').value }} km</td>
              <td>{{ itineraryStations.at(i).get('duration').value }} min</td>
              <td>
                <input type="number" min="0" class="form-control" id="validationTooltip05"
                  [formControl]="itineraryStations.at(i).get('stoppingTime')"
                  [ngClass]="{ 'is-invalid': submitted && itineraryStations.at(i).get('stoppingTime').invalid, 'is-valid': submitted && !itineraryStations.at(i).get('stoppingTime').invalid }"
                  placeholder="{{'PARKING_DURATION'|translate}}">
                <div *ngIf="submitted && itineraryStations.at(i).get('stoppingTime').errors?.required"
                  class="invalid-feedback">
                  {{ "Stopping-Time" | translate }} {{ "REQUIRED" | translate }}
                </div>
                <div *ngIf="submitted && itineraryStations.at(i).get('stoppingTime').errors?.['min']" class="invalid-feedback d-block">
                  {{ "Stopping-Time" | translate }} {{ 'MUST_BE_POSITIVE' | translate }}
              </div>
                
              </td>
              <td>
                <button class="btn btn-danger p-1" (click)="removeStation(i)">
                  <i class="ft-trash-2 font-medium-3"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>

      </div>
      <div class="text-center text-warning" *ngIf="group1.length < 2">
        <p>{{"TWO_STATIONS_REQUIRED"| translate}}</p>
      </div>
      <div class=" pt-1 pb-1 d-flex align-content-center flex-wrap">
        <button type="button" class="btn bg-light-secondary px-2 w-100" (click)="toggleSelectList()">
          <em class="ft-plus-circle mx-1"></em>{{ 'Add_Station' | translate }}
        </button>
      </div>
      <div class="mb-1 p-1" style="height: 400px;">
        <app-itineray-map #mapComponent [shape]="shape" (shapeCalculated)="onShapeCalculated($event)"
          (returnShapeCalculated)="onReturnShapeCalculated($event)"
          (routeCalculated)="onRouteCalculated($event)"></app-itineray-map>
      </div>
    </div>


    <div class="modal-footer">
      <button type="submit" class="btn btn-primary"> <i class="ft-check-square mr-1"></i>{{ "SAVE" | translate }}</button>
      <button type="button" class="btn btn-secondary" (click)="activeModal.close('Close click')"> <i class="ft-x mr-1"></i>{{ "CANCEL" | translate }}</button>
    </div>
  </form>
</div>