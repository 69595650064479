<div class="modal-dialog-centered" appRefresh>
  <form [formGroup]="serviceGroupForm" (ngSubmit)="saveForm()">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title"> {{ getCardTitle() | translate }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
          <i class="fa-solid fa-xmark"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label for="validationTooltip02">{{
            "SERVICE_GROUP_NAME" | translate
            }}</label>
          <input type="text" id="validationTooltip02" class="form-control" formControlName="name" [ngClass]="{
                      'is-invalid':
                        submitted &&
                        s.name.invalid,
                      'is-valid':
                        submitted &&
                        !s.name.invalid
                    }" />
          <div *ngIf="
                      submitted &&
                      s.name.errors?.required
                    " class="invalid-feedback">
            {{ "SERVICE_GROUP_NAME" | translate }}
            {{ "REQUIRED" | translate }}
          </div>
        </div>
        <fieldset class="form-group">
          <label for="validationTooltip02">{{ "flatRateHours" | translate }}</label>
          <div class="input-group">
            <input type="number" min="0" class="form-control" style="border-radius: 7px" aria-describedby="basic-addon2"
              formControlName="flatRateHours" [ngClass]="{
                        'is-invalid': submitted && s.flatRateHours.invalid,
                        'is-valid': submitted && !s.flatRateHours.invalid
                      }" />
            <div class="input-group-append">
              <span class="input-group-text" id="basic-addon2">{{ "HOURS" | translate }}</span>
            </div>
          </div>
          <div *ngIf="submitted && s.flatRateHours.errors?.required" class="invalid-feedback">
            {{ "FLAT_RATE_HOURS_REQUIRED" | translate }}
          </div>
          <div *ngIf="submitted && s.flatRateHours.errors?.['min']" class="invalid-feedback">
            {{ 'FLAT_RATE_HOURS' | translate }} {{ 'MUST_BE_POSITIVE' | translate }}
          </div>

        </fieldset>
        <div class="form-group">
          <label for="validationTooltip03">{{ "TRIPS" | translate }}</label>
          <ng-select [items]="filteredTrips" bindLabel="code" bindValue="id" [multiple]="true" [closeOnSelect]="false"
            placeholder="{{ 'SELECT_TRIP' | translate }}" id="validationTooltip03" formControlName="trips" [ngClass]="{
                'is-invalid': submitted && serviceGroupForm.get('trips')?.invalid,
                'is-valid': submitted && !serviceGroupForm.get('trips')?.invalid
              }">
          </ng-select>
          <div *ngIf="submitted && serviceGroupForm.get('trips')?.errors?.required" class="invalid-feedback">
            {{ "TRIP" | translate }} {{ "REQUIRED" | translate }}
          </div>
        </div>



        <h6 class="underline">{{"LIST_OF_SELECTED_TRIPS" | translate}}</h6>

        <div class="table-responsive ">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">{{"LIGNE" | translate}}</th>
                <th scope="col"></th>
                <th scope="col">{{"TRIPS" | translate}}</th>
                <th scope="col">{{"DEPART" | translate}}</th>
                <th scope="col">{{"ARRIVAL(est)" | translate}}</th>
              </tr>
            </thead>
            <tbody *ngIf="getSelectedTrips().length > 0">

              <ng-container>
                <tr *ngFor="let row of getSelectedTrips()">
                  <th scope="row">
                    <span class="badge badge-light">
                      {{ row?.routeNum }}
                    </span>
                  </th>
                  <td>{{ row?.routeName }}</td>
                  <td>
                    <span class="badge"
                      [ngClass]="{'badge-danger': row.code.endsWith('R'), 'badge-success': row.code.endsWith('A')}">
                      {{ row?.code }}
                    </span>
                  </td>
                  <td>{{ row.departure | slice:0:5 }}</td>
                  <td>{{ row.arrival | slice:0:5 }}</td>
                </tr>
              </ng-container>
            </tbody>

          </table>


          <div *ngIf="getSelectedTrips().length ===0" class="text-center ">
            <i class="icon-ban font-medium-4"></i>
            <p class="font-medium-1  warning darken-1">
              {{"NO_SELECTED_TRIP"| translate}}
            </p>

          </div>

        </div>
        <div *ngIf="missingAssociatedTrips.length > 0">
          <p>{{"Missing_trips"| translate}}</p>
          <ul>
            <li *ngFor="let trip of missingAssociatedTrips" class=" mb-1">
              {{ trip.code }}
              <button type="button" class=" ml-2 btn btn-primary btn-sm" (click)="addTripToSelected(trip.id)">
                <em class="ft-plus mr-1">{{"Add"| translate}}</em>
              </button>
            </li>
          </ul>
        </div>

      </div>

    </div>
    <div class="modal-footer">
      <div *ngIf="getOverlapError()" class="text-danger mb-2">
        {{ getOverlapError() }}
      </div>
      <button type="submit" class="btn btn-primary  mt-3 mr-2" [disabled]="serviceGroupForm.invalid">
        <i class="ft-check-square mr-1"></i>{{ "SAVE" | translate }}
      </button>
      <button type="button" class="btn btn-secondary mt-3" (click)="cancelForm()">
        <i class="ft-x mr-1"></i>{{ "CANCEL" | translate }}
      </button>
    </div>
  </form>
</div>