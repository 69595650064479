import { Injectable } from '@angular/core';
import {NgbDatepickerI18n, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class CustomDatepickerI18nService extends NgbDatepickerI18n {

  private monthsEN = [
    'January', 'February', 'March', 'April',
    'May', 'June', 'July', 'August',
    'September', 'October', 'November', 'December'
  ];

  private monthsFR = [
    'Janvier', 'Février', 'Mars', 'Avril',
    'Mai', 'Juin', 'Juillet', 'Août',
    'Septembre', 'Octobre', 'Novembre', 'Décembre'
  ];

  private weekdaysEN = [
    'Monday', 'Tuesday', 'Wednesday', 'Thursday',
    'Friday', 'Saturday', 'Sunday'
  ];

  private weekdaysFR = [
    'Lundi', 'Mardi', 'Mercredi', 'Jeudi',
    'Vendredi', 'Samedi', 'Dimanche'
  ];

  private langue: string;

  constructor() {
    super();
    this.langue = localStorage.getItem('langue') || 'en';
  }

  private getMonths(): string[] {
    return this.langue === 'fr' ? this.monthsFR : this.monthsEN;
  }

  private getWeekdays(): string[] {
    return this.langue === 'fr' ? this.weekdaysFR : this.weekdaysEN;
  }

  getMonthShortName(month: number): string {
    return this.getMonths()[month - 1].substring(0, 3);
  }

  getMonthFullName(month: number): string {
    return this.getMonths()[month - 1];
  }

  getWeekdayShortName(weekday: number): string {
    return this.getWeekdays()[weekday - 1].substring(0, 2);
  }

  getWeekdayLabel(weekday: number): string {
    return this.getWeekdays()[weekday - 1];
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return this.langue === 'fr'
        ? `${date.day}/${date.month}/${date.year}`
        : `${date.month}/${date.day}/${date.year}`;
  }
}
