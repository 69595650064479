<div *ngIf="control" class="mb-3">
    <div class="form-group row mb-0">
      <label [class.text-danger]="control.invalid&&(control.dirty||control.touched)"
             [for]="idName"
             class="col-xl-3 col-md-4 mx-1"><span *ngIf="controlIsRequired(control)" class="text-danger">*</span>
        {{labelPlaceholder|translate| titlecase}}
        <!--<a *ngIf="validators && prepareTooltips(validators).length > 0"
          [ngbTooltip]="titleTemplate"
          placement="top">
      <i class="ft-alert-circle"></i>
  </a>-->
  
 <!-- <ng-template #titleTemplate>
      <ng-container *ngFor="let toolTip of prepareTooltips(validators)">
          <span>{{ toolTip | translate}}</span><br>
      </ng-container>
  </ng-template>-->
      </label>
  
      <div class="col-xl-8 col-md-7">
  
        <div (click)="fileInput.click()" (onFileDropped)="uploadFile($event)" appDragDrop class="uploadfilecontainer">
          <input #fileInput (change)="uploadFile($event.target.files)" [accept]="acceptedFiles" hidden type="file" [id]="idName">
        </div>
        <div *ngFor="let file of files;let i= index" class="files-list">
          <p> {{ file.name }} </p>
          <button (click)="deleteAttachment(i)" class="delete-file">
            <i class="ft-trash-2"></i>
          </button>
        </div>
      </div>
    </div>
  
    <app-error-handling [control]="control"></app-error-handling>
  </div>
