import { Component, ElementRef, Input, OnDestroy, OnInit } from "@angular/core";
// @ts-ignore
import L from "leaflet";
import { HttpClient, HttpParams } from "@angular/common/http";
import { RealTimeData } from "../vehicles-list-tracking/vehicles-list-tracking.component";
import { SaeService } from "../services/sae.service";
import { Vehicle } from "app/modules/maintenance-management/work-request/models/work-request";
import { OPN_BASE_URL } from "app/shared/global/var";
import { CrudService } from "app/shared/services/crud.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RouteTripMapComponent } from "../../operating-networks/route-management/route-detail/route-trip-map/route-trip-map.component";
import { ZoomService } from "../../../shared/services/zoom.service";
import { TranslateService } from "@ngx-translate/core";
import {catchError, map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';


// export class TimeFormatter implements NouiFormatter {
//   to(value: number): string {
//     let h = Math.floor(value / 3600);
//     let m = Math.floor(value % 3600 / 60);
//     let s = value - 60 * m - 3600 * h;
//     let values = [h, m, s];
//     let timeString: string = '';
//     let i = 0;
//     for(let v of values) {
//       if(values[i] < 10)
//         timeString += '0';
//         timeString += values[i].toFixed(0);
//       if(i < 2) {
//         timeString += ':';
//       }
//       i++;
//     }
//     return timeString;
//   };

//   from(value: string): number {
//     let v = value.split(':').map(parseInt);
//     let time: number = 0;
//     time += v[0] * 3600;
//     time += v[1] * 60;
//     time += v[2];
//     return time;
//   }
// }

interface Coordinates {
  lat: number;
  lon: number;
}


@Component({
  selector: "app-real-time-map",
  templateUrl: "./real-time-map.component.html",
  styleUrls: ["./real-time-map.component.scss"],
})
export class RealTimeMapComponent implements OnInit, OnDestroy {
  private readonly DISTANCE_THRESHOLD = 10; // meters
  private readonly EARTH_RADIUS = 6371; // km

  private map = L.map;
  private markers: any[] = [];
  private stopMarkers: any[] = [];
  private vehicleMarker: any[] = [];
  private routeLine: any;

  public someValue: number = 5;
  public someMin: number = -10;
  public someMax: number = 10;
  public someRange: number[] = [3, 7];
  public someRange2: number[] = [10, 15];
  public someRange3: number[] = [2, 8];

  animationTimeoutId: number | null = null; // Pour stocker l'ID du timeout
  isAnimationRunning: boolean = false;

  animationPosition: any;
  animationPositionIndex: number;

  //@ts-ignore
  routeLines: L.Polyline[] = []; // Array to store all route lines
  //@ts-ignore
  osrmRouteLines: L.Polyline[] = []; // Array to store all route lines
  lastLocation: { lat: number; lon: number };
  vehiclesCoordinates: any[] = [];
  archivedVehiclesCoordinates: any[] = [];
  stationsCoordinates: any[] = [];

  language = localStorage.getItem("langue");

  vehiculeMarkerTemp: any;

  @Input() stations: any[] = [];
  @Input() idBoitier: string;
  @Input() isHistoric = false;
  @Input() startDate: string;
  @Input() endDate: string;
  @Input() vehicle: Vehicle;
  @Input() isTracking = false;
  @Input() trip: any;
  @Input() itinerary: any;
  @Input() dateDepart: any;
  @Input() dateArrivee: any;
  @Input() driverName: string;


  archivedPositions: any;

  isStationIgnored = false;
  tripId: number;

  constructor(
    private _elementRef: ElementRef,
    private http: HttpClient,
    private saeService: SaeService,
    private crudService: CrudService,
    private modalService: NgbModal,
    private zoomService: ZoomService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.initMap(); // Initialize the map
    this.markers = []; // Initialize the markers array
    if (this.stations && this.stations.length > 0) {
      this.stations.sort((a, b) => a.stationOrder - b.stationOrder);
      for (const station of this.stations) {
        this.addMarkers(
          station.lat && station.lon
            ? [station.lat, station.lon]
            : [station.station.lat, station.station.lon],
          station.name ? station.name : station.station.name,
          station.id
        ).then((r) => {
          console.info("Marker added");
        });
      }
      //  this.map.fitBounds(this.markers.map((marker) => marker.getLatLng()));
      if (this.trip) {
        //this.getShape();
        this.getTripData(this.trip.idTrip);
      }
      if (this.trip && this.trip.bus) {
        this.vehicle = this.trip.bus;
        if (this.trip.status === 3) {
          this.dateDepart = this.trip.rtDeparture
            ? this.trip.rtDeparture
            : this.trip.plannedDeparture;
          this.dateArrivee = this.trip.rtArrival
            ? this.trip.rtArrival
            : this.trip.estimatedArrival;
          this.getArchiveData();
          this.getUpdatedData();
        } else if (this.trip.status === 5) {
          this.isHistoric = true;
          this.dateDepart = this.trip.rtDeparture ? this.trip.rtDeparture : this.trip.plannedDeparture;
          this.dateArrivee = this.trip.rtArrival ? this.trip.rtArrival : this.trip.estimatedArrival;
          this.getArchiveData();
          this.getStopTime();
        } else if (this.dateDepart && this.dateArrivee && this.vehicle) {
          this.getArchiveData();
          this.getStops();
        }
      }
    } else if (this.vehicle) {
      if (this.dateDepart && this.dateArrivee) {
        this.getStops();
        this.getArchiveData();
        if (this.vehicle.family?.bus) {
          this.getMostLikelyTrip();
        }
      } else {
        this.dateDepart = new Date(
          new Date().setHours(new Date().getHours() - 1)
        );
        this.dateArrivee = new Date();
        this.getArchiveData();
        this.getStops();

      }
    }
  }

  /**
   * Lifecycle hook that is called when the component is destroyed.
   * Clears the map by removing all markers and the route line.
   */
  ngOnDestroy(): void {
    this.markers.forEach((marker) => this.map.removeLayer(marker));
    this.routeLines.forEach((line) => this.map.removeLayer(line));
    this.osrmRouteLines.forEach((line) => this.map.removeLayer(line));
    this.clearMap();
    // this.saeService.closeConnection();
  }

  /**
   * Initializes the map and sets its view to a default center and zoom level.
   * Adds a tile layer to the map and draws the route using OSRM.
   */
  initMap(): void {
    this.clearMap();

    const el = this._elementRef.nativeElement.querySelector(".tracking-map");
    this.map = L.map(el, {
      center: [34.551117, 9.369019],
      zoom: 10,
      minZoom: 5,
    });

    L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
      attribution: "",
      attributionControl: false,
    }).addTo(this.map);
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 300);
  }

  /**
   * Adds a marker to the map at the specified latitude and longitude.
   * The marker is customized with a bus stop icon and a popup displaying the station name.
   *
   * @param {any} latlng - The latitude and longitude of the marker.
   * @param {string} stationName - The name of the station to display in the popup.
   * @param stationId
   */
  async addMarkers(
    latlng: any,
    stationName: string,
    stationId: number
  ): Promise<void> {
    try {
      const stopTime = await this.getStationStopTimeAsync(stationId); // Wait for stop time
      const initialIconSize: [number, number] = [25, 41];
      const iconUrl = "./assets/img/leaflet/bus-stop.png";
      const marker = this.zoomService.createResizableIcon(
        this.map,
        latlng,
        iconUrl,
        stationName,
        initialIconSize
      );
      let popupContent = `${stationName}`;

      if (stopTime?.arrivalTime) {
        const estimatedArrivalTranslation = await this.getTranslation(
          "EST_ARRIVAL"
        );
        popupContent += ` <br> ${estimatedArrivalTranslation}: ${stopTime?.arrivalTime.replace(
          "T",
          " "
        )}`;
      }
      if (stopTime?.rtArrivalTime) {
        const arrivalTimeTranslation = await this.getTranslation("ARRIVAL");
        popupContent += `${stationName} <br> ${arrivalTimeTranslation}: ${stopTime?.rtArrivalTime.replace(
          "T",
          " "
        )}`;
      }
      // Bind the popup with translated content
      marker.bindPopup(popupContent);

      // Add marker click event
      marker.on("click", () => {
        marker.openPopup();
      });

      // Push marker to array and set map view
      this.markers.push(marker);
      this.map.setView(this.markers[0].getLatLng(), 10);
    } catch (error) {
      console.error("Error adding marker:", error);
    }
  }

  addStopMarkers(latlng: any, stoDetails: any) {
    this.translate
      .get(["DATE", "Location", "Stopping_Duration"])
      .subscribe((translations: string) => {
        const initialIconSize: [number, number] = [25, 35];
        const iconUrl = "./assets/img/markers/stop-sign.png";
        const marker = this.zoomService.createResizableIcon(
          this.map,
          latlng,
          iconUrl,
          stoDetails.lieu,
          initialIconSize
        );

        // Format date to YYYY-MM-DD HH:mm
        const dateObj = new Date(stoDetails.id.date);
        const reformatedDate = dateObj
          .toISOString()
          .replace("T", " ")
          .slice(0, 16);

        const popupContent = `
        <div class="marker-popup">
            <p><strong  class="text-info marker-title">${translations["DATE"]}:</strong> ${reformatedDate}</p>
            <p><strong  class="text-info marker-title">${translations["Location"]}:</strong> ${stoDetails.lieu}</p>
            <p><strong  class="text-info marker-title">${translations["Stopping_Duration"]}:</strong> ${stoDetails.temps}</p>
        </div>
    `;

        // Bind popup to marker
        marker.bindPopup(popupContent, {
          maxWidth: 300,
          className: "custom-popup",
        });

        marker.setZIndexOffset(1000);

        this.stopMarkers.push(marker);
      });
  }

  private getStationStopTimeAsync(stationId: number): Promise<any> {
    return this.crudService
      .getAll(
        OPN_BASE_URL +
          `/stop-times/itinerary-station/${stationId}/trips-instance/${this.trip.id}`
      )
      .toPromise();
  }

  public getTranslation(word: string): Promise<string> {
    if (!word) {
      return Promise.resolve("");
    }
    return new Promise((resolve, reject) => {
      this.translate.get(word).subscribe({
        next: (translation: string) => resolve(translation.toLowerCase()),
        error: (err) => reject(err),
      });
    });
  }

  /**
   * Clears the map by removing all markers and the route line.
   * Resets the markers array and sets the route line to null.
   */
  clearMap(): void {
    this.markers.forEach((marker) => this.map.removeLayer(marker));
    this.markers = [];

    // Supprimer les markers de véhicules
    this.vehicleMarker.forEach((marker) => marker.remove());
    this.vehicleMarker = [];

    if (this.routeLine) {
      this.map.removeLayer(this.routeLine);
      this.routeLine = null;
    }

    // Supprimer les lignes
    this.routeLines.forEach((line) => line.remove());
    this.routeLines = [];
  }

  /**
   * Adds a marker for a vehicle to the map.
   * The marker is customized with an icon based on the vehicle type (bus or car).
   *
   * @param {any} latlng - The latitude and longitude of the marker.
   * @param {Vehicle} vehicle - The vehicle object containing details for the marker.
   */
  // addVehiclesMarkers(latlng: any, vehicle: Vehicle): void {
  //   const initialIconSize: [number, number] = [30, 41];
  //   const iconUrl = vehicle.bus || vehicle.family.bus ? './assets/img/markers/bus.png' : './assets/img/markers/car.png';
  //   const marker = this.zoomService.createResizableIcon(this.map, latlng, iconUrl, vehicle.vehicleNumber, initialIconSize);
  //   this.vehicleMarker.push(marker);
  //   this.vehicleMarker.push(marker);
  // }

  /**
   * Adds a marker to the map indicating the start of a trip.
   * The marker is customized with a "go" icon.
   *
   * @param {any} latlng - The latitude and longitude of the marker.
   */
  addTripStartMarker(latlng: any, rtData): void {
    const initialIconSize: [number, number] = [25, 25];
    const iconUrl = "./assets/img/markers/go.png";
    const marker = this.zoomService.createResizableIcon(
      this.map,
      latlng,
      iconUrl,
      "",
      initialIconSize
    );
    this.markers.push(marker);
    this.updatePopUpData(marker, rtData)
  }

  mouseDown() {
    this.getArchiveData();
  }

  onSliderPositionChange(event: any) {
    if (!this.isAnimationRunning) {
      // this.vehiculeMarkerTemp.setLatLng([this.archivedPositions[event].lat, this.archivedPositions[event].lon]);

      // const bounds = L.latLngBounds();
      // bounds.extend([this.archivedPositions[event].lat, this.archivedPositions[event].lon]);
      // bounds.extend([this.archivedPositions[0].lat, this.archivedPositions[0].lon]);

      // this.map.fitBounds(bounds, { padding: [50, 50] });

      this.addSegment1(event);
    }
  }

  /**
   * Fetches archived data for a specific vehicle within a date range.
   * Converts the departure and arrival dates to ISO strings and makes an HTTP GET request to retrieve the data.
   * Draws the route on the map, adds markers for the last location and trip start, and fetches updated data.
   */

  getArchiveData(timeOut: number = 0): void {
    this.animationPositionIndex = 0;
    this.animationPosition = null;

    // Nettoyer la carte
    this.clearMap();

    // Si les données sont déjà chargées
    if (this.archivedPositions) {
      this.displayTrajectory(this.archivedPositions, timeOut);
      return;
    }
    // Si pas de données, appel API
    this.dateDepart = this.dateDepart.replace("T", " ");
    this.dateArrivee = this.dateArrivee.replace("T", " ");

    this.http
      .get(OPN_BASE_URL + `/archive2/${this.vehicle.idBoitier}/dataRange`, {
        params: {
          startDate: this.dateDepart.split(".")[0],
          endDate: this.dateArrivee.split(".")[0],
        },
      })
      .subscribe((res: any) => {
        this.archivedPositions = res; // Sauvegarder les positions
        this.displayTrajectory(res, timeOut);
      });
  }

  getStops(): void {
    this.dateDepart = this.dateDepart.replace("T", " ");
    this.dateArrivee = this.dateArrivee.replace("T", " ");
    this.http
      .get(OPN_BASE_URL + `/repArret2/all-stops/${this.vehicle.idBoitier}`, {
        params: {
          startDate: this.dateDepart.split(".")[0],
          endDate: this.dateArrivee.split(".")[0],
        },
      })
      .subscribe((res: any) => {
        if (res.length > 0) {
          res.map((stop: any) => {
            this.addStopMarkers([stop.lat, stop.lon], stop);
          });
        }
      });
  }

  // Fonction pour arrêter l'animation
  stopTrajectoryAnimation(): void {
    if (this.animationTimeoutId !== null) {
      window.clearTimeout(this.animationTimeoutId);
      this.animationTimeoutId = null;
    }
    this.isAnimationRunning = false;
  }

  addSegment1(index: number) {
    this.animationPositionIndex = index;
    this.animationPosition = this.archivedPositions[index];

    const bounds = L.latLngBounds();
    const routeLine = L.polyline(
      [
        [this.archivedPositions[index].lat, this.archivedPositions[index].lon],
        [
          this.archivedPositions[index + 1]?.lat,
          this.archivedPositions[index + 1]?.lon,
        ],
      ],
      {
        color: "#1e90ff",
        weight: 5,
      }
    ).addTo(this.map);

    const marker = L.circleMarker(
      [this.archivedPositions[index].lat, this.archivedPositions[index].lon],
      {
        radius: 2,
        color: "#ff4500",
        fillOpacity: 1,
      }
    ).addTo(this.map);

    if (this.vehiculeMarkerTemp) {
      this.vehiculeMarkerTemp.setLatLng([
        this.archivedPositions[index + 1].lat,
        this.archivedPositions[index + 1].lon,
      ]);
    }

    bounds.extend([
      this.archivedPositions[0].lat,
      this.archivedPositions[0].lon,
    ]);
    bounds.extend([
      this.archivedPositions[index].lat,
      this.archivedPositions[index].lon,
    ]);

    this.routeLines.push(routeLine);
    this.markers.push(marker);

    this.map.fitBounds(bounds, { padding: [50, 50] });
  }

  private displayTrajectory(positions: any[], timeOut: number): void {
    // Arrêter toute animation en cours
    this.stopTrajectoryAnimation();

    if (positions.length >= 2) {
      const bounds = L.latLngBounds();

      // Ajout du marker de départ
      this.addTripStartMarker([positions[0].lat, positions[0].lon], positions[0]);

      // Créer le marqueur du véhicule initialement
      this.vehiculeMarkerTemp = this.addVehiclesMarkers(
        [positions[0].lat, positions[0].lon],
        this.vehicle
      );

      if (timeOut === 0) {
        // Affichage instantané
        for (let i = 0; i < positions.length - 1; i++) {
          const routeLine = L.polyline(
            [
              [positions[i].lat, positions[i].lon],
              [positions[i + 1].lat, positions[i + 1].lon],
            ],
            {
              color: "#1e90ff",
              weight: 5,
            }
          ).addTo(this.map);

          const marker = L.circleMarker([positions[i].lat, positions[i].lon], {
            radius: 2,
            color: "#ff4500",
            fillOpacity: 1,
          }).addTo(this.map);

            marker.on('click', () => {
                this.updatePopupContent(marker, positions[i]);
            });


            bounds.extend([positions[i].lat, positions[i].lon]);
          bounds.extend([positions[i + 1].lat, positions[i + 1].lon]);

          this.routeLines.push(routeLine);
          this.markers.push(marker);
        }

        if (this.vehiculeMarkerTemp) {
          this.vehiculeMarkerTemp.setLatLng([
            positions[positions.length - 1].lat,
            positions[positions.length - 1].lon,
          ]);
        }

        this.finalizeTrajectory(positions, bounds);
      } else {
        // Affichage progressif
        this.isAnimationRunning = true;

        const addSegment = (index: number) => {
          this.animationPositionIndex = index;
          this.animationPosition = this.archivedPositions[index];

          if (!this.isAnimationRunning || index >= positions.length - 1) {
            if (this.isAnimationRunning) {
              this.finalizeTrajectory(positions, bounds);
            }
            this.isAnimationRunning = false;
            return;
          }

          const routeLine = L.polyline(
            [
              [positions[index].lat, positions[index].lon],
              [positions[index + 1].lat, positions[index + 1].lon],
            ],
            {
              color: "#1e90ff",
              weight: 5,
            }
          ).addTo(this.map);

          const marker = L.circleMarker(
            [positions[index].lat, positions[index].lon],
            {
              radius: 2,
              color: "#ff4500",
              fillOpacity: 1,
            }
          ).addTo(this.map);

        marker.on('click', () => {
            this.updatePopupContent(marker, positions[index]);
        });


        if (this.vehiculeMarkerTemp) {
            this.vehiculeMarkerTemp.setLatLng([
              positions[index + 1].lat,
              positions[index + 1].lon,
            ]);
        }

          bounds.extend([positions[index].lat, positions[index].lon]);
          bounds.extend([positions[index + 1].lat, positions[index + 1].lon]);

          this.routeLines.push(routeLine);
          this.markers.push(marker);

          this.map.fitBounds(bounds, { padding: [50, 50] });

          this.animationTimeoutId = window.setTimeout(
            () => addSegment(index + 1),
            timeOut
          );
        };

        addSegment(0);
      }
    } else if (positions.length === 1) {
      this.lastLocation = {
        lat: positions[0].lat,
        lon: positions[0].lon,
      };
      this.addVehiclesMarkers(
        [this.lastLocation.lat, this.lastLocation.lon],
        this.vehicle
      );
      this.stopTrajectoryAnimation();
    }
  }

  private finalizeTrajectory(positions: any[], bounds: L.LatLngBounds): void {
    this.lastLocation = {
      lat: positions[positions.length - 1].lat,
      lon: positions[positions.length - 1].lon,
    };

    this.map.fitBounds(bounds, { padding: [50, 50] });
    this.map.setView(this.lastLocation, 12);

    if (
      this.vehicle &&
      (this.vehicle.bus || this.vehicle.family.bus) &&
      this.isHistoric
    ) {
      this.archivedVehiclesCoordinates = positions;
    }
  }
  // Modifier addVehiclesMarkers pour retourner le marker
  addVehiclesMarkers(latlng: any, vehicle: Vehicle): L.Marker {
    const initialIconSize: [number, number] = [30, 41];
    const iconUrl =
      vehicle.bus || vehicle.family.bus
        ? "./assets/img/markers/bus.png"
        : "./assets/img/markers/car.png";
    const marker = this.zoomService.createResizableIcon(
      this.map,
      latlng,
      iconUrl,
      vehicle.vehicleNumber,
      initialIconSize
    );
    this.vehicleMarker.push(marker);
    return marker; // Retourner le marker pour pouvoir le manipuler
  }

  setVehiclesMarkers(latlng: any, vehicle: Vehicle): L.Marker {
    const initialIconSize: [number, number] = [30, 41];
    const iconUrl =
      vehicle.bus || vehicle.family.bus
        ? "./assets/img/markers/bus.png"
        : "./assets/img/markers/car.png";
    const marker = this.zoomService.createResizableIcon(
      this.map,
      latlng,
      iconUrl,
      vehicle.vehicleNumber,
      initialIconSize
    );
    this.vehicleMarker.push(marker);
    return marker; // Retourner le marker pour pouvoir le manipuler
  }

  /**
   * Connects to the real-time tracking service and subscribes to messages.
   * Handles incoming messages by updating the vehicle marker on the map.
   *
   * @param {number} idTrip - The ID of the trip to track in real-time.
   */
  getUpdatedData(): void {
    this.saeService.connect();
    this.saeService.message$.subscribe((message) => {
      if (this.trip.id === parseInt(message.TripId, 10)) {
        this.handleMessage(message);
      }
    });
  }

  /**
   * Handles incoming real-time tracking messages.
   * Updates the vehicle marker on the map if the message is for the current vehicle.
   *
   * @param {any} message - The incoming message containing real-time tracking data.
   */
  handleMessage(message: any): void {
    this.updateVehicleMarker(this.vehicle, message);
  }

  /**
   * Updates the vehicle marker on the map with new real-time data.
   * Removes the previous marker, creates a new marker with the updated location, and draws the route.
   *
   * @param {Vehicle} vehicle - The vehicle object containing details for the marker.
   * @param {RealTimeData} rtData - The real-time data containing the new location.
   */
  updateVehicleMarker(vehicle: Vehicle, rtData: RealTimeData): void {
    const lat = rtData.lat;
    const lon = rtData.lon;
    const newLatLng = [lat, lon];

    // Remove all previous vehicle markers
    if (this.vehicleMarker.length > 0) {
      this.vehicleMarker.forEach((m) => this.map.removeLayer(m));
    }
    const initialIconSize: [number, number] = [30, 41];
    const iconUrl =
      vehicle.bus || vehicle.family.bus
        ? "./assets/img/markers/bus.png"
        : "./assets/img/markers/car.png";
    const marker = this.zoomService.createResizableIcon(
      this.map,
      newLatLng,
      iconUrl,
      vehicle.vehicleNumber,
      initialIconSize
    );
    if (this.vehicleMarker.length > 0) {
      this.vehicleMarker[this.vehicleMarker.length - 1].remove();
    }
    this.vehicleMarker = [marker];
    this.vehiclesCoordinates.push(newLatLng);
    if (this.lastLocation) {
      // Draw the route from stored vehicle coordinates to the new location
      const color = rtData.isAlert ? "red" : "#1e90ff";
      const routeLine = L.polyline([this.lastLocation, newLatLng], {
        color: color,
        weight: 5,
      }).addTo(this.map);
      this.routeLines.push(routeLine);
    }
    this.lastLocation = { lat, lon };
  }

  tryShapeOrOSRM(tripId: number): void {
    this.crudService
        .getAll(OPN_BASE_URL + `/shape/trip/${tripId}`)
        .subscribe({
          next: (routePoints: Coordinates[]) => {
            if (!this.isValidRouteData(routePoints)) {
              this.fallbackToOSRM();
              return;
            }

            if (this.isRouteMatchingStations(routePoints)) {
              this.drawRoute(routePoints);
              this.setOptimalMapView(routePoints);
            } else {
              this.fallbackToOSRM();
            }
          },
          error: () => this.fallbackToOSRM()
        });
  }

  private drawRoute(routePoints: Coordinates[]): void {
    for (let i = 0; i < routePoints.length - 1; i++) {
      const routeLine = L.polyline(
          [
            [routePoints[i].lat, routePoints[i].lon],
            [routePoints[i + 1].lat, routePoints[i + 1].lon],
          ],
          {
            color: "#008080",
            weight: 4,
          }
      ).addTo(this.map);
      this.routeLines.push(routeLine);
    }
  }


  private setOptimalMapView(routePoints: Coordinates[]): void {
    const bounds = routePoints.reduce((bounds, point) => {
      return bounds.extend([point.lat, point.lon]);
    }, L.latLngBounds([routePoints[0].lat, routePoints[0].lon], [routePoints[0].lat, routePoints[0].lon]));
    this.map.fitBounds(bounds, {
      padding: [50, 50],
      maxZoom: 10
    });
  }

  private isValidRouteData(routePoints: Coordinates[]): boolean {
    return routePoints && routePoints.length > 2;
  }

  private isRouteMatchingStations(routePoints: Coordinates[]): boolean {
    const firstPoint = routePoints[0];
    const lastPoint = routePoints[routePoints.length - 1];
    const firstStation = this.stationsCoordinates[0].station ? this.stationsCoordinates[0].station : this.stationsCoordinates[0];
    const lastStation = this.stationsCoordinates[this.stationsCoordinates.length - 1].station ? this.stationsCoordinates[this.stationsCoordinates.length - 1].station :
        this.stationsCoordinates[this.stationsCoordinates.length - 1];
    return (
        this.calculateDistance(firstPoint, firstStation) < this.DISTANCE_THRESHOLD &&
        this.calculateDistance(lastPoint, lastStation) < this.DISTANCE_THRESHOLD
    );
  }


  private fallbackToOSRM(): void {
    this.drawRouteOSRM(this.stationsCoordinates).then(() => {
      console.info("Route drawn with OSRM");
    });
  }


  private calculateDistance(point1, point2): number {
    const dLat = this.deg2rad(point2.lat - point1.lat);
    const dLon = this.deg2rad(point2.lon - point1.lon);

    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.deg2rad(point1.lat)) *
        Math.cos(this.deg2rad(point2.lat)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return this.EARTH_RADIUS * c * 1000; // Convert to meters
  }

  private deg2rad(deg: number): number {
    return deg * (Math.PI / 180);
  }


  /**
   * Draws a route on the map using the OSRM API.
   * Fetches the route data from OSRM and draws a polyline on the map.
   * Adjusts the map bounds to fit the route.
   *
   * @param {Object[]} stations - An array of station objects with latitude and longitude properties.
   * @returns {Promise<void>} A promise that resolves when the route is drawn.
   */
  async drawRouteOSRM(stations): Promise<void> {
    if (stations.length < 2) {
      return;
    }
    const coordinates = stations
        .map((station) =>
            station.lat && station.lon
                ? `${station.lon},${station.lat}`
                : `${station.station.lon},${station.station.lat}`
        )
        .join(";");
    const url = `/route/v1/driving/${coordinates}?overview=full&geometries=geojson`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.code !== "Ok") {
        console.error("OSRM request failed:", data);
        return;
      }

      const route = data.routes[0];

      const routeCoordinates = route.geometry.coordinates.map(
        (coord: any[]) => [coord[1], coord[0]]
      );

      if (this.routeLine) {
        this.map.removeLayer(this.routeLine);
      }
      this.routeLine = L.polyline(routeCoordinates, {
        color: "#008080",
        weight: 4,
      }).addTo(this.map);

      // Fit the map to the bounds of the route
      this.map.fitBounds(this.routeLine.getBounds());
      this.map.setView(this.routeLine.getBounds().getCenter(), 10);
    } catch (error) {
      console.error("Error fetching OSRM route:", error);
    }
  }

  getMostLikelyTrip(): void {
    if (!this.trip) {
      this.crudService
        .getAllWithParams(
          OPN_BASE_URL + `/trips-instance/bus-trip/${this.vehicle.id}`,
          new HttpParams().set(
            "date",
            this.dateDepart.replace("T", " ").replace("Z", "")
          )
        )
        .subscribe((res: any) => {
          if (!res) {
            return;
          }
          this.trip = res;
          this.tripId  = res.idTrip;
          if(this.trip && this.trip.rtArrival && this.trip.rtDeparture) {
            this.getTripData(this.tripId);
          }
      });
    } else {
      this.tripId = this.trip.idTrip;
      if(this.trip && this.trip.rtArrival && this.trip.rtDeparture) {
        this.getTripData(this.tripId);
      }
    }


  }

  private getTripData(tripId: number) {
    if(!this.stations || this.stations.length === 0) {
      this.crudService
          .getAll(OPN_BASE_URL + "/trip/" + tripId)
          .subscribe((res: any) => {
            if (!res) return;
            this.crudService
                .getAll(
                    OPN_BASE_URL +
                    "/itinerary-station/by-itinerary/" +
                    res.itineraryId
                )
                .subscribe((itineraryStations: any[]) => {
                  this.stationsCoordinates = itineraryStations;
                  for (const station of itineraryStations) {
                    this.addMarkers(
                        [station.station.lat, station.station.lon],
                        station.station.name,
                        station.id
                    );
                  }
                  this.tryShapeOrOSRM(tripId);
                });
          });
    }else {
      this.stationsCoordinates = this.stations;
      this.tryShapeOrOSRM(tripId);
    }

  }

  addItineraryToggleButton(): void {
    const buttonControl = L.control({ position: "topright" });

    buttonControl.onAdd = () => {
      const button = L.DomUtil.create("button", "btn btn-info");
      button.innerHTML =
        this.language === "fr" ? "Sauvegarder l'itineraire" : "Save itinerary";
      button.onclick = () => {
        const modalRef = this.modalService.open(RouteTripMapComponent, {
          size: "xl",
        });
        modalRef.componentInstance.stations = this.stations; // Pass data to modal if needed
        modalRef.componentInstance.shape = this.archivedVehiclesCoordinates;
        modalRef.componentInstance.createItinerary = true;
        modalRef.componentInstance.trip = this.trip;
        modalRef.componentInstance.itinerary = this.itinerary;

        modalRef.result.then(
          (result) => {
            console.info("Modal closed with result:", result);
          },
          (reason) => {
            console.info("Modal dismissed:", reason);
          }
        );
      };
      return button;
    };

    buttonControl.addTo(this.map);
  }

    getStopTime(): any {
        this.crudService
        /*.getAll(OPN_BASE_URL + `/stop-times/trips-instance/${this.trip.id}`)
        .subscribe((r: any) => {*/
        this.stations.map((stopTime: any) => {
            if (stopTime.status === 3) {
                this.isStationIgnored = true;
            }
            /* this.stations.map((station: any) => {
               if (station.stationId === stopTime.itineraryStation.stationId) {
                 station.status = stopTime.state;
                 if (stopTime.rtArrivalTime) {
                   station.duration =
                     Math.round(
                       new Date(stopTime.rtArrivalTime).getTime() -
                         new Date(this.trip.rtDeparture).getTime()
                     ) / 60000;
                 }
               }
             });*/
        });
        if (this.isStationIgnored) {
            this.addItineraryToggleButton();
        }
        // });
    }

    getAddress(rtData: RealTimeData): Observable<string> {
        const lat = rtData.lat.toString(10).replace(',', '.');
        const lon = rtData.lon.toString(10).replace(',', '.');

        const params = new HttpParams()
            .set('format', 'json')
            .set('zoom', '18')
            .set('addressdetails', '1')
            .set('accept-language', 'fr')
            .set('lat', lat)
            .set('lon', lon);

        return this.http.get<any>('/reverse', {params}).pipe(
            map((res) => {
                let address = '';
                if (res.address?.road) {
                    address += res.address.road;
                }
                if (res.address?.village) {
                    address += ', ' + res.address.village;
                }
                if (res.address?.suburb) {
                    address += ', ' + res.address.suburb;
                }
                if (res.address?.town) {
                    address += ', ' + res.address.town;
                }
                return address;
            }),
            catchError(() => of('Address not found')) // Handle potential errors gracefully.
        );
    }

    updatePopupContent(marker: any, rtData: RealTimeData): void {
        if (!rtData.localite) {
            this.getAddress(rtData).subscribe(
                (address) => {
                    rtData.localite = address;
                    this.updatePopUpData(marker, rtData);
                }
            )
        } else {
            this.updatePopUpData(marker, rtData);
        }

    }

    updatePopUpData(marker: any, rtData: RealTimeData): void {
        if (!rtData.vitesseInst) {
            rtData.vitesseInst = rtData.vitesse;
        }
        if (!rtData.rpm) {
            rtData.rpm = 0;
        }
        if (!rtData.car) {
            rtData.car = rtData.carLevel ? rtData.carLevel : 0;
        }
        this.translate
            .get([
                'Track',
                'DATE',
                'Speed',
                'Temp',
                'RPM',
                'Fuel_Level',
                'Distance',
                'Lat',
                'Lon',
                'Location',
                'Driver'
            ])
            .subscribe((translations: any) => {
                const driverContent = this.driverName != undefined && this.driverName !== '/'
                    ? `<div style="display: flex; justify-content: space-between;">
             <span><strong>${translations['Driver']}:</strong></span>
             <span>${this.driverName}</span>
           </div>`
                    : '';
                const popupContent = `
                          <div class="popup-container d-flex flex-column">
                            <h3 class="bg-light-info flex-grow-1 font-medium-2 p-2 rounded text-center vehicle-number">
                                <i class="fas fa-bus text-info mr-1"></i>${
                    this.vehicle.vehicleNumber
                }
                            </h3>
                            <div class="vehicle-stats font-small-3">
                                <div style="display: flex; justify-content: space-between;">
                                    <span><strong>${
                    translations['DATE']
                }:</strong></span>
                                    <span>${rtData.date
                    .replace('T', ' ')
                    .replace('Z', '')}</span>
                                </div>
                                <div style="display: flex; justify-content: space-between;">
                                    <span><strong>${
                    translations['Speed']
                }:</strong></span>
                                    <span>${rtData.vitesseInst} km/h</span>
                                </div>
                                <div style="display: flex; justify-content: space-between;">
                                    <span><strong>${
                    translations['Temp']
                }:</strong></span>
                                    <span>${rtData.temp} °C</span>
                                </div>
                                <div style="display: flex; justify-content: space-between;">
                                    <span><strong>${
                    translations['RPM']
                }:</strong></span>
                                    <span>${rtData.rpm}</span>
                                </div>
                                <div style="display: flex; justify-content: space-between;">
                                    <span><strong>${
                    translations['Fuel_Level']
                }:</strong></span>
                                    <span>${rtData.car} %</span>
                                </div>
                                <div style="display: flex; justify-content: space-between;">
                                    <span><strong>${
                    translations['Distance']
                }:</strong></span>
                                    <span>${rtData.dist} km</span>
                                </div>
                                   <div style="display: flex; justify-content: space-between;">
                                    <span><strong>${
                    translations['Lat']
                }:</strong></span>
                                    <span>${rtData.lat}</span>
                                </div>
                                    <div style="display: flex; justify-content: space-between;">
                                    <span><strong>${
                    translations['Lon']
                }:</strong></span>
                                    <span>${rtData.lon}</span>
                                </div>
                                <div style="display: flex; justify-content: space-between;">
                                    <span><strong>${
                    translations['Location']
                }:</strong></span>
                                    <span>${rtData.localite}</span>
                                </div>
                                 ${driverContent}
                            </div>
                        </div>
                        `;
                marker.bindPopup(popupContent);
                marker.openPopup();
                /*if (marker.isPopupOpen()) {
                  marker.openPopup();
                }*/
            });
    }


}
