import {
  Component,
  Output,
  EventEmitter,
  OnDestroy,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  ViewChildren,
  QueryList,
  HostListener,
  Injector,
  Inject,
  Renderer2,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { LayoutService } from "../services/layout.service";
import { Subscription } from "rxjs";
import { ConfigService } from "../services/config.service";
import { DOCUMENT } from "@angular/common";
import { UntypedFormControl } from "@angular/forms";
import { LISTITEMS } from "../data/template-search";
import { Router } from "@angular/router";
import { AuthService } from "../auth/auth.service";
import { NzI18nService } from "ng-zorro-antd/i18n";

import { UsersService } from "../services/users.service";
import { User } from "./user";
import { WebsocketService } from "../services/websocket.service";
import { WINDOW } from "../services/window.service";
import { WorkRequestService } from "app/modules/maintenance-management/work-request/services/work-request.service";
import { ScheduledTasksService } from "app/modules/maintenance-management/scheduled-tasks-management/services/scheduled-tasks.service";
import { NgxSpinnerService } from "ngx-spinner";
import { OPN_BASE_URL } from "../global/var";
import { CrudService } from "../services/crud.service";
import { NotyService } from "../services/noty.service";

interface MessageHistory {
  message: string;
  messageDate: string;
  logDate: string;
  read: boolean;
  message_fr: string;
  message_en: string;
}
@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  private websocketSubscription: Subscription | null = null;
  notifications: MessageHistory[] = [];
  unreadCount: number = 0;
  toggleClass = "ft-maximize";
  placement = "bottom-right";
  logoUrl = "assets/img/logo.png";
  menuPosition = "Side";
  isSmallScreen = false;
  protected innerWidth: any;
  searchOpenClass = "";
  transparentBGClass = "";
  hideSidebar: boolean = true;
  public isCollapsed = true;
  layoutSub: Subscription;
  configSub: Subscription;
  username: string;
  user: User;
  userId: number;
  private pollingInterval: any;
  langue = localStorage.getItem("langue");
  @ViewChild("search") searchElement: ElementRef;
  @ViewChildren("searchResults") searchResults: QueryList<any>;

  @Output()
  toggleHideSidebar = new EventEmitter<Object>();

  @Output()
  seachTextEmpty = new EventEmitter<boolean>();

  listItems = [];
  control = new UntypedFormControl();

  public config: any = {};
  connectedUserFullName: any;
  constraintConfig: any;

  constructor(
    public translate: TranslateService,
    private layoutService: LayoutService,
    private router: Router,
    private notyService: NotyService,
    private nzI18nService: NzI18nService,
    private authService: AuthService,
    private userService: UsersService,
    private spinner: NgxSpinnerService,
    private injector: Injector,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private crudService: CrudService,
    private workRequestService: WorkRequestService,
    private scheduledService: ScheduledTasksService,
    private websocketService: WebsocketService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    private renderer: Renderer2
  ) {
    const savedLanguage = localStorage.getItem("langue");
    translate.use(savedLanguage || "fr");
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;

    this.layoutSub = layoutService.toggleSidebar$.subscribe((isShow) => {
      this.hideSidebar = !isShow;
    });
  }

  async ngOnInit() {
    this.loadSettings();
    this.listItems = LISTITEMS;
    if (this.authService.isLoggedIn()) {
      await this.getCurrentUser();
    }

    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    } else {
      this.isSmallScreen = false;
    }
    const currentUsername = this.getCurrentUsername();
    if (currentUsername) {
      this.userService.getUserIdByUsername(currentUsername).subscribe(
        (userId: number) => {
          this.userId = userId;
          this.loadMessageHistory();
        },
        (error) => {
          console.error(
            "Erreur lors de la récupération de l'ID utilisateur:",
            error
          );
        }
      );
    } else {
      console.error("Impossible de récupérer le nom d'utilisateur");
    }
  }

  private loadMessageHistory() {
    if (this.userId) {
      this.userService.getLimitMessageHistoryByUserId(this.userId).subscribe(
        (messages: MessageHistory[]) => {
          const savedLanguage = localStorage.getItem("langue") || "fr";

          this.notifications = messages
            .map((msg) => {
              const selectedMessage =
                savedLanguage === "fr" ? msg.message_fr : msg.message_en;

              return {
                ...msg,
                message: selectedMessage,
                messageDate: msg.messageDate.replace("T", " "),
              };
            })
            .filter((msg) => {
              const languageTag = savedLanguage === "fr" ? "(FR)" : "(EN)";
              return msg.message.includes(languageTag);
            });

          this.unreadCount = this.notifications.filter(
            (msg) => !msg.read
          ).length;
        },
        (error) => {
          console.error("Erreur lors de la récupération des messages:", error);
        }
      );
    }
  }
  getTranslatedMessage(originalMessage: string): string {
    let cleanedMessage = originalMessage
      .replace("(FR) ", "")
      .replace("(EN) ", "");
    const alertMatch = cleanedMessage.match(/(Alert) - (\w+)/);

    if (alertMatch) {
      const alertWord = alertMatch[1];
      const originalLabel = alertMatch[2];
      const isFrench = originalMessage.includes("(FR) ");

      const translatedAlert = isFrench ? "Alerte" : "Alert";
      const translationCode = `alerts.libelles.${originalLabel.toLowerCase()}`;
      const translatedLabel = this.translate.instant(translationCode);

      if (isFrench) {
        cleanedMessage = cleanedMessage
          .replace(/driver/gi, "Chauffeur")
          .replace(/receiver/gi, "Receveur");
      }

      return cleanedMessage.replace(
        `${alertWord} - ${originalLabel}`,
        `${translatedAlert} - ${translatedLabel}`
      );
    }

    return cleanedMessage;
}


  markAllAsRead(): void {
    this.userService.markAllMessagesAsRead(this.userId).subscribe(
      () => {
        this.notifications.forEach(
          (notification) => (notification.read = true)
        );
        this.unreadCount = 0;
      },
      (error) => {
        console.error("Erreur lors de la mise à jour des messages:", error);
      }
    );
  }

  markMessageAsRead(notification: MessageHistory): void {
    if (notification.read) return;
    const messageDate = notification.messageDate.replace(" ", "T");
    this.userService
      .getMessageIdByTextAndDate(notification.message, messageDate)
      .subscribe(
        (messageId: number | string) => {
          if (typeof messageId === "number") {
            this.userService
              .markMessageAsRead(this.userId, messageId, messageDate)
              .subscribe(
                () => {
                  notification.read = true;
                  this.unreadCount = this.notifications.filter(
                    (msg) => !msg.read
                  ).length;
                },
                (error) => {
                  console.error(
                    "Erreur lors de la mise à jour du message:",
                    error
                  );
                }
              );
          } else {
            console.warn("Message not found:", messageId);
          }
        },
        (error) => {
          console.error(
            "Erreur lors de la récupération de l'ID du message:",
            error
          );
        }
      );
  }

  isMaintenanceNotification(notification: MessageHistory): boolean {
    const savedLanguage = localStorage.getItem("langue") || "fr";

    if (savedLanguage === "fr") {
      return notification.message_fr.includes("échéance");
    } else {
      return notification.message_en.includes("due date");
    }
  }

  private loadSettings(): void {
    this.crudService
      .getAll<any>(OPN_BASE_URL + "/company-settings/all")
      .subscribe((config) => {
        const settingsMap = config.map((item: any) => ({
          configKey: item.configKey,
          configValue: JSON.parse(item.configValue),
        }));
        this.constraintConfig = settingsMap?.find(
          (setting) => setting.configKey === "maintenance-config"
        )?.configValue;
      });
  }

  createWorkRequest(notification: MessageHistory, event: Event): void {
    event.stopPropagation();

    // First check if work request is already created
    const messageDate = notification.messageDate.replace(" ", "T");
    this.userService
      .getMessageIdByTextAndDate(notification.message, messageDate)
      .subscribe(
        (messageId: number) => {
          this.userService
            .checkIsCreated(this.userId, messageId, messageDate)
            .subscribe(
              (isCreated: boolean) => {
                if (isCreated) {
                  const errorMessage =
                    this.langue === "fr"
                      ? "Cette demande de travail a déjà été créée pour cette notification"
                      : "This work request has already been created for this notification";
                  this.notyService.displayNotification(errorMessage, "error");
                  return;
                }

                // If not created, proceed with creating work request
                this.spinner.show(undefined, {
                  type: "ball-triangle-path",
                  size: "medium",
                  bdColor: "rgba(0, 0, 0, 0.8)",
                  color: "#fff",
                  fullScreen: true,
                });

                const maintenancePreventiveId =
                  this.constraintConfig?.maintenance_preventive?.id || null;
                const currentUsername = this.getCurrentUsername();

                // First, get metadata to extract vehicle registration and scheduled task label
                this.userService
                  .getMessageMetaDataByTextAndDate(
                    notification.message,
                    messageDate
                  )
                  .subscribe(
                    (metaData) => {
                      // Chain: Get Scheduled Task by Label
                      this.scheduledService
                        .getTaskByLabel(metaData.scheduledTaskLabel)
                        .subscribe(
                          (scheduledTask) => {
                            // Chain: Get Vehicle by Registration
                            this.scheduledService
                              .getVehicleByVehicleNumber(metaData.vehicleId)
                              .subscribe(
                                (vehicleData) => {
                                  // Create Work Request with retrieved data
                                  const newWorkRequest = {
                                    requestDate: new Date().toISOString(),
                                    state:
                                      scheduledTask.requestType === "Renouvellement"
                                        ? 2
                                        : 1,
                                    urgency: 2,
                                    requestType:
                                      scheduledTask.requestType === "Renouvellement"
                                        ? "Renouvellement"
                                        : "Maintenance",
                                    vehiclesId: vehicleData.vehicleId,
                                    vehicleTypeId: vehicleData.genreId,
                                    description: `${
                                      scheduledTask.requestType === "Renouvellement"
                                        ? "Renouvellement"
                                        : "Maintenance"
                                    } for vehicle ${metaData.vehicleId}`,
                                    applicant: currentUsername,
                                    scheduledTaskId: scheduledTask.id,
                                    appointment: null,
                                    interventionCategoryId: maintenancePreventiveId,
                                    files: [],
                                  };

                                  // Create Work Request
                                  this.workRequestService
                                    .createNotifWorkRequest(newWorkRequest)
                                    .subscribe(
                                      (createdWorkRequest) => {
                                        this.spinner.hide();
                                        this.router.navigate([
                                          "/maintenance-management/work-request/list",
                                        ]);

                                        // After successful creation, update isCreated status:
                                        this.userService
                                          .updateIsCreated(this.userId, messageId, messageDate)
                                          .subscribe(
                                            () => {
                                              console.log("IsCreated status updated successfully");
                                            },
                                            (error) => {
                                              console.error("Error updating isCreated status:", error);
                                            }
                                          );
                                      },
                                      (error) => {
                                        console.error("Error creating work request:", error);
                                        this.spinner.hide();
                                      }
                                    );
                                },
                                (error) => {
                                  console.error("Error fetching vehicle:", error);
                                  this.spinner.hide();
                                }
                              );
                          },
                          (error) => {
                            console.error("Error fetching scheduled task:", error);
                            this.spinner.hide();
                          }
                        );
                    },
                    (error) => {
                      console.error("Error fetching metadata:", error);
                      this.spinner.hide();
                    }
                  );
              },
              (error) => {
                console.error("Error checking isCreated status:", error);
              }
            );
        },
        (error) => {
          console.error("Error getting message ID:", error);
        }
      );
  }


  remindMeTomorrow(notification: MessageHistory, event: Event): void {
    event.stopPropagation();
    
    const messageDate = notification.messageDate.replace(" ", "T");
    this.userService.getMessageIdByTextAndDate(notification.message, messageDate)
      .subscribe(
        (messageId: number) => {
          this.userService.checkIsCreated(this.userId, messageId, messageDate)
            .subscribe(
              (isCreated: boolean) => {
                if (isCreated) {
                  // Show toast message
                  const errorMessage =
                    this.langue === "fr"
                      ? "Cette demande de travail a déjà été créée pour cette notification"
                      : "Il n'est pas possible d'établir un rappel car la demande de travail a déjà été créée.";
                  this.notyService.displayNotification(errorMessage, "error");
                  return;
                }
                
                // If not created, proceed with reminder
                this.spinner.show(undefined, {
                  type: "ball-triangle-path",
                  size: "medium",
                  bdColor: "rgba(0, 0, 0, 0.8)",
                  color: "#fff",
                  fullScreen: true,
                });

                // First, get metadata to extract scheduled task label
                this.userService
                  .getMessageMetaDataByTextAndDate(notification.message, messageDate)
                  .subscribe(
                    (metaData) => {
                      // Get Scheduled Task by Label to obtain task ID
                      this.scheduledService
                        .getTaskByLabel(metaData.scheduledTaskLabel)
                        .subscribe(
                          (scheduledTask) => {
                            // Use the task ID to remind tomorrow
                            this.scheduledService
                              .remindTomorrow(scheduledTask.id)
                              .subscribe(
                                (response: any) => {
                                  this.spinner.hide();

                                  // After successful reminder set, update isCreated status:
                                  this.userService.updateIsCreated(this.userId, messageId, messageDate)
                                    .subscribe(
                                      () => {
                                        console.log("IsCreated status updated successfully");
                                      },
                                      error => {
                                        console.error("Error updating isCreated status:", error);
                                      }
                                    );
                                },
                                (error) => {
                                  console.error("Error updating task reminder", error);
                                  this.spinner.hide();
                                }
                              );
                          },
                          (error) => {
                            console.error("Error fetching scheduled task", error);
                            this.spinner.hide();
                          }
                        );
                    },
                    (error) => {
                      console.error("Error fetching metadata", error);
                      this.spinner.hide();
                    }
                  );
              },
              error => {
                console.error("Error checking isCreated status:", error);
              }
            );
        },
        error => {
          console.error("Error getting message ID:", error);
        }
      );
  }


  startNotificationPolling() {
    setInterval(() => {
      this.loadMessageHistory();
    }, 70000);
  }

  onDropdownClose() {
    this.unreadCount = 0;
  }

  get notificationCount(): number {
    return this.unreadCount;
  }

  private getCurrentUsername(): string | null {
    return this.authService.getUsernameFromToken();
  }

  async getCurrentUser() {
    const decodedToken = this.authService.getDecodedToken();
    this.username = decodedToken.preferred_username;
    this.userService.getLocalUserDetailsByUsername(this.username).subscribe(
      (user) => {
        this.user = user;
      },
      (error) => {
        console.error(error);
      }
    );
  }

  ngAfterViewInit() {
    this.configSub = this.configService.templateConf$.subscribe(
      (templateConf) => {
        if (templateConf) {
          this.config = templateConf;
        }
        this.loadLayout();
        this.cdr.markForCheck();
      }
    );
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval);
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    } else {
      this.isSmallScreen = false;
    }
  }

  loadLayout() {
    if (
      this.config.layout.menuPosition &&
      this.config.layout.menuPosition.toString().trim() != ""
    ) {
      this.menuPosition = this.config.layout.menuPosition;
    }

    if (this.config.layout.variant === "Light") {
      this.logoUrl = "assets/img/logo-dark.png";
    } else {
      this.logoUrl = "assets/img/logo.png";
    }

    if (this.config.layout.variant === "Transparent") {
      this.transparentBGClass = this.config.layout.sidebar.backgroundColor;
    } else {
      this.transparentBGClass = "";
    }
  }

  onSearchKey(event: any) {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.add(
        "first-active-item"
      );
    }

    if (event.target.value === "") {
      this.seachTextEmpty.emit(true);
    } else {
      this.seachTextEmpty.emit(false);
    }
  }

  removeActiveClass() {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.remove(
        "first-active-item"
      );
    }
  }

  onEscEvent() {
    this.control.setValue("");
    this.searchOpenClass = "";
    this.seachTextEmpty.emit(true);
  }

  onEnter() {
    if (this.searchResults && this.searchResults.length > 0) {
      let url = this.searchResults.first.url;
      if (url && url != "") {
        this.control.setValue("");
        this.searchOpenClass = "";
        this.router.navigate([url]);
        this.seachTextEmpty.emit(true);
      }
    }
  }

  redirectTo(value) {
    this.router.navigate([value]);
    this.seachTextEmpty.emit(true);
  }

  ToggleClass() {
    this.toggleClass =
      this.toggleClass === "ft-maximize" ? "ft-minimize" : "ft-maximize";
  }

  toggleSearchOpenClass(display) {
    this.control.setValue("");
    if (display) {
      this.searchOpenClass = "open";
      setTimeout(() => {
        this.searchElement.nativeElement.focus();
      }, 0);
    } else {
      this.searchOpenClass = "";
    }
    this.seachTextEmpty.emit(true);
  }

  logout() {
    this.websocketService.disconnect();
    this.renderer.removeClass(this.document.body, "vertical-layout");
    this.authService.logout();
  }

  toggleNotificationSidebar() {
    this.layoutService.toggleNotificationSidebar(true);
  }

  toggleSidebar() {
    this.layoutService.toggleSidebarSmallScreen(this.hideSidebar);
  }

  gotToSettings() {
    this.router.navigate(["/settings"]);
  }

  goToProfile() {
    this.router.navigate(["/profile"]);
  }

  goToNotification() {
    this.onDropdownClose();
    this.router.navigate(["/notification/list"]);
  }
}
