<div *ngIf="control" class="mb-3">
    <div class="form-group row mb-0">
      <label [class.text-danger]="control.invalid&&(control.dirty||control.touched)" [for]="idName" class="col-xl-3 col-md-4 mx-1">
        <span *ngIf="controlIsRequired(control)" class="text-danger">*</span>
        {{labelPlaceholder |translate| titlecase}}
        <!--<a *ngIf="validators && prepareTooltips(validators).length > 0"
          [ngbTooltip]="titleTemplate"
          placement="top">
      <i class="ft-alert-circle"></i>
  </a>-->
  
  <!--<ng-template #titleTemplate>
      <ng-container *ngFor="let toolTip of prepareTooltips(validators)">
          <span>{{ toolTip| translate }}</span><br>
      </ng-container>
  </ng-template>-->
  
      </label>
      
      <div class="col-xl-8 col-md-7 p-0">
        <input (blur)="preventDefault($event)"
               (change)="onChange($event)"
               (mousewheel)="preventDefault($event)"
               (wheel)="preventDefault($event)"
               [checked]="checked"
               [class.border-danger]="control.invalid&&(control.dirty||control.touched)"
               [class.form-control]="type!=='checkbox'" [disabled]="disabled"
               [formControl]="control"
               [id]="idName"
               [type]="type"
        ></div>
    </div>
    <app-text-length [control]="control" [validators]="validators"></app-text-length>
    <app-error-handling [control]="control"></app-error-handling>
  
  </div>
