<div class="table-responsive" style="max-height: 308px; overflow-y: auto;">
    <table class="table">
        <thead>
        <tr>
            <th scope="col">{{"STATIONS" | translate}}</th>
            <th scope="col">{{"ARRIVAL" | translate}}</th>
            <th scope="col">{{"ESTIMATED_ARRIVAL" | translate}}</th>
            <th scope="col">{{"DEVIATIONS" | translate}}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let row of stations">
            <td>{{row.name}}</td>
            <td>{{row.rtArrivalTime | date: 'HH:mm'}}</td>
            <td>{{row.estimatedArrivalTime | date: 'HH:mm'}}</td>
            <td>
                <span *ngIf="row.deviation > 0" class="text-danger">+{{row.deviation | number: '1.0-0'}} min</span>
                <span *ngIf="row.deviation < 0" class="text-success">{{row.deviation | number: '1.0-0'}} min</span>
                <span *ngIf="row.deviation === 0" class="text-muted">{{row.deviation | number: '1.0-0'}} min</span>
            </td>
        </tr>
        </tbody>
    </table>
</div>
