import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import {
  getRelatedModel,
  hideField,
  prepareValidators
} from "app/shared/global/funct";
import { CrudService } from "app/shared/services/crud.service";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import * as pluralize from "pluralize";
import { NotyService } from "app/shared/services/noty.service";
import { Location } from "@angular/common";
@Component({
  selector: "app-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class FormComponent implements OnInit {
  @Input() showModalNameFromRoute = true;
  @Input() fields = [];
  langue = localStorage.getItem("langue");
  /**
   * Form used to collect values
   */
  form: FormGroup;
  /**
   * Model retrieved from backend
   */
  model: any;
  /**
   * Model id from route
   */
  modelId: any;
  files: any = {};
  /**
   * Model url in backend
   */
  @Input() url: string;
  modelNameFromRoute: string;
  @Input() relatedModels = {};
  selected: { startDate; endDate };

  @Input() modalTitle: string;
  @Input() formArrayValues: any;
  @Input() submitFunction: (submittedData: any) => void;
  @Input() formName: string;

  @Output() modalClicked: EventEmitter<boolean>;
  @Output() fileUploaded: EventEmitter<any>;
  @Output() submittedData: EventEmitter<any>;
  @Input() forceValidate: boolean;

  @Input() redirectionUrl: string;

  constructor(
    protected fb: FormBuilder,
    protected router: Router,
    protected crudService: CrudService,
    protected route: ActivatedRoute,
    private notyService: NotyService,
    private location: Location,
    private cdr: ChangeDetectorRef,

  ) {
    this.modalClicked = new EventEmitter<boolean>();
    this.fileUploaded = new EventEmitter<any>();
  }

  /**
   * Returns form controls
   */
  get controls() {
    return this.form.controls;
  }

  /**
   * Get one form control by name, name can be "att1.att2.att3" and so one
   */
  getFormControl(name: string): FormControl {
    return this.form.get(name) as FormControl;
  }

  /**
   * Get one form array by name, name can be "att1.att2.att3" and so one
   */
  getFormArray(name: string): FormArray {
    return this.form.get(name) as FormArray;
  }

  getFormArrayField(name: string, index: number): FormGroup {
    return this.getFormArray(name).controls[index] as FormGroup;
  }

  /**
   * Submit data to backend
   */
  async onSubmit() {
    if (this.submitFunction !== undefined) {
      if (this.forceValidate) {
        this.validateForm();
      }
      this.submitFunction(this.form);
    } else {
      this.validateForm();
      // @ts-ignore
      // const redirectionUrl = this.route._routerState.snapshot.url.split('/')[1] + '/list';

      // @ts-ignore
      const redirectionUrl =
        this.router.url.split("/")[1] +
        "/" +
        this.router.url.split("/")[2] +
        "/list";

      const data = this.prepareData();

      // Based on userId's values, whether Create a new user
      if (!this.modelId) {
        this.crudService.post(this.url, data).subscribe(
          () => {
            this.router.navigateByUrl(redirectionUrl);
        
            const successMessage = this.langue === 'fr' 
            ? "Ajouté avec succès" 
            : "Added successfully";
          this.notyService.displayNotification(successMessage, "success");
          },
          () => {
        
            const errorMessage = this.langue === 'fr' 
            ? "Échec de l'ajout" 
            : "Failed Add";
          this.notyService.displayNotification(errorMessage, "error");
          }
        );
      } else {
        this.crudService.update(this.url, this.modelId, data).subscribe(
          () => {
            this.router.navigateByUrl(redirectionUrl);
         
            const successMessage = this.langue === 'fr' 
            ? "Mise à jour réussie" 
            : "Updated successfully";
          this.notyService.displayNotification(successMessage, "success");
          },
          () => {
            const errorMessage = this.langue === 'fr' 
            ? "Échec de la mise à jour" 
            : "Failed Update";
          this.notyService.displayNotification(errorMessage, "error");
          }
        );
      }
    }
  }

  onCancel() {
    const redirectionUrl =
        this.router.url.split("/")[1] +
        "/" +
        this.router.url.split("/")[2] +
        "/list";
    this.router.navigateByUrl(redirectionUrl).then(r => {

    });
  }

  ngOnInit(): void {
    if (this.redirectionUrl) {
      // @ts-ignore
      this.redirectionUrl =
        this.router.url.split("/")[0] +
        this.redirectionUrl;
    } else {
      this.route.queryParams.subscribe((params) => {
        if (params.redirectTo) {
          this.redirectionUrl = params.redirectTo;
        }
      });
    }

    this.initForm();
    this.initRelatedModels();
    this.modelId = this.route.snapshot.params.id;
    if (this.modelId) {
      this.getOne().subscribe();
    }
    // this.initRelatedModels();
    // @ts-ignore
    this.modelNameFromRoute = pluralize.singular(
      this.router.url.split("/")[2]
    );
  }

  log($event: any) {
    // tslint:disable-next-line:no-console
    console.debug($event);
  }

  returnBackbtn() {
    this.location.back();
  }

  getObjectKeys(object) {
    return Object.keys(object);
  }

  async setRelatedModels(
    fieldName,
    url,
    specificField = null,
    search: { relatedField: string; relatedValue: any } = null,
    formControlName = null
  ) {
    getRelatedModel(url, search, formControlName).then((data: any) => {
      this.relatedModels[fieldName] = data.data;
      if (specificField) {
        this.relatedModels[fieldName] =
          this.relatedModels[fieldName][0][specificField];
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formArrayValues) {
      for (const field in changes.formArrayValues.currentValue) {
        this.getFormArray(field).clear();
        for (const value of changes.formArrayValues.currentValue[field]) {
          this.getFormArray(field).push(this.fb.group(value));
        }
      }
    }
  }

  /**
   * prepare data before submit
   */
  protected prepareData(): any {
    const values = Object.assign({}, this.form.value);
    this.fields.forEach((field) => {
      if (field.type === "parent") {
        field.children.forEach((child) => {
          if (child.type === "file" && this.files[field.name][child.name]) {
            values[field.name][child.name] =
              this.files[field.name][child.name][0];
            if (!values[field.name][child.name]) {
              delete values[field.name][child.name];
            }
          }
        });
      } else if (field.type === "file" && this.files[field.name]) {
        values[field.name] = this.files[field.name][0];
        if (!values[field.name]) {
          delete values[field.name];
        }
      }
    });

    return values;
  }

  /**
   * Initialize component variables after getting the model
   */
  protected initVarsAfterGetRequest() {
    //empty
  }

  /**
   * Map an array, extract some attributes and set a form array value
   * @param data array to map
   * @param attributeToReturn attribute in the array to extract
   * @param controlNameToSet form array name to set
   */
  protected mapAndSetFormArray(
    data: any[],
    attributeToReturn: string,
    controlNameToSet: string
  ) {
    if (data[0]) {
      const dataToReturn = data.map((elt) => {
        return elt[attributeToReturn];
      });
      this.form.setControl(controlNameToSet, this.fb.array(dataToReturn || []));
    }
  }

  /**
   * Mark all fields in form as dirty
   */
  protected markFieldsDirty(forSubmit = false): void {
    for (const field of this.fields) {
      if (field.type !== "file" || (forSubmit && !this.files[field.name])) {
        this.markOneFieldDirty(this.controls[field.name]);
      }
    }
  }

  private validateForm() {
    this.markFieldsDirty(true);

    if (this.form.invalid) {
      for (const name in this.controls) {
        if (this.controls[name].invalid) {
          for (const field of this.fields) {
            if (
              field.name === name &&
              (field.type !== "file" || !this.files[name])
            ) {
              this.notyService.displayNotification(
                "Please check all the fields",
                "warning"
              );
              throw new Error("Invalid form");
            }
          }
        }
      }
    }
  }

  /**
   * Get model by id
   */
  private getOne(): Observable<any> {
    return this.crudService.getOne(this.url, this.modelId).pipe(
      tap((data: any) => {
        this.model = data;
        if (this.model.roles) {
          this.model.role = this.model.roles[0].name;
        }
        this.initVarsAfterGetRequest();
        for (let i = 0; i < this.fields.length; i++) {
          if (this.fields[i].type === "file") {
            if (!this.fields[i].validators) {
              this.fields[i].validators = {};
            }
            this.fields[i].validators.required = false;
          }
        }
        this.initForm();
        this.markFieldsDirty();
      })
    );
  }

  /**
   * Initialize form
   */
  private initForm() {
    this.form = this.fb.group({});
    this.fields?.forEach(async (field) => {
      if (this.model && field.backend?.relatedUrl) {
        await this.setRelatedModels(
          field.backend.relatedFormControl,
          field.backend.relatedUrl,
          field.backend.specificField,
          {
            relatedField: field.backend.relatedField,
            relatedValue: this.model[field.name],
          }
        );
      }
      if (field.type === "parent") {
        this.form.addControl(field.name, new FormGroup({}));
        const control = this.form.get(field.name) as FormGroup;
        field.children.forEach((child) => {
          control.addControl(
            child.name,
            new FormControl(
              this.model && child.type !== "file"
                ? this.model[field.name]
                  ? this.model[field.name][child.name]
                  : null
                : null,
              prepareValidators(child, this.form, this.modelId)
            )
          );
        });
      } else if (field.type === "array") {
        const array = new FormArray([]);
        const temp = {};
        if (this.model) {
          this.model[field.name].forEach((elem) => {
            field.children.forEach((child) => {
              temp[child.name] = [
                elem[child.name],
                prepareValidators(child, this.form, this.modelId),
              ];
            });
            array.push(this.fb.group(temp));
          });
        } else {
          field.children.forEach((child) => {
            temp[child.name] = [
              child.defaultValue,
              prepareValidators(child, this.form, this.modelId),
            ];
          });
          array.push(this.fb.group(temp));
        }
        this.form.addControl(field.name, array);
      } else {
        let value;
        if (field.type === "select" && field.multipleSelect) {
          value = [];
        } else if (this.model) {
          if (field.type === "checkbox") {
            value = this.model[field.name] === true;
          } else if (field.type !== "file") {
            value = this.model[field.name];
          }
          if (field.type === "radio") {
            for (const option of field.radioOptions) {
              if (option.value === value) {
                option.hide
                  ? hideField(option.hide, true, this.fields, this.form)
                  : option.show
                  ? hideField(option.show, false, this.fields, this.form)
                  : null;
              }
            }
          }
        } else {
          value = field.defaultValue;
        }
        if (Array.isArray(value) && this.model) {
          this.model[field.name].forEach((v) => {
            if (field.relatedField) {
              value.push(v[field.relatedField]);
            } else {
              value.push(v.id);
            }
          });
        }
        this.form.addControl(
          field.name,
          new FormControl(
            value,
            prepareValidators(field, this.form, this.modelId)
          )
        );
      }
    });
    this.cdr.detectChanges()
  }

  private async initRelatedModels() {
    if(this.fields && Array.isArray(this.fields)){
      for (const field of this.fields) {
        if (field.backend?.url) {
          await this.setRelatedModels(field.name, field.backend.url);
        } else if (field.children) {
          for (const child of field.children) {
            if (child.backend?.url) {
              await this.setRelatedModels(child.name, child.backend.url);
            }
          }
        }
      }
    }
  }

  /**
   * Mark one field and its children as dirty
   */
  private markOneFieldDirty(control: AbstractControl) {
    if (control) {
      if (control instanceof FormGroup || control instanceof FormArray) {
        for (const field in control.controls) {
          if (control.controls[field]) {
            this.markOneFieldDirty(control.controls[field]);
          }
        }
      } else {
        control.markAsDirty();
        control.updateValueAndValidity();
      }
    }
  }
}
