import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'idFormatter'
})
export class IdFormatterPipe implements PipeTransform {

  transform(value: number, padLength: number = 7, padChar: string = '0'): string {
    const paddedId = value.toString().padStart(padLength, padChar);
    return `#${paddedId}`;
  }

}
