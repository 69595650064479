<div class="modal-header">
    <h4 class="modal-title">{{"EXPORT-AFFECTATION"|translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
        <i class="fa-solid fa-xmark"></i>
    </button>
</div>
<div class="modal-body">

    <form [formGroup]="exportForm">
        <ng-container *ngIf="viewState === 'form'">

            <div class="row">
                <!-- Exportation numbers -->
                <div class="col-xl-3 col-lg-6 col-12">
                    <div class="card card-inverse color bg-warning bg-lighten-1">
                        <div class="card-content">
                            <div class="card-body">
                                <div class="media">
                                    <div class="media-body text-left">
                                        <h3 class="card-text">{{nbrOfTrips}}</h3>
                                        <span>{{'TRIPS'|translate}}</span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-12">
                    <div class="card card-inverse bg-success bg-lighten-1">
                        <div class="card-content">
                            <div class="card-body">
                                <div class="media">
                                    <div class="media-body text-left">
                                        <h3 class="card-text">{{nbrOfDays}}</h3>
                                        <span>{{'DAYS'|translate}}</span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-12">
                    <div class="card card-inverse bg-danger bg-lighten-1">
                        <div class="card-content">
                            <div class="card-body">
                                <div class="media">
                                    <div class="media-body text-left">
                                        <h3 class="card-text">{{nbrOfDrivers}}</h3>
                                        <span>{{'CHAUFFEUR'|translate}}</span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-12">
                    <div class="card card-inverse bg-primary bg-lighten-1">
                        <div class="card-content">
                            <div class="card-body">
                                <div class="media">
                                    <div class="media-body text-left">
                                        <h3 class="card-text">{{nbrOfLines}}</h3>
                                        <span>{{'LIGNE'|translate}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="ml-1 row text-bold-600">{{"EXPORT-FROM"| translate}} </div>
            <div class="row col-8 mt-3">


                <!-- date to export for-->
                <div class="input-group col-5">
                    <input class="form-control" id="validationTooltip03" placeholder="{{'DATE_FORMAT' | translate}}"
                        ngbDatepicker #d="ngbDatepicker" formControlName="startDate">
                    <div class="input-group-append">
                        <div class="input-group-text" (click)="d.toggle()">
                            <em class="fa fa-calendar" style="cursor: pointer;"></em>
                        </div>
                    </div>
                </div>
                <span class="card-text mt-1">{{nbrOfDays}}<span>{{'DAYS'| translate}}</span></span>

            </div>



            <!-- Analyse periode to export to - same instances of trips !? -->
            <div class="mt-3 alert alert-light d-flex align-items-center">
                <i *ngIf="sameSchedule == true" class="fa-solid fa-check text-success font-weight-bold mr-2"></i>
                <i *ngIf="sameSchedule == false" class="fa-solid fa-x text-danger font-weight-bold mr-2"></i>
                <p class="text-bold-600 mb-0">{{ "SAME-SCHEDULE" | translate }}</p>
            </div>
            <div *ngIf="missingInstances">
                <div *ngIf="!sameSchedule && missingInstances.length > 0" class="alert alert-light mr-1 ml-1">
                    <span>{{ "MISSING_INSTANCES_MSG" | translate }}</span>
                    <ul>
                        <li *ngFor="let instance of missingInstances" class="text-danger">
                            {{ instance.lineDirection }}
                        </li>
                    </ul>
                </div>
            </div>

        </ng-container>


        <ng-container *ngIf="viewState === 'review'">
            <div class="font-medium-3">{{ 'TRIPS_TO_BE_ASSIGNED' | translate }} :</div>
            <div *ngFor="let group of groupedInstances; let i = index">


                <div (click)="toggleCollapse(i)">
                    <span class="d-flex align-items-center text-dark font-weight-bold py-1 cursor-pointer">
                        <i class="fa"
                            [ngClass]="{'fa-chevron-down': !group.isCollapsed, 'fa-chevron-right': group.isCollapsed}"></i>
                        <span class="ml-2">{{ getDayOfWeek(group.date) }} {{ group.date }}</span>
                        <span class="font-weight-lighter ml-1 ">({{group.instances.length }} {{ 'TRIP' | translate
                            }})</span>
                    </span>
                </div>
                <div [ngbCollapse]="group.isCollapsed">
                    <div class="card-body">

                        <ng-template #notAssigned>non <b>affecté</b>!</ng-template>

                        <div class="table-responsive border">
                            <table class="table mb-0">
                                <thead>
                                    <tr class="bg-grey bg-lighten-4 rounded p-2 mb-2">
                                        <th># {{ 'LINE' | translate }}</th>
                                        <th>{{ 'TRIP' | translate }}</th>
                                        <th>{{ 'DEPARTURE' | translate }}</th>
                                        <th>{{ 'DRIVER-NUM' | translate }}</th>
                                        <th>{{ 'BUS-NUM' | translate }}</th>
                                        <th>{{ 'RECEIVER-NUM' | translate }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="" *ngFor="let trip of group.instances">
                                        <!-- route number -->
                                        <td>
                                            <span class="badge badge-dark rounded-pill font-weight-bold px-3">
                                                {{ trip.lineNumber}}
                                            </span>
                                        </td>
                                        <!-- trip number -->
                                        <td>
                                            <span class="badge badge-secondary rounded-pill">
                                                <i class="fa-solid fa-bus"></i><span
                                                    class="font-weight-bold hovering px-1">{{
                                                    trip.lineDirection }}
                                                </span>
                                            </span>
                                        </td>
                                        <!-- trip departure -->
                                        <td>
                                            {{ trip.plannedDeparture | date: 'dd MMM yyyy' }} <span
                                                class="font-weight-bold">{{
                                                trip.plannedDeparture | date: 'HH:MM' }} </span>
                                        </td>
                                        <!-- Driver -->
                                        <td>
                                            <!-- already assigned -->
                                            <div class="align-content-center" *ngIf="trip.driver; else noDriver">
                                                <div class="d-flex flex-column align-items-center">
                                                    <span class="text-nowrap font-weight-bold">#{{
                                                        trip.driver?.matricule }}</span>
                                                    <span class="text-center">{{trip.driver.firstName}}
                                                        {{trip.driver.lastName}} </span>
                                                    <button *ngIf="!trip.removedDriver"
                                                        class="btn btn-sm bg-light-danger round text-nowrap"
                                                        (click)="removeDriver(trip)">
                                                        <i class="fa fa-trash mr-2"></i>Désaffecter
                                                    </button>
                                                </div>
                                            </div>
                                            <!-- not assigned or assignation removed -->
                                            <ng-template #noDriver>
                                                <div class="text-center align-content-center">
                                                    <span class="text-danger" [ngbTooltip]="notAssigned"
                                                        *ngIf="!trip.removedDriver">
                                                        <i class="fa-solid fa-slash"></i>
                                                    </span>
                                                    <!-- assignation removed -->
                                                    <button *ngIf="trip.removedDriver"
                                                        class="btn btn-sm bg-light-success round text-nowrap"
                                                        (click)="undoRemoveDriver(trip)">
                                                        <i class="fa fa-undo mr-2"></i>Réaffecter
                                                    </button>
                                                </div>
                                            </ng-template>
                                        </td>
                                        <!-- Bus -->
                                        <td>
                                            <!-- already assigned -->
                                            <div class="align-content-center" *ngIf="trip.bus; else noBus">
                                                <div class="d-flex flex-column align-items-center">
                                                    <span class="text-nowrap font-weight-bold">#{{
                                                        trip.bus.vehicleNumber }}</span>
                                                    <!-- <span>{{trip.driver.firstName}} {{trip.driver.lastName}} </span>                             -->
                                                    <button *ngIf="!trip.removedBus"
                                                        class="btn btn-sm bg-light-danger round text-nowrap mt-1"
                                                        (click)="removeBus(trip)">
                                                        <i class="fa fa-trash mr-2"></i>Désaffecter
                                                    </button>
                                                </div>
                                            </div>
                                            <!-- not assigned or assignation removed -->
                                            <ng-template #noBus>
                                                <div class="text-center align-content-center">
                                                    <span class="text-danger" [ngbTooltip]="notAssigned"
                                                        *ngIf="!trip.removedBus">
                                                        <i class="fa-solid fa-slash"></i>
                                                    </span>
                                                    <!-- assignation removed -->
                                                    <button *ngIf="trip.removedBus"
                                                        class="btn btn-sm bg-light-success round text-nowrap"
                                                        (click)="undoRemoveBus(trip)">
                                                        <i class="fa fa-undo mr-2"></i>Réaffecter
                                                    </button>
                                                </div>
                                            </ng-template>
                                        </td>
                                        <!-- Receiver -->
                                        <td>
                                            <!-- already assigned -->
                                            <div class="align-content-center" *ngIf="trip.receiver; else noReceiver">
                                                <div class="d-flex flex-column align-items-center">
                                                    <span class="text-nowrap font-weight-bold">#{{
                                                        trip.receiver.matricule }}</span>
                                                    <span class="text-center">{{trip.receiver.firstName}}
                                                        {{trip.receiver.lastName}} </span>
                                                    <button *ngIf="!trip.removedReceiver"
                                                        class="btn btn-sm bg-light-danger flex-grow-1 round text-nowrap"
                                                        (click)="removeReceiver(trip)">
                                                        <i class="fa fa-trash mr-2"></i>Désaffecter
                                                    </button>
                                                </div>
                                            </div>
                                            <!-- not assigned or assignation removed -->
                                            <ng-template #noReceiver>
                                                <div class="text-center align-content-center">
                                                    <span class="text-danger" [ngbTooltip]="notAssigned"
                                                        *ngIf="!trip.removedReceiver">
                                                        <i class="fa-solid fa-slash"></i>
                                                    </span>
                                                    <!-- assignation removed -->
                                                    <button *ngIf="trip.removedReceiver"
                                                        class="btn btn-sm bg-light-success flex-grow-1 round text-nowrap"
                                                        (click)="undoRemoveReceiver(trip)">
                                                        <i class="fa fa-undo mr-2"></i>Réaffecter
                                                    </button>
                                                </div>
                                            </ng-template>
                                        </td>
                                    </tr>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </ng-container>


        <ng-container *ngIf="viewState === 'export'">
            <div *ngIf="isLoading" class="text-center">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>

            <div class="bg-light-secondary mb-2 p-2 rounded" *ngIf="!isLoading">
                <div class="font-medium-3">{{ 'TRIPS_TO_BE_ASSIGNED' | translate }} :</div>
                <div *ngFor="let group of groupedInstances; let i = index">

                    <div (click)="toggleCollapse(i)">
                        <span
                            class="d-flex align-items-center text-dark font-weight-bold p-2 bg-light-secondary round  cursor-pointer">
                            <i class="fa"
                                [ngClass]="{'fa-chevron-down': !group.isCollapsed, 'fa-chevron-right': group.isCollapsed}"></i>
                            <span class="ml-2">{{ getDayOfWeek(group.date) }} {{ group.date }} ({{
                                group.instances.length }} {{ 'TRIP' | translate }})</span>
                        </span>
                    </div>

                    <div [ngbCollapse]="group.isCollapsed">
                        <div class="card-body">
                            <div class="d-flex m-0 flex-wrap">
                                <div *ngFor="let trip of group.instances" class="col-md-3 p-2">
                                    <div
                                        class="d-flex flex-column h-100 justify-content-around mission-item-w rounded p-1 bg-white">
                                        <div class="d-flex flex-column justify-content-around m-0 p-1">
                                            <div class="d-flex gap-05 mb-1">
                                                <span class="badge badge-pill bg-success">
                                                    {{ trip.lineDirection}}
                                                </span>
                                                <div class="badge badge-pill bg-light-secondary flex-grow-1">
                                                    <span class="font-weight-bold">{{ trip.plannedDeparture |
                                                        date:"HH:mm" }}</span>
                                                    <i class="fa-solid fa-van-shuttle mx-1"></i>
                                                    <span class="font-weight-lighter">{{ trip.estimatedArrival |
                                                        date:"HH:mm" }}</span>
                                                </div>
                                            </div>
                                            <div class="px-1 text-secondary">{{ trip.tripRoute }}</div>
                                        </div>
                                        <div class="d-flex justify-content-center m-0 rounded-bottom p-1">
                                            <div class="d-flex p-1">
                                                <!-- BEGIN - Overlapping Trips - tooltips -->
                                                <ng-template #popTitle1 let-overlappingMsg="overlappingMsg">
                                                    {{ overlappingMsg | translate}}
                                                </ng-template>

                                                <ng-template #popContent1 let-overlappings="overlappings"
                                                    let-assignationEmpty="assignationEmpty"
                                                    let-overlappingTrigger="overlappingTrigger">
                                                    <!-- overlappings trips -->
                                                    <div class="d-flex flex-column"
                                                        *ngIf="(overlappings && overlappings.length > 0); else noOverlappingContent">

                                                        <div *ngIf="overlappingTrigger == 'Driver'"
                                                            class="d-flex gap-05 flex-column">
                                                            <span>
                                                                {{"#"+trip.driver?.matricule+" -
                                                                "+trip.driver?.firstName+ " " +
                                                                trip.driver?.lastName}}
                                                            </span>
                                                            <span class="d-flex font-small-2 gap-05"
                                                                [class.line-through]="trip.overrideDriver"
                                                                *ngFor="let t of overlappings">
                                                                <span class="badge badge-pill bg-light-info">
                                                                    {{ t.lineDirection}}
                                                                </span>
                                                                <div
                                                                    class="badge badge-pill bg-light-secondary flex-grow-1">
                                                                    <span class="font-weight-bold">{{ t.plannedDeparture
                                                                        | date:"HH:mm" }}</span>
                                                                    <i class="fa-solid fa-van-shuttle mx-1"></i>
                                                                    <span class="font-weight-lighter">{{
                                                                        t.estimatedArrival | date:"HH:mm"
                                                                        }}</span>
                                                                </div>
                                                            </span>
                                                        </div>

                                                        <div *ngIf="overlappingTrigger == 'Bus'"
                                                            class="d-flex gap-05 flex-column">
                                                            <span>
                                                                {{"#"+trip.bus?.vehicleNumber+" -
                                                                "+trip.bus?.brand?.name+ " - " +
                                                                trip.bus?.model?.name}}
                                                            </span>
                                                            <span class="d-flex font-small-2 gap-05"
                                                                [class.line-through]="trip.overrideBus"
                                                                *ngFor="let t of overlappings">
                                                                <span class="badge badge-pill bg-light-info">
                                                                    {{ t.lineDirection}}
                                                                </span>
                                                                <div
                                                                    class="badge badge-pill bg-light-secondary flex-grow-1">
                                                                    <span class="font-weight-bold">{{ t.plannedDeparture
                                                                        | date:"HH:mm" }}</span>
                                                                    <i class="fa-solid fa-van-shuttle mx-1"></i>
                                                                    <span class="font-weight-lighter">{{
                                                                        t.estimatedArrival | date:"HH:mm"
                                                                        }}</span>
                                                                </div>
                                                            </span>
                                                        </div>

                                                        <div *ngIf="overlappingTrigger == 'Receiver'"
                                                            class="d-flex gap-05 flex-column">
                                                            <span>
                                                                {{"#"+trip.receiver?.matricule+" - "
                                                                + trip.receiver?.firstName+ " "
                                                                + trip.receiver?.lastName }}
                                                            </span>
                                                            <span class="d-flex font-small-2 gap-05"
                                                                [class.line-through]="trip.overrideReceiver"
                                                                *ngFor="let t of overlappings">
                                                                <span class="badge badge-pill bg-light-info">
                                                                    {{ t.lineDirection}}
                                                                </span>
                                                                <div
                                                                    class="badge badge-pill bg-light-secondary flex-grow-1">
                                                                    <span class="font-weight-bold">{{ t.plannedDeparture
                                                                        | date:"HH:mm" }}</span>
                                                                    <i class="fa-solid fa-van-shuttle mx-1"></i>
                                                                    <span class="font-weight-lighter">{{
                                                                        t.estimatedArrival | date:"HH:mm"
                                                                        }}</span>
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <ng-template #noOverlappingContent>
                                                        <div class="text-center">
                                                            <div *ngIf="!assignationEmpty">
                                                                <span *ngIf="overlappingTrigger == 'Driver'">
                                                                    {{"#"+trip.driver?.matricule + " - "
                                                                    + trip.driver?.firstName+ " "
                                                                    + trip.driver?.lastName}}
                                                                </span>
                                                                <span *ngIf="overlappingTrigger == 'Bus'">
                                                                    {{"#"+trip.bus?.vehicleNumber + " - "
                                                                    + trip.bus?.brand?.name+ " - "
                                                                    + trip.bus?.model?.name}}
                                                                </span>
                                                                <span *ngIf="overlappingTrigger == 'Receiver'">
                                                                    {{"#"+trip.receiver?.matricule + " - "
                                                                    +trip.receiver?.firstName
                                                                    + " " + trip.receiver?.lastName}}
                                                                </span>
                                                            </div>
                                                            <span *ngIf="assignationEmpty">
                                                                <i class="fa-solid fa-ban font-medium-4 text-grey"></i>
                                                            </span>
                                                        </div>
                                                    </ng-template>

                                                </ng-template>
                                                <!-- END - Overlapping Trips - tooltips -->

                                                <!-- Driver Badge -->
                                                <div class="badge badge-secondary p-1 mr-1 d-flex align-items-center "
                                                    [ngClass]="{
                                                        'bg-danger': trip.driverConflict,
                                                        'bg-success': !trip.driverConflict && !trip.driverDayOff,
                                                        'bg-warning': !trip.driverConflict && trip.driverDayOff
                                                    }">
                                                    <!-- pas de conflit -->
                                                    <span [ngbPopover]="popContent1" class="cursor-pointer"
                                                        [popoverTitle]="popTitle1" triggers="manual"
                                                        #pDriver="ngbPopover"
                                                        (click)="toggleOverlappingTrips(pDriver,'Driver', trip)">
                                                        <i *ngIf="!trip.driverConflict  && trip.driver"
                                                            class="fa-solid fa-check"></i>
                                                        <i *ngIf="trip.driverConflict" class="fa-solid fa-x"></i>
                                                        <img src="./assets/img/icons/driver.png"
                                                            class="mx-1 icon-small">
                                                    </span>
                                                    <input *ngIf="trip.driverConflict" class="col-auto" type="checkbox"
                                                        id="export-driver" [checked]="trip.overrideDriver"
                                                        (change)="toggleOverrideDriver(trip, $event.target.checked)">
                                                    <input *ngIf="!trip.driverConflict && trip.driverDayOff"
                                                        class="col-auto" type="checkbox" id="dayOff-driver"
                                                        [checked]="trip.overrideDriverDayOff"
                                                        (change)="toggleOverrideDriverDayOff(trip, $event.target.checked)">
                                                </div>

                                                <!-- Receiver Badge -->
                                                <div class="badge badge-secondary p-1 mr-1 d-flex align-items-center "
                                                    [ngClass]="{
                                                        'bg-danger': trip.receiverConflict,
                                                        'bg-success': !trip.receiverConflict && !trip.receiverDayOff,
                                                        'bg-warning': !trip.receiverConflict && trip.receiverDayOff
                                                    }">
                                                    <span [ngbPopover]="popContent1" class="cursor-pointer"
                                                        [popoverTitle]="popTitle1" triggers="manual"
                                                        #pReceiver="ngbPopover"
                                                        (click)="toggleOverlappingTrips(pReceiver,'Receiver', trip)">
                                                        <i *ngIf="(!trip.receiverConflict && trip.receiver) || (!trip.receiverConflict && trip.receiver)"
                                                            class="fa-solid fa-check"></i>
                                                        <i *ngIf="trip.receiverConflict" class="fa-solid fa-x"></i>
                                                        <img src="./assets/img/icons/cashier.png"
                                                            class="mx-1 icon-small">
                                                    </span>
                                                    <input *ngIf="trip.receiverConflict" class="col-auto"
                                                        type="checkbox" id="export-receiver"
                                                        [checked]="trip.overrideReceiver"
                                                        (change)="toggleOverrideReceiver(trip, $event.target.checked)">
                                                    <input *ngIf="!trip.receiverConflict && trip.receiverDayOff"
                                                        class="col-auto" type="checkbox" id="dayOff-receiver"
                                                        [checked]="trip.overrideReceiverDayOff"
                                                        (change)="toggleOverrideReceiverDayOff(trip, $event.target.checked)">
                                                </div>

                                                <!-- Bus Badge -->
                                                <div class="badge badge-secondary p-1 mr-1 d-flex align-items-center "
                                                    [ngClass]="{
                                                        'bg-danger': trip.busConflict,
                                                        'bg-success': !trip.busConflict
                                                    }">

                                                    <span [ngbPopover]="popContent1" class="cursor-pointer"
                                                        [popoverTitle]="popTitle1" triggers="manual" #pBus="ngbPopover"
                                                        (click)="toggleOverlappingTrips(pBus,'Bus', trip)">
                                                        <i *ngIf="!trip.busConflict && trip.bus"
                                                            class="fa-solid fa-check"></i>
                                                        <i *ngIf="trip.busConflict" class="fa-solid fa-x"></i>
                                                        <img src="./assets/img/icons/bus.png" class="mx-1 icon-small">
                                                    </span>
                                                    <input *ngIf="trip.busConflict" class="col-auto" type="checkbox"
                                                        id="export-bus" [checked]="trip.overrideBus"
                                                        (change)="toggleOverrideBus(trip, $event.target.checked)">

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </ng-container>

    </form>
    <div class="modal-footer">


        <!-- Bouton RETURN pour retourner de 'export' à 'review' -->
        <button *ngIf="viewState === 'export'" type="button" class="btn btn-secondary" (click)="returnToPrevious()">
            {{ 'RETURN' | translate }}
        </button>

        <!-- Bouton RETURN pour retourner de 'review' à 'form' -->
        <button *ngIf="viewState === 'review'" type="button" class="btn btn-secondary" (click)="returnToPrevious()">
            {{ 'RETURN' | translate }}
        </button>

        <button *ngIf="viewState === 'form' " type="button" class="btn btn-primary" (click)="onNextClicked()"
            [disabled]="isNextDisabled">
            {{ 'NEXT' | translate }}
        </button>

        <!-- Bouton NEXT pour passer de 'review' à 'export' -->
        <button *ngIf="viewState === 'review'" type="button" class="btn btn-primary" (click)="onNextClicked()">
            {{ 'NEXT' | translate }}
        </button>


        <button (click)="onSave()" *ngIf="viewState === 'export'" class="btn btn-primary">Save</button>
        <button type="button" class="btn btn-secondary" (click)="activeModal.close('Close click')">{{"Close" |
            translate}}</button>
    </div>