import { NotyService } from "./../../services/noty.service";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {
  BASE_API_ENERGY,
  Energy_BASE_URL,
  M_BASE_API,
  M_BASE_URL,
  OPN_BASE_URL,
} from "app/shared/global/var";
import { CrudService } from "app/shared/services/crud.service";
import { KeycloakService } from "app/shared/services/keycloak.service";
import { UsersService } from "app/shared/services/users.service";

@Component({
  selector: "app-delete-modal",
  templateUrl: "./delete-modal.component.html",
  styleUrls: ["./delete-modal.component.scss"],
})
export class DeleteModalComponent {
  @Input() itemId: string;
  @Input() itemType: string;
  @Input() itemName: string;
  @Output() itemDeleted: EventEmitter<void> = new EventEmitter<void>();
  langue = localStorage.getItem("langue");

  SUCCESS_MESSAGES = {
    fr: {
      service: "Service supprimé avec succès",
      servicePeriod: "Période de service supprimée avec succès",
      route: "Route supprimée avec succès",
      workRequest: "Demande de travail supprimée avec succès",
      standard: "Standard supprimé avec succès",
      station: "Station supprimée avec succès",
      serviceGroup: "Groupe de service supprimé avec succès",
      "scheduled-task": "Tâche planifiée supprimée avec succès",
      realmRole: "Rôle supprimé avec succès",
      clientRole: "Permission supprimée avec succès",
      itinerary: "Itinéraire supprimé avec succès",
      user: "Utilisateur supprimé avec succès",
      intervention: "Intervention supprimée avec succès",
      Refuelling: "Ravitaillement supprimé avec succès",
      exceptionDate:"Date exceptionnelle supprimée avec succès "
    },
    en: {
      service: "Service deleted successfully",
      servicePeriod: "Service period deleted successfully",
      route: "Route deleted successfully",
      workRequest: "Work request deleted successfully",
      standard: "Standard deleted successfully",
      station: "Station deleted successfully",
      serviceGroup: "Service group deleted successfully",
      "scheduled-task": "Scheduled task deleted successfully",
      realmRole: "Role deleted successfully",
      clientRole: "Permission deleted successfully",
      itinerary: "Itinerary deleted successfully",
      user: "User deleted successfully",
      intervention: "Intervention deleted successfully",
      Refuelling: "Refuelling deleted successfully",
      exceptionDate: "Exception date deleted successfully"
    },
  };

    ERROR_MESSAGES = {
        fr: {
            deleteRouteError: "Erreur lors de la suppression d'une route car elle est associée à des voyages.",
            deleteServiceGroupError: "Erreur lors de la suppression d'un groupe de services car il est associé à des voyages.",
            deleteAgencyError: "Erreur lors de la suppression d'une agence car elle a des lignes associées.",
            deleteCenterError: "Erreur lors de la suppression d'un centre car il a des agences associées.",
            deleteRouteTypeError: "Erreur lors de la suppression d'un type de route car il a des lignes associées.",
            deleteVehicleBrandError: "Erreur lors de la suppression d'une marque de véhicule car elle a des modèles associés.",
            deleteBusTypeError: "Erreur lors de la suppression d'un type de bus car il a des véhicules associés.",
            deleteVehicleError: "Erreur lors de la suppression d'un véhicule car il a des instances de voyage associées.",
            deleteVehicleFamilyError: "Erreur lors de la suppression d'une famille de véhicules car elle a des véhicules associés.",
            deleteVehicleModelError: "Erreur lors de la suppression d'un modèle de véhicule car il a des véhicules associés.",
            deleteStaffError: "Erreur lors de la suppression d'un personnel car il est associé à un voyage.",
            deleteStaffTypeError: "Erreur lors de la suppression d'un type de personnel car il a des personnels associés.",
            deleteInterventionError: "Erreur lors de la suppression d'une intervention car elle a des ordres de travail associés.",
            deleteInterventionCategoryError: "Erreur lors de la suppression d'une catégorie d'intervention car elle a des types de maintenance associés.",
            deleteInterventionTypeError: "Erreur lors de la suppression d'un type d'intervention car il a des interventions associées.",
            deleteMaintenanceTypeError: "Erreur lors de la suppression d'un type de maintenance car il a des tâches planifiées associées.",
            deleteWorkDiaryError: "Erreur lors de la suppression d'un journal de travail car il a des interventions associées.",
            deleteStandardError: "Erreur lors de la suppression d'une norme car elle a des véhicules associés.",
            defaultError: "Une erreur inconnue est survenue.",
        },
        en: {
            deleteRouteError: "Error deleting route because it has associated trips.",
            deleteServiceGroupError: "Error deleting service group because it has associated trips.",
            deleteAgencyError: "Error deleting agency because it has associated routes.",
            deleteCenterError: "Error deleting center because it has associated agencies.",
            deleteRouteTypeError: "Error deleting route type because it has associated routes.",
            deleteVehicleBrandError: "Error deleting vehicle brand because it has associated vehicle models.",
            deleteBusTypeError: "Error deleting bus type because it has associated vehicles.",
            deleteVehicleError: "Error deleting vehicle because it has associated trip instances.",
            deleteVehicleFamilyError: "Error deleting vehicle family because it has associated vehicles.",
            deleteVehicleModelError: "Error deleting vehicle model because it has associated vehicles.",
            deleteStaffError: "Error deleting staff because they are associated with a trip.",
            deleteStaffTypeError: "Error deleting staff type because it has associated staff.",
            deleteInterventionError: "Error deleting intervention because it has associated work orders.",
            deleteInterventionCategoryError: "Error deleting intervention category because it has associated maintenance types.",
            deleteInterventionTypeError: "Error deleting intervention type because it has associated interventions.",
            deleteMaintenanceTypeError: "Error deleting maintenance type because it has associated scheduled tasks.",
            deleteWorkDiaryError: "Error deleting work diary because it has associated interventions.",
            deleteStandardError: "Error deleting standard because it has associated vehicles.",
            defaultError: "An unknown error occurred.",
        },
    };


    /**
   * Creates an instance of DeleteModalComponent.
   * @param activeModal The active modal service for managing modal operations.
   * @param notyService Service for displaying notifications to the user.
   * @param crudService Service for performing CRUD operations.
   * @param keycloakService Service for managing Keycloak-related operations.
   */
  constructor(
    public activeModal: NgbActiveModal,
    private notyService: NotyService,
    private crudService: CrudService,
    private keycloakService: KeycloakService,
    private usersService: UsersService
  ) {}

  private getSuccessMessage(itemType: string): string {
    const messages =
      this.langue === "fr"
        ? this.SUCCESS_MESSAGES.fr
        : this.SUCCESS_MESSAGES.en;
    return (
      messages[itemType] ||
      (this.langue === "fr"
        ? "Suppression effectuée avec succès"
        : "Deletion successful")
    );
  }

  private getErrorMessage(itemType: string): string {
    const messages =
      this.langue === "fr" ? this.ERROR_MESSAGES.fr : this.ERROR_MESSAGES.en;
    return (
      messages[itemType] ||
      (this.langue === "fr" ? "Erreur de suppression " : "Deletion error")
    );
  }

  /**
   * Deletes an item based on the item type.
   * This method determines the type of the item to be deleted and
   * calls the corresponding deletion API endpoint. Upon successful
   * deletion, an event is emitted to notify other components of the
   * deletion. If an error occurs during the deletion process, a
   * notification is displayed to the user.
   *
   * The method handles the deletion of various item types including
   * services, service periods, routes, work requests, standards,
   * service groups, and realm roles. After the deletion process,
   * the modal is closed.
   */

  deleteItem(): void {
    if (this.itemType === "service") {
      this.crudService
        .delete(OPN_BASE_URL + "/services/delete", this.itemId)
        .subscribe(
          (data: any) => {
            this.itemDeleted.emit();
            this.notyService.displayNotification(
              this.getSuccessMessage("service"),
              "success"
            );
          },
          (error) => {
            if (error.status === 400) {
              this.notyService.displayNotification(
                this.getErrorMessage("deleteServiceError"),
                "error"
              );
            }
          }
        );
    } else if (this.itemType === "servicePeriod") {
      this.crudService
        .delete(OPN_BASE_URL + "/servicePeriod/delete", this.itemId)
        .subscribe(
          (data: any) => {
            this.itemDeleted.emit();
            this.notyService.displayNotification(
              this.getSuccessMessage("servicePeriod"),
              "success"
            );
          },
          (error) => {
            console.log("error", error);
            if (error.status === 400) {
              this.notyService.displayNotification(
                this.getErrorMessage("deleteServicePeriodError"),
                "error"
              );
            }
          }
        );
    } else if (this.itemType === "route") {
      this.crudService
        .delete(OPN_BASE_URL + "/route/delete", this.itemId)
        .subscribe(
          (data: any) => {
            this.itemDeleted.emit();
            this.notyService.displayNotification(
              this.getSuccessMessage("route"),
              "success"
            );
          },
          (error) => {
            if (error.status === 400) {
              this.notyService.displayNotification(error.error, "error");
            }
          }
        );
    } else if (this.itemType === "workRequest") {
      this.crudService
        .delete(M_BASE_API + "/workRequest/delete", this.itemId)
        .subscribe((data: any) => {
          this.itemDeleted.emit();
          this.notyService.displayNotification(
            this.getSuccessMessage("workRequest"),
            "success"
          );
        });
    } else if (this.itemType === "standard") {
      this.crudService
        .delete(Energy_BASE_URL + "/standard/delete", this.itemId)
        .subscribe((data: any) => {
          this.itemDeleted.emit();
          this.notyService.displayNotification(
            this.getSuccessMessage("standard"),
            "success"
          );
        });
    } 
    else if (this.itemType === "exceptionDate") {
      this.crudService
        .delete(OPN_BASE_URL+ "/exceptiondates/delete", this.itemId)
        .subscribe((data: any) => {
          this.itemDeleted.emit();
          this.notyService.displayNotification(
            this.getSuccessMessage("exceptionDate"),
            "success"
          );
        });
    }
    else if (this.itemType === "station") {
      this.crudService
        .delete(OPN_BASE_URL + "/stations/delete", this.itemId)
        .subscribe(
          (data: any) => {
            this.itemDeleted.emit();
            this.notyService.displayNotification(
              this.getSuccessMessage("station"),
              "success"
            );
          },
          (error) => {
            if (error.status === 400) {
              this.notyService.displayNotification(
                this.getErrorMessage("deleteStationError"),
                "error"
              );
            }
          }
        );
    } else if (this.itemType === "serviceGroup") {
      this.crudService
        .delete(OPN_BASE_URL + "/serviceGroup/delete", this.itemId)
        .subscribe((data: any) => {
          this.itemDeleted.emit();
          this.notyService.displayNotification(
            this.getSuccessMessage("serviceGroup"),
            "success"
          );
        });
    } else if (this.itemType === "scheduled-task") {
      this.crudService
        .delete(M_BASE_URL + "/scheduled-tasks", this.itemId)
        .subscribe((data: any) => {
          this.itemDeleted.emit();
          this.notyService.displayNotification(
            this.getSuccessMessage("scheduled-task"),
            "success"
          );
        });
    } else if (this.itemType === "realmRole") {
      this.keycloakService.deleteRealmRole(this.itemId).subscribe(
        (data: any) => {
          this.itemDeleted.emit();
          this.notyService.displayNotification(
            this.getSuccessMessage("realmRole"),
            "success"
          );
        },
        (error) => {
          if (error.status === 400) {
            this.notyService.displayNotification(error.error, "error");
          }
        }
      );
    } else if (this.itemType === "clientRole") {
      this.keycloakService
        .deleteClientRole(this.itemId)
        .subscribe((data: any) => {
          this.itemDeleted.emit();
          this.notyService.displayNotification(
            this.getSuccessMessage("clientRole"),
            "success"
          );
        });
    } else if (this.itemType === "itinerary") {
      this.crudService
        .delete(OPN_BASE_URL + "/itinerary/delete", this.itemId)
        .subscribe(
          (data: any) => {
            this.itemDeleted.emit();
            this.notyService.displayNotification(
              this.getSuccessMessage("itinerary"),
              "success"
            );
          },
          (error) => {
            if (error.status === 400) {
              this.notyService.displayNotification(
                this.getErrorMessage("deleteItineraryError"),
                "error"
              );
            }
          }
        );
    } else if (this.itemType === "user") {
      this.usersService.deleteUserAccount(this.itemName).subscribe(
        () => {
          this.itemDeleted.emit();
          this.notyService.displayNotification(
            this.getSuccessMessage("user"),
            "success"
          );
        },
        (error) => {
          console.error("Error deleting user:", error);
          if (error.status === 400) {
            this.notyService.displayNotification(error.error, "error");
          }
        }
      );
    } else if (this.itemType === "inscription") {
      this.usersService.deleteInscription(Number(this.itemId)).subscribe(
        () => {
          this.itemDeleted.emit();
        },
        (error) => {
          if (error.status === 400) {
            this.notyService.displayNotification(error.error, "error");
          }
        }
      );
    } else if (this.itemType === "rent") {
      this.crudService
        .delete(OPN_BASE_URL + "/rent/delete", this.itemId)
        .subscribe((data: any) => {
          this.itemDeleted.emit();
        });
    } else if (this.itemType === "intervention") {
      this.crudService
        .delete(M_BASE_URL + "/intervention/delete", this.itemId)
        .subscribe(
          (data: any) => {
            this.itemDeleted.emit();
            this.notyService.displayNotification(
              this.getSuccessMessage("intervention"),
              "success"
            );
          },
          (error) => {
            console.error("Error deleting intervention:", error);
            if (error.status === 400) {
              this.notyService.displayNotification(error.error, "error");
            }
          }
        );
    } else if (this.itemType === "crud") {
      this.itemDeleted.emit();
    } else if (this.itemType === "Refuelling") {
      this.crudService
        .delete(BASE_API_ENERGY + "/refuelling", this.itemId)
        .subscribe((data) => {
          this.itemDeleted.emit();
          this.notyService.displayNotification(
            this.getSuccessMessage("Refuelling"),
            "success"
          );
        });
    }

    this.activeModal.close();
  }

  static getErrorMessageForKey(errorKey: string, langue: string): string {
    const ERROR_MESSAGES = {
      fr: {
        deleteStationError: "Erreur lors de la suppression d'une station car elle a des itinéraires associés",
        deleteServiceError: "Erreur lors de la suppression d'un service car il est associé à un voyage",
        deleteServicePeriodError: "Erreur lors de la suppression d'une période de service car elle a un service associé",
        deleteItineraryError: "Erreur lors de la suppression d'un itinéraire car il est déjà affecté à un voyage",
        defaultError: "Erreur de suppression",
          deleteRouteError: "Erreur lors de la suppression d'une route car elle est associée à des voyages.",
          deleteServiceGroupError: "Erreur lors de la suppression d'un groupe de services car il est associé à des voyages.",
          deleteAgencyError: "Erreur lors de la suppression d'une agence car elle a des lignes associées.",
          deleteCenterError: "Erreur lors de la suppression d'un centre car il a des agences associées.",
          deleteRouteTypeError: "Erreur lors de la suppression d'un type de route car il a des lignes associées.",
          deleteVehicleBrandError: "Erreur lors de la suppression d'une marque de véhicule car elle a des modèles associés.",
          deleteBusTypeError: "Erreur lors de la suppression d'un type de bus car il a des véhicules associés.",
          deleteVehicleError: "Erreur lors de la suppression d'un véhicule car il a des instances de voyage associées.",
          deleteVehicleFamilyError: "Erreur lors de la suppression d'une famille de véhicules car elle a des véhicules associés.",
          deleteVehicleModelError: "Erreur lors de la suppression d'un modèle de véhicule car il a des véhicules associés.",
          deleteStaffError: "Erreur lors de la suppression d'un personnel car il est associé à un voyage.",
          deleteStaffTypeError: "Erreur lors de la suppression d'un type de personnel car il a des personnels associés.",
          deleteInterventionError: "Erreur lors de la suppression d'une intervention car elle a des ordres de travail associés.",
          deleteInterventionCategoryError: "Erreur lors de la suppression d'une catégorie d'intervention car elle a des types de maintenance associés.",
          deleteInterventionTypeError: "Erreur lors de la suppression d'un type d'intervention car il a des interventions associées.",
          deleteMaintenanceTypeError: "Erreur lors de la suppression d'un type de maintenance car il a des tâches planifiées associées.",
          deleteWorkDiaryError: "Erreur lors de la suppression d'un journal de travail car il a des interventions associées.",
          deleteStandardError: "Erreur lors de la suppression d'une norme car elle a des véhicules associés.",
      },
      en: {
        deleteStationError: "Error deleting station because it has associated itineraries",
        deleteServiceError: "Error deleting service because it has an associated trip",
        deleteServicePeriodError: "Error deleting service period because it has an associated service",
        deleteItineraryError: "Error deleting itinerary because it is already assigned to a trip",
        defaultError: "Deletion error",
          deleteRouteError: "Error deleting route because it has associated trips.",
          deleteServiceGroupError: "Error deleting service group because it has associated trips.",
          deleteAgencyError: "Error deleting agency because it has associated routes.",
          deleteCenterError: "Error deleting center because it has associated agencies.",
          deleteRouteTypeError: "Error deleting route type because it has associated routes.",
          deleteVehicleBrandError: "Error deleting vehicle brand because it has associated vehicle models.",
          deleteBusTypeError: "Error deleting bus type because it has associated vehicles.",
          deleteVehicleError: "Error deleting vehicle because it has associated trip instances.",
          deleteVehicleFamilyError: "Error deleting vehicle family because it has associated vehicles.",
          deleteVehicleModelError: "Error deleting vehicle model because it has associated vehicles.",
          deleteStaffError: "Error deleting staff because they are associated with a trip.",
          deleteStaffTypeError: "Error deleting staff type because it has associated staff.",
          deleteInterventionError: "Error deleting intervention because it has associated work orders.",
          deleteInterventionCategoryError: "Error deleting intervention category because it has associated maintenance types.",
          deleteInterventionTypeError: "Error deleting intervention type because it has associated interventions.",
          deleteMaintenanceTypeError: "Error deleting maintenance type because it has associated scheduled tasks.",
          deleteWorkDiaryError: "Error deleting work diary because it has associated interventions.",
          deleteStandardError: "Error deleting standard because it has associated vehicles.",
      },
    };

    const messages = ERROR_MESSAGES[langue] || ERROR_MESSAGES.en;
    return messages[errorKey] || messages.defaultError;
  }

}
