import {Component, Input} from '@angular/core';
import {FormArray, FormControl} from '@angular/forms';

@Component({
  selector: 'app-error-handling',
  templateUrl: './error-handling.component.html',
  styleUrls: ['./error-handling.component.scss']
})
export class ErrorHandlingComponent {

  @Input() control: FormControl | FormArray;
  possibleErrors: { errorName: string, errorPlaceHolder: string }[];

  constructor() {
    this.possibleErrors = [
      {errorName: 'required', errorPlaceHolder: 'FIELD_REQUIRED'},
      {errorName: 'minlength', errorPlaceHolder: 'FIELD_MIN_LENGTH'},
      {errorName: 'min', errorPlaceHolder: 'FIELD_MIN'},
      {errorName: 'maxlength', errorPlaceHolder: 'FIELD_MAX_LENGTH'},
      {errorName: 'max', errorPlaceHolder: 'FIELD_MAX'},
      {errorName: 'email', errorPlaceHolder: 'EMAIL_FORMAT_REQUIRED'},
      {errorName: 'matchValueError', errorPlaceHolder: 'Confirm Password & Password must be same.'},
      {errorName: 'invalidUrl', errorPlaceHolder: 'FIELD_URL'},
      {errorName: 'mustMatch', errorPlaceHolder: 'must match'},
      {errorName: 'regex', errorPlaceHolder: 'FIELD_REGEX'},
    ];
  }
}
