import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import * as echarts from "echarts";
import { ICON_PATH, TEMPERATURE_ICON } from "./icon";
import { RealTimeTrackingService } from "../services/real-time-tracking.service";
// @ts-ignore
import L from "leaflet";
import { HttpClient, HttpParams } from "@angular/common/http";
import { RealTimeData } from "../vehicles-list-tracking/vehicles-list-tracking.component";
import { Vehicle } from "app/modules/maintenance-management/work-request/models/work-request";
import {OPN_BASE_URL} from 'app/shared/global/var';
import { CrudService } from "app/shared/services/crud.service";
import { TranslateService } from "@ngx-translate/core";
import { format } from "date-fns";
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {ZoomService} from '../../../shared/services/zoom.service';

@Component({
  selector: "app-vehicle-tracking",
  templateUrl: "./vehicle-tracking.component.html",
  styleUrls: ["./vehicle-tracking.component.scss"],
})
export class VehicleTrackingComponent implements OnInit, OnDestroy {
  private map = L.map;
  private markers: any[] = [];
  private routeLine: any;
  private charts: { [key: string]: echarts.ECharts } = {};
  //@ts-ignore
  routeLines: L.Polyline[] = []; // Array to store all route lines
  //@ts-ignore
  osrmRouteLines: L.Polyline[] = []; // Array to store all route lines
  lastKnownLocation: any;

  @Input() stations: any[] = [];
  @Input() isHistoric = true;
  @Input() startDate: string;
  @Input() endDate: string;
  @Input() vehicle: Vehicle;
  @Input() rt: RealTimeData;

  @Output() vehicleRt = new EventEmitter<RealTimeData>();

  rtData: any;
  driverName: string;

  constructor(
    private _elementRef: ElementRef,
    private crudService: CrudService,
    private http: HttpClient,
    private realTimeTrackingService: RealTimeTrackingService,
    private translate: TranslateService,
    private zoomService: ZoomService
  ) {}

  ngOnInit(): void {
    this.rtData = this.rt;
    if(this.rtData.vitesseInst === undefined){
        this.rtData.vitesseInst = 0;
    }else if (this.rtData.rpm === undefined){
        this.rtData.rpm = 0;
    }

    this.driverName = this.rt.driver;
    this.initMap();
    //  this.initGauges();
  }

  ngOnDestroy() {
    this.disposeCharts();
    this.markers.forEach((marker) => this.map.removeLayer(marker));
    this.routeLines.forEach((routeLine) => this.map.removeLayer(routeLine));
    this.osrmRouteLines.forEach((routeLine) => this.map.removeLayer(routeLine));
    this.markers = [];
  //  this.realTimeTrackingService.closeConnection();
  }

  private initGauges(): void {
    const gaugeNames = [
      "fuel-gauge",
      "speed-gauge",
      "rpm-gauge",
      "temperature-gauge",
    ];
    gaugeNames.forEach((name) => this.initGauge(name));
  }

  private initGauge(gaugeName: string): void {
    const el = document.getElementById(gaugeName);
    if (el) {
      switch (gaugeName) {
        case "fuel-gauge":
          this.initFuelGauge();
          break;
        case "speed-gauge":
          this.initSpeedGauge();
          break;
        case "rpm-gauge":
          this.initRpmGauge();
          break;
        case "temperature-gauge":
          this.initTemperatureGauge();
          break;
        default:
          break;
      }
    }
  }

  private disposeCharts(): void {
    Object.keys(this.charts).forEach((key) => {
      const chart = this.charts[key];
      if (chart && !chart.isDisposed()) {
        chart.dispose();
      }
    });
    this.charts = {};
  }

  updateValue(value: number, gaugeName: string): void {
    const chart = this.charts[gaugeName];
    if (chart && !chart.isDisposed()) {
      chart.setOption({
        series: [
          {
            data: [{ value: value }],
          },
        ],
      }); // Avoid resetting the chart, just update the value
    } else {
      console.warn(`Chart ${gaugeName} not found or disposed`);
      switch (gaugeName) {
        case "fuel-gauge":
          this.initFuelGauge();
          break;
        case "speed-gauge":
          this.initSpeedGauge();
          break;
        case "rpm-gauge":
          this.initRpmGauge();
          break;
        case "temperature-gauge":
          this.initTemperatureGauge();
          break;
      }
    }
  }

  initMap(): void {
    this.clearMap();
    const el = this._elementRef.nativeElement.querySelector(".rt-tracking-map");
    this.map = L.map(el, {
      center: [34.551117, 9.369019],
      zoom: 10,
      maxZoom: 16,
      minZoom: 5
    });
    L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
      attributionControl: false,
    }).addTo(this.map);
    if (this.stations.length > 0) {
      this.stations.sort((a, b) => a.stationOrder - b.stationOrder);
      for (const station of this.stations) {
        this.addMarkers(
          [station.station.lat, station.station.lon],
          station.station.name
        );
      }
    }
    if (!this.isHistoric) {
      this.getRealTimeData();
    }
  }

  addMarkers(latlng: L.LatLngExpression, stationName: string): void {
    const initialIconSize: [number, number] = [25, 41];
    const iconUrl = "./assets/img/leaflet/bus-stop.png";

    const marker = this.zoomService.createResizableIcon(this.map, latlng, iconUrl, stationName, initialIconSize);
    marker.bindPopup(stationName);
    this.markers.push(marker);
  }

  clearMap(): void {
    this.markers.forEach((marker) => this.map.removeLayer(marker));
    this.markers = [];
    if (this.routeLine) {
      this.map.removeLayer(this.routeLine);
      this.routeLine = null;
    }
    if (this.routeLines.length > 0) {
      this.routeLines.forEach((routeLine) => this.map.removeLayer(routeLine));
      this.routeLines = [];
    }

    if (this.osrmRouteLines.length > 0) {
      this.osrmRouteLines.forEach((routeLine) =>
        this.map.removeLayer(routeLine)
      );
      this.osrmRouteLines = [];
    }
  }

  addVehiclesMarker(latlng: any): void {
    const initialIconSize: [number, number] = [30, 41];
    const iconUrl = this.vehicle.family.bus ? './assets/img/markers/bus.png' : './assets/img/markers/car.png';

    const marker = this.zoomService.createResizableIcon(this.map, latlng, iconUrl, this.vehicle.vehicleNumber, initialIconSize);
    this.markers.push(marker);
  //  this.map.setView(markegetCurrentTrajetData()r.getLatLng(), 12);
    this.openPopup(this.rtData, marker);
  }

  getRealTimeData(): void {
    this.rtData = this.rt;
  
    this.addVehiclesMarker([this.rtData.lat, this.rtData.lon]);
    this.initGauges();
    if (this.vehicle.family.bus) {
      this.getCurrentTripData();
      //this.getCurrentTrajetData()
      this.getArchiveData();
    } else {
     // this.getCurrentTrajetData()
      this.getArchiveData();
    }
    this.getUpdatedData(this.vehicle.idBoitier);
  }

  getCurrentTripData(): void {
    const now = format(new Date(), "yyyy-MM-dd HH:mm:ss");
    this.crudService
      .getAllWithParams(
        OPN_BASE_URL + `/trips-instance/bus-trip/${this.vehicle.id}`,
        new HttpParams().set("date", now)
      )
      .subscribe(
        (res: any) => {
          if(!res) return
          this.getStopTime(res.id);
        },
        (error) => {
          // Handle error
          console.error("Error fetching trip data", error);
        }
      );
  }

  getCurrentTrajetData(): void {
    this.crudService
        .getOne(
            OPN_BASE_URL + '/rep-trajet/latest',
            String(this.vehicle.idBoitier)
        )
        .subscribe({
          next: (res: any) => {
            if (!res) return;

            // Parse dates properly
            const startDateTime = new Date(res?.id?.dateDepart);
            const endDateTime = new Date(res?.dateArrive);
            const currentDateTime = new Date();

            // Check if dates are valid
            if (isNaN(startDateTime.getTime()) || isNaN(endDateTime.getTime())) {
              console.error('Invalid date received');
              return;
            }

            // Calculate the difference in days
            const diffInDays = (currentDateTime.getTime() - endDateTime.getTime()) / (1000 * 60 * 60 * 24);
            // If difference is more than 1 day, use the original trip start/end dates
            // Otherwise use the current time as end date
            if (diffInDays > 1) {
              this.startDate = format(startDateTime, "yyyy-MM-dd'T'HH:mm:ss");
              this.endDate = format(endDateTime, "yyyy-MM-dd'T'HH:mm:ss");
            } else {
              this.startDate = format(endDateTime, "yyyy-MM-dd'T'HH:mm:ss");
              this.endDate = format(currentDateTime, "yyyy-MM-dd'T'HH:mm:ss");
            }

            // Format start date for API call
            const formattedStartDate = this.startDate.split('.')[0].replace("T", " ").replace("Z", "");
            const formattedEndDate = this.endDate.split('.')[0].replace("T", " ").replace("Z", "");

            this.getArchiveData();
          },
          error: (error: any) => {
            console.error('Error fetching latest trajet data:', error);
          }
        });
  }

  getArchiveData(): void {
    this.http
        .get(OPN_BASE_URL + `/rep-trajet/latest/${this.vehicle.idBoitier}`)
        .subscribe((res: any) => {
          const data = res;
          data.push(this.rtData);
          // Draw polyline between data points
          if (data.length > 0) {
            this.addTripStartMarker([res[0].lat, res[0].lon]);
            for (let i = 0; i < data.length - 1; i++) {
              const routeLine = L.polyline(
                  [
                    [data[i].lat, data[i].lon],
                    [data[i + 1].lat, data[i + 1].lon],
                  ],
                  {
                    color: "#1e90ff",
                    weight: 5,
                  }
              ).addTo(this.map);
              this.routeLines.push(routeLine);

              const marker = L.circleMarker([data[i].lat, data[i].lon], {
                radius: 2,
                color: "#ff4500",
                fillOpacity: 1,
              }).addTo(this.map);

              // Add click event listener to resolve address on click
              marker.on('click', () => {
                this.updatePopupContent(marker, data[i]);
              });

              this.markers.push(marker);
            }

            // Add marker for the last location in the data array
            const lastMarker = L.circleMarker(
                [data[data.length - 1].lat, data[data.length - 1].lon],
                {
                  radius: 2,
                  color: "#ff4500",
                  fillOpacity: 1,
                }
            ).addTo(this.map);

            lastMarker.on('click', () => {
              this.updatePopupContent(lastMarker, data[data.length - 1]);
            });

            this.markers.push(lastMarker);

            // Zoom in on the last known location
            this.map.setView(
                [data[data.length - 1].lat, data[data.length - 1].lon],
                12
            );
          }
        });
  }


  getStopTime(id): any {
    this.crudService
        .getAll(OPN_BASE_URL + `/stop-times/trips-instance/${id}`)
        .subscribe((r: any) => {
          r.map((itineraryStation: any) => {
            if(itineraryStation.rtArrivalTime) {
              this.stations.push(itineraryStation.itineraryStation.station);
            }
          })
          if (this.stations.length > 0) {
            this.stations.sort((a, b) => a.stationOrder - b.stationOrder);
            for (const station of this.stations) {
              this.addMarkers(
                  [station.lat, station.lon],
                  station.name
              );
            }
          }
        });
  }

  /**
   * Adds a marker to the map indicating the start of a trip.
   * The marker is customized with a "go" icon.
   *
   * @param {any} latlng - The latitude and longitude of the marker.
   */
    addTripStartMarker(latlng: any): void {
      const customIcon = L.icon({
        iconUrl: "./assets/img/markers/go.png",
        iconSize: [25, 25],
      });
      const marker = L.marker(latlng, { icon: customIcon }).addTo(this.map);
      this.markers.push(marker);
    }

  initFuelGauge(): void {
    if (document.getElementById("fuel-gauge")) {
      echarts.dispose(document.getElementById("fuel-gauge"));
    }
      const gauge = echarts.init(document.getElementById("fuel-gauge"));
      const option = {
        tooltip: {
          formatter: "{a} <br/>{b} : {c}%",
        },
        series: [
          {
            name: "Fuel",
            type: "gauge",
            startAngle: 180,
            endAngle: 0,
            center: ["50%", "75%"],
            radius: "90%",
            data: [
              {
                value: this.rtData.carLevel
                  ? this.rtData.carLevel
                  : this.rtData.car,
              },
            ],
            axisLine: {
              lineStyle: {
                width: 15,
                color: [
                  [0, "#FF4500"], // red
                  [0.25, "#FF4500"],
                  [0.5, "#FFD700"], // yellow
                  [1, "#32CD32"], // green
                ],
              },
            },
            splitLine: {
              length: 12,
              lineStyle: {
                color: "auto",
              },
            },
            axisLabel: {
              distance: 20,
              fontSize: 14,
              fontWeight: "bold",
              color: "#ffffff",
              fontFamily: "Arial",
              formatter: function (value: number) {
                return value % 20 === 0 ? value.toString() : "";
              },
            },
            axisTick: {
              splitNumber: 5,
              length: 8,
              lineStyle: {
                color: "auto",
              },
            },
            anchor: {
              show: true,
              itemStyle: {},
              offsetCenter: ["-10%", "-10%"],
              size: 20,
              icon: ICON_PATH,
            },
            pointer: {
              width: 5,
              length: "70%",
              color: "auto",
            },
            detail: {
              valueAnimation: true,
              formatter: "{value}%",

              fontSize: 16,
              color: "#f0f0f0",
              backgroundColor: "rgba(0,0,0,0.5)",
              borderColor: "#fff",
              borderWidth: 2,
              shadowColor: "#000",
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              offsetCenter: [0, "-100%"],
            },
          },
        ],
      };
      gauge.setOption(option);
      this.charts["fuel-gauge"] = gauge; // Store the instance in the class
  }

  initSpeedGauge(): void {
    if (document.getElementById("speed-gauge")) {
      echarts.dispose(document.getElementById("speed-gauge"));
    }
    const gauge = echarts.init(document.getElementById("speed-gauge"));
    const option = {
      tooltip: {
        formatter: "{a} <br/>{b} : {c} km/h",
      },
      series: [
        {
          name: "Speed",
          type: "gauge",
          startAngle: 180,
          endAngle: 0,
          center: ["50%", "75%"],
          radius: "90%",
          min: 0,
          max: 220,
          splitNumber: 11, // Adjusting to show more scale labels
          data: [
            {
              value: this.rtData.vitesse
                ? this.rtData.vitesse
                : this.rtData.vitesseInst,
            },
          ],
          axisLine: {
            lineStyle: {
              width: 15,
              color: [
                [0.5, "#1e90ff"], // Blue for 0-110
                [0.727, "#f7b733"], // Yellow for 110-160
                [1, "#ff4500"], // Red for 160-220
              ],
            },
          },
          axisTick: {
            splitNumber: 5,
            length: 8,
            lineStyle: {
              color: "auto",
            },
          },
          splitLine: {
            length: 12,
            lineStyle: {
              color: "auto",
            },
          },
          axisLabel: {
            distance: 20, // Distance of labels from the gauge
            fontSize: 14, // Font size of labels
            fontWeight: "bold", // Font weight
            color: "#ffffff", // Color of labels
            fontFamily: "Arial", // Font family
            formatter: function (value: number) {
              if (value % 40 === 0) {
                return value.toString();
              }
              return "";
            },
          },
          pointer: {
            width: 5,
            length: "70%",
            color: "auto",
          },
          anchor: {
            show: true,
            itemStyle: {},
            offsetCenter: ["-10%", "-10%"],
            size: 20,
            icon: "",
          },
          title: {
            offsetCenter: [0, "110%"],
            fontSize: 20,
            color: "#fff",
          },
          detail: {
            valueAnimation: true,
            formatter: "{value} km/h",
            fontSize: 16,
            color: "#f0f0f0", // Lighter color for a brighter effect
            backgroundColor: "rgba(0,0,0,0.5)",
            borderColor: "#fff", // White border color for contrast
            borderWidth: 2, // Border width to add some emphasis
            shadowColor: "#000", // Shadow color to add depth
            shadowBlur: 10, // Blur radius for the shadow
            shadowOffsetX: 0, // Horizontal shadow offset
            shadowOffsetY: 0, // Vertical shadow offset
            offsetCenter: [0, "-100%"],
          },
        },
      ],
    };
    gauge.setOption(option, { notMerge: true, lazyUpdate: false });
    this.charts["speed-gauge"] = gauge;
  }

  // rpm gauge
  initRpmGauge(): void {
    if (document.getElementById("rpm-gauge")) {
      echarts.dispose(document.getElementById("rpm-gauge"));
    }
    const gauge = echarts.init(document.getElementById("rpm-gauge"));
    const option = {
      tooltip: {
        formatter: "{a} <br/>{b} : {c} rpm",
      },
      series: [
        {
          name: "RPM",
          type: "gauge",
          startAngle: 90,
          endAngle: 0,
          center: ["50%", "75%"],
          radius: "90%",
          min: 0,
          max: 8000,
          splitNumber: 8, // Adjusting to show more scale labels
          data: [
            { value: this.rtData.RPM ? this.rtData.RPM : this.rtData.rpm },
          ],
          axisLine: {
            lineStyle: {
              width: 15,
              color: [
                // one color for all ranges = grey
                [1, "#808080"],
              ],
            },
          },
          axisTick: {
            splitNumber: 5,
            length: 8,
            lineStyle: {
              color: "auto",
            },
          },
          splitLine: {
            length: 12,
            lineStyle: {
              color: "auto",
            },
          },
          axisLabel: {
            distance: 20, // Distance of labels from the gauge
            fontSize: 14, // Font size of labels
            fontWeight: "bold", // Font weight
            color: "#ffffff", // Color of labels
            fontFamily: "Arial", // Font family
            formatter: function (value: number) {
              if (value % 4000 === 0) {
                return value.toString();
              }
              return "";
            },
          },
          pointer: {
            width: 5,
            length: "70%",
            color: "auto",
          },
          anchor: {
            show: true,
            itemStyle: {},
            offsetCenter: ["-10%", "-10%"],
            size: 20,
            icon: "",
          },
          title: {
            offsetCenter: [0, "110%"],
            fontSize: 20,
            color: "#fff",
          },
          detail: {
            valueAnimation: true,
            width: "20%",
            lineHeight: 50,
            borderRadius: 8,
            offsetCenter: ["100%", "12%"],
            fontSize: 14,
            fontWeight: "bolder",
            formatter: "{value} rpm",
            color: "#f0f0f0", // Lighter color for a brighter effect
          },
        },
      ],
    };
    gauge.setOption(option);
    this.charts["rpm-gauge"] = gauge;
  }

  // temperature gauge
  initTemperatureGauge(): void {
    if (document.getElementById("temperature-gauge")) {
      echarts.dispose(document.getElementById("temperature-gauge"));
    }
    const gauge = echarts.init(document.getElementById("temperature-gauge"));
    const option = {
      tooltip: {
        formatter: "{a} <br/>{b} : {c} °C",
      },
      series: [
        {
          name: "Temperature",
          type: "gauge",
          startAngle: -180,
          endAngle: 90,
          center: ["50%", "75%"],
          radius: "90%",
          min: 50,
          max: 150,
          splitNumber: 8, // Adjusting to show more scale labels
          data: [{ value: this.rtData.T1 ? this.rtData.T1 : this.rtData.temp }],
          axisLine: {
            lineStyle: {
              width: 15,
              color: [
                // blue for 0-90
                [0.5, "#1e90ff"],
                [1, "#FF4500"], // Red for 110-150
              ],
            },
          },
          axisTick: {
            splitNumber: 5,
            length: 8,
            lineStyle: {
              color: "auto",
            },
          },
          splitLine: {
            length: 12,
            lineStyle: {
              color: "auto",
            },
          },
          axisLabel: {
            distance: 20, // Distance of labels from the gauge
            fontSize: 14, // Font size of labels
            fontWeight: "bold", // Font weight
            color: "#ffffff", // Color of labels
            fontFamily: "Arial", // Font family
            formatter: function (value: number) {
              if (value % 50 === 0) {
                return value.toString();
              }
              return "";
            },
          },
          pointer: {
            width: 5,
            length: "70%",
            color: "auto",
          },
          anchor: {
            show: true,
            itemStyle: {},
            offsetCenter: ["-10%", "-10%"],
            size: 20,
            icon: TEMPERATURE_ICON,
          },
          title: {
            offsetCenter: [0, "110%"],
            fontSize: 20,
            color: "#ffffff",
          },
          detail: {
            valueAnimation: true,
            width: "20%",
            lineHeight: 50,
            borderRadius: 8,
            offsetCenter: ["-100%", "12%"],
            fontSize: 14,
            fontWeight: "bolder",
            formatter: "{value} °C",
            color: "#f0f0f0", // Lighter color for a brighter effect
          },
        },
      ],
    };
    gauge.setOption(option);
    this.charts["temperature-gauge"] = gauge;
  }

  getUpdatedData(idBoitier: number): void {
    this.realTimeTrackingService.connect();
    this.realTimeTrackingService.message$.subscribe((message) => {
      this.handleMessage(message);
    });
  }

  handleMessage(message: any): void {
    const rt = JSON.parse(JSON.stringify(message));
    this.vehicleRt.emit(message);
    if (rt.id === this.vehicle.idBoitier) {
      this.rtData = rt;
      let speed = this.rtData.vitesseInst;
      if (speed === undefined) {
        speed = this.rtData.vitesse ? this.rtData.vitesse : 0;
      }
      const value = this.rtData.carLevel ? this.rtData.carLevel: this.rtData.car;
      let rpm = this.rtData.RPM;
      if (rpm === undefined) {
        rpm = this.rtData.rpm ? this.rtData.rpm : 0;
      }
      this.updateValue(speed, "speed-gauge");
      this.updateValue(value, "fuel-gauge");
      this.updateValue(rpm, "rpm-gauge");
      this.updateValue(this.rtData.temp, "temperature-gauge");
      this.vehicle.location = this.rtData.localite;
      this.updateVehicleMarker(this.rtData);
    }
  }

  updateVehicleMarker(rtData: RealTimeData): void {
    this.lastKnownLocation = {
      lat: this.markers[0]?.getLatLng().lat,
      lon: this.markers[0]?.getLatLng().lng,
    };

    this.markers[0]?.setLatLng([rtData.lat, rtData.lon]);

    this.map.setView([rtData.lat, rtData.lon], this.map.getZoom());
   

    const newLatLng = L.latLng(rtData.lat, rtData.lon);
    const circleMarker = L.circleMarker(newLatLng, {
      radius: 2,
      color: "#ff4500",
      fillOpacity: 1,
    }).addTo(this.map);

    const newCoords = { lat: rtData.lat, lon: rtData.lon };
    const routeLine = L.polyline(
      [
        [this.lastKnownLocation.lat, this.lastKnownLocation.lon],
        [newCoords.lat, newCoords.lon],
      ],
      {
        color: "#1e90ff",
        weight: 5,
      }
    ).addTo(this.map);

    this.routeLines.push(routeLine);

    this.updatePopupContent(circleMarker, rtData);

    this.updatePopupContent(this.markers[0], rtData);
  }

  openPopup(rtData: RealTimeData, marker: any): void {
      if (!rtData.rpm) {
        rtData.rpm = 0;
      }
      if(!rtData.car){
        rtData.car = rtData.carLevel ? rtData.carLevel : 0;
      }
    if(!rtData.vitesseInst){
      rtData.vitesseInst = rtData.vitesse;
    }
    let speed = this.rtData.vitesseInst;
    if (speed === undefined) {
      speed = this.rtData.vitesse ? this.rtData.vitesse : 0;
      this.rt.vitesseInst = speed;
    }
    // Fetch all translations at once
    this.translate
        .get([
          "Track",
          "DATE",
          "Speed",
          "Temp",
          "RPM",
          "Fuel_Level",
          "Distance",
          "Lat",
          "Lon",
          "Location",
          "Driver"
        ])
        .subscribe((translations: any) => {
          const driverContent = this.driverName != undefined  && this.driverName !== '/'
              ? `<div style="display: flex; justify-content: space-between;">
             <span><strong>${translations["Driver"]}:</strong></span>
             <span>${this.driverName}</span>
           </div>`
              : '';

          const popupContent = `
        <div class="popup-container d-flex flex-column">
            <h3 class="bg-light-info flex-grow-1 font-medium-2 p-2 rounded text-center vehicle-number">
                <i class="fas fa-bus text-info mr-1"></i>${this.vehicle.vehicleNumber}
            </h3>
            <div class="vehicle-stats font-small-3">
                <div style="display: flex; justify-content: space-between;">
                    <span><strong>${translations["DATE"]}:</strong></span>
                    <span>${rtData.date.replace("T", " ").replace("Z", "")}</span>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <span><strong>${translations["Speed"]}:</strong></span>
                    <span>${rtData.vitesseInst} km/h</span>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <span><strong>${translations["Temp"]}:</strong></span>
                    <span>${rtData.temp} °C</span>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <span><strong>${translations["RPM"]}:</strong></span>
                    <span>${rtData.rpm}</span>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <span><strong>${translations["Fuel_Level"]}:</strong></span>
                    <span>${rtData.car} %</span>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <span><strong>${translations["Distance"]}:</strong></span>
                    <span>${rtData.dist} km</span>
                </div>
                    <div style="display: flex; justify-content: space-between;">
                                    <span><strong>${
                                      translations["Lat"]
                                    }:</strong></span>
                                    <span>${rtData.lat}</span>
                                </div>
                                    <div style="display: flex; justify-content: space-between;">
                                    <span><strong>${
                                      translations["Lon"]
                                    }:</strong></span>
                                    <span>${rtData.lon}</span>
                                </div>
                <div style="display: flex; justify-content: space-between;">
                    <span><strong>${translations["Location"]}:</strong></span>
                    <span>${rtData.localite}</span>
                </div>
                ${driverContent}
            </div>
        </div>
      `;

          marker.bindPopup(popupContent);
          marker.openPopup();
        });
  }

  updatePopupContent(marker: any, rtData: RealTimeData): void {
    if(!rtData.localite){
      this.getAddress(rtData).subscribe(
        (address) => {
          rtData.localite = address;
          this.updatePopUpData(marker, rtData);
        }
      )
    }else {
      this.updatePopUpData(marker, rtData);
    }

  }

  updatePopUpData(marker: any, rtData: RealTimeData): void {
    if(!rtData.vitesseInst){
      rtData.vitesseInst = rtData.vitesse;
    }
    if(!rtData.rpm){
      rtData.rpm = 0;
    }
    if(!rtData.car){
      rtData.car = rtData.carLevel ? rtData.carLevel : 0;
    }
    this.translate
      .get([
        "Track",
        "DATE",
        "Speed",
        "Temp",
        "RPM",
        "Fuel_Level",
        "Distance",
        "Lat",
        "Lon",
        "Location",
        "Driver"
      ])
      .subscribe((translations: any) => {
        const driverContent = this.driverName != undefined &&this.driverName !== '/'
            ? `<div style="display: flex; justify-content: space-between;">
             <span><strong>${translations["Driver"]}:</strong></span>
             <span>${this.driverName}</span>
           </div>`
            : '';
        const popupContent = `
                          <div class="popup-container d-flex flex-column">
                            <h3 class="bg-light-info flex-grow-1 font-medium-2 p-2 rounded text-center vehicle-number">
                                <i class="fas fa-bus text-info mr-1"></i>${
                                  this.vehicle.vehicleNumber
                                }
                            </h3>
                            <div class="vehicle-stats font-small-3">
                                <div style="display: flex; justify-content: space-between;">
                                    <span><strong>${
                                      translations["DATE"]
                                    }:</strong></span>
                                    <span>${rtData.date
                                      .replace("T", " ")
                                      .replace("Z", "")}</span>
                                </div>
                                <div style="display: flex; justify-content: space-between;">
                                    <span><strong>${
                                      translations["Speed"]
                                    }:</strong></span>
                                    <span>${rtData.vitesseInst} km/h</span>
                                </div>
                                <div style="display: flex; justify-content: space-between;">
                                    <span><strong>${
                                      translations["Temp"]
                                    }:</strong></span>
                                    <span>${rtData.temp} °C</span>
                                </div>
                                <div style="display: flex; justify-content: space-between;">
                                    <span><strong>${
                                      translations["RPM"]
                                    }:</strong></span>
                                    <span>${rtData.rpm}</span>
                                </div>
                                <div style="display: flex; justify-content: space-between;">
                                    <span><strong>${
                                      translations["Fuel_Level"]
                                    }:</strong></span>
                                    <span>${rtData.car} %</span>
                                </div>
                                <div style="display: flex; justify-content: space-between;">
                                    <span><strong>${
                                      translations["Distance"]
                                    }:</strong></span>
                                    <span>${rtData.dist} km</span>
                                </div>
                                   <div style="display: flex; justify-content: space-between;">
                                    <span><strong>${
                                      translations["Lat"]
                                    }:</strong></span>
                                    <span>${rtData.lat}</span>
                                </div>
                                    <div style="display: flex; justify-content: space-between;">
                                    <span><strong>${
                                      translations["Lon"]
                                    }:</strong></span>
                                    <span>${rtData.lon}</span>
                                </div>
                                <div style="display: flex; justify-content: space-between;">
                                    <span><strong>${
                                      translations["Location"]
                                    }:</strong></span>
                                    <span>${rtData.localite}</span>
                                </div>
                                 ${driverContent}
                            </div>
                        </div>
                        `;
        marker.bindPopup(popupContent);
        marker.openPopup();
        /*if (marker.isPopupOpen()) {
          marker.openPopup();
        }*/
      });
  }

  getAddress(rtData: RealTimeData): Observable<string> {
    const lat = rtData.lat.toString(10).replace(',', '.');
    const lon = rtData.lon.toString(10).replace(',', '.');

    const params = new HttpParams()
        .set('format', 'json')
        .set('zoom', '18')
        .set('addressdetails', '1')
        .set('accept-language', 'fr')
        .set('lat', lat)
        .set('lon', lon);

    return this.http.get<any>('/reverse', { params }).pipe(
        map((res) => {
          let address = '';
          if(res.address?.road) {
            address += res.address.road;
          }
          if(res.address?.village) {
            address +=  ', ' +res.address.village;
          }
          if(res.address?.suburb) {
            address += ', ' + res.address.suburb;
          }
          if(res.address?.town) {
            address += ', ' + res.address.town;
          }
          return address;
        }),
        catchError(() => of('Address not found')) // Handle potential errors gracefully.
    );
  }
}
