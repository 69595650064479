<div class="modal-body text-center">
    <div class="checkmark">
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24">
            <path class="path" fill="none" stroke="#00b894" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" d="M2 12 l6 6 l14 -14" />
        </svg>
    </div>
    <p>{{ successMessage }}</p>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="activeModal.close()">{{"Close"|translate}}</button>
</div>