import { Injectable } from '@angular/core';
import L from "leaflet";

@Injectable({
  providedIn: 'root'
})
export class ZoomService {

  constructor() { }

  // Method to create and resize icons based on the zoom level
  createResizableIcon(map: L.Map, latlng: L.LatLngExpression, iconUrl: string, label = '', initialIconSize: [number, number]): L.Marker {
    const customIcon = L.icon({
      iconUrl: iconUrl,
      iconSize: initialIconSize,
      text: label,
    });

    const marker = L.marker(latlng, { icon: customIcon }).addTo(map);

    const updateIconSize = () => {
      const zoom = map.getZoom();
      const scale = Math.pow(2, (zoom - 10) / 5);
      const newSize: [number, number] = [initialIconSize[0] * scale, initialIconSize[1] * scale];
      const newIcon = L.icon({
        iconUrl: iconUrl,
        iconSize: newSize,
        text: label
      });
      marker.setIcon(newIcon);
    };

    // Listen for zoom changes to update the icon size
    map.on('zoom', updateIconSize);
    updateIconSize(); // Initial update based on the current zoom

    return marker;
  }
}
