import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
@Component({
  selector: "app-confirmation-modal",
  templateUrl: "./confirmation-modal.component.html",
  styleUrls: ["./confirmation-modal.component.scss"],
})
export class ConfirmationModalComponent implements OnInit {
  @Input() title: string = "Confirm Action";
  @Input() message: string = "Are you sure you want to proceed?";
  @Input() confirmText: string = "Confirm";
  @Input() cancelText: string = "Cancel";
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}
}
