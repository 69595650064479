import { CrudService } from "app/shared/services/crud.service";
import { Component, Input, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  BASE_API_ENERGY,
  BASE_API_FLEET,
  BASE_API_STAFF,
  M_BASE_API,
} from "app/shared/global/var";
import { Vehicle, WorkRequest } from "../../models/work-request";
import { AuthService } from "app/shared/auth/auth.service";
import { NotyService } from "app/shared/services/noty.service";
import { WorkRequestService } from "../../services/work-request.service";
import { ImageModalComponent } from "../image-modal/image-modal.component";
import { VehiclePositionComponent } from "../vehicle-position/vehicle-position.component";
import { RealTimeMapComponent } from "app/modules/real-time-tracking/real-time-map/real-time-map.component";
import { ConfirmationModalComponent } from "app/shared/modals/confirmation-modal/confirmation-modal.component";
const MAX_IMAGE_UPLOAD_LIMIT = 3; // Maximum number of images allowed
const MAX_TOTAL_IMAGE_SIZE_MB = 20; // Maximum total size of all images
const MAX_SINGLE_IMAGE_SIZE_MB = 10; // Maximum size of a single image
interface Items {
  id: number;
  name: string;
}
@Component({
  selector: "app-add-work-request",
  templateUrl: "./add-work-request.component.html",
  styleUrls: [
    "./add-work-request.component.scss",
    "../../../../../../assets/sass/libs/select.scss",
  ],
})
export class AddWorkRequestComponent implements OnInit {
  @Input() type: string;
  @Input() data: WorkRequest;
  applicants: Items[] = [];
  vehicleType: Items[] = [];
  vehicles: Items[] = [];
  appointments: any[] = []; // Add this line for appointments

  selectedApplicant: any;
  selectedType: any = null;
  selectedFiles: any[] = [];
  notValid: boolean = false;
  isError: boolean = false;
  enPanne: boolean = false;
  formGroup: FormGroup;
  connectedUserFullName: string;
  enlargedImageSrc: string;
  maxImageUploadLimit = MAX_IMAGE_UPLOAD_LIMIT;
  maxTotalImageSizeMB = MAX_TOTAL_IMAGE_SIZE_MB;
  maxSingleImageSizeMB = MAX_SINGLE_IMAGE_SIZE_MB;
  errorMessage: string = "";
  allVehicles: any;
  langue = localStorage.getItem("langue");
  vehicleDetails: any;
  constructor(
    public activeModal: NgbActiveModal,
    private notyService: NotyService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private crudService: CrudService,
    private authService: AuthService,
    private workRequestService: WorkRequestService
  ) {}

  ngOnInit(): void {
    this.fetchVehicleTypes();
    this.connectedUserFullName = this.authService.getUserFullNameFromToken();
    const now = new Date();
    const timezoneOffset = now.getTimezoneOffset() * 60000;
    const today = now.toISOString().substring(0, 10);
    const localDate = new Date(now.getTime() - timezoneOffset).toISOString().substring(0, 16);
    this.formGroup = this.formBuilder.group({
      requestDate: [localDate, Validators.required],
      requestType: ["", Validators.required],
      description: ["", Validators.required],
      urgency: [null, Validators.required],
      applicant: [this.connectedUserFullName, Validators.required],
      vehicleTypeId: [null, Validators.required],
      vehiclesId: [{ value: null, disabled: true }, Validators.required],
      files: this.formBuilder.array([]),
      state: [1, Validators.required], // Default state
      appointment: [today], // Default appointment date set to today
    });

    if (this.type === "update" && this.data) {
      this.loadDataIntoForm();
    }
  }

  loadDataIntoForm(): void {
    const formatDate = (datetime: string | Date) => {
      const date = new Date(datetime);
      const userTimezoneOffset = date.getTimezoneOffset() * 60000;
      return new Date(date.getTime() - userTimezoneOffset)
        .toISOString()
        .split("T")[0];
    };

    const formatDatetime = (datetime: string) => {
      if (!datetime) return "";
      // Forcer l'interprétation en UTC
      const date = new Date(Date.parse(datetime));
      return date.toISOString().slice(0, 16);
    };

    const formattedRequestDate = formatDatetime(this.data.requestDate);
    const formattedAppointmentDate = formatDate(this.data.appointment);

    // First, fetch the vehicle details
    this.crudService
      .getOne<any>(
        BASE_API_FLEET + "/vehicles",
        this.data.vehiclesId.toString()
      )
      .subscribe((vehicleData) => {
        this.vehicleDetails = vehicleData;
        this.enPanne = this.vehicleDetails.status === 3;

        // Set the vehicle type based on the vehicle's model
        const vehicleTypeId = this.vehicleDetails.model.id;

        // Populate the form with initial data
        this.formGroup.patchValue({
          requestDate: formattedRequestDate,
          requestType: this.data.requestType,
          description: this.data.description,
          urgency: this.data.urgency,
          applicant: this.data.applicant,
          vehicleTypeId: vehicleTypeId,
          state: this.data.state,
          appointment: formattedAppointmentDate,
        });

        // Fetch vehicles for this vehicle type
        const username = this.authService.getUsernameFromToken();
        this.fetchVehicles(vehicleTypeId, username);

        // After a short delay to ensure vehicles are fetched, set the specific vehicle
        this.formGroup.patchValue({
          vehiclesId: this.data.vehiclesId,
        });
      });

    // Handle file upload form array
    const filesArray = this.formGroup.get("files") as FormArray;
    filesArray.clear();
    this.data.files.forEach((file) => {
      filesArray.push(this.formBuilder.control(file));
    });

    // Disable the form if state > 1, but keep appointment field enabled
    if (this.data.state > 1) {
      this.formGroup.disable();
      this.formGroup.get("appointment").enable();
      this.formGroup.get("files").disable();
    }
  }

  isImageFile(file: any): boolean {
    return file.type.startsWith("image/");
  }

  onFileSelected(event: any): void {
    const files: FileList = event.target.files;
    const filesArray = this.formGroup.get("files") as FormArray;

    // Reset error messages
    this.isError = false;
    this.errorMessage = "";

    // Check total number of files
    if (filesArray.length + files.length > MAX_IMAGE_UPLOAD_LIMIT) {
      this.isError = true;
      this.errorMessage =
        this.langue === "fr"
          ? `Vous ne pouvez télécharger que ${MAX_IMAGE_UPLOAD_LIMIT} images maximum.`
          : `You can upload a maximum of ${MAX_IMAGE_UPLOAD_LIMIT} images.`;
      return;
    }

    // Calculate current total image size
    let currentTotalSize = this.calculateCurrentTotalImageSize(filesArray);
    let newFilesToAdd: string[] = [];

    // First, validate all files
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      // Check individual file size
      const fileSizeMB = file.size / 1024 / 1024;
      if (fileSizeMB > MAX_SINGLE_IMAGE_SIZE_MB) {
        this.isError = true;
        this.errorMessage =
          this.langue === "fr"
            ? `La taille d'un image ne doit pas dépasser ${MAX_SINGLE_IMAGE_SIZE_MB} Mo.`
            : `Individual image size should not exceed ${MAX_SINGLE_IMAGE_SIZE_MB} MB.`;
        return;
      }

      // Check total image size including new files
      if (currentTotalSize + fileSizeMB > MAX_TOTAL_IMAGE_SIZE_MB) {
        this.isError = true;
        this.errorMessage =
          this.langue === "fr"
            ? `La taille totale des images ne doit pas dépasser ${MAX_TOTAL_IMAGE_SIZE_MB} Mo.`
            : `Total image size should not exceed ${MAX_TOTAL_IMAGE_SIZE_MB} MB.`;
        return;
      }

      if (!this.isImageFile(file)) {
        this.isError = true;
        this.errorMessage =
          this.langue === "fr"
            ? "Veuillez sélectionner uniquement des images."
            : "Please select only images.";
        return;
      }

      // Prepare to add file
      const reader = new FileReader();
      reader.onload = (e: any) => {
        newFilesToAdd.push(e.target.result);

        // Only add files when all have been processed
        if (newFilesToAdd.length === files.length) {
          newFilesToAdd.forEach((fileData) => {
            filesArray.push(this.formBuilder.control(fileData));
          });
        }
      };
      reader.readAsDataURL(file);

      // Update current total size for subsequent checks
      currentTotalSize += fileSizeMB;
    }
  }

  // Helper method to calculate current total image size
  calculateCurrentTotalImageSize(filesArray: FormArray): number {
    return filesArray.controls
      .map((control) => {
        // If it's a base64 string, calculate size
        if (
          typeof control.value === "string" &&
          control.value.startsWith("data:image")
        ) {
          // Remove the data URL prefix to get the base64 string
          const base64String = control.value.split(",")[1];
          const sizeInBytes = (base64String.length * 3) / 4;
          return sizeInBytes / 1024 / 1024; // Convert to MB
        }
        return 0;
      })
      .reduce((a, b) => a + b, 0);
  }

  validateWorkRequest(): void {
    this.workRequestService.changeState(this.data.id, 2).subscribe(
      (response) => {
        const successMessage =
          this.langue === "fr"
            ? "Demande de travail validée"
            : "Work request validated";
        this.notyService.displayNotification(successMessage, "success");
        this.activeModal.close("ok");
      },
      (error) => {
        const errorMessage =
          this.langue === "fr"
            ? "Échec de la validation de la demande de travail"
            : "Failed to validate work request";
        this.notyService.displayNotification(errorMessage, "error");
      }
    );
  }

  rejectWorkRequest(): void {
    // Open confirmation modal
    const modalRef = this.modalService.open(ConfirmationModalComponent);
    
    // Customize the modal text based on language
    modalRef.componentInstance.title = this.langue === 'fr' 
      ? 'Confirmer le rejet' 
      : 'Confirm Rejection';
    
    modalRef.componentInstance.message = this.langue === 'fr'
      ? 'Êtes-vous sûr de vouloir rejeter cette demande de travail ?'
      : 'Are you sure you want to reject this work request?';
    
    modalRef.componentInstance.confirmText = this.langue === 'fr'
      ? 'Rejeter'
      : 'Reject';
    
    modalRef.componentInstance.cancelText = this.langue === 'fr'
      ? 'Annuler'
      : 'Cancel';
  
    // Handle the modal result
    modalRef.result.then(
      (result) => {
        // User confirmed, proceed with rejection
        if (result === 'confirm') {
          this.workRequestService.changeState(this.data.id, 3).subscribe(
            (response) => {
              const successMessage = this.langue === 'fr'
                ? 'Demande de travail rejetée'
                : 'Work request rejected';
              this.notyService.displayNotification(successMessage, 'success');
              this.activeModal.close('ok');
            },
            (error) => {
              const errorMessage = this.langue === 'fr'
                ? 'Échec du rejet de la demande de travail'
                : 'Failed to reject work request';
              this.notyService.displayNotification(errorMessage, 'error');
            }
          );
        }
      },
      (dismiss) => {
        // Modal dismissed, do nothing
      }
    );
  }

  removeFile(index: number) {
    const filesArray = this.formGroup.get("files") as FormArray;
    if (index > -1 && index < filesArray.length) {
      filesArray.removeAt(index);
    }
  }

  openImageModal(imageSrc: string) {
    const modalRef = this.modalService.open(ImageModalComponent, {
      size: "lg",
    });
    modalRef.componentInstance.imageSrc = imageSrc;
  }

  openMap() {
    const modalRef = this.modalService.open(VehiclePositionComponent, {
      size: "lg",
      centered: true,
    });
    // Only pass the vehicle when one is selected
    if (this.formGroup.get("vehiclesId").value) {
      const selectedVehicleId = this.formGroup.get("vehiclesId").value;
      const selectedVehicle = this.allVehicles.find(
        (v) => v.id === selectedVehicleId
      );

      if (selectedVehicle) {
        modalRef.componentInstance.vehicle = selectedVehicle;
      }
      const requestDate = this.formGroup.get("requestDate").value;
      modalRef.componentInstance.dateDepart =
        this.calculateDateRange(requestDate).startDate;
      modalRef.componentInstance.dateArrivee =
        this.calculateDateRange(requestDate).endDate;
      modalRef.componentInstance.requestDate = requestDate;
    }
  }
  calculateDateRange(requestDate: any) {
    if (requestDate) {
      // Convert request date to Date object
      const requestDateTime = new Date(requestDate);

      // Calculate start date (request date - 1 hour)
      const startDateTime = new Date(
        requestDateTime.getTime() - 60 * 60 * 1000
      );
      return {
        startDate: startDateTime.toISOString(),
        endDate: requestDateTime.toISOString(),
      };
    }
  }

  submitForm() {
    if (this.formGroup.valid) {
      let url = M_BASE_API + "/workRequest";
      const formData = this.formGroup.value;

      if (this.type === "update") {
        if (
          this.formGroup.get("state").value >= 2 &&
          this.formGroup.get("appointment").value
        ) {
          this.workRequestService
            .updateAppointment(
              this.data.id,
              this.formGroup.get("appointment").value,
              this.enPanne
            )
            .subscribe(
              (response) => {
                const successMessage =
                  this.langue === "fr"
                    ? "Rendez-vous mis à jour et bon de travail créé"
                    : "Appointment updated and work order created";
                this.notyService.displayNotification(successMessage, "success");
                this.activeModal.close("ok");
              },
              (error) => {
                const errorMessage =
                  this.langue === "fr"
                    ? "Échec de la mise à jour du rendez-vous"
                    : "Failed to update appointment";
                this.notyService.displayNotification(errorMessage, "error");
              }
            );
        } else {
          url += "/update";
          this.crudService
            .update(url, this.data.id.toString(), formData)
            .subscribe(
              (response) => {
                this.activeModal.close("ok");
                this.notValid = false;
                const successMessage =
                  this.langue === "fr"
                    ? "Demande de travail mise à jour avec succès"
                    : "Work request updated successfully";
                this.notyService.displayNotification(successMessage, "success");
              },
              (error) => {
                console.error("Error updating data:", error);

                const errorMessage =
                  this.langue === "fr"
                    ? "Échec de la mise à jour"
                    : "Failed Update";
                this.notyService.displayNotification(errorMessage, "error");
              }
            );
        }
      } else {
        url += "/add";
        this.crudService.post(url, formData).subscribe(
          (response) => {
            this.activeModal.close("ok");
            this.notValid = false;
            const successMessage =
              this.langue === "fr"
                ? "Demande de travail créée avec succès"
                : "Work request created successfully";
            this.notyService.displayNotification(successMessage, "success");
          },
          (error) => {
            console.error("Error posting data:", error);
            // this.notyService.displayNotification("Failed add", "error");
            const errorMessage =
              this.langue === "fr" ? "Échec de l'ajout" : "Failed add";
            this.notyService.displayNotification(errorMessage, "error");
            // Handle error
          }
        );
      }
    } else {
      this.notValid = true;
    }
  }

  fetchVehicleTypes() {
    const url = BASE_API_FLEET + "/vehicle-models";
    this.crudService.getAll(url).subscribe(
      (data: any) => {
        this.vehicleType = data.data.map((item) => ({
          id: item.id,
          name: `${item.vehicleBrand.brandName}-${item.name}`,
        }));
      },
      (error) => {
        console.error("Error fetching vehicle types:", error);
      }
    );
  }

  onVehicleTypeChange(selectedVehicleType: any): void {
    // Clear the current list of vehicles
    console.log("selectedVehicleTypeId", selectedVehicleType);
    this.vehicles = [];
    const username = this.authService.getUsernameFromToken();
    // Fetch the vehicles based on the selected vehicle type
    if (selectedVehicleType) {
      this.fetchVehicles(selectedVehicleType.id, username);
    } else {
      this.vehicles = []; // Clear vehicles if no type is selected
      this.formGroup.get("vehiclesId")?.disable(); // Disable vehicles select
    }
  }

  fetchVehicles(vehicleTypeId: number, username: string): void {
    const url = `${BASE_API_FLEET}/vehicles/type/${vehicleTypeId}?username=${encodeURIComponent(
      username
    )}`;

    // Call the service to fetch vehicles based on the selected vehicle type
    this.crudService.getAll(url).subscribe(
      (data: any) => {
        this.vehicles = data.map((item) => ({
          id: item.id,
          name:
            item.vehicleNumber +
            " - " +
            item.model.vehicleBrand.brandName +
            " | " +
            item.model.name +
            " | " +
            item.registration,
        }));
        if (this.vehicles.length > 0) {
          this.formGroup.get("vehiclesId")?.enable(); // Enable vehicles select
        } else {
          this.formGroup.get("vehiclesId")?.disable(); // Disable vehicles select if no data
        }
      },
      (error) => {
        console.error("Error fetching vehicles:", error);
      }
    );
  }
}
