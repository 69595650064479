import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { forkJoin, Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { OPN_BASE_URL } from "../global/var";

@Injectable({
  providedIn: "root",
})
export class KeycloakService {

  private roleBaseUrl = `${environment.baseUrlAdmin}${environment.realmClient}/clients/${environment.clientCode}/roles`;
  private realmRoleBaseUrl = `${environment.baseUrlAdmin}${environment.realmClient}/roles`;
  
 /**
   * Creates an instance of KeycloakService.
   * @param http - The HttpClient service used for making HTTP requests.
   */
 constructor(private http: HttpClient) {}

 /**
  * Retrieves the authorization headers for HTTP requests.
  * @returns {HttpHeaders} - The HTTP headers containing the authorization token.
  */
 private getAuthHeaders(): HttpHeaders {
   const token = localStorage.getItem("id_token"); // Replace with your method of getting the token
   return new HttpHeaders({
     Authorization: `Bearer ${token}`,
   });
 }

 /**
  * Retrieves the permissions associated with a specific client role.
  * @param roleName - The name of the client role.
  * @returns {Observable<any>} - An observable containing the role's permissions.
  */
 getRoleWithPermissions(roleName: string): Observable<any> {
   return this.http.get(
     `${environment.baseUrlAdmin}${environment.realmClient}/clients/${environment.clientCode}/roles/${roleName}/composites`,
     { headers: this.getAuthHeaders() }
   );
 }

 /**
  * Retrieves the details and permissions of a specific client role.
  * @param roleName - The name of the client role.
  * @returns {Observable<any>} - An observable containing the role's details and permissions.
  */
 getRoleDetailsWithPermissions(roleName: string): Observable<any> {
   return this.http
     .get(
       `${environment.baseUrlAdmin}${environment.realmClient}/clients/${environment.clientCode}/roles/${roleName}`,
       { headers: this.getAuthHeaders() }
     )
     .pipe(
       switchMap((roleDetails: any) => {
         return this.http
           .get(
             `${environment.baseUrlAdmin}${environment.realmClient}/clients/${environment.clientCode}/roles/${roleName}/composites`,
             { headers: this.getAuthHeaders() }
           )
           .pipe(
             map((permissions: any) => {
               return { ...roleDetails, permissions };
             })
           );
       })
     );
 }

 /**
  * Retrieves all client roles.
  * @returns {Observable<any[]>} - An observable containing a list of client roles.
  */
 getClientRoles(): Observable<any[]> {
   return this.http.get<any[]>(this.roleBaseUrl, {
     headers: this.getAuthHeaders(),
   });
 }

 /**
  * Retrieves a specific client role by name.
  * @param roleName - The name of the client role.
  * @returns {Observable<any>} - An observable containing the client role.
  */
 getClientRole(roleName: string): Observable<any> {
   return this.http.get<any>(`${this.roleBaseUrl}/${roleName}`, {
     headers: this.getAuthHeaders(),
   });
 }

 /**
  * Creates a new client role.
  * @param roleName - The name of the new role.
  * @param description - The description of the new role.
  * @param displayName - The display name of the new role.
  * @returns {Observable<any>} - An observable that resolves when the role is created.
  */
 createClientRole(
   roleName: string,
   description: string,
   displayName: string
 ): Observable<any> {
   const roleData = {
     name: roleName,
     description: description,
     attributes: {
       displayName: [displayName]
     }
   };
   return this.http.post<any>(this.roleBaseUrl, roleData, {
     headers: this.getAuthHeaders(),
   });
 }

 /**
  * Updates an existing client role.
  * @param roleName - The name of the role to update.
  * @param description - The new description for the role.
  * @returns {Observable<any>} - An observable that resolves when the role is updated.
  */
 updateClientRole(roleName: string, description: string): Observable<any> {
   const roleData = {
     name: roleName,
     description: description,
   };
   return this.http.put<any>(`${this.roleBaseUrl}/${roleName}`, roleData, {
     headers: this.getAuthHeaders(),
   });
 }

 /**
  * Deletes a client role.
  * @param roleName - The name of the client role to delete.
  * @returns {Observable<any>} - An observable that resolves when the role is deleted.
  */
 deleteClientRole(roleName: string): Observable<any> {
   return this.http.delete<any>(`${this.roleBaseUrl}/${roleName}`, {
     headers: this.getAuthHeaders(),
   });
 }

 /**
  * Retrieves permissions associated with a specific role.
  * @param roleName - The name of the role.
  * @returns {Observable<any>} - An observable containing the role's permissions.
  */
 getPermissionsForRole(roleName: string): Observable<any> {
   return this.http.get(`${OPN_BASE_URL}/roles/${roleName}/permissions`, {
     headers: this.getAuthHeaders(),
   });
 }

 /**
  * Creates a new realm role.
  * @param roleName - The name of the new realm role.
  * @param description - The description of the new realm role.
  * @returns {Observable<any>} - An observable that resolves when the role is created.
  */
 createRealmRole(roleName: string, description: string): Observable<any> {
   const roleData = {
     name: roleName,
     description: description
   };
   return this.http.post<any>(`${environment.baseUrlAdmin}${environment.realmClient}/roles`, roleData, {
     headers: this.getAuthHeaders()
   });
 }

 /**
  * Retrieves all realm roles.
  * @returns {Observable<any[]>} - An observable containing a list of realm roles.
  */
 getRealmRoles(): Observable<any[]> {
   return this.http.get<any[]>(this.realmRoleBaseUrl, {
     headers: this.getAuthHeaders(),
   });
 }

 /**
  * Retrieves the ID of a client role by its name.
  * @param roleName - The name of the client role.
  * @returns {Observable<string>} - An observable that returns the client role ID.
  */
 getClientRoleIdByName(roleName: string): Observable<string> {
   return this.http.get<any[]>(this.roleBaseUrl, {
     headers: this.getAuthHeaders()
   }).pipe(
     map(roles => {
       const role = roles.find(r => r.name === roleName);
       if (role) {
         return role.id;
       } else {
         throw new Error('Client role not found');
       }
     })
   );
 }

 /**
  * Deletes a realm role by its name.
  * @param roleName - The name of the realm role to delete.
  * @returns {Observable<any>} - An observable that resolves when the role is deleted.
  */
 deleteRealmRole(roleName: string): Observable<any> {
   return this.http.delete<any>(`${this.realmRoleBaseUrl}/${roleName}`, {
     headers: this.getAuthHeaders(),
   });
 }
 /**
  * Retrieves the ID of a realm role by its name.
  * @param roleName - The name of the realm role.
  * @returns {Observable<string>} - An observable that returns the realm role ID.
  */
 getRealmRoleIdByName(roleName: string): Observable<string> {
   return this.http.get<any[]>(this.realmRoleBaseUrl, {
     headers: this.getAuthHeaders(),
   }).pipe(
     map(roles => {
       const role = roles.find(r => r.name === roleName);
       if (role) {
         return role.id;
       } else {
         throw new Error('Realm role not found');
       }
     })
   );
 }

 /**
  * Assigns a client role to a realm role.
  * @param clientRoleName - The name of the client role.
  * @param realmRoleName - The name of the realm role.
  * @returns {Observable<any>} - An observable that resolves when the client role is assigned to the realm role.
  */
 assignClientRoleToRealmRole(clientRoleName: string, realmRoleName: string): Observable<any> {
   return forkJoin({
     clientRoleId: this.getClientRoleIdByName(clientRoleName),
     realmRoleId: this.getRealmRoleIdByName(realmRoleName)
   }).pipe(
     switchMap(({ clientRoleId, realmRoleId }) => {
       const roleRepresentation = {
         id: clientRoleId,
         name: clientRoleName
       };
       return this.http.post(
         `${environment.baseUrlAdmin}${environment.realmClient}/roles-by-id/${realmRoleId}/composites`,
         [roleRepresentation],
         { headers: this.getAuthHeaders() }
       );
     })
   );
 }

 /**
  * Retrieves the roles assigned to a specific realm role.
  * @param realmRoleId - The ID of the realm role.
  * @returns {Observable<any[]>} - An observable containing a list of assigned roles.
  */
 getAssignedRoles(realmRoleId: string): Observable<any[]> {
   return this.http.get<any[]>(
     `${environment.baseUrlAdmin}${environment.realmClient}/roles-by-id/${realmRoleId}/composites`,
     { headers: this.getAuthHeaders() }
   );
 }

 /**
  * Removes a client role from a realm role.
  * @param clientRoleName - The name of the client role.
  * @param realmRoleName - The name of the realm role.
  * @returns {Observable<any>} - An observable that resolves when the client role is removed from the realm role.
  */
 removeClientRoleFromRealmRole(clientRoleName: string, realmRoleName: string): Observable<any> {
  return forkJoin({
    clientRoleId: this.getClientRoleIdByName(clientRoleName),
    realmRoleId: this.getRealmRoleIdByName(realmRoleName)
  }).pipe(
    switchMap(({ clientRoleId, realmRoleId }) => {
      const roleRepresentation = [{
        id: clientRoleId,
        name: clientRoleName
      }];
      
      return this.http.request(
        'DELETE',
        `${environment.baseUrlAdmin}${environment.realmClient}/roles-by-id/${realmRoleId}/composites`,
        {
          headers: this.getAuthHeaders(),
          body: roleRepresentation
        }
      );
    })
  );
}




 /**
 * Retrieves all client roles with their descriptions.
 * @returns {Observable<any[]>} - An observable containing a list of client roles.
 */
getClientRolesWithDescriptions(): Observable<any[]> {
  return this.http.get<any[]>(this.roleBaseUrl, {
    headers: this.getAuthHeaders(),
  }).pipe(
    map(roles => roles.map(role => ({
      ...role,
      description: role.description || ''
    })))
  );
}

/**
   * Retrieves the token from localStorage.
   * @returns {string} - The JWT token.
   */
getToken(): string | null {
  return localStorage.getItem('id_token'); 
}
}




