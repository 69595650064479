import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ColumnMode } from "@swimlane/ngx-datatable";
import { CrudService } from "app/shared/services/crud.service";
import { NotyService } from "app/shared/services/noty.service";
import { NzTableQueryParams } from "ng-zorro-antd/table";
import pluralize from "pluralize";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { saveAs } from "file-saver";
import { ExportService } from "../../services/export.service";
import { AuthService } from "app/shared/auth/auth.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DeleteModalComponent } from "app/shared/modals/delete-modal/delete-modal.component";

@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
})
export class ListComponent implements OnInit {
  temp: any;
  modelNameFromRoute: string;
  // =================================================================================
  // =================================================================================
  @Input() showCreateButton = true;
  @Input() createButtonText = null;
  @Input() createButtonLink = null;
  @Input() showTitle = true;
  @Input() redirectTo = null;
  @Input() deleteFunction: (id) => Promise<any> | null;
  @Input() relations: string[];
  @Input() module: string;
  @Input() definingField = "";
  @Input() definingFieldLabel = "";
  private isLoadingData: boolean = false;
  searchQuery: "";

  langue = localStorage.getItem("langue");

  public ColumnMode = ColumnMode;
  /**
   * Array to store elements from back
   */
  elements: any[];
  /**
   * Total number of elements, used in pagination
   */
  nbElements: number;

  // =================================================================================
  // construct and initialization
  /**
   * Current page index, used in pagination
   */
  pageIndex: number;
  /**
   * Array of elements to display after treatment (using fieldsName variable)
   */
  dataToDisplay: any[];
  /**
   * Indicates which key to sort by and in which order
   */
  sortBy: { key: string; value: string };
  /**
   * Fields to search by
   */
  searchFields: { [key: string]: string };
  /**
   * Actual searching key
   */
  searchKey: string;
  /**
   * Determine which data is being edited
   */
  editCache: { [key: string]: { edit: boolean; data: any } };
  /**
   * Determine which data is selected
   */
  selectedLine: number;
  /**
   * Determine page size
   */
  pageSize: number;

  // ===============================================================================
  isCollapsed = false;
  isExporting = false;
  selectedOptionsCount = 0;
  selectedFilters: { [key: string]: string[] } = {};
  filterElements: any;

  // vars used to customize the datatable
  /**
   * Url used to manipulate data
   */
  @Input() url: string;
  @Input() deleteUrl: string;
  /**
   * Set pagination
   */
  @Input() pagination = true;
  /**
   * Define default order of data table
   */
  @Input() defaultSortBy;

  // =================================================================================
  /**
   * Define the fields to be displayed in the datatable.
   *
   * If not null, extract data to display from data received from backend
   */
  @Input() fields = [];
  /**
   * Define if user can edit data
   */
  @Input() canEdit: "form" | null = null;

  /**
   * PermissionModel needed to delete an entry
   */
  @Input() canDelete = true;
  @Input() hasEditControl = true;

  /**
   * Define if there is more data to display under datatable
   */
  @Input() hasDetails = false;
  /**
   * Emit selected data
   */
  @Output() selectedData: EventEmitter<any> = new EventEmitter();

  // =================================================================================
  @Input() showExportButton = false;

  // =================================================================================
  @Input() showFilter = false;
  @Input() filterFields = [];

  private getDataSubscription: Subscription;
  pageSizeOptions = [10, 20, 40, 60];
  selectedOperators: { [key: string]: string } = {};
  operatorOptions = [
    { label: "Not Equal", value: "!=" },
    { label: "Less Than or Equal", value: "<=" },
    { label: "Less Than", value: "<" },
    { label: "More Than or Equal", value: ">=" },
    { label: "More Than", value: ">" },
    { label: "Like", value: ":=" },
    { label: "Equal", value: "=" },
  ];
  isSearchDropdownVisible: { [key: string]: boolean } = {};
  private profileConfig: any;
  public translatedText: string;

  constructor(
    protected crud: CrudService,
    public router: Router = null,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute = null,
    private notyService: NotyService,
    private translate: TranslateService,
    private exportService: ExportService,
    private authService: AuthService,
    private modalService: NgbModal
  ) {
    this.searchFields = {};
    this.elements = [];
    this.editCache = {};
  }

  toggleSearchDropdown(fieldName: string): void {
    this.isSearchDropdownVisible[fieldName] =
      !this.isSearchDropdownVisible[fieldName];
  }

  get canEditThroughForm() {
    return this.canEdit === "form";
  }

  get canEditThroughDatatable() {
    return false; // Puisque nous ne voulons plus utiliser 'datatable'
  }

  ngOnInit() {
    this.checkCreatePermission();
    this.updateEditCache();
    this.modelNameFromRoute = this.router.url.split("/")[2];
    const RouteName = this.router.url.split("/")[1];

    const modelName = this.router.url.split("/")[2];

    if (!this.createButtonText) {
      this.createButtonText =
        "Create " + this.singular(modelName).replace("-", " ");
    }
    if (!this.createButtonLink) {
      this.createButtonLink = "/" + RouteName + "/" + modelName + "/form";
    }
  }
  ngAfterViewInit() {
    this.route.queryParams.subscribe((params) => {
      this.pageIndex = !isNaN(parseInt(params.page, 10))
        ? parseInt(params.page, 10)
        : 1;

      this.pageSize = !isNaN(parseInt(params.size, 10))
        ? parseInt(params.size, 10)
        : 10;

      this.updateRouteQueryParams();
      this.loadData();
    });
  }


  singular(word) {
    if (!word) {
      return "";
    }
    return pluralize.singular(word);
  }

  showDetail(id) {
    this.redirectTo != null
      ? this.router.navigate([this.redirectTo + "/" + id])
      : false;
  }

  /**
   * Load data from backend since page changed
   */
  loadData() {
    if (this.isLoadingData) return; // Prevent multiple API calls

    this.isLoadingData = true;
    const startIndex = this.pageIndex;

    this.getAllPaginate(
      startIndex,
      this.pageSize,
      this.authService.getUsernameFromToken()
    )
      .then(() => {})
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .then(() => {
        this.isLoadingData = false;
      });
  }




  updateRouteQueryParams() {
    const queryParams: { page, size } = {
      page: this.pageIndex,
      size: this.pageSize,

    };

    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams,
      });
  }

  /**
   * Delete element
   */
  async deleteElement(item: any, e: MouseEvent = null) {
    const modelName = this.router.url.split("/")[2];
    e.preventDefault();
    e.stopPropagation();

    const modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.itemType = "crud";
    let name = "";
    if (this.definingField) {
      name = this.elements.find((x) => x.id === item)[this.definingField];
    } else {
      name = this.elements.find((x) => x.id === item)[0];
    }
    modalRef.componentInstance.itemName = name;
    // Adjust this based on how you want to display the item name
    modalRef.componentInstance.itemDeleted.subscribe(() => {
      if (this.deleteFunction) {
        this.deleteFunction(item).then(() => {
          this.loadData();
        });
      } else {
        const url = this.deleteUrl ?? this.url.split("?")[0];
        this.crud.delete(url, item).subscribe(
          () => {
            this.loadData();
            const successMessage =
              this.langue === "fr"
                ? "Supprimé avec succès"
                : "Deleted successfully";
            this.notyService.displayNotification(successMessage, "success");
          },
          (error) => {
            if (error.status === 400) {
              this.notyService.displayNotification(error.error, "error");
            }
          }
        );
      }
    });
  }

  getFieldTitleBySearchKey(key: string): string {
    // const titleMapping: { [key: string]: string } = {};
    // return titleMapping[key] || key;
    return (
      this.filterFields.find((field) => field.column === key)?.title || key
    );
  }

  getOptionValueByid(field) {
    const options =
      this.filterFields.find((filterField) => filterField.column === field)
        ?.options || [];
    const values = this.searchFields[field].split("&");
    return values
      .map(
        (val) => options.find((option) => option.id.toString() === val)?.name
      )
      .filter(Boolean)
      .join(", ");
  }

  reset(isDate: boolean, field: string) {
    const fieldToDelete = field;
    if (isDate) {
      delete this.searchFields[fieldToDelete + "_between1"];
      delete this.searchFields[fieldToDelete + "_between2"];
    } else {
      delete this.searchFields[fieldToDelete];
    }
    this.pageIndex = 1;
    // get field name from field
    const fieldName = this.filterFields.find(
      (filterField) => filterField.column === field
    )?.name;

    this.selectedFilters[fieldName] = [];
    this.selectedOptionsCount = 0;
    this.updateRouteQueryParams();
  }

  // =================================================================================
  // searching methods

  rangeChange($event: Date[], field: string) {
    const date1 =
      $event[0].getFullYear() +
      "-" +
      ($event[0].getMonth() + 1) +
      "-" +
      $event[0].getDate();
    const date2 =
      $event[1].getFullYear() +
      "-" +
      ($event[1].getMonth() + 1) +
      "-" +
      $event[1].getDate();
    this.searchFields[field + "_between1"] = date1;
    this.searchFields[field + "_between2"] = date2;
    delete this.searchFields[field];
    this.cd.detectChanges();
  }

  /**
   * Enable editing in datatable
   */
  startEdit(data): void {
    const id = data[data.length - 1];
    this.editCache[id].edit = true;
  }

  /**
   * Cancel editing in datatable
   */
  cancelEdit(id: string): void {
    const index = this.elements.findIndex((item) => item.id === id);
    this.editCache[id] = {
      data: { ...this.elements[index] },
      edit: false,
    };
  }

  // =================================================================================
  // editing from datatable methods
  dateRange: any;

  /**
   * Save editing in backend
   */
  saveEdit(id: string): void {
    const index = this.elements.findIndex((item) => item.id === id);
    this.crud
      .update(this.url, id, this.toFormData(this.editCache[id].data))
      .subscribe(async () => {
        this.elements[index] = this.editCache[id].data;
        this.elements.splice(index, 1, this.editCache[id].data);
        if (this.fields) {
          await this.prepareFieldsToDisplay();
        }
        this.editCache[id].edit = false;
        const successMessage =
          this.langue === "fr" ? "Mise à jour réussie" : "Updated successfully";
        this.notyService.displayNotification(successMessage, "success");
      });
  }

  toFormData<T>(formValue: T) {
    const formData = new FormData();

    for (const key of Object.keys(formValue)) {
      const value = formValue[key];
      if (Array.isArray(value)) {
        if (!value.length) {
          formData.append(`${key}`, "[]");
        } else {
          value.forEach((val) => {
            if (typeof val === "object" && !(val instanceof File)) {
              formData.append(`${key}[]`, JSON.stringify(val));
            } else {
              formData.append(`${key}[]`, val);
            }
          });
        }
      } else {
        if (
          typeof value === "object" &&
          value !== null &&
          !(value instanceof File)
        ) {
          for (const keyValue in value) {
            formData.append(`${key}[${keyValue}]`, value[keyValue]);
          }
        } else {
          formData.append(key, value);
        }
      }
    }
    return formData;
  }

  keys(object) {
    if (object) {
      return Object.keys(object);
    }
    return [];
  }

  /**
   * Create update route from actual route
   */
  createUpdateRoute(data) {
    // @ts-ignore
    const routeFragments = this.route._routerState.snapshot.url.split("/");
    delete routeFragments[routeFragments.length - 1];
    return routeFragments.join("/") + "form/" + data;
  }

  log($event) {}

  /**
   * Navigate to
   */
  navigateTo(path) {
    this.router.navigate([path]);
  }

  updateCheckBox(line: number, $event: MouseEvent = null) {
    if (this.selectedLine !== null && this.selectedLine !== line) {
      this.elements[this.selectedLine].selected = false;
    }
    this.selectedLine = this.selectedLine === line ? null : line;
    this.updateRouteQueryParams();
    this.updateSelectedItem();
    if ($event) {
      $event.stopPropagation();
    }
  }

  private updateSelectedItem() {
    if (this.selectedLine !== null && this.selectedLine !== undefined) {
      this.selectedData.emit(this.elements[this.selectedLine]);
    } else {
      this.selectedData.emit(null);
    }

    setTimeout(() => {
      const detailsElement = document.getElementById("details");
      if (detailsElement) {
        detailsElement.scrollIntoView({ behavior: "smooth" });
      }
    });
  }

  /**
   * Get data with pagination
   */
  getAllPaginate(
    offset: number,
    limit: number,
    username?: string
  ): Promise<void> {
    const url = this.prepareUrl(offset, limit, username);

    return new Promise<void>((resolve, reject) => {
      this.selectedLine = null;
      this.selectedData.emit(null);

      this.getDataSubscription = this.crud.getPaginate<any>(url).subscribe(
        async (data) => {
          if (this.pagination) {
            this.elements = data.data;
            this.nbElements = data.count;
          } else {
            this.elements = data;
          }
          if (this.fields) {
            await this.prepareFieldsToDisplay();
          }
          this.updateEditCache();
          this.cd.detectChanges();
          resolve();
        },
        (error) => {
          console.error("Error fetching paginated data:", error);
          reject(error);
        }
      );
    });
  }

  /**
   * Update edit cache
   */
  private updateEditCache(): void {
    if (this.elements) {
      this.elements.forEach((item) => {
        this.editCache[item.id] = {
          edit: false,
          data: { ...item },
        };
      });
    }

    setTimeout(() => {
      const detailsElement = document.getElementById("details");
      if (detailsElement) {
        detailsElement.scrollIntoView({ behavior: "smooth" });
      }
    });
  }
  /**
   * Prepare url for get/search request
   *
   * Check if there is fields to search by, if yes add "search/" to the url
   * Add offset and limit
   * If sorting by, add sort by field and order
   * Add fields to search by and value
   */

  private prepareUrl(offset: number, limit: number, username?: string): string {
    let url = this.url;

    // Ajouter les paramètres de pagination
    let queryParams = `?page=${offset}&limit=${limit}`;

    // Ajouter le paramètre username si présent
    if (username) {
      queryParams += `&username=${encodeURIComponent(username)}`;
    }

    // Assurer que les paramètres sont correctement ajoutés
    if (url.includes("?")) {
      url += queryParams.replace("?", "&");
    } else {
      url += queryParams;
    }

    return url;
  }

  /**
   * Prepare fieldsToDisplay array
   *
   * Used when other component call this component
   * Loop over fieldsName array and extracts data to display
   */
  private async prepareFieldsToDisplay() {
    this.dataToDisplay = [];
    for (const element of this.elements) {
      const result = [];
      for (const field of this.fields) {
        try {
          if (field.isVisible) {
            const value = field.isVisible(element[field.name], element);
            result.push(value);
          } else if (field.prepareValueFunction) {
            const value = await field.prepareValueFunction(
              element[field.name],
              element
            );
            result.push(value);
          } else {
            const fieldSplit = field.name.split(".");
            let output = element[fieldSplit[0]];
            for (let i = 1; i < fieldSplit.length; i++) {
              output = output[fieldSplit[i]];
            }
            result.push(output);
          }
        } catch (e) {
          result.push("");
        }
      }
      result.push(element.id);
      this.dataToDisplay.push(result);
    }
    this.cd.detectChanges();
  }

  // =================================================================================
  // filter methods
  private prepareUrlForFilters(): string {
    const username = this.authService.getUsernameFromToken(); // Récupérer le username
    let url = this.url;

    // Ajouter les paramètres de tri s'ils sont définis
    if (this.sortBy && this.sortBy.key && this.sortBy.value) {
      url +=
        (url.includes("?") ? "&" : "?") +
        "order=" +
        encodeURIComponent(this.sortBy.key) +
        "=" +
        encodeURIComponent(this.sortBy.value);
    }

    // Ajouter les relations si elles sont définies
    if (this.relations) {
      if (this.relations.length) {
        url +=
          (url.includes("?") ? "&" : "?") +
          "relations=" +
          encodeURIComponent(this.relations.join(","));
      } else {
        url += (url.includes("?") ? "&" : "?") + "relations=no";
      }
    }

    // Ajouter le username s'il est défini
    if (username) {
      url +=
        (url.includes("?") ? "&" : "?") +
        "username=" +
        encodeURIComponent(username);
    }

    return url;
  }

  closeFilter() {
    this.isCollapsed = false;
  }

  resetFilter(): void {
    this.searchFields = {};
    this.pageIndex = 1;
    this.selectedFilters = {};
    this.selectedOptionsCount = 0;
    this.loadData();
  }

  getUniqueFieldValues(field) {
    const fieldPath = field.name.split("."); // Split the field name by '.' for nested properties

    const values = this.filterElements.map((element) => {
      // Traverse the path to access the nested property
      let value = element;
      for (const key of fieldPath) {
        value = value?.[key];
      }

      // If prepareValueFunction is defined, apply it to the value
      let displayValue = value;
      if (field.prepareValueFunction) {
        displayValue = field.prepareValueFunction(value);
      }

      // Return the object with id and name
      value = element[field.column];
      return {
        id: value, // Use the original value for id
        name: displayValue, // Use the prepared value for name
      };
    });

    // Remove duplicates and return the unique values
    const uniqueValues = Array.from(
      new Map(values.map((item) => [item.id, item])).values()
    );

    return uniqueValues;
  }

  filterData() {
    this.pageIndex = 1;
    // this.updateRouteQueryParams();
  }

  onCheckboxChange(option, field) {
    const value = option.id; // Use option.id as the value for comparison
    if (!this.selectedFilters[field.name]) {
      this.selectedFilters[field.name] = [];
    }

    if (this.selectedFilters[field.name].includes(value)) {
      this.selectedFilters[field.name] = this.selectedFilters[
        field.name
      ].filter((val) => val !== value);
      this.selectedOptionsCount--;
    } else {
      this.selectedFilters[field.name].push(value);
      this.selectedOptionsCount++;
    }

    this.searchFields[field.column] =
      this.selectedFilters[field.name].join("&");
    if (this.searchFields[field.column] === "") {
      delete this.searchFields[field.column];
    }
    this.loadData();
  }

  // =================================================================================
  // export methods

  getTranslation(word: string): Promise<string> {
    if (!word) {
      return Promise.resolve("");
    }
    return new Promise((resolve, reject) => {
      this.translate.get(word).subscribe({
        next: (translation: string) => resolve(translation),
        error: (err) => reject(err),
      });
    });
  }

  async getHeaders(): Promise<string[]> {
    const translations = await Promise.all(
      this.fields.map((field) => this.getTranslation(field.title.toUpperCase()))
    );
    return translations;
  }

  async exportToCSV() {
    this.isExporting = true;

    try {
      const fields = this.fields.map((field) => field.name);
      const headers = await this.getHeaders();
      const { headers: preparedHeaders, data } = await this.prepareFields(
        headers,
        fields
      );
      const csv = this.exportService.convertToCSV(data, preparedHeaders);
      const blob = new Blob([csv], { type: "text/csv" });
      saveAs(blob, `${this.modelNameFromRoute}-list.csv`);
    } catch (error) {
      console.error("Error exporting to CSV:", error);
    } finally {
      this.isExporting = false;
    }
  }

  async exportToExcel() {
    this.isExporting = true;

    try {
      // Prepare filter headers and values
      const filterHeaders = [];
      const filterValues = [];
      for (const field in this.searchFields) {
        if (field.includes("_between2")) {
          continue;
        }
        if (field.includes("_between1")) {
          const baseField = field.split("_between")[0];
          filterHeaders.push(baseField);
          filterValues.push(
            `${this.getFieldTitleBySearchKey(baseField)} ${
              this.selectedOperators[baseField]
            } ${this.searchFields[field]} -> ${
              this.searchFields[field.replace("_between1", "_between2")]
            }`
          );
        } else {
          filterHeaders.push(`${this.getFieldTitleBySearchKey(field)}`);
          filterValues.push(` ${this.getOptionValueByid(field)}`);
        }
      }

      const fields = this.fields.map((field) => field.name);
      const headers = await this.getHeaders();
      const { headers: preparedHeaders, data } = await this.prepareFields(
        headers,
        fields
      );
      this.exportService.exportToExcel(
        this.modelNameFromRoute,
        filterHeaders,
        filterValues,
        preparedHeaders,
        data
      );
    } catch (error) {
      console.error("Error exporting to Excel:", error);
    } finally {
      this.isExporting = false;
    }
  }

  private async prepareFields(h: string[], fields: any[]) {
    let allElements = [];
    const username = this.authService.getUsernameFromToken(); // Assurez-vous de récupérer le username

    if (!username) {
      console.error("Username is not available");
      return { headers: h, data: [] };
    }

    for (let i = 1; i <= Math.ceil(this.nbElements / this.pageSize); i++) {
      await this.getAllPaginate(i, this.pageSize, username); // Passer le username ici
      allElements = allElements.concat(this.elements);
    }

    const headers = h;
    const data = allElements.map((element) => {
      return fields.map((fieldName) => {
        const fieldPath = fieldName.split(".");
        let value = element;
        for (const key of fieldPath) {
          value = value?.[key];
        }
        const prepareValueFunction = this.filterFields.find(
          (field) => field.name === fieldName
        )?.prepareValueFunction;
        return prepareValueFunction ? prepareValueFunction(value) : value;
      });
    });

    return { headers, data };
  }

  checkCreatePermission() {
    this.authService
      .hasModulePermission(this.module, "add")
      .subscribe((hasPermission) => {
        this.showCreateButton = hasPermission;
      });
    this.authService
      .hasModulePermission(this.module, "delete")
      .subscribe((hasPermission: boolean) => {
        this.canDelete = hasPermission;
      });

    this.authService
      .hasModulePermission(this.module, "update")
      .subscribe((hasPermission: boolean) => {
        this.canEdit = hasPermission ? "form" : null;
      });

    this.authService
      .hasModulePermission(this.module, "filter")
      .subscribe((hasPermission: boolean) => {
        this.showFilter = hasPermission;
      });

    this.authService
      .hasModulePermission(this.module, "export")
      .subscribe((hasPermission: boolean) => {
        this.showExportButton = hasPermission;
      });
    this.authService
      .hasModulePermission(this.module, "view_details")
      .subscribe((hasPermission: boolean) => {
        this.hasDetails = hasPermission;
      });
  }

  onDataSelected(data: any) {
    this.selectedData.emit(data);
  }
}
