import { NgModule } from "@angular/core";
import { CommonModule, DatePipe, DecimalPipe } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { OverlayModule } from "@angular/cdk/overlay";

import {
  NgbModule,
  NgbNavModule,
  NgbPopoverModule,
} from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { ClickOutsideModule } from "ng-click-outside";

import { AutocompleteModule } from "./components/autocomplete/autocomplete.module";
import { PipeModule } from "app/shared/pipes/pipe.module";

//COMPONENTS
import { FooterComponent } from "./footer/footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { HorizontalMenuComponent } from "./horizontal-menu/horizontal-menu.component";
import { VerticalMenuComponent } from "./vertical-menu/vertical-menu.component";
import { CustomizerComponent } from "./customizer/customizer.component";
import { NotificationSidebarComponent } from "./notification-sidebar/notification-sidebar.component";

//DIRECTIVES
import { ToggleFullscreenDirective } from "./directives/toggle-fullscreen.directive";
import { SidebarLinkDirective } from "./directives/sidebar-link.directive";
import { SidebarDropdownDirective } from "./directives/sidebar-dropdown.directive";
import { SidebarAnchorToggleDirective } from "./directives/sidebar-anchor-toggle.directive";
import { SidebarDirective } from "./directives/sidebar.directive";
import { TopMenuDirective } from "./directives/topmenu.directive";
import { TopMenuLinkDirective } from "./directives/topmenu-link.directive";
import { TopMenuDropdownDirective } from "./directives/topmenu-dropdown.directive";
import { TopMenuAnchorToggleDirective } from "./directives/topmenu-anchor-toggle.directive";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { ImagePathPipe } from "./pipes/image-path.pipe";
import { FormModule } from "./components/form/form.module";
import { ListComponent } from "./components/list/list.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzPaginationModule } from "ng-zorro-antd/pagination";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzPopconfirmModule } from "ng-zorro-antd/popconfirm";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { NzModalModule } from "ng-zorro-antd/modal";
import { NzEmptyModule } from "ng-zorro-antd/empty";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { SpinnerInterceptor } from "./interceptors/spinner.interceptor";

import { GroupServicesManagementComponent } from "./modals/group-services-management/group-services-management.component";
import { TripsDetailsComponent } from "./modals/trips-details/trips-details.component";
import { HorizontalTimelineComponent } from "./components/horizontal-timeline/horizontal-timeline.component";
import { DeleteModalComponent } from "./modals/delete-modal/delete-modal.component";

import { PeriodDateFilterComponent } from "./components/period-date-filter/period-date-filter.component";
import { DateFilterComponent } from "./components/date-filter/date-filter.component";
import { VerticalTimelineComponent } from "./components/vertical-timeline/vertical-timeline.component";
import { SuccessModalComponent } from "./modals/success-modal/success-modal.component";

import { DayPilotModule } from "daypilot-pro-angular";
import { GroupedAssignmentModalComponent } from "./modals/grouped-assignment-modal/grouped-assignment-modal.component";
import { MultipleDeletionModalComponent } from "./modals/multiple-deletion-modal/multiple-deletion-modal.component";
import { AssignmentByServiceGroupComponent } from "./modals/assignment-by-service-group/assignment-by-service-group.component";
import { DateRangeFilterComponent } from "./components/date-range-filter/date-range-filter.component";
import { NgbdSortableHeader } from "./directives/sortable.directive";
import { MapComponent } from "./components/map/map.component";
import { ItineraryFormComponent } from "./modals/itinerary-form/itinerary-form.component";
import { DragulaModule } from "ng2-dragula";
import { ItinerayMapComponent } from "./components/itineray-map/itineray-map.component";

import { TripReporterModalComponent } from "./modals/trip-reporter-modal/trip-reporter-modal.component";

import { AddPermissionModalComponent } from "./modals/add-permission-modal/add-permission-modal.component";
import { HasPermissionDirective } from "./directives/has-permission.directive";
import { RefreshDirectiveDirective } from "./directives/refresh-directive.directive";
import { ExportAssignmentModalComponent } from "./modals/export-assignment-modal/export-assignment-modal.component";
import { WorkOrderFormComponent } from "./modals/work-order-form/work-order-form.component";
import { InterventionFormComponent } from "./modals/intervention-form/intervention-form.component";
import { WorkDiaryComponent } from "./modals/work-diary/work-diary.component";
import { RentModalComponent } from "./modals/rent-modal/rent-modal.component";
import { ScheduledTasksService } from "app/modules/maintenance-management/scheduled-tasks-management/services/scheduled-tasks.service";
import { WorkRequestService } from "app/modules/maintenance-management/work-request/services/work-request.service";
import { NgxSpinnerModule } from "ngx-spinner";
import { RealTimeTrackingModule } from "app/modules/real-time-tracking/real-time-tracking.module";
import { TripsListModalComponent } from "./modals/trips-list-modal/trips-list-modal.component";
import { RefuelsModalComponent } from "./modals/refuels-modal/refuels-modal.component";
import { PieChartComponent } from "./components/charts/pie-chart/pie-chart.component";
import { DateRangeWithCompareComponent } from "./components/date-range-with-compare/date-range-with-compare.component";
import { TopTableComponent } from "./components/top-table/top-table.component";
import { BarChartComponent } from "./components/charts/bar-chart/bar-chart.component";
import { SearchPipe } from './components/list/search.pipe';
import { ConfirmationModalComponent } from './modals/confirmation-modal/confirmation-modal.component';
@NgModule({
    exports: [
        CommonModule,
        FooterComponent,
        NavbarComponent,
        VerticalMenuComponent,
        HorizontalMenuComponent,
        CustomizerComponent,
        NotificationSidebarComponent,
        ToggleFullscreenDirective,
        SidebarDirective,
        TopMenuDirective,
        NgbModule,
        TranslateModule,
        BreadcrumbComponent,
        ListComponent,
        FormModule,
        FormsModule,
        ReactiveFormsModule,
        ImagePathPipe,
        PeriodDateFilterComponent,
        GroupServicesManagementComponent,
        HorizontalTimelineComponent,
        VerticalTimelineComponent,
        DateFilterComponent,
        ReactiveFormsModule,
        FormsModule,
        DayPilotModule,
        DateRangeFilterComponent,
        MapComponent,
        NgbdSortableHeader,
        NzSpinModule,
        NgbPopoverModule,
        NgSelectModule,
        DragulaModule,
        NgxSpinnerModule,
        ItinerayMapComponent,
        HasPermissionDirective,
        RefreshDirectiveDirective,
        PieChartComponent,
        DateRangeWithCompareComponent,
        TopTableComponent,
        BarChartComponent,
        SidebarLinkDirective,
        TripsListModalComponent
    ],
  imports: [
    RouterModule,
    CommonModule,
    NgbModule,
    TranslateModule,
    FormsModule,
    OverlayModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    ClickOutsideModule,
    AutocompleteModule,
    PipeModule,
    FormModule,
    NgbNavModule,
    NgxDatatableModule,
    NzDropDownModule,
    NzDatePickerModule,
    NzPaginationModule,
    NzTableModule,
    NzButtonModule,
    NzInputModule,
    NzPopconfirmModule,
    NzDividerModule,
    NzSpinModule,
    NzModalModule,
    NzEmptyModule,
    NzIconModule,
    TranslateModule,
    NgbPopoverModule,
    NzSpinModule,
    NgSelectModule,
    DragulaModule,
    NgxSpinnerModule,
    RealTimeTrackingModule,
    
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    CustomizerComponent,
    NotificationSidebarComponent,
    ToggleFullscreenDirective,
    SidebarLinkDirective,
    SidebarDropdownDirective,
    SidebarAnchorToggleDirective,
    SidebarDirective,
    TopMenuLinkDirective,
    TopMenuDropdownDirective,
    TopMenuAnchorToggleDirective,
    TopMenuDirective,
    BreadcrumbComponent,
    ListComponent,
    ImagePathPipe,
    PeriodDateFilterComponent,
    GroupServicesManagementComponent,
    TripsDetailsComponent,
    HorizontalTimelineComponent,
    DeleteModalComponent,
    DateFilterComponent,
    VerticalTimelineComponent,
    SuccessModalComponent,
    GroupedAssignmentModalComponent,
    MultipleDeletionModalComponent,
    AssignmentByServiceGroupComponent,
    DateRangeFilterComponent,
    NgbdSortableHeader,
    MapComponent,
    ItineraryFormComponent,
    AddPermissionModalComponent,
    ItinerayMapComponent,
    TripReporterModalComponent,
    HasPermissionDirective,
    RefreshDirectiveDirective,
    ExportAssignmentModalComponent,
    RentModalComponent,
    WorkOrderFormComponent,
    InterventionFormComponent,
    WorkDiaryComponent,
    TripsListModalComponent,
    RefuelsModalComponent,
    PieChartComponent,
    DateRangeWithCompareComponent,
    TopTableComponent,
    BarChartComponent,
    SearchPipe,
    ConfirmationModalComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true,
    },
    DatePipe,
    DecimalPipe,
    ScheduledTasksService,
    WorkRequestService,
  ],
})
export class SharedModule {}
