<div class="table-container">
    <h4 class="card-title">{{ topTableTitle }}</h4>
    <p class="grey">{{value}}</p>
    <ul *ngIf="data.length > 0" class="list-group mb-3">
        <li *ngFor="let value of data; let i = index" class="mb-2">
            <div>
                        <span *ngIf="i === 0" class="mr-2">
                            <i *ngIf="!isFlop" class="fa-solid fa-crown" style="color: gold;"></i>
                            <i *ngIf="isFlop" class="fa-solid ft-alert-triangle" style="color: darkred;"></i>
                        </span>
                <span>{{ value.name }}</span><br>
                <div class="badge w-25 bg-light-info float-left">{{ value.value }} {{unit}}</div>
            </div>
        </li>
    </ul>

    <div *ngIf="data.length === 0" class="no-data-message">
        <i class="icon-ban font-medium-4"></i>
        <p class="font-small-4 secondary darken-1">
            {{ "NO_DATA_DATE_RANGE" | translate }}
        </p>
    </div>
</div>
