import {Component, Input} from '@angular/core';
import {AbstractFormElement} from '../abstract-form-elements';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss']
})
export class RadioComponent extends AbstractFormElement {

  @Input() options: {
    hide?: string;
    value: any,
    label: string,
    show?: string
  }[];
  @Input() canClear = false;

}