<!-- *NAVBAR* starts -->
<app-navbar (seachTextEmpty)="checkNavbarSeachTextEmpty($event)"></app-navbar>
<!-- *NAVBAR* ends -->
<div class="" [ngClass]="{'show-overlay': !isNavbarSeachTextEmpty}" (click)="onWrapperClick();"
  (window:resize)="onResize($event)">
  <!-- *TOP* Menu starts -->
  <app-horizontal-menu appTopMenu *ngIf="menuPosition === 'Top' && !displayOverlayMenu"></app-horizontal-menu>
  <!-- *TOP* Menu ends -->
  <!-- *SIDE* Menu starts -->
  <div appSidebar *ngIf="menuPosition === 'Side' || displayOverlayMenu"
    [ngClass]="{'main-menu': menuPosition === 'Side' || displayOverlayMenu, 'menu-fixed': menuPosition === 'Side' || displayOverlayMenu, 'menu-native-scroll': !perfectScrollbarEnable }"
    class="app-sidebar" (mouseenter)="sidebarMouseenter($event)" (mouseleave)="sidebarMouseleave($event)"
    data-active-color="white" [attr.data-background-color]="config?.layout.variant === 'Transparent' ? 'black': bgColor"
    [attr.data-image]="bgImage">
    <app-sidebar></app-sidebar>
    <div class="sidebar-background" [ngStyle]="{'background-image': 'url(' + bgImage + ')'}"
      *ngIf="config?.layout.sidebar.backgroundImage"></div>
  </div>
  <!-- *SIDE* Menu ends -->
  <div class="main-panel">
    <div class="main-content">
      <div class="content-overlay"></div>
      <div class="content-wrapper py-2">
        <!-- Assuming this is a component template or part of a larger Angular template -->
          <app-breadcrumb (titleChange)="updateTitle($event)"></app-breadcrumb>        <nz-spin [nzSpinning]="spinning">
          <main [@animateRoute]="getRouterOutletState(o)" appRefresh>
            <router-outlet #o="outlet"></router-outlet>
          </main>
          <!-- <router-outlet></router-outlet> -->
        </nz-spin>
      </div>
    </div>
    <!-- FOOTER -->
    <app-footer></app-footer>
    <!-- Scroll to top button -->
    <button class="btn btn-primary scroll-top" type="button" *ngIf="isScrollTopVisible" (click)="scrollToTop()"><i
        class="ft-arrow-up"></i></button>
  </div>
  <!-- *NOTIFICATION-SIDEBAR* starts -->
  <app-notification-sidebar></app-notification-sidebar>
  <div class="sidenav-overlay" (click)="onOutsideClick($event);"
    [ngClass]="{'d-none': displayOverlayMenu && hideSidebar && !overlayContent , 'd-block': displayOverlayMenu && !hideSidebar && overlayContent && innerWidth < 1200}">
  </div>
  <div class="drag-target"></div>
</div>
