import { Component, EventEmitter, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-date-range-with-compare",
  templateUrl: "./date-range-with-compare.component.html",
  styleUrls: ["./date-range-with-compare.component.scss"],
})
export class DateRangeWithCompareComponent implements OnInit {
  @Output() dateRangeSelected: EventEmitter<{
    startDate: string;
    endDate: string;
  }> = new EventEmitter();

  @Output() isComparing: EventEmitter<boolean> = new EventEmitter();

  @Output() CompareDateRangeSelected: EventEmitter<{
    startDate: string;
    endDate: string;
  }> = new EventEmitter();
  comparingMode = false;
  toggleCompare() {
    this.comparingMode = !this.comparingMode;
    this.handleIsComparing(this.comparingMode);
  }

  constructor() {}

  ngOnInit(): void {}

  handleFirstDateRangeSelection($event: {
    startDate: string;
    endDate: string;
  }) {
    this.dateRangeSelected.emit($event);
  }

  handleCompareDateRangeSelection($event: {
    startDate: string;
    endDate: string;
  }) {
    this.CompareDateRangeSelected.emit($event);
  }

  handleIsComparing($event: boolean) {
    this.isComparing.emit($event);
  }
}
