import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const isAuthenticated = this.authService.isLoggedIn();
    
    if (!isAuthenticated) {
      this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
      return false;
    }

    // Vérifier les permissions de la route
    const requiredPermissions = route.data.permissions;
    const userPermissions = this.authService.getRolesFromToken(); // Supposons que vous ayez une méthode pour récupérer les permissions de l'utilisateur

    if (requiredPermissions && !requiredPermissions.every(p => userPermissions.includes(p))) {
      this.router.navigate(['error']); // Redirige si l'utilisateur n'a pas les permissions
      return false;
    }

    return true;
  }
}

