import {
  Component,
  Output,
  EventEmitter,
  AfterViewInit,
  ElementRef,
} from "@angular/core";
import * as L from "leaflet";
import {ZoomService} from '../../services/zoom.service';

@Component({
  selector: "app-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.scss"],
})
export class MapComponent implements AfterViewInit {
  private map;  // Reference to the Leaflet map instance
  private marker;  // Reference to the marker on the map

  /**
   * Constructor to inject the ElementRef service.
   * @param {ElementRef} _elementRef - ElementRef service to access the DOM element.
   * @param {ZoomService} zoomService - ZoomService to update icon size
   */
  constructor(private _elementRef: ElementRef,
              private zoomService: ZoomService
  ) {}

  @Output() locationSelected = new EventEmitter<{ lat: number; lon: number }>();  // Event emitter to notify when a location is selected

  ngAfterViewInit(): void {
    this.initMap();  // Initialize the map after the view is initialized
  }

  /**
   * Initialize the Leaflet map and set up the click event listener.
   */
  initMap(): void {
    let el = this._elementRef.nativeElement.querySelector(".leaflet-maps");  // Get the map container element

    this.map = L.map(el, {
      center: [34.551117, 9.369019],  // Initial center coordinates of the map
      zoom: 6,  // Initial zoom level of the map
      minZoom: 5
    });

    L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
      attributionControl: false,
    }).addTo(this.map);

    this.map.on("click", (e) => {
      this.addMarker(e.latlng);  // Add a marker on the map when it is clicked
    });
  }

  /**
   * Add a marker on the map at the specified latitude and longitude.
   * @param {LatLng} latlng - The latitude and longitude where the marker should be added.
   */
  addMarker(latlng): void {
    if (this.marker) {
      this.map.removeLayer(this.marker);  // Remove the existing marker if present
    }
    const initialIconSize: [number, number] = [25, 41];
    const iconUrl = "./assets/img/leaflet/bus-stop.png";
    this.marker = this.zoomService.createResizableIcon(this.map, latlng, iconUrl,'', initialIconSize);
    this.locationSelected.emit({
      lat: latlng.lat,
      lon: latlng.lng,
    });  // Emit the location selected event
  }

  /**
   * Set a marker on the map when user put The latitude and longitude manually , and optionally adjust the map view.
   * @param {LatLng} latlng - The latitude and longitude where the marker should be set.
   * @param {string} action - Action to determine if the map view should be adjusted.
   */
  setMarker(latlng, action): void {
    if (this.marker) {
      this.map.removeLayer(this.marker);  // Remove the existing marker if present
    }
    const initialIconSize: [number, number] = [25, 41];
    const iconUrl = "./assets/img/leaflet/bus-stop.png";
    this.marker = this.zoomService.createResizableIcon(this.map, latlng, iconUrl,'', initialIconSize);  // Add the new marker to the map
    if (action === "isEdited") {
      this.map.setView(latlng, 13);  // Adjust the map view if the action indicates editing
    }
  }
}
