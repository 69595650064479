<div class="modal-header">
  <h4 class="modal-title">{{"Work_Diary_Of" | translate}} {{operator.firstName}} {{ operator.lastName}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <i class="fa-solid fa-xmark"></i>
  </button>
</div>
<div class="modal-body">
  <div *ngFor="let group of groupedWorkDiary; let i = index" class="p-2">
    <div (click)="toggleCollapse(i)" class="cursor-pointer bg-light-secondary p-2 rounded">
      <span class="d-flex align-items-center text-dark font-weight-bold">
        <i class="fa" [ngClass]="{'fa-chevron-down': !group.isCollapsed, 'fa-chevron-right': group.isCollapsed}"></i>
        <span class="ml-2">{{ getDayOfWeek(group.date) }} {{ group.date }} </span>
      </span>
    </div>
    <div [ngbCollapse]="group.isCollapsed">
      <div class="card-body table-responsive">
        <table class="table text-center table-striped m-0">
          <thead>
            <tr>
              <th>{{ 'Session' | translate }}</th>
              <th>{{ 'From' | translate }}</th>
              <th>{{ 'To' | translate }}</th>
              <th>{{ 'Duration' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of group.instances">
              <td>
                {{item.sessionName}}
              </td>
              <td>
                {{item.startTime}}
              </td>
              <td>
                {{item.endTime}}
              </td>
              <td>
                {{item.formattedDuration}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="px-2 py-1 d-flex align-content-center flex-wrap text-white">
    <button type="button" class="btn bg-info px-2 w-100" (click)="addWorkDiary()">
      <em class="ft-plus-circle mx-1 white"></em> <span class="text-white">{{ 'Add_Work_Diary' | translate }}</span>
    </button>
  </div>


  <div class="card" *ngIf="isFormVisible">
    <div class="card-header">
      <i class="ft-settings mr-2 align-middle"></i>
      <span class="text-bold-600">{{"Work_Diary_Of" | translate}}{{operator.firstName}} {{ operator.lastName}}</span>
    </div>
    <hr />
    <div class="card-body ">
      <div class="card-body">
        <form [formGroup]="workDiaryForm" (ngSubmit)="save()">

          <div class="form-row">
            <div class="col-12">
              <div class="form-group mb-2">
                <label for="basic-form-1">{{ "Date" | translate }}</label>
                <div class="input-group">
                  <input class="form-control" id="date" formControlName="date"
                    placeholder="{{'DATE_FORMAT' | translate}}" ngbDatepicker #d="ngbDatepicker"
                    (dateSelect)="onDateSelect($event)" />
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                      <i class="fa fa-calendar"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div formArrayName="entries">
            <div *ngFor="let entry of entries.controls; let i = index" [formGroupName]="i">
              <div class="row mt-3">
                <span class="col-12 alert alert-light">{{ timeSlots[i].session }}</span>
              </div>
              <div class="form-row">
                <div class="col-md-6 col-12">
                  <div class="form-group mb-2">
                    <label for="basic-form-1">{{ "Start_Time" | translate }}</label>
                    <input type="time" formControlName="startTime" class="form-control" />
                    <small class="text-danger" *ngIf="entry.get('startTime').errors?.timeOutOfRange">
                      Start time must be between {{timeSlots[i].fromHour}} and {{timeSlots[i].toHour}}.
                    </small>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="form-group mb-2">
                    <label for="basic-form-2">{{ "End_Time" | translate }}</label>
                    <input type="time" formControlName="endTime" class="form-control" />
                    <small class="text-danger" *ngIf="entry.get('endTime').errors?.timeOutOfRange">
                      End time must be between {{timeSlots[i].fromHour}} and {{timeSlots[i].toHour}}.
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <button type="submit" class="btn btn-primary" [disabled]="!workDiaryForm.valid || !hasValidEntries()">
                <i class="ft-check-square mr-1"></i> {{ "Save" | translate }}
              </button>
              <button type="button" class="btn btn-secondary ml-2" (click)="closeModal()">
                <i class="ft-x mr-1"></i>{{ "Cancel" | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>