import { Component, EventEmitter, Output } from "@angular/core";
import { filter, map } from "rxjs/operators";
import {
  ActivatedRoute,
  NavigationEnd,
  PRIMARY_OUTLET,
  Router,
} from "@angular/router";
import { ROUTES } from "app/shared/vertical-menu/vertical-menu-routes.config";

@Component({
  selector: "app-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.scss"],
})
export class BreadcrumbComponent {
  public breadcrumbs;

  @Output() titleChange = new EventEmitter<string>();

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === PRIMARY_OUTLET)
      )
      .subscribe((route) => {
        const currentUrl = this.router.url;
        const routeInfo = this.findRouteInfo(currentUrl);

        // Special handling for settings route
        if (currentUrl.includes("/settings")) {
          this.breadcrumbs = {
            parentBreadcrumb: "Settings",
            childBreadcrumb: "",
            parentPath: "/settings",
            childPath: null,
            parentIcon: "ft-settings",
            childIcon: "ft-settings",
          };
          this.titleChange.emit("Settings");
          return;
        }
        
        if (currentUrl.includes("/profile")) {
          this.breadcrumbs = {
            parentBreadcrumb: "Profile",
            childBreadcrumb: "",
            parentPath: "/profile",
            childPath: null,
            parentIcon: "ft-user",
            childIcon: "ft-user",
          };
          this.titleChange.emit("Profile");
          return;
        }

        // Special handling for notification list route
        if (currentUrl.includes("/notification/list")) {
          this.breadcrumbs = {
            parentBreadcrumb: "Notifications",
            childBreadcrumb: "",
            parentPath: "/notification/list",
            childPath: null,
            parentIcon: "ft-bell",
            childIcon: "ft-bell",
          };
          this.titleChange.emit("Notifications List");
          return;
        }

        // Default handling
        const parent = route.parent?.snapshot?.data?.breadcrumb || "";
        const child = route.snapshot?.data?.breadcrumb || "";

        this.breadcrumbs = {
          parentBreadcrumb: parent,
          childBreadcrumb: child,
          parentPath:
            routeInfo?.submenu?.length > 0
              ? null
              : this.getParentPath(routeInfo),
          childPath: this.getChildPath(routeInfo),
          parentIcon: this.getParentIcon(routeInfo),
          childIcon: this.getChildIcon(routeInfo),
        };

        const pageTitle = `${parent} ${child}`.trim();
        this.titleChange.emit(pageTitle);
      });
  }

  private findRouteInfo(currentUrl) {
    currentUrl = currentUrl.endsWith("/")
      ? currentUrl.slice(0, -1)
      : currentUrl;
    let route = ROUTES.find((r) => currentUrl.startsWith(r.path));
    return route;
  }

  private getParentPath(routeInfo): string {
    if (!routeInfo) return "";
    // If route has submenu, parent path is the main route path
    if (routeInfo.submenu && routeInfo.submenu.length > 0) {
      return routeInfo.path;
    }
    // If no submenu, parent path is the route path
    return routeInfo.path;
  }

  private getChildPath(routeInfo) {
    if (!routeInfo) return "";
    // If route has submenu, child path is first submenu item's path
    if (routeInfo.submenu && routeInfo.submenu.length > 0) {
      return routeInfo.submenu[0].path;
    }
    // If no submenu, child path is the same as parent path
    return routeInfo.path;
  }

  private getParentIcon(routeInfo) {
    if (!routeInfo) return "";
    return routeInfo.icon;
  }

  private getChildIcon(routeInfo) {
    if (!routeInfo) return "";
    // If route has submenu, child icon is first submenu item's icon
    if (routeInfo.submenu && routeInfo.submenu.length > 0) {
      return routeInfo.submenu[0].icon;
    }
    // If no submenu, child icon is the same as parent icon
    return routeInfo.icon;
  }

  ngOnInit() {}

  ngOnDestroy() {}
}
