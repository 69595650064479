import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SubmitButtonComponent} from './submit-button/submit-button.component';
import {InputComponent} from './input/input.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AbstractFormElement} from './abstract-form-elements';
import {ErrorHandlingComponent} from './error-handling/error-handling.component';
import {DropzoneComponent} from './dropzone/dropzone.component';
import {SelectComponent} from './select/select.component';
import {TextAreaComponent} from './text-area/text-area.component';
import {DatepickerComponent} from './datepicker/datepicker.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgSelectModule} from '@ng-select/ng-select';
import {RadioComponent} from './radio/radio.component';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzSelectModule} from 'ng-zorro-antd/select';
import {NzRadioModule} from 'ng-zorro-antd/radio';
import { TextLengthComponent } from './text-length/text-length.component';
import { TranslateModule } from '@ngx-translate/core';
import { CancelButtonComponent } from './cancel-button/cancel-button.component';


@NgModule({
  declarations: [
    SubmitButtonComponent,
    InputComponent,
    AbstractFormElement,
    ErrorHandlingComponent,
    DropzoneComponent,
    SelectComponent,
    TextAreaComponent,
    DatepickerComponent,
    RadioComponent,
    TextLengthComponent,
    CancelButtonComponent
  ],
    exports: [
        InputComponent,
        SubmitButtonComponent,
        DropzoneComponent,
        SelectComponent,
        TextAreaComponent,
        DatepickerComponent,
        RadioComponent,
        CancelButtonComponent
    ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    NgSelectModule,
    NzToolTipModule,
    NzIconModule,
    NzSelectModule,
    NzRadioModule,
    NgSelectModule,
    TranslateModule
  ]
})
export class InputsModule {
}
