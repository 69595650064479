import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormArray } from '@angular/forms';

@Component({
  selector: 'app-text-length',
  templateUrl: './text-length.component.html',
  styleUrls: ['./text-length.component.scss']
})
export class TextLengthComponent implements OnInit {
  @Input() control: FormControl | FormArray;
  @Input() validators: any;
  constructor() { }

  ngOnInit(): void {
  }

}
