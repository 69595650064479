import { CrudService } from "app/shared/services/crud.service";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { BASE_API_STAFF, M_BASE_URL } from "app/shared/global/var";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  NgbActiveModal,
  NgbDateStruct,
  NgbModal,
  NgbTimeStruct,
} from "@ng-bootstrap/ng-bootstrap";
import { WorkDiaryComponent } from "../work-diary/work-diary.component";
import { AuthService } from "app/shared/auth/auth.service";

@Component({
  selector: "app-intervention-form",
  templateUrl: "./intervention-form.component.html",
  styleUrls: ["./intervention-form.component.scss"],
})
export class InterventionFormComponent implements OnInit {
  @Input() itemId;
  @Input() item;
  @Input() interventionLength;
  @Output() listUpdated: EventEmitter<void> = new EventEmitter<void>();
  @Output() interventionAdded = new EventEmitter<void>();
  operator: any;
  selectedOperators: any[] = [];
  submitted = false;
  operatorError = false;
  totalDuration: number;
  currentOperatorId: number;

  operatorForm!: FormGroup;
  showOperatorForm = false;
  isEditMode = false;
  username :string;
  interventionTypes: any;

  availableOperators: any[] = [];


  constructor(
    private crudService: CrudService,
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private authservice:AuthService)
{
  this.username = this.authservice.getUsernameFromToken();
}
    
  

  /**
   * Initializes the component and checks if it is in edit mode.
   * Calls methods to initialize the forms and fetch the list of operators.
   */

  ngOnInit(): void {
    this.initForms();
    this.getOperatorList();
    this.fetchInterventionTypes();
    if (this.item) {
      this.isEditMode = true;
      this.loadInterventionData();
    }
  }

  /**
   * Initializes the operator form with required form controls and validation rules.
   */

  initForms() {
    this.operatorForm = this.fb.group({
      date: [null, Validators.required],
      time: [null, Validators.required],
      supervisor: ["", Validators.required],
      // duration: [null, [Validators.required, Validators.min(0)]],
      duration: [{ value: '0h 0m', disabled: true }],
      diagnostic: [""],
      oilQuantity: [null, [Validators.min(0)]],
      indexKilometers: [null, [Validators.min(0)]],
      observation: [""],
      selectedOperator: [null],
      interventionTypeId: [null, Validators.required],
    });
  }

  /**
   * Getter method for easy access to the form controls in the template.
   */

  get o() {
    return this.operatorForm.controls;
  }

  /**
   * Loads existing intervention data into the form if the component is in edit mode.
   * Maps the technician data to populate the selected operators.
   */

  loadInterventionData() {
    if (this.item) {
      const date = new Date(this.item.date);
      this.operatorForm.patchValue({
        date: {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate(),
        },
        time: { hour: date.getHours(), minute: date.getMinutes() },
        supervisor: this.item.supervisor,
        duration: this.item.duration,
        diagnostic: this.item.diagnostic,
        oilQuantity: this.item.oilQuantity,
        indexKilometers: this.item.indexKilometers,
        observation: this.item.observation,
        interventionTypeId: this.item.interventionTypeId, 
      });

      // Load selected operators
      if (this.item.techniciens && Array.isArray(this.item.techniciens)) {
        this.selectedOperators = this.item.techniciens.map((tech: any) => ({
          id: tech.id,
          matricule: tech.matricule,
          firstName: tech.firstName,
          lastName: tech.lastName,
          totalWorkDuration: 0,
        }));
      }

      // Fetch work diaries for the current intervention
      const fetchPromises = this.selectedOperators.map((operator) =>
        this.fetchWorkDiaries(operator.id, this.item.id).then((workDiaries) => {
          // Sum durations for each operator
          workDiaries.forEach((diary) => {
            operator.totalWorkDuration += Number(diary.duration);
          });
          // Convert total duration into hours and minutes
          operator.totalWorkDuration = `${Math.floor(
            operator.totalWorkDuration / 60
          )}h ${operator.totalWorkDuration % 60}m`;
        })
      );

      // Wait for all fetch operations to complete
      Promise.all(fetchPromises).then(() => {
        const totalDuration = this.calculateTotalDuration();
        this.operatorForm.patchValue({ duration: totalDuration });
      
      });
    }
  }

  // Function to fetch work diaries
  fetchWorkDiaries(operatorId: number, interventionId: number): Promise<any[]> {
    const url = `${M_BASE_URL}/workDiary/${operatorId}/${interventionId}`;
    return this.crudService.getAll(url).toPromise() as Promise<any[]>;
  }

  /**
   * Closes the current modal window.
   */
  closeModal() {
    this.activeModal.close();
  }

  /**
   * Updates the form's date field when a date is selected.
   * @param date The selected date from the datepicker.
   */

  onDateSelect(date: NgbDateStruct) {
    this.operatorForm.patchValue({ date });
  }

  /**
   * Updates the form's time field when the time is changed.
   * @param time The selected time from the timepicker.
   */

  onTimeChange(time: NgbTimeStruct) {
    this.operatorForm.patchValue({ time });
  }

  /**
   * Retrieves the list of operators from the API and filters them based on the job.
   */

  getOperatorList() {

    const url = `${BASE_API_STAFF }/staff?username=${encodeURIComponent(this.username)}`;
    return this.crudService.getAll(url).subscribe((data: any) => {
      this.operator = data.data;
      this.operator = this.operator.filter((applicant) =>
        applicant.job.includes(3)
      );
      this.operator.forEach(op => {
        op.fullName = `${op.firstName} ${op.lastName}`;
      });
      
      // Initialisez les opérateurs disponibles
      this.updateAvailableOperators();
    });
  }

  updateAvailableOperators() {
    this.availableOperators = this.operator.filter(op => 
      !this.selectedOperators.some(selected => selected.id === op.id)
    );
  }

  /**
   * Displays the operator form to add a new operator.
   */

  addOperator() {
    this.showOperatorForm = true;
  }

  /**
   * Cancels adding a new operator, hides the form, and resets the form.
   */

  cancelAddOperator() {
    this.showOperatorForm = false;
    this.operatorForm.reset();
  }

  /**
   * Saves the selected operator into the list of selected operators.
   * Hides the operator form after saving.
   */

  saveOperator() {
    const selectedOperatorId = this.operatorForm.get("selectedOperator")?.value;
    const selectedOperator = this.operator.find(
      (op) => op.id === selectedOperatorId
    );

    if (selectedOperator) {
      this.selectedOperators.push({
        id: selectedOperator.id,
        matricule: selectedOperator.matricule,
        firstName: selectedOperator.firstName,
        lastName: selectedOperator.lastName,
        totalWorkDuration: this.totalDuration,
      });

      this.showOperatorForm = false;
      this.operatorError = false;
      this.operatorForm.patchValue({ selectedOperator: null,   duration: this.calculateTotalDuration() });
    }
    this.updateAvailableOperators();
  }

  /**
   * Removes an operator from the list of selected operators.
   * @param operator The operator to be removed.
   */

  // removeOperator(operator: any) {
  //   this.selectedOperators = this.selectedOperators.filter(
  //     (op) => op.id !== operator.id
  //   );
  // }
  removeOperator(operator: any) {
    this.selectedOperators = this.selectedOperators.filter(
      (op) => op.id !== operator.id
    );
    
    // Mettez à jour la liste des opérateurs disponibles après la suppression
    this.updateAvailableOperators();
  }

  /**
   * Validates the form by checking both the form controls and if there are any selected operators.
   * @returns {boolean} True if the form is valid, false otherwise.
   */

  isFormValid(): boolean {
    return this.operatorForm.valid && this.selectedOperators.length > 0;
  }

  /**
   * Opens a modal to add a work diary entry for a selected operator.
   * @param operator The operator for which the work diary is being added.
   */

 
  addWorkDiary(operator: any) {
    this.currentOperatorId = operator.id; // Store the current operator's ID

    const modalRef = this.modalService.open(WorkDiaryComponent, {
      size: "xl",
    });

    modalRef.componentInstance.operator = operator;
    modalRef.componentInstance.intervention = this.item;

    modalRef.result.then(
      (totalDuration: number | null) => {
        if (totalDuration !== null) {
          // Ensure it's not null
          this.updateTotalDuration(totalDuration);
        } else {
          // Optionally handle the case where the modal was closed without changes
          this.resetTotalDuration(); // Reset or keep previous duration
        }
      },
      (reason) => {
        // Optionally handle modal dismissal if needed
      }
    );
  }

  resetTotalDuration() {
    const operator = this.selectedOperators.find(
      (op) => op.id === this.currentOperatorId
    );
    if (operator) {
      operator.totalWorkDuration = "0h 0m";
    }
  }

  updateTotalDuration(newDurationInMinutes: number) {
    if (newDurationInMinutes < 0) return; // Validate input

    const operator = this.selectedOperators.find(
      (op) => op.id === this.currentOperatorId
    );

    if (operator) {
      // Parse existing duration to minutes if it was set previously
      const existingDurationParts = operator.totalWorkDuration.split("h");
      const existingHours = parseInt(existingDurationParts[0]) || 0;
      const existingMinutes = parseInt(existingDurationParts[1]) || 0;

      // Calculate total minutes
      const totalMinutes =
        existingHours * 60 + existingMinutes + newDurationInMinutes;

      // Convert back to hours and minutes
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;

      operator.totalWorkDuration = `${hours}h ${minutes}m`; // Format as "Xh Ym"
    }

    // this.totalDuration += newDurationInMinutes;
    this.operatorForm.patchValue({ 
      duration: this.calculateTotalDuration() 
    });
  }
  
  /**
   * Fetch interventions types
   */
  private fetchInterventionTypes(): void {
    const url = `${M_BASE_URL}/interventionType`;
    this.crudService.getAll(url).subscribe(
      (data: any) => {
        this.interventionTypes = data.data.map((item) => ({
          id: item.id,
          name: item.name,
          type:item.type
        }));
        
      },
      (error) => console.error("Error fetching intervention types:", error)
    );
  }

  /**
   * Saves the intervention data by either adding a new intervention or updating an existing one.
   * Handles form validation and submission to the API.
   */

  save() {
    this.submitted = true;
    this.operatorError = this.selectedOperators.length === 0;

    if (this.operatorForm.invalid || this.operatorError) {
      return;
    } else {
      const formValue = this.operatorForm.value;
      const date = formValue.date;
      const time = formValue.time;
      const totalDuration = this.calculateTotalDuration();
      const intervention = {
        ...formValue,
        date: new Date(
          date.year,
          date.month - 1,
          date.day,
          time.hour,
          time.minute
        ).toISOString(),
        duration: totalDuration,
        workOrderId: this.isEditMode ? this.item.workOrderId : this.itemId,
        techniciens: this.selectedOperators.map((op) => ({ id: op.id })),
      };
      const url = this.isEditMode
        ? `${M_BASE_URL}/intervention/update`
        : `${M_BASE_URL}/intervention/add`;

      if (this.isEditMode) {
        this.crudService.update(url, this.item.id, intervention).subscribe(
          (response) => {
            this.listUpdated.emit();
            this.activeModal.close(response);
          },
          (error) => {
            console.error("Error updating intervention:", error);
          }
        );
      } else {
        this.crudService.post(url, intervention).subscribe(
          (response) => {
           
            this.activeModal.close(response);

            const workOrderData = { status: 2 };
            
            this.crudService
              .update(
                `${M_BASE_URL}/workOrder/updateStatus`,
                this.itemId,
                workOrderData
              )
              .subscribe(
                () => {
                  this.listUpdated.emit();
              
                },
                (error) => {
                  console.error("Error updating work order status:", error);
                }
              );
          },
          (error) => {
            console.error("Error adding intervention:", error);
          }
        );
      }
    }
  }

  calculateTotalDuration(): string {
    let totalMinutes = 0;
    
    this.selectedOperators.forEach(operator => {
      if (operator.totalWorkDuration && typeof operator.totalWorkDuration === 'string') {
        const durationParts = operator.totalWorkDuration.split('h');
        if (durationParts.length === 2) {
          const hours = parseInt(durationParts[0]) || 0;
          const minutes = parseInt(durationParts[1]) || 0;
          totalMinutes += (hours * 60) + minutes;
        }
      }
    });

    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}h ${minutes}m`;
  }

  shouldShowOilQuantity(): boolean {
    if(this.interventionTypes){
    const selectedInterventionType = this.interventionTypes.find(
      type => type.id === this.operatorForm.get('interventionTypeId').value
    );
    
    return selectedInterventionType ? [1, 2].includes(selectedInterventionType.type) : false;
  }
}
}
