import { FormGroup, Validators } from "@angular/forms";
import { CrudService } from "../services/crud.service";
import { AppInjector } from "app/app.module";


let oldConsoleLog = null;

export function toFormData<T>(formValue: T) {
  const formData = new FormData();

  for (const key of Object.keys(formValue)) {
    const value = formValue[key];
    if (Array.isArray(value)) {
      if (!value.length) {
        formData.append(`${key}`, '[]');
      } else {
        value.forEach(val => {
          if (typeof val === 'object' && !(val instanceof File)) {

            formData.append(`${key}[]`, JSON.stringify(val));

          } else {
            formData.append(`${key}[]`, val);
          }
        });
      }
    } else {
      if (typeof value === 'object' && value !== null && !(value instanceof File)) {
        for (const keyValue in value) {
          formData.append(`${key}[${keyValue}]`, value[keyValue]);
        }
      } else
      {
        formData.append(key, value);
      }
    }
  }
  return formData;
}

export function pad(num, size) {
  num = num.toString();
  while (num.length < size) {
    num = '0' + num;
  }
  return num;
}

export function dateFromUnixTimeStamp(date): string {
  return convertDateToString(new Date(date * 1000));
}

export function convertDateToString(date: Date): string {
  if (date) {
    return `
    ${date.getFullYear()}-${pad(date.getMonth() + 1, 2)}-${pad(date.getDate(), 2)}
    ${pad(date.getHours(), 2)}:${pad(date.getMinutes(), 2)}:${pad(date.getSeconds(), 2)}
    `;
  }
  return '';
}

export function prepareValidators(field: any, form: FormGroup, modelId: string) {
  const validators = [];
  if (field.validators?.required && (field.type !== 'password' || !modelId)) {
    validators.push(Validators.required);
  }
  if (typeof field.validators?.maxLength === 'number') {
    validators.push(Validators.maxLength(field.validators.maxLength));
  }
  if (typeof field.validators?.minLength === 'number') {
    validators.push(Validators.minLength(field.validators.minLength));
  }
  if (field.validators?.email) {
    validators.push(Validators.email);
  }
  if (field.validators?.regex) {
    validators.push(Validators.pattern(field.validators.regex));
  }
  if (typeof field.validators?.min === 'number') {
    validators.push(Validators.min(field.validators.min));
  }
  if (typeof field.validators?.max === 'number') {
    validators.push(Validators.max(field.validators.max));
  }

  return validators;
}


/**
 * Get related model
 * @param url url of related model
 * @param search indicated which field to search on and its value
 * @param formControlName form control to set null
 */
export async function getRelatedModel<T>(
  url: string,
  search: { relatedField: string, relatedValue: any } = null,
  formControlName = null
) {
  return AppInjector
    .get(CrudService)
    .getAll<T>(url + (search ? (url.includes('?') ? '&' : '?') + search.relatedField + '=' + search.relatedValue : ''))
    .toPromise();
}

export function hideField(fieldName: string, hide: boolean, fields: any[], form: FormGroup, is_parent = false) {
  for (const field of fields) {
    if (field.name === fieldName.split('.').slice(-1)[0]) {
      if (!is_parent) {
        field.hide = hide;
      }
      field.validators.required = !hide && field.requiredWhenNotHidden;
      if (form.get(fieldName)) {
        if (hide) {
          form.get(fieldName).reset();
        }
        form.get(fieldName).setValidators(prepareValidators(field, form, null));
      }
      if (field.children?.length) {
        for (const child of field.children) {
          hideField(fieldName + '.' + child.name, hide, field.children, form, true);
        }
      }
      break;
    }
  }
}





export function enableLogger() {
  if (oldConsoleLog == null) {
    return;
  }
  window.console.log = oldConsoleLog;
}

export function disableLogger() {
  oldConsoleLog = console.log;
  window.console.log = () => {
  };
}
