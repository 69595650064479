<div class="timeline-container">
  <button class="nav-button prev" [class.disabled]="isAtTop" [disabled]="isAtTop" (click)="scrollUp()">
    <i class="arrow up"></i>
  </button>

  <div class="timeline-container">
    <div class="timeline-content" #timelineContent>
      <div class="timeline" [@scrollAnimation]="scrollDirection" (@scrollAnimation.done)="onAnimationDone()">
        <div class="timeline-event" *ngFor="let event of displayedEvents; let i = index" [style.marginTop]="event.spacing + 'px'">
          <div class="start-point" *ngIf="event.isStart">
            <div class="timeline-bullet start"></div>
            <div class="timeline-details start">
              <div class="start-label">{{event.route}}</div>
            </div>
          </div>
  
          <div *ngIf="!event.isStart">
            <div class="timeline-bullet"></div>
            <div class="timeline-details">
              <div class="event-info">
                <div class="line" *ngIf="event.line">Line: {{ event.line }}</div>
                <div class="route">Route: {{ event.route }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <button class="nav-button next" [class.disabled]="isAtBottom" [disabled]="isAtBottom" (click)="scrollDown()">
    <i class="arrow down"></i>
  </button>
</div>