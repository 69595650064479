import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-refuels-modal',
  templateUrl: './refuels-modal.component.html',
  styleUrls: ['./refuels-modal.component.scss']
})
export class RefuelsModalComponent implements OnInit {

  @Input() refuels: any[];
  @Input() refuellingNumber: string;
  @Input() refuellingType: number;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
  }
  convertStringToDate(time: string): Date {
    if (!time) return new Date();
    const [hours, minutes] = time.split(':');
    const date = new Date();
    date.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0);
    return date;
  }

}
