<div class="modal-header">
  <h4 class="modal-title">{{"DELETE_TITLE" | translate}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
    <i class="fa-solid fa-xmark"></i>
  </button>
</div>
<div class="modal-body">
  <p>{{"DELETE_MESSAGE" | translate}} <strong> {{itemName}} </strong> ?</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="deleteItem()">{{"DELETE" | translate}}</button>
  <button type="button" class="btn btn-secondary" (click)="activeModal.close('Close click')">{{"CANCEL" |
    translate}}</button>
</div>
