<nav class="navbar navbar-expand-lg navbar-light header-navbar {{transparentBGClass}}" [ngClass]="{'navbar-brand-center': menuPosition === 'Top', 'navbar-static': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Static' , 'navbar-sticky': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Fixed', 'fixed-top': isSmallScreen && menuPosition === 'Top'}">
  <div class="container-fluid navbar-wrapper">
    <div class="navbar-header d-flex">
      <div class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"
        data-toggle="collapse" (click)="toggleSidebar()">
        <i class="ft-menu font-medium-3"></i>
      </div>
      <ul class="navbar-nav" (clickOutside)="toggleSearchOpenClass(false)">
        <li class="nav-item mr-2 d-none d-lg-block">
          <a class="nav-link apptogglefullscreen" id="navbar-fullscreen" href="javascript:;" appToggleFullscreen
            (click)="ToggleClass()"><i class=" {{toggleClass}} font-medium-3"></i></a>
        </li>

      </ul>
      <div class="navbar-brand-center">
        <div class="navbar-header">
          <ul class="navbar-nav">
            <li class="nav-item">
              <div class="logo"><a class="logo-text" [routerLink]="['/']" *ngIf="config?.layout.menuPosition === 'Top'">
                  <div class="logo-img"><img class="logo-img" alt="SAE logo" [src]="logoUrl"></div>
                </a></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="navbar-container">
      <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <!-- seetings icon -->
          <li class=" nav-item mr-2 " [appHasPermission]="['Company Settings']">
            <a class="ft-settings font-medium-3  nav-link d-flex align-items-center dropdown-language settings"
              ngbTooltip="{{'COMPANY-SETTINGS' | translate}}" (click)="gotToSettings()"></a>
          </li>
          <li class="dropdown nav-item" placement="bottom-left" display="static" ngbDropdown
            [appHasPermission]="['Notification_List']">
            <a class="nav-link dropdown-toggle dropdown-notification p-0 " id="drp-notification" href="javascript:;"
              data-toggle="dropdown" ngbDropdownToggle>
              <i class="ft-bell font-medium-3 "> </i>
              <span class="notification badge badge-pill badge-danger" *ngIf="unreadCount > 0">
                +{{ unreadCount }}
              </span>
            </a>
            <ul ngbDropdownMenu
              class="notification-dropdown dropdown-menu dropdown-menu-media dropdown-menu-right m-0 overflow-hidden">
              <li class="dropdown-menu-header">
                <div class="dropdown-header d-flex justify-content-between justify-content-center m-0 px-3 py-2 white bg-primary">
                  <div class="d-flex">
                    <i class="ft-bell font-medium-3 d-flex align-items-center mr-2"></i>
                    <span class="noti-title">
                      {{ unreadCount > 0
                      ? (unreadCount + ' ' + ('NEW_NOTIFICATIONS' | translate) + (unreadCount !== 1 ? 's' : ''))
                      : ('NO_NEW_NOTIFICATIONS' | translate) }}
                    </span>
                  </div>
                  <span *ngIf="unreadCount > 0" class="text-bold-400 cursor-pointer" (click)="markAllAsRead()">
                    {{"MARK_READ" | translate}}
                  </span>
                </div>
              </li>
              <li class="scrollable-container" [perfectScrollbar]>
                <a class="d-flex justify-content-between" href="javascript:void(0)"
                  *ngFor="let notification of notifications.slice(0, 10)" (click)="markMessageAsRead(notification)">
                  <div class="media d-flex align-items-center">
                    <div class="media-body">
                      <h6 class="m-0">
                        <small class="grey lighten-1 font-italic float-right">
                          {{ notification.messageDate }}
                        </small>
                      </h6>
                      <small class="noti-text" [class.font-weight-bold]="!notification.read">
                        {{ notification.message }}
                      </small>
                      <div *ngIf="isMaintenanceNotification(notification)" class="mt-2">
                        <button class="btn btn-sm btn-primary mr-2 " style="font-size: 0.78rem;"
                          (click)="createWorkRequest(notification, $event)">
                          {{ "CREATE_WORK_REQUEST" | translate }}
                        </button>
                        <button class="btn btn-sm btn-secondary " style="font-size: 0.78rem;"
                          (click)="remindMeTomorrow(notification, $event)">
                          {{ "Remind_Me_Tomorrow" | translate }}
                        </button>
                      </div>
                    </div>
                  </div>
                </a>
              </li>
              <li class="dropdown-menu-footer">
                <div class="noti-footer text-center cursor-pointer primary border-top text-bold-400 py-1"
                  (click)="goToNotification()">
                  {{"READ_ALL_NOTIFICATIONS"|translate}}
                </div>
              </li>
            </ul>
          </li>
          <li class="dropdown nav-item mr-1" placement="bottom-left" display="static" ngbDropdown>
            <a class="nav-link dropdown-toggle user-dropdown d-flex align-items-end" id="dropdownBasic2"
              href="javascript:;" data-toggle="dropdown" ngbDropdownToggle>
              <div class="user d-md-flex d-none mr-2">
                <span class="text-right">{{username}}</span>
                <span *ngIf="user" class="text-right text-muted font-small-3 text-success">{{'Active'}}</span>
              </div>
              <img class="avatar p-0"
                [src]="user?.profileImage ? user?.profileImage : 'assets/img/portrait/default-user.png'" alt="avatar"
                height="35" width="35" />
            </a>
            <div class="dropdown-menu text-left dropdown-menu-right m-0 pb-0" aria-labelledby="dropdownBasic2"
              ngbDropdownMenu>
              <!-- <a class="dropdown-item" href="javascript:;">
                <div class="d-flex align-items-center">
                  <i class="ft-message-square mr-2"></i><span>Chat</span>
                </div>
              </a> -->
              <a (click)="goToProfile()" class="dropdown-item" href="javascript:;"
                [appHasPermission]="['User_Profile']">
                <div class="d-flex align-items-center">
                  <i class="ft-edit mr-2"></i><span>{{"EDIT_PROFILE"|translate}}</span>
                </div>
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" (click)="logout()">
                <div class="d-flex align-items-center">
                  <i class="ft-power mr-2"></i><span>{{"Logout"|translate}}</span>
                </div>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>