import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LayoutService {
  private toggleSidebar = new Subject<boolean>(); // small screen
  private overlaySidebarToggle = new Subject<boolean>();
  private toggleNotiSidebar = new Subject<boolean>();

  private toggleFilter = new Subject<boolean>(); // small screen
  private overlayFilterToggle = new Subject<boolean>();
  private toggleFilterBar = new Subject<boolean>();

  // Observable
  toggleSidebar$ = this.toggleSidebar.asObservable();
  overlaySidebarToggle$ = this.overlaySidebarToggle.asObservable();
  toggleNotiSidebar$ = this.toggleNotiSidebar.asObservable();

  toggleFilter$ = this.toggleFilter.asObservable();
  overlayFilterToggle$ = this.overlayFilterToggle.asObservable();
  toggleFilterBar$ = this.toggleFilterBar.asObservable();

  toggleSidebarSmallScreen(toggle: boolean) {
    this.toggleSidebar.next(toggle);
  }

  overlaySidebartoggle(toggle: boolean) {
    this.overlaySidebarToggle.next(toggle);
  }

  toggleNotificationSidebar(toggle: boolean) {
    this.toggleNotiSidebar.next(toggle);
  }

  toggleFilterSmallScreen(toggle: boolean) {
    this.toggleFilter.next(toggle);
  }

  overlayFiltertoggle(toggle: boolean) {
    this.overlayFilterToggle.next(toggle);
  }

  toggleLineFilter(test: boolean) {
    this.toggleFilterBar.next(test);
  }
}
