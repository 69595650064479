<div class="modal-header">
    <h4 class="modal-title">
        {{ 'REFUELS_FOR' | translate }} {{ refuellingType == 1 ? ('Voucher' | translate) : ('Document' | translate) }} #{{ refuellingNumber }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <i class="fa-solid fa-xmark"></i>
    </button>
</div>

<div class="modal-body">
  <div class="table-responsive">
    <table class="table table-striped">
        <thead>
            <tr>
                <th>{{ 'Vehicle' | translate }}</th>
                <th>{{ 'Driver' | translate }}</th>
                <th>{{ 'Quantity' | translate }}</th>
                <th>{{ 'Kilometer_Index' | translate }}</th>
                <th>{{ 'Time' | translate }}</th>
                <th>{{ 'Fuel_Type' | translate }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let refuel of refuels">
                <td class="text-center">
                    <ng-container *ngIf="refuel.vehicle">
                        <div class="media p-0">
                            <div class="media-body text-left">
                                <h4 class="font-small-3 mb-0">
                                    {{refuel.vehicle.model?.vehicleBrand?.brandName}} {{refuel.vehicle?.model?.name}}
                                </h4>
                                <p class="text-muted font-small-2 m-0"># {{refuel.vehicle?.vehicleNumber}}</p>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!refuel.vehicle">
                        <div style="font-size: 30px;">
                            <i class="fa-solid fa-ellipsis text-muted font-medium-4"></i>
                        </div>
                    </ng-container>
                </td>

                <td class="text-center">
                    <ng-container *ngIf="refuel.driver">
                        <div class="media p-0">
                            <div class="media-body text-left">
                                <h4 class="font-small-3 mb-0">{{refuel.driver.firstName}} {{refuel.driver.lastName}}</h4>
                                <p class="text-muted font-small-2 m-0"># {{refuel.driver.matricule}}</p>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!refuel.driver">
                        <div style="font-size: 30px;">
                            <i class="fa-solid fa-ellipsis text-muted font-medium-4"></i>
                        </div>
                    </ng-container>
                </td>

                <td class="text-center">
                    <div class="media p-0">
                        <div class="media-body text-left">
                            <h4 class="font-small-3 mb-0">{{ refuel.quantity }}</h4>
                        </div>
                    </div>
                </td>

                <td class="text-center">
                    <div class="media p-0">
                        <div class="media-body text-left">
                            <h4 class="font-small-3 mb-0">{{ refuel.kilometerIndex }}</h4>
                        </div>
                    </div>
                </td>

                <td>
                    <div class="media p-0">
                        <div class="media-body text-left">
                            <h4 class="font-small-3 mb-0">{{ convertStringToDate(refuel.time) | date:'HH\'h\' mm\'min\'' }}</h4>
                        </div>
                    </div>
                </td>

                <td class="text-center">
                    <div class="media p-0">
                        <div class="media-body text-left">
                            <h4 class="font-small-3 mb-0">{{ refuel.fuelType }}</h4>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
  </div>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">
        {{ 'CLOSE' | translate }}
    </button>
</div>
