<div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
        <i class="fa-solid fa-xmark"></i>
    </button>
</div>

<section id="striped-light">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ "CURRENTLY_IN_PROGRESS_TRIPS" | translate }}</h4>
                </div>
                <div class="card-content">
                    <div class="card-body">
                        <div *ngIf="trips && trips.length > 0">
                            <div class="table-responsive">
                                <table class="table table-striped m-0">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>{{ "TRIP" | translate }}</th>
                                            <th>{{ "DEPART" | translate }}</th>
                                            <th>{{ "DIFFERENCE" | translate }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let trip of trips; let i = index">
                                            <td>{{ i + 1 }}</td>
                                            <td>{{ trip.lineDirection }}</td>
                                            <td>{{ trip.plannedDeparture | date: 'HH:mm' }}</td>
                                            <td>
                                                <!-- Info (Blue) for minor advancement or minor delay -->
                                                <ng-container
                                                    *ngIf="(trip.difference <= 0 && trip.difference > -1 * delayConstraints['minorDelay'] && trip.difference > -1 * delayConstraints['majorDelay'])
                                          || (trip.difference > 0 && trip.difference <  delayConstraints['minorAdvancement'] && trip.difference < delayConstraints['minorAdvancement'])">
                                                    <span class="badge bg-info text-bold-400">
                                                        {{ -1 * trip.difference}} min
                                                    </span>
                                                </ng-container>

                                                <!-- Warning (Orange) for minor delay -->
                                                <ng-container
                                                    *ngIf="(trip.difference <= -1 * delayConstraints['minorDelay'] && trip.difference > -1 * delayConstraints['majorDelay'])
                                          || (trip.difference > 0 && trip.difference >=  delayConstraints['minorAdvancement'] && trip.difference < delayConstraints['majorAdvancement'])">
                                                    <span class="badge bg-warning text-bold-400">
                                                        {{ trip.difference }} min
                                                    </span>
                                                </ng-container>

                                                <!-- Red (Danger) for major delay -->
                                                <ng-container
                                                    *ngIf="(trip.difference <= -1 * delayConstraints['majorDelay'])
                                          || (trip.difference > 0 && trip.difference >=  delayConstraints['majorAdvancement'])">
                                                    <span class="badge bg-danger text-bold-400">
                                                        {{ trip.difference }} min
                                                    </span>
                                                </ng-container>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>