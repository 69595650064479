import { formatDate } from "@angular/common";
import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import {NgbDatepickerI18n, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import * as moment from "moment";
import {CustomDatepickerI18nService} from '../../services/custom-datepicker-i18n.service';
const now = new Date();
const I18N_VALUES = {
  en: {
    weekdays: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
    months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
};

const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one &&
  two &&
  two.year === one.year &&
  two.month === one.month &&
  two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) => {
  if (!one || !two) {
    return false;
  }

  if (one.year !== two.year) {
    return one.year < two.year;
  }

  if (one.month !== two.month) {
    return one.month < two.month;
  }

  if (one.day !== two.day) {
    return one.day < two.day;
  }

  return false;
};

const after = (one: NgbDateStruct, two: NgbDateStruct) => {
  if (!one || !two) {
    return false;
  }

  if (one.year !== two.year) {
    return one.year > two.year;
  }

  if (one.month !== two.month) {
    return one.month > two.month;
  }

  if (one.day !== two.day) {
    return one.day > two.day;
  }

  return false;
};

@Component({
  selector: "app-date-range-filter",
  templateUrl: "./date-range-filter.component.html",
  styleUrls: [
    "./date-range-filter.component.scss",
    "../../../../assets/sass/libs/datepicker.scss",
  ],
  providers: [
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18nService },
  ],
})
export class DateRangeFilterComponent implements OnInit {
  @Output() dateRangeSelected: EventEmitter<{
    startDate: string;
    endDate: string;
  }> = new EventEmitter();
  startDate = moment().format("YYYY-MM-DD");
  endDate = moment().format("YYYY-MM-DD");
  langue = localStorage.getItem("langue");
  dateRange: NgbDateStruct[] | null = null;
  showCalendar: boolean = false;
  d: any;
  d2: any;
  d3: any;
  model: NgbDateStruct;
  popupModel;
  date: { year: number; month: number };
  displayMonths = 2;
  navigation = "select";
  disabledModel: NgbDateStruct = {
    year: now.getFullYear(),
    month: now.getMonth() + 1,
    day: now.getDate(),
  };
  disabled = true;
  customModel: NgbDateStruct;
  configModal;
  hoveredDate: NgbDateStruct;
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  ranges = [
    {
      label: this.langue === "fr" ? "Période personnalisée" : "Custom Range",
      value: [],
    },
  ];

  

  selectedRange: { label: string; value: NgbDateStruct[] } = this.ranges[0]; // Initialize with the first range

  /**
   * Formats a date range into a string.
   *
   * @param dateRange An array of NgbDateStruct representing the date range.
   * @returns A formatted string representing the date range.
   */

  formatDateRange(dateRange: NgbDateStruct[]): string {
    if (!dateRange || dateRange.length !== 2 || !this.selectedRange) {
      return "";
    }

    if (dateRange[0] && dateRange[1]) {
      const startDate = moment({
        year: dateRange[0].year,
        month: dateRange[0].month - 1,
        day: dateRange[0].day,
      }).format("MM/DD/YYYY");
      const endDate = moment({
        year: dateRange[1].year,
        month: dateRange[1].month - 1,
        day: dateRange[1].day,
      }).format("MM/DD/YYYY");
      return `${startDate} > ${endDate}`;
    }
  }

  /**
   * Handles click event on a date range.
   *
   * @param selectedRange The selected date range.
   * @param event The click event.
   */

  onRangeClick(
    selectedRange: { label: string; value: NgbDateStruct[] },
    event: Event
  ): void {
    event.preventDefault();
    event.stopPropagation();

    this.selectedRange = selectedRange;
    this.showCalendar = true; // Always show the calendar
    this.dateRange = null;
  }

  /**
   * Checks if a date is hovered.
   *
   * @param date The date to check.
   * @returns A boolean indicating whether the date is hovered.
   */

  isHovered = (date) =>
    this.fromDate &&
    !this.toDate &&
    this.hoveredDate &&
    after(date, this.fromDate) &&
    before(date, this.hoveredDate);

  /**
   * Checks if a date is inside the selected range.
   *
   * @param date The date to check.
   * @returns A boolean indicating whether the date is inside the selected range.
   */

  isInside = (date) => after(date, this.fromDate) && before(date, this.toDate);

  /**
   * Checks if a date is the start date of the selected range.
   *
   * @param date The date to check.
   * @returns A boolean indicating whether the date is the start date of the selected range.
   */

  isFrom = (date) => equals(date, this.fromDate);

  /**
   * Checks if a date is the end date of the selected range.
   *
   * @param date The date to check.
   * @returns A boolean indicating whether the date is the end date of the selected range.
   */

  isTo = (date) => equals(date, this.toDate);

  /**
   * Converts a JavaScript Date object to NgbDateStruct.
   *
   * @param date The JavaScript Date object to convert.
   * @returns The NgbDateStruct equivalent of the input date.
   */

  private toNgbDate(date: Date): NgbDateStruct {
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
  }

  /**
   * Handles date change event.
   *
   * @param date The selected date.
   */

  onDateChange(date: NgbDateStruct) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && after(date, this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    if (this.selectedRange.label === "Custom Range" || this.selectedRange.label === "Période personnalisée") {
      this.selectedRange.value = [this.fromDate, this.toDate];

      const formattedFromDate = this.fromDate
        ? formatDate(
            new Date(
              this.fromDate.year,
              this.fromDate.month - 1,
              this.fromDate.day
            ),
            "yyyy-MM-dd",
            "en-US"
          )
        : "";
      const formattedToDate = this.toDate
        ? formatDate(
            new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day),
            "yyyy-MM-dd",
            "en-US"
          )
        : "";
      if (formattedFromDate != null && formattedToDate != "") {
        this.dateRangeSelected.emit({
          startDate: formattedFromDate,
          endDate: formattedToDate,
        });
      }
    }
  }

  /**
   * Selects today's date.
   */

  selectToday() {
    this.model = {
      year: now.getFullYear(),
      month: now.getMonth() + 1,
      day: now.getDate(),
    };
  }

  /**
   * Checks if a date is a weekend.
   *
   * @param date The date to check.
   * @returns A boolean indicating whether the date is a weekend.
   */

  isWeekend(date: NgbDateStruct) {
    const d = new Date(date.year, date.month - 1, date.day);
    return d.getDay() === 0 || d.getDay() === 6;
  }

  /**
   * Checks if a date is disabled.
   *
   * @param date The date to check.
   * @param current The current month.
   * @returns A boolean indicating whether the date is disabled.
   */

  isDisabled(date: NgbDateStruct, current: { month: number }) {
    return date.month !== current.month;
  }

  /**
   * Initializes the component.
   */

  ngOnInit() {
    this.showCalendar = true;

    const today = new Date();
    const futureDate = new Date(today);
    futureDate.setDate(today.getDate() + 6);

    this.fromDate = this.toNgbDate(today);
    this.toDate = this.toNgbDate(futureDate);

    this.selectedRange.value = [this.fromDate, this.toDate];
    if (this.selectedRange.value.length >= 2) {
      this.dateRangeSelected.emit({
        startDate: formatDate(
          new Date(
            this.selectedRange.value[0].year,
            this.selectedRange.value[0].month - 1,
            this.selectedRange.value[0].day
          ),
          "yyyy-MM-dd",
          "en-US"
        ),
        endDate: formatDate(
          new Date(
            this.selectedRange.value[1].year,
            this.selectedRange.value[1].month - 1,
            this.selectedRange.value[1].day
          ),
          "yyyy-MM-dd",
          "en-US"
        ),
      });
    }
  }
}
