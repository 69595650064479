import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as echarts from 'echarts';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() data: any;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() units: string;
  @Input() id: string;
  @Input() xAxisLabel: string = '';
  @Input() yAxisLabel: string = '';
  @Input() xAxisData: string[] = [];

  @ViewChild('chartContainer') chartContainer!: ElementRef;
  private chartInstance: echarts.ECharts | null = null;

  constructor() {}

  ngOnInit(): void {}
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      this.chartInstance?.dispose();
      this.initBarChart();
    }
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.initBarChart();
    });
  }

  ngOnDestroy(): void {
    if (this.chartInstance) {
      this.chartInstance.dispose();
    }
  }

  initBarChart(): void {
    if (!this.chartContainer) return;

    if (this.chartInstance) {
      this.chartInstance.dispose();
    }

    this.chartInstance = echarts.init(this.chartContainer.nativeElement);
    
    const option = {
      title: {
        text: this.title,
        subtext: this.subtitle,
        left: 'center'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        formatter: (params: any) => {
          let tooltip = `${params[0].axisValue}<br/>`;
          params.forEach((param: any) => {
            tooltip += `${param.seriesName}: ${param.value} ${this.units}<br/>`;
          });
          return tooltip;
        }
      },
      legend: {
        data: this.data.map((d: any) => d.name),
        top: '7%',
        left: '75%',
        icon: 'circle',
        itemWidth: 10,
        itemHeight: 10
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: this.xAxisData,
        name: this.xAxisLabel,
        axisLabel: {
          rotate: 45,
          interval: 0
        },
        splitLine: {
          show: false  // Cache les lignes verticales
        }
      },
      yAxis: {
        type: 'value',
        name: this.yAxisLabel,
        axisLabel: {
          formatter: '{value}'
        },
        splitLine: {
          show: false  // Cache les lignes horizontales
        }
      },
      series: this.data.map((d: any) => ({
        name: d.name,
        type: 'bar',
        data: d.data,
        emphasis: {
          itemStyle: {
            color: '#ff4444',
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }))
    };

    this.chartInstance.setOption(option);

    window.addEventListener('resize', () => {
      if (this.chartInstance) {
        this.chartInstance.resize();
      }
    });
  }
}