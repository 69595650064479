import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "app/shared/auth/auth.service";
import { NgbdSortableHeader } from "app/shared/directives/sortable.directive";
import { OPN_BASE_URL } from "app/shared/global/var";
import { CrudService } from "app/shared/services/crud.service";
import { NotyService } from "app/shared/services/noty.service";
import { tripOverlapValidator } from "app/shared/validators/tripOverlapValidator";
import { BehaviorSubject, forkJoin } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Component({
  selector: "app-group-services-management",
  templateUrl: "./group-services-management.component.html",
  styleUrls: ["./group-services-management.component.scss"],
})
export class GroupServicesManagementComponent implements OnInit {
  hasOverlap: boolean = false;
  submitted = false;
  trips: any[] = [];
  isOpen = true;
  isEdit = false;
  selectedItem: any;
  selectedTrips: any;
  routes: any;
  username: string;
  missingAssociatedTrips: any[] = [];
  allTrips: any[] = [];
  displayedTrips: any[] = [];

  filteredTrips: any[] = []; // Nouvelle propriété pour les trips filtrés

  @Input() itemId: string;
  private tripsSubject = new BehaviorSubject<any[]>([]);
  public trips$ = this.tripsSubject.asObservable();
  @Output() itemAdded: EventEmitter<void> = new EventEmitter<void>();
  @Output() itemEdited: EventEmitter<void> = new EventEmitter<void>();
  langue = localStorage.getItem("langue");

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  serviceGroupForm = new UntypedFormGroup({
    name: new UntypedFormControl("", [Validators.required]),
    trips: new UntypedFormControl(
      [],
      [Validators.required, tripOverlapValidator(this.trips)]
    ),
    flatRateHours: new UntypedFormControl("",  [
      Validators.required, 
      Validators.min(0)
    ]),
  });

  constructor(
    public activeModal: NgbActiveModal,
    private crudService: CrudService,
    private authservice: AuthService,
    private notyService: NotyService
  ) {
    this.username = this.authservice.getUsernameFromToken();
  }

  /**
   * Initializes the component, retrieves necessary data, and sets up editing mode if required.
   */

  ngOnInit(): void {
    this.loadInitialData();

    this.serviceGroupForm
      .get("trips")
      ?.valueChanges.subscribe((selectedIds) => {
        this.getSelectedTrips();
        this.filterTripsByService(selectedIds);
      });
  }

  filterTripsByService(selectedIds: number[]) {
    if (!selectedIds || selectedIds.length === 0) {
      // Show all trips if no trip is selected
      this.filteredTrips = this.trips;
      return;
    }

    // Get the `idService` of the first selected trip
    const selectedTrip = this.trips.find((trip) => trip.id === selectedIds[0]);
    if (selectedTrip && selectedTrip.idService) {
      // Filter trips with the same `idService`
      this.filteredTrips = this.trips.filter(
        (trip) => trip.idService === selectedTrip.idService
      );
    } else {
      // Show all trips if no matching idService is found
      this.filteredTrips = this.trips;
    }
  }

  /**
   * Loads the initial data required for the component.
   * Fetches trips and routes data from the server and stores them in component properties.
   * If the component is in edit mode, it fetches the service group data to edit.
   */

  // loadInitialData() {
  //   forkJoin({
  //     trips: this.crudService.getAll<any>(
  //       `${OPN_BASE_URL}/trip?username=${encodeURIComponent(this.username)}`
  //     ),
  //     routes: this.crudService.getAll<any>(
  //       `${OPN_BASE_URL}/route?username=${encodeURIComponent(this.username)}`
  //     ),
  //   })
  //     .pipe(
  //       map((results) => {
  //         // this.trips = results.trips;
  //         this.trips = results.trips.filter(trip => trip.idRent === null);
  //         this.filteredTrips =this.trips;

  //         this.routes = results.routes;
  //         if (!this.isEdit) {
  //           this.editServiceGroup(this.itemId);
  //         }
  //       }),
  //       catchError((error) => {
  //         console.error("Error loading initial data:", error);
  //         return [];
  //       })
  //     )
  //     .subscribe();
  // }

  loadInitialData() {
    forkJoin({
      trips: this.crudService.getAll<any>(
        `${OPN_BASE_URL}/trip?username=${encodeURIComponent(this.username)}`
      ),
      routes: this.crudService.getAll<any>(
        `${OPN_BASE_URL}/route?username=${encodeURIComponent(this.username)}`
      ),
    })
      .pipe(
        map((results) => {
          this.trips = results.trips.filter((trip) => trip.idRent === null);
          this.filteredTrips = this.trips;
          this.routes = results.routes;

          // Update the validator with the new trips
          const tripsControl = this.serviceGroupForm.get("trips");
          if (tripsControl) {
            tripsControl.setValidators([
              Validators.required,
              tripOverlapValidator(this.trips),
            ]);
            tripsControl.updateValueAndValidity();
          }

          if (!this.isEdit) {
            this.editServiceGroup(this.itemId);
          }
        }),
        catchError((error) => {
          console.error("Error loading initial data:", error);
          return [];
        })
      )
      .subscribe();
  }

  getOverlapError(): string | null {
    const tripsControl = this.serviceGroupForm.get("trips");
    if (tripsControl?.errors?.["tripOverlap"]) {
      const error = tripsControl.errors["tripOverlap"];
      return this.langue === "fr"
        ? `Chevauchement détecté entre les voyages de ${error.trip1.code} et ${error.trip2.code}`
        : `Overlap detected between trips at ${error.trip1.code} and ${error.trip2.code}`;
    }
    return null;
  }

  /**
   * Edits the service group with the specified ID.
   *
   * @param serviceGroupId The ID of the service group to edit.
   */

  editServiceGroup(serviceGroupId: string) {
    if (!serviceGroupId) {
      console.error("Service group ID is undefined");
      return;
    }

    this.selectedItem = serviceGroupId;
    this.crudService
      .getOne<any>(OPN_BASE_URL + "/serviceGroup", serviceGroupId)
      .subscribe(
        (serviceGroup: any) => {
          const tripIds = serviceGroup.trips.map((trip: any) => trip.id);
          this.serviceGroupForm.patchValue({
            id: serviceGroup.id,
            name: serviceGroup.name,
            flatRateHours: serviceGroup.flatRateHours,
            trips: tripIds,
          });
          this.isOpen = true;
          this.isEdit = true;
        },
        (error) => {
          console.error("Error retrieving service for editing:", error);
        }
      );
  }

  /**
   * Saves the form data. Handles both adding a new service group and updating an existing one.
   */

  saveForm() {
    this.submitted = true;
    if (this.serviceGroupForm.invalid) {
      return;
    }
    const transformedTrips = this.serviceGroupForm.value.trips.map(
      (tripId: number) => ({ id: tripId })
    );
    const formValue = {
      ...this.serviceGroupForm.value,
      trips: transformedTrips,
    };
    if (this.isEdit) {
      this.crudService
        .update(
          OPN_BASE_URL + "/serviceGroup/update",
          this.itemId.toString(),
          formValue
        )
        .subscribe(
          (response) => {
            this.itemEdited.emit();
            this.serviceGroupForm.reset();
            this.submitted = false;
            this.isOpen = false;

            const successMessage =
              this.langue === "fr"
                ? "Groupe de service mis à jour avec succès"
                : "Service group updated successfully";

            this.notyService.displayNotification(successMessage, "success");
          },
          (error) => {
            console.error("Error updating service:", error);
            this.notyService.displayNotification("Failed update", "error");
          }
        );
    } else {
      this.crudService
        .post(OPN_BASE_URL + "/serviceGroup/add", formValue)
        .subscribe(
          (response) => {
            this.itemAdded.emit();
            this.serviceGroupForm.reset();
            this.submitted = false;
            this.isOpen = false;
            const successMessage =
              this.langue === "fr"
                ? "Groupe de service ajouté avec succès"
                : "Service Group added successfully";

            this.notyService.displayNotification(successMessage, "success");
          },
          (error) => {
            console.error("Error adding service:", error);
            this.notyService.displayNotification("Failed Add", "error");
          }
        );
    }
    this.activeModal.close();
  }

  /**
   * Returns the form controls for validation.
   * @returns The form controls.
   */

  get s() {
    return this.serviceGroupForm.controls;
  }

  /**
   * Cancels the form, resets the data, and closes the modal.
   */

  cancelForm() {
    this.serviceGroupForm.reset();
    this.submitted = false;
    this.activeModal.close();
  }

  /**
   * Retrieves the selected trips and adds route details to each trip.
   *
   * @returns The list of selected trips with route details.
   */

  getSelectedTrips() {
    this.missingAssociatedTrips = [];
    const selectedTripIds = this.serviceGroupForm.get("trips")?.value;

    if (selectedTripIds == null || selectedTripIds.length === 0) {
      return [];
    }

    const addedAssociatedTrips: Set<number> = new Set();
    this.selectedTrips = this.trips
      .filter((trip) => selectedTripIds.includes(trip.id))
      .map((trip) => {
        return this.processTrip(trip, selectedTripIds, addedAssociatedTrips);
      })

      // Sort selected trips by departure time (handle null values)
      .sort((a: any, b: any) => {
        if (!a.departure) return 1; // If a.departure is null, put it at the end
        if (!b.departure) return -1; // If b.departure is null, put it at the end

        const [aHours, aMinutes] = a.departure.split(":").map(Number);
        const [bHours, bMinutes] = b.departure.split(":").map(Number);

        const aTime = aHours * 60 + aMinutes; // Convert to total minutes
        const bTime = bHours * 60 + bMinutes;

        return aTime - bTime; // Sort in ascending order
      });

    this.hasOverlap = this.hasOverlapInSelectedTrips();

    return this.selectedTrips;
  }

  hasOverlapInSelectedTrips(): boolean {
    const selectedTrips = this.selectedTrips;

    for (let i = 0; i < selectedTrips.length; i++) {
      const departureTime1 = selectedTrips[i].departure.split(":").map(Number);
      const arrivalTime1 = selectedTrips[i].arrival.split(":").map(Number);

      const start1 = new Date();
      const end1 = new Date();

      start1.setHours(
        departureTime1[0],
        departureTime1[1],
        departureTime1[2],
        0
      );
      end1.setHours(arrivalTime1[0], arrivalTime1[1], arrivalTime1[2], 0);

      for (let j = i + 1; j < selectedTrips.length; j++) {
        const departureTime2 = selectedTrips[j].departure
          .split(":")
          .map(Number);
        const arrivalTime2 = selectedTrips[j].arrival.split(":").map(Number);

        const start2 = new Date();
        const end2 = new Date();

        start2.setHours(
          departureTime2[0],
          departureTime2[1],
          departureTime2[2],
          0
        );
        end2.setHours(arrivalTime2[0], arrivalTime2[1], arrivalTime2[2], 0);

        if (
          (start1 < end2 && end1 > start2) ||
          (start1.getTime() === start2.getTime() &&
            end1.getTime() === end2.getTime())
        ) {
          return true; // Overlap detected
        }
      }
    }

    return false; // No overlap detected
  }

  /**
   * Processes a trip by associating it with its route details and checking for associated trips.
   * If an associated trip is not selected and not already added, it is added to the missingAssociatedTrips list.
   *
   * @param trip - The trip object to be processed.
   * @param selectedTripIds - Array of selected trip IDs.
   * @param addedAssociatedTrips - Set of IDs of trips that have already been checked and added to the missingAssociatedTrips list.
   * @returns A new trip object with additional route details.
   */

  processTrip(
    trip: any,
    selectedTripIds: any,
    addedAssociatedTrips: Set<number>
  ) {
    if (this.routes != null) {
      const route = this.routes.find((route) => route.id === trip.idRoute);
      const associatedTripCode = trip.code.includes("A")
        ? trip.code.replace("A", "R")
        : trip.code.replace("R", "A");
      const associatedTrip = this.trips.find(
        (t: any) => t.code === associatedTripCode
      );

      if (
        !selectedTripIds.includes(associatedTrip.id) &&
        !addedAssociatedTrips.has(associatedTrip.id)
      ) {
        this.missingAssociatedTrips.push(associatedTrip);
        addedAssociatedTrips.add(associatedTrip.id);
      }
      return {
        ...trip,
        routeNum: route ? route.number : null,
        routeName: route ? route.name : null,
      };
    }
  }

  /**
   * Returns the appropriate card title based on the edit mode.
   *
   * @returns The card title.
   */

  getCardTitle(): string {
    return this.isEdit ? "UPDATE_SERVICE_GROUP" : "ADD_SERVICE_GROUP";
  }

  addTripToSelected(tripId: number) {
    const tripsControl = this.serviceGroupForm.get("trips");
    if (tripsControl) {
      const currentTrips = tripsControl.value || [];
      if (!currentTrips.includes(tripId)) {
        tripsControl.setValue([...currentTrips, tripId]);
      }
    }
  }

  isFormValidWithoutOverlap(): boolean {
    return (
      this.serviceGroupForm.get("name")?.valid &&
      this.serviceGroupForm.get("trips")?.value?.length > 0 &&
      this.serviceGroupForm.get("flatRateHours")?.valid
    );
  }
}
