import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AbstractFormElement} from '../abstract-form-elements';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent extends AbstractFormElement implements OnChanges {
  @Input() data: any[];
  @Input() multiple = false;
  @Input() labelField: string;
  @Input() valueField: string;
  @Input() prepareLabelFieldFunction: (item) => string;
  values = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (this.labelField && this.data) {
      if (this.labelField.includes('&')) {
        const fields = this.labelField.split('&');
        this.data.forEach(d => {
          let result = '';
          fields.forEach(field => {
            if (field.includes('"')) {
              while (field.includes('"')) {
                field = field.replace('"', '');
              }
              result += field + ' ';
            } else if (field.includes('.')) {
              const split_fields = field.split('.');
              let r = d[split_fields[0]];
              for (let i = 1; i < split_fields.length; i++) {
                r = r[split_fields[i]];
              }
              result += r + ' ';
            } else if (field.includes('?') && field.includes(':')) {
              result += (d[field.split('?')[0]] ? field.split('?')[1].split(':')[0] : field.split(':')[1]);
            } else {
              result += d[field] + ' ';
            }
          });
          d.label_to_show_in_select = result.trim();
        });
        this.labelField = 'label_to_show_in_select';
      }
    }
    if (this.valueField && this.data) {
      this.data.forEach(d => {
          if (this.valueField.includes('.')) {
            const split_fields = this.valueField.split('.');
            let r = d[split_fields[0]];
            for (let i = 1; i < split_fields.length; i++) {
              r = r[split_fields[i]];
            }
            this.values.push(r);
          } else {
            this.values.push(d[this.valueField]);
          }
        }
      );
    }

  }
}
